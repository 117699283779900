<template>
  <div>
    <!-- 商户管理page页面 -->
    <a-card title="商户管理" style="margin: 20px">
      <!-- 筛选区域 -->
      <div class="selectBox">
        <div class="item">
          <span>所属机构</span>
          <DepartmentChoose :department-code.sync="paramsData.departmentCode" @change="handleQuery"></DepartmentChoose>
        </div>
        <div class="item">
          <span>商户编号</span>
          <a-input v-model.trim="paramsData.mchNo" placeholder="请输入商户编号" />
        </div>
        <div class="item">
          <span>商户名称</span>
          <a-input v-model.trim="paramsData.mchName" placeholder="请输入商户名称" />
        </div>
<!--        <div class="item">-->
<!--          <span>客户名称</span>-->
<!--          <a-input v-model.trim="paramsData.custname" placeholder="请输入客户名称" />-->
<!--        </div>-->
        <!-- 回款周期/修改 -->
<!--        <div class="item">-->
<!--          <span>回款周期</span>-->
<!--          <a-select v-model.trim="paramsData.orderType">-->
<!--            <a-select-option value=""> 全部 </a-select-option>-->
<!--            <a-select-option value="T+22"> 22天 </a-select-option>-->
<!--            <a-select-option value="T+10"> 10天 </a-select-option>-->
<!--            <a-select-option value="T+1"> 1天 </a-select-option>-->
<!--          </a-select>-->
<!--        </div>-->
        <!-- 贷款产品 -->
<!--        <div class="item">-->
<!--          <span>贷款产品</span>-->
<!--          <a-select placeholder="全部" @change="changeProductId" v-model="loadProductId" allowClear>-->
<!--            <a-select-option v-for="item in loanProductList" :key="item.id" :value="item.id" >-->
<!--              {{ item.productName }}-->
<!--            </a-select-option>-->
<!--          </a-select>-->
<!--        </div>-->
        <!-- 客户类型 -->
<!--        <div class="item">-->
<!--          <span>客户类型</span>-->
<!--          <a-select v-model="paramsData.custtypecd">-->
<!--            <a-select-option value=""> 全部 </a-select-option>-->
<!--            <a-select-option value="1"> 个人</a-select-option>-->
<!--            <a-select-option value="2"> 企业 </a-select-option>-->
<!--          </a-select>-->
<!--        </div>-->
        <!-- 商户类型 -->
        <div class="item">
          <span>商户类型</span>
          <a-select v-model="paramsData.mchType">
            <a-select-option value=""> 全部 </a-select-option>
            <a-select-option value="0"> <a-tag color="blue">回款商户</a-tag></a-select-option>
            <a-select-option value="1"> <a-tag color="purple">融资商户</a-tag></a-select-option>
          </a-select>
        </div>
        <!-- 产品类型 -->
        <div class="item">
          <span>商品类型</span>
          <a-select v-model="paramsData.goodsType">
            <a-select-option value=""> 全部 </a-select-option>
            <a-select-option value="10"> 商品类</a-select-option>
            <a-select-option value="11"> 商品类(特殊）</a-select-option>
            <a-select-option value="20"> 娱乐类 </a-select-option>
            <a-select-option value="30"> 资金类 </a-select-option>
            <a-select-option value="40"> 代销类 </a-select-option>
            <a-select-option value="41"> 代销类(特殊) </a-select-option>
            <a-select-option value="50"> 权益类 </a-select-option>
          </a-select>
        </div>
        <!-- 是否同步 -->
<!--        <div class="item">-->
<!--          <span>同步状态</span>-->
<!--          <a-select v-model="paramsData.synch">-->
<!--            <a-select-option value=""> 全部 </a-select-option>-->
<!--            <a-select-option value="1"> 是</a-select-option>-->
<!--            <a-select-option value="0"> 否 </a-select-option>-->
<!--          </a-select>-->
<!--        </div>-->
        <div class="item">
          <a-button type="primary" @click="handleQuery" icon="search">查询</a-button>
          <a-button @click="handleReset">重置</a-button>
        </div>
      </div>
      <!-- 表格部分 -->
      <div class="tableBox">
        <!-- 操作按钮 -->
        <div class="handleBtn">
          <a-button type="primary" v-if="$authority('mchAdd')">
            <router-link :to="{ name: 'AddMchManage', query: { type: 'add' } }" target="_self"
              rel="opener">新增</router-link>
          </a-button>
          <a-button type="primary" :disabled="selectedRowKeys.length==0 || selectCustData[0]?.checkStatus=='1'" @click="handleBtn('edit')"
                    icon="edit" v-if="$authority('mchEdit')">编辑</a-button>
          <a-button type="primary" :disabled="!selectedRowKeys.length" @click="handleBtn('view')"
                    icon="eye" v-if="$authority('mchView')">查看</a-button>
          <a-button type="danger" :disabled="!selectedRowKeys.length" @click="handleBtn('del')"
                    icon="delete" v-if="$authority('mchDel')">删除</a-button>
          <!-- <a-button type="primary" @click="approve" v-if="$authority('mchPush')">审批</a-button> -->
          <a-button type="primary" :disabled="selectedRowKeys.length==0" @click="approve" v-if="$authority('mchAudit')"
          icon="audit">审批</a-button>

        </div>
        <!-- 表格 -->
        <div class="tableBody">
          <a-table size="small" :scroll="{ x: 2000, }" :row-selection="{
            selectedRowKeys: selectedRowKeys,
            onChange: onSelectChange,
          }" :columns="columns" :customRow="clickrow" :data-source="data" :pagination="false"
            :rowKey="(record, index) => `${record.id}`">
          </a-table>
        </div>
      </div>
      <!-- 分页部分 -->
      <div class="paginationBox">
        <a-button class="Homepage" :disabled="Number(paramsData.pageNo) == 1" @click="Homepage">
          首页
        </a-button>
        <a-pagination v-model.trim="paramsData.pageNo" :page-size-options="pageSizeOptions" :total="total"
          show-size-changer :page-size="paramsData.pageSize" @change="handleChangePage" @showSizeChange="onShowSizeChange"
          :item-render="itemRender">
        </a-pagination>
        <a-button @click="Trailingpage"
          :disabled="paramsData.pageNo == Math.ceil(Number(total) / Number(paramsData.pageSize))" class="Trailingpage">
          尾页
        </a-button>
      </div>
      <!-- 删除弹框 -->
      <a-modal title="提示" :visible="isVisibleDelDialog" @cancel="isVisibleDelDialog = false" :centered="true"
        :body-style="{ textAlign: 'center' }">
        <template slot="footer">
          <a-button type="primary" @click="handleOk">确认</a-button>
          <a-button @click="isVisibleDelDialog = false">取消</a-button>
        </template>
        <p>是否确认删除所勾选数据？删除后数据将不可恢复</p>
      </a-modal>
    </a-card>
  </div>
</template>
<script>
import { message } from "ant-design-vue";
import { BizDict, BizDictCascade } from "@/utils/bizDict/kvMap";
import { handleChannelNo } from '@/utils/commonMethods'
import WalletInfo from '@/view/components/WalletInfo.vue'
import DepartmentChoose from "@/view/components/DepartmentChoose.vue";
import DeptInfo from "@/view/components/DeptInfo.vue";

const data = [];
export default {
  components: {DepartmentChoose, WalletInfo },
  name: "MchManage",
  data() {
    const columns = [
      {
        title: "No",
        customRender: (text, record, index) =>
          `${Number(this.paramsData.pageNo - 1) *
          Number(this.paramsData.pageSize) +
          Number(index + 1)
          }`,
        align: "center",
        width: 60,
      },
      {
        title: "渠道",
        dataIndex: "channelNo",
        align: "center",
        width: 80,
        customRender: (text) => {
          return handleChannelNo(JSON.parse(this.$session.getItem('coreCompanyList')), text, '1')
        },
      },
      {
        title: "类型",
        dataIndex: "mchType",
        align: "center",
        width: 60,
        customRender: (text) => {
          if (text === "0") {
            return <a-tag color="blue">回款</a-tag>
          } else if (text === "1") {
            return <a-tag color="purple">融资</a-tag>
          } else {
            return "";
          }
        },
      },
      {
        title: "商户编号",
        dataIndex: "mchNo",
        align: "center",
        width: 120,
      },
      {
        title: "商户",
        dataIndex: "mchName",
        align: "center",
        // width: 260,
      },
      // {
      //   title: "客户编号",
      //   dataIndex: "custnum",
      //   align: "center",
      //   width: 230,
      //   ellipsis: true,
      //   customRender: (text) => {
      //     return text != null ? text : '---'
      //   }
      // },
      {
        title: "商户主体",
        dataIndex: "accountName",
        align: "center",
        // width: 260,
        customRender: (text, row) => {
          if (row.custtypecd === "2") {
            return <span><a-tag>企</a-tag>{text}</span>
          } else {
            return text
          }
        }
      },
      {
        title: "主体钱包",
        dataIndex: "accountNoA1",
        width: 220,
        ellipsis: true,
        align: "center",
        customRender: (text, row) => {
          return <WalletInfo accountNo={text} accountClass='0' accountName={row.accountName}></WalletInfo>
        }
      },
      {
        title: "回款商户",
        dataIndex: "fatherName",
        width: 260,
        ellipsis: true,
        align: "center",
      },
      {
        title: "商品类型",
        dataIndex: "goodsType",
        align: "center",
        width: 100,
        ellipsis: true,
        customRender: (text) => {
          if (text === "10") {
            return "商品(特殊）";
          }else if (text === "11") {
            return "商品";
          } else if (text === "20") {
            return "娱乐";
          } else if (text === "30") {
            return "资金";
          } else if (text === "40") {
            return "代销";
          } else if (text === "41") {
            return "代销(特殊)";
          }else if (text === "50") {
            return "权益";
          }
          else {
            return "--";
          }
        },
      },
      {
        title: "回款周期",
        dataIndex: "orderType",
        align: "center",
        width: 80,
        customRender: (text) => {
          if(text?.startsWith("T+")){
            return text.substring(2) + "天"
          }else{
            return "手动"
          }
        },
      },

      {
        title: "贷款期限",
        dataIndex: "period",
        align: "center",
        width: 80,
        customRender: (text) => {
          return !text ? "---" : text + "天"
        },
      },
      {
        title: "审批状态",
        dataIndex: "checkStatus",
        align: "center",
        width: 80,
        customRender: (text, row) => {
          if(row.mchType === '0'){
            return ""
          }else if (text === '0') {
            return <a-tag color="blue">待审批</a-tag>
          } else if (text === '1') {
            return <a-tag color="green">审批通过</a-tag>
          } else if (text === '2') {
            return <a-tag color="red">审批拒绝</a-tag>
          } else if (text === '3') {
            return <a-tag color="red">审批失败</a-tag>
          } else {
            return "";
          }
        },
      },
      {
        title: "机构",
        dataIndex: "departmentId",
        align: "center",
        customRender: (text) => {
          return <DeptInfo dept-id={text}></DeptInfo>
        },
      },
    ];
    return {
      loadProductId: undefined,
      // 机构
      treeData: [],
      departmentData: {},
      data,
      columns,
      selectedRowKeys: [],
      pageSizeOptions: ["10", "20", "30", "40", "50"],
      total: 0,
      // 筛选参数
      paramsData: {
        departmentId: undefined,
        departmentCode: undefined,
        // 当前页，
        pageNo: 1,
        // 一页多少条
        pageSize: 10,
        // 商户编号
        mchNo: "",
        // 商户名称
        mchName: "",
        // 客户名称
        custname: "",
        // 回款周期(订单类型)
        orderType: "",
        // 贷款产品编号
        productId: undefined,
        // 客户类型
        custtypecd: "",
        // 商户类型
        mchType: "1",
        // 产品类型
        goodsType: "",
        // 同步状态
        synch: '',
      },
      // 贷款产品数组
      loanProductList: [],
      // 选中的客户数组
      selectCustData: [],
      isVisibleDelDialog: false
    };
  },
  created() {
    if(this.$route.query?.mchNo){
      this.paramsData.mchNo = this.$route.query.mchNo
    }    this.busiDict = { ...BizDict, ...BizDictCascade };
    // 获取机构列表
    // 存到store,代表要缓存的组件
    // this.$store.commit('addKeepAlive', 'MchManage')
  },

  mounted() {
    // console.log('mounted');
    if (sessionStorage.getItem('msgInfo')) {
      // 从store里读取筛选条件
      this.paramsData = this.$store.state.searchMsgInfo
    }
    // 获取表格
    // this.paramsData.departmentId = sessionStorage.getItem("companyId");

    this.getTableData();
    this.getLoanProduct();
  },

  async activated() {
    if (this.$route.query.fromAddPage || this.$route.query.fromEditPage || this.$route.query.mchNo) {
      if(this.$route.query.mchNo){
        this.paramsData.mchNo = this.$route.query.mchNo
      }
      let data = {
        pageNo: this.$route.query.fromAddPage ? 1 : this.paramsData.pageNo,
        pageSize: 10,
        obj: this.$route.query.fromAddPage ? {} : this.paramsData
      }
      const res = await this.api.getEcListApi(data);
      if (res.code == 200) {
        this.data = res.data.records;
        this.paramsData.pageNo = res.data.current;
        this.paramsData.pageSize = res.data.size;
        this.total = res.data.total;
      } else {
        return message.error(res.info);
      }
    }
  },
  deactivated() {
    sessionStorage.removeItem('msgInfo')

  },
  destroyed() {
    sessionStorage.removeItem('msgInfo')
  },
  methods: {
    // 获取表格数据
    async getTableData() {
      console.info(this.paramsData)
      const res = await this.api.getEcListApi({
        pageNo: this.paramsData.pageNo,
        pageSize: this.paramsData.pageSize,
        obj: this.paramsData
      });
      if (res.code == 200) {
        this.data = res.data.records;
        this.paramsData.pageNo = res.data.current;
        this.paramsData.pageSize = res.data.size;
        this.total = res.data.total;
      } else {
        return message.error(res.info);
      }
    },
    // 获取贷款产品
    async getLoanProduct() {
      let data = {};
      // data.departmentId = this.paramsData.departmentId;
      let res = await this.api.creditProductInfolistApi(data);
      if (res.code == 200) {
        // console.log(res, "resdepartment");
        this.loanProductList = res.data;
      }
    },
    // 查询
    handleQuery() {
      this.selectCustData = []
      this.selectedRowKeys = []
      this.paramsData.pageNo = 1;
      this.getTableData();
    },
    // 重置
    handleReset() {
      this.selectCustData = []
      this.selectedRowKeys = []
      this.loadProductId = undefined
      this.paramsData = {
        departmentId: undefined,
        // 当前页，
        pageNo: 1,
        // 一页多少条
        pageSize: 10,
        // 商户编号
        mchNo: "",
        // 商户名称
        mchName: "",
        // 客户名称
        custname: "",
        // 回款周期(订单类型)
        orderType: "",
        // 贷款产品编号
        productId: undefined,
        // 客户类型
        custtypecd: "",
        // 商户类型
        mchType: "",
        // 产品类型
        goodsType: "",
        // 同步状态
        synch: '',
      };
      this.getTableData();
    },
    // 操作事件
    handleBtn(handlleType) {

      if (handlleType == 'edit' || handlleType == 'view') {
        if (this.selectCustData.length != 1) {
          return message.warning("只能选择一个客户!");
        } else {
          // 存储筛选条件
          this.$store.commit('saveSearchMsgInfo', this.paramsData)
          this.$router.push({
            name: "AddMchManage",
            query: {
              id: this.selectCustData[0].id,
              type: handlleType == 'edit' ? 'edit' : 'view',
            },
          });
        }
      } else if (handlleType == 'del') {
        // 删除操作
        if (this.selectCustData.length === 0) {
          return message.warning("请选择客户!");
        } else {
          this.isVisibleDelDialog = true;
        }
      }

    },
    // 审批
    approve() {
      if (this.selectCustData.length == 0) {
        return message.warning('请选择一个客户!')
      } else if (this.selectCustData.length > 1) {
        return message.warning('只能选择一个客户!')
      }
      // 跳转页面
      this.$router.push({
        name: "AddMchManage",
        query: {
          id: this.selectCustData[0].id,
          type: 'approve',
        },
      });
    },
    // 确认删除
    async handleOk() {
      // console.log(this.selectCustData, 'selectCustData');
      let idArr = []
      this.selectCustData.forEach(item => {
        idArr.push(item.id)
      })
      let res = await this.api.delMchManageDataApi(idArr)
      if (res.code == 200) {
        this.$message.success(res.info);
      }
      this.selectCustData = []
      this.selectedRowKeys = []
      this.isVisibleDelDialog = false;
      this.getTableData()
    },
    // 选中表格的事件函数
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys.splice(-1,1)
      this.selectCustData = selectedRows.splice(-1,1)
    },
    clickrow(record) {
      return {
        on: {
          click: () => {
            if(this.selectedRowKeys[0] === record.id.toString()){
              this.selectedRowKeys = []
              this.selectCustData = []
            }else{
              this.selectedRowKeys = [record.id.toString()]
              this.selectCustData = [record]
            }
          }
        }
      }
    },
    // 改变当前页的事件函数
    // 改变pagesize的事件函数
    onShowSizeChange(current, pageSize) {
      // console.log(current);
      // console.log(pageSize);
      this.selectCustData = []
      this.selectedRowKeys = []
      this.paramsData.pageNo = current;
      this.paramsData.pageSize = pageSize;
      this.selectedRowKeys = [];
      this.getTableData();
    },
    // 改变当前页的事件
    handleChangePage(page) {
      this.selectCustData = []
      this.selectedRowKeys = []
      this.paramsData.pageNo = page;
      this.selectedRowKeys = [];
      this.getTableData();
    },
    // 首页
    Homepage() {
      if (this.total == 0) {
        return
      }
      this.selectCustData = []
      this.selectedRowKeys = []
      this.paramsData.pageNo = 1,
        this.getTableData()
    },
    // 尾页
    Trailingpage() {
      if (this.total == 0) {
        return
      }
      this.selectCustData = []
      this.selectedRowKeys = []
      this.paramsData.pageNo = Math.ceil(Number(this.total) / Number(this.paramsData.pageSize))
      this.getTableData()
    },
    // 修改上一页和下一页为文字链接
    itemRender(current, type, originalElement) {
      if (type === "prev") {
        return <a>上一页</a>;
      } else if (type === "next") {
        return <a>下一页</a>;
      }
      return originalElement;
    },
  },
};
</script>
<style lang="less" scoped>
/deep/.ant-card-body {
  padding: 24px 34px;
}

.selectBox {
  display: flex;
  flex-wrap: wrap;

  /deep/ .item {
    margin-bottom: 20px;
    margin-right: 20px;

    .ant-input {
      width: 200px;
      margin-left: 20px;
    }

    .ant-select {
      width: 200px;
      margin-left: 20px;
    }
  }
}

.tableBox {
  margin-bottom: 20px;

  .handleBtn {
    margin-bottom: 20px;
  }
}

/deep/.paginationBox {
  // float: right;
  display: flex;
  margin-top: 20px;
  justify-content: flex-end;

  // .ant-pagination-options {
  //   float: left;
  // }
  .ant-pagination-prev,
  .ant-pagination-next,
  .ant-pagination-jump-prev,
  .ant-pagination-jump-next {
    border: 1px solid #d9d9d9;
    width: 60px;
  }
}
</style>
