export const mchshopinvoiceDict = {
    settleStatus: [{
        text: '未清算',
        value: '0',
        color: 'red'
    }, {
        text: '已清算',
        value: '1',
        color: 'green'
    }, {
        text: '部分清算',
        value: '2',
        color: 'orange'
    }],
    matchStatus: [{
        text: '未到账',
        value: '0',
        color: 'red'
    }, {
        text: '已到账',
        value: '1',
        color: 'green'
    }, {
        text: '部分到账',
        value: '2',
        color: 'orange'
    }],
    invalidStatus: [{
        text: '有效',
        value: '0',
        color: 'green'
    }, {
        text: '作废',
        value: '1',
        color: 'red'
    }],
    transItemStatus: [{
        text: '待清算',
        value: -1,
        color: '#008CFF'
    },{
        text: '可清算',
        value: 0,
        color: '#52C41A'
    }, {
        text: '已清算',
        value: 1,
        color: '#5522AA'
    }, {
        text: '无发票',
        value: 2,
        color: '#A0A0A0'
    }, {
        text: '金额错',
        value: 3,
        color: '#FF4D4F'
    }],
    transItemStatusMap : {
        '-1': { text: '待清算', color: '#008CFF' },
        '0': { text: '可清算', color: '#52C41A' },
        '1': { text: '已清算', color: '#5522AA' },
        '2': { text: '无发票', color: '#A0A0A0' },
        '3': { text: '金额错', color: '#FF4D4F' },
    }

}