<template>
  <div>
    <a-card style="margin: 20px">
      <template #title>
        <div class="top-title">
          <div class="left">
            <span> 归集协议</span>
            <a-icon type="right"/>
            <span>登记</span>
          </div>
          <!-- 隐私协议 -->
          <div style="display: flex;align-items: center;">
            <div class="agreement">
              <div class="item">
                <a-checkbox v-model="Userauthorization">
                  本人已阅读并同意
                  <a-tooltip>
                    <template slot="title"> 点击下载</template>
                    <a-button type="link" size="small" @click="downLoadTemplate('TMPL_CZCB_PWVC')">{{
                        mapMapList("TMPL_CZCB_PWVC").state
                      }}
                    </a-button>
                  </a-tooltip>

                </a-checkbox>
              </div>
            </div>
            <div class="right">
              <!-- 确认 -->
              <a-button type="primary" @click="confirm"> 确认</a-button>
              <!-- 取消 -->
              <a-button @click="cancel"> 取消</a-button>
            </div>
          </div>

        </div>
      </template>


      <div class="Individualaccountopening">

        <!-- 基础信息 -->
        <div v-if="!$route.query.status">


          <a-form-model ref="ruleForm" :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
            <div class="toptitle">基础信息*</div>
            <a-form-model-item ref="accountNo" class="accountNo" label="代扣账户" prop="accountNo">
              <WalletChooseDialog v-model="form.accountName" :searchable="true" account-class="6" :openAccountClass="true"
                                  @chooseDone="onWalletChosen">
              </WalletChooseDialog>
              <WalletInfo v-if="form.accountNo" :account-no="form.accountNo" :account-class="form.accountClass"
                          :account-name="form.accountName"></WalletInfo>
            </a-form-model-item>

            <a-form-model-item ref="oppAccountNo" class="oppAccountNo" label="归集账户" prop="oppAccountNo">
              <WalletChooseDialog v-model="form.oppAccountName" :searchable="true" account-class="0" :openAccountClass="true"
                                  @chooseDone="onOppWalletChosen">
              </WalletChooseDialog>
              <WalletInfo v-if="form.oppAccountNo" :account-no="form.oppAccountNo" :account-class="form.oppAccountClass"
                          :account-name="form.oppAccountName"></WalletInfo>
            </a-form-model-item>
            <a-form-model-item label="自动归集">
              <a-switch v-model="form.auto"></a-switch>
            </a-form-model-item>
            <a-form-model-item label="需要小额打款认证">
              <a-switch v-model="form.needAuth"></a-switch>
            </a-form-model-item>
            <div class="toptitle">手续费信息1</div>

            <a-form-model-item ref="feeAccountNo" class="feeAccountNo" label="收取账户" prop="accountNo">
              <WalletChooseDialog v-model="form.feeAccountName" :searchable="true" account-class="0" :openAccountClass="true"
                                  @chooseDone="onWalletFeeChosen">
              </WalletChooseDialog>
              <WalletInfo v-if="form.feeAccountNo" :account-no="form.feeAccountNo" :account-class="form.feeAccountClass"
                          :account-name="form.feeAccountName"></WalletInfo>
            </a-form-model-item>

            <a-form-model-item label="服务费规则" prop="feeRuleNo">
              <a-select @change="handleChange($event)" v-model="form.feeRuleName" placeholder="请选择服务费规则">
                <a-select-option v-for="item in feeRuleList" :key="item.feeNo" :value="item.feeNo">{{
                    item.feeName
                  }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <div class="toptitle">手续费信息2</div>
            <a-form-model-item ref="feeAccountNo2" class="feeAccountNo2" label="收取账户" prop="accountNo">
              <WalletChooseDialog v-model="form.feeAccountName2" :searchable="true" account-class="0" :openAccountClass="true"
                                  @chooseDone="onWalletFeeChosen2">
              </WalletChooseDialog>
              <WalletInfo v-if="form.feeAccountNo2" :account-no="form.feeAccountNo2" :account-class="form.feeAccountClass2"
                          :account-name="form.feeAccountName2"></WalletInfo>
            </a-form-model-item>

            <a-form-model-item label="服务费规则" prop="feeRuleNo2">
              <a-select @change="handleChange2($event)" v-model="form.feeRuleName2" placeholder="请选择服务费规则">
                <a-select-option v-for="item in feeRuleList" :key="item.feeNo" :value="item.feeNo">{{
                    item.feeName
                  }}
                </a-select-option>
              </a-select>
            </a-form-model-item>

          </a-form-model>
        </div>
      </div>
    </a-card>

    <!-- 确认 -->
    <a-modal title="提示" class="confirmloading" :maskClosable="true" @cancel="confirmshow = false" :centered="true"
             :visible="confirmshow">
      <div class="text">是否确认添加归集协议？</div>
      <template v-slot:footer>
        <!-- 提现 -->
        <div class="custom-footer">
          <a-button type="primary" @click="ConfirmSave">保存</a-button>
          <a-button @click="confirmshow = false">取消</a-button>
          <a-button @click="ConfirmQRPush">邀请扫码办理</a-button>
        </div>
      </template>
    </a-modal>
    <QRConfirm :url="qrConfirmUrl" :display.sync=qrConfirmShow busi_intro="电子账户代扣协议签约" :username="this.form.accountName"></QRConfirm>

  </div>
</template>
<script>
import {BizDict} from '../../../utils/bizDict/kvMap.js'

import WalletChooseDialog from "@/view/components/ChooseDialog/WalletChooseDialog.vue";
import WalletInfo from '@/view/components/WalletInfo.vue'
import {contractDocTemp} from "@/utils/contractDocTemp";
import QRConfirm from "@/view/components/QRConfirm.vue";

export default {
  name: 'payWithholdAdd',
  data() {
    return {
      // 服务费规则列表
      feeRuleList: [],

      Userauthorization: false, // 用户协议是否通过
      confirmshow: false,
      qrConfirmShow: false,
      qrConfirmUrl: "",
      labelCol: {span: 3},
      wrapperCol: {span: 21},
      form: {
        appNo: '',
        channelNo: '',
        mobile: '',
        accountNo: '', // 付款账户账号
        accountName: '', // 付款账户户名
        accountClass: '', // 付款账户账户类型

        oppAccountNo: '', // 收款账户账号
        oppAccountName: '', // 收款账户户名
        oppBankNo: '313338707013', // 收款账户银行行号-
        oppBankName: '浙江稠州商业银行', // 收款账户银行名称

        feeAccountNo: '', // 手续费账户账号
        feeAccountName: '', // 手续费账户户名
        feeAccountClass: '', // 手续费账户账户类型

        feeRuleName: '', // 项目名称
        feeRuleNo: '', // 项目编号


        feeAccountNo2: '', // 手续费账户账号
        feeAccountName2: '', // 手续费账户户名
        feeAccountClass2: '', // 手续费账户账户类型

        feeRuleName2: '', // 项目名称
        feeRuleNo2: '', // 项目编号

        needAuth: false, // 需要小额认证
        auto: false, // 自动归集
      },
    }
  },
  components: {
    QRConfirm,
    WalletChooseDialog,
    WalletInfo,
  },
  async created() {
    this.getFeeRule()
  },
  mounted() {
    if (this.$route.query.status) {
      sessionStorage.setItem('msgInfo', true)
    }
  },
  methods: {
    // 获取服务费规则
    async getFeeRule() {
      let data = {}
      let res = await this.api.getCommisionFeeRuleApi(data)
      if (res.code == 200) {
        this.feeRuleList = res.data.filter(item => item.feeType !== '4');
      }
    },

    // 选中事件
    handleChange(event) {
      // 服务规则
      this.feeRuleList.forEach(item => {
        if (item.feeNo == event) {
          this.form.feeRuleNo = item.feeNo
          this.form.feeRuleName = item.feeName
        }
      })
    },
    // 选中事件
    handleChange2(event) {
      // 服务规则
      this.feeRuleList.forEach(item => {
        if (item.feeNo == event) {
          this.form.feeRuleNo2 = item.feeNo
          this.form.feeRuleName2 = item.feeName
        }
      })
    },

    mapMapList(code) {
      let obj = "";
      this.$store.state.getMapList.forEach((item) => {
        if (item.code === code) {
          obj = item;
        }
      });
      return obj;
    },
    // 状态筛选
    customRender: (text) => {
      return BizDict.LoanAppStatus.find(item => item.value === text)?.label || "---"
    },

    onWalletChosen: async function (wallet) {
      console.log("wallet", wallet)
      this.form.accountNo = wallet.accountNo
      this.form.accountName = wallet.accountName
      this.form.accountClass = wallet.accountClass
      this.form.mobile = wallet.mobile
      this.form.appNo = wallet.appNo
      this.form.channelNo = wallet.channelNo

      const fee = JSON.parse(JSON.stringify(this.$store.state.payWithholdFeeTpl[wallet.channelNo]))
      if (fee) {
        this.form.feeAccountNo = fee[0].feeAccountNo
        this.form.feeAccountName = fee[0].feeAccountName
        this.form.feeAccountClass = fee[0].feeAccountClass
        this.form.feeRuleName = fee[0].feeRuleName
        this.form.feeRuleNo = fee[0].feeRuleNo
      }

    },

    async onOppWalletChosen(wallet) {
      this.form.oppAccountNo = wallet.accountNo
      this.form.oppAccountName = wallet.accountName
      this.form.oppAccountClass = wallet.accountClass
    },

    async onWalletFeeChosen(wallet) {
      this.form.feeAccountNo = wallet.accountNo
      this.form.feeAccountName = wallet.accountName
      this.form.feeAccountClass = wallet.accountClass
    },

    async onWalletFeeChosen2(wallet) {
      this.form.feeAccountNo2 = wallet.accountNo
      this.form.feeAccountName2 = wallet.accountName
      this.form.feeAccountClass2 = wallet.accountClass
    },


    // 保存
    async ConfirmSave() {

      const res = await this.api.payWithholdContractRegister(this.form)
      if (res.code == 200) {
        this.$message.success('登记成功,等待签约')
        this.$router.push({path: '/orders/payWithhold', query: {}});
      } else {
        this.$message.error(res.info)
      }
    },

    async ConfirmQRPush() {
      delete this.form.departmentId;

      const res = await this.api.custOpenAccTaskSave({
        channelNo: this.form.channelNo,
        appNo: this.form.appNo,
        taskType: 4,
        name: this.form.accountName,
        mobile: this.form.mobile,
        cardNo: this.form.accountNo,
        reqData: this.form,
        exData: {
          "授权代扣账户": this.form.accountName + "-" + this.form.accountNo,
          "归集目标账户": this.form.oppAccountName + "-" + this.form.oppAccountNo,
        },
      });
      if (res.code !== 200) {
        this.$message.error(res.info);
        return;
      }
      this.confirmshow = false;
      this.qrConfirmUrl = res.data
      this.qrConfirmShow = true

    },

    // 取消
    cancel() {
      this.$router.push({path: '/orders/payWithhold', query: {}});
    },


    // 下载模板
    async downLoadTemplate(type) {
      contractDocTemp.call(this, type, this.api, this.$message);
    },


    // 点击校验
    confirm() {
      if (!this.Userauthorization) {
        this.$message.warning(
            `请先同意${this.mapMapList("TMPL_CZCB_PWVC").state} }`
        );
        return;
      }
      this.confirmshow = true;
    }


  },
}
</script>
<style>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
</style>

<style lang="less" scoped>
.ant-table-thead > tr > th {
  background-color: #f2f2f2;
}

/deep/ .ant-modal-footer {
  width: 100%;
  border: none;
  padding-bottom: 20px;

  text-align: center;
}

/deep/ .ant-card-head {
  position: sticky;
  top: 0px;
  z-index: 99;
  background-color: #fff;
}

/deep/ .ant-modal-content {
  width: 420px;
}

.top-title {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .left {
    span {
      &:first-child {
        font-size: 15px;
        font-weight: 700;
      }

      // &:last-child {}
    }
  }

  .right {
    button {
      height: 38px;
      border: none;

      &:first-child {
        width: 108px;

        color: #ffff;
      }

      &:last-child {
        width: 108px;
        background-color: #d7d7d7;
        color: #ffff;
      }
    }
  }
}

.Individualaccountopening {
  padding-bottom: 100px;

  .Approvaldetails {
    width: 100%;
    min-height: 58px;
    margin-bottom: 25px;

    h2 {
      font-weight: 600;
    }

    .text {
      width: 100%;
      height: 44px;
      display: flex;

      .left {
        width: 227px;
        height: 44px;
        display: flex;
        align-items: center;
        color: #242424;
        padding-left: 20px;
        background-color: #f2f2f2;
      }

      .right {
        display: flex;
        align-items: center;
        padding-left: 30px;
        flex: 1;
        height: 44px;
        border-top: 1px solid #d7d7d7;
        border-bottom: 1px solid #d7d7d7;

        .status {
          display: flex;
          align-items: center;
          font-weight: 700;
          // font-size: 16px;
          height: 100%;
          margin-right: 220px;
        }
      }
    }
  }

  .toptitle {
    font-weight: 600;
    margin-bottom: 10px;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .departmentId {
    /deep/ .ant-form-item-control-wrapper {
      border-top: 1px solid #d7d7d7;
    }
  }

  .app_no {
    // /deep/.ant-form-item-required {
    //   display: none;
    // }

    .app_nobox {
      display: flex;

      span {
        display: flex;
        width: 120px;
        align-items: center;
      }
    }

    p {
      height: 20px;
    }
  }

  /deep/ .ant-form-item-label {
    min-height: 45px;
    padding-left: 20px;
    display: flex;
    align-items: center;
  }

  // .channel_no {
  //   // /deep/ .ant-form-item-label {
  //   //   padding-top: 10px;

  //   // }
  // }

  .id_type,
  .id_no,
  .job_note,
  .open_dimensions,
  .open_longitude {
    /deep/ .ant-form-item-label {
      min-height: 45px;
      padding-left: 30px;
      display: flex;
      align-items: center;
    }
  }

  /deep/ .ant-input-suffix {
    right: 0;
    width: 40px;

    height: 30px;
    border-radius: 0px 4px 4px 0px;

    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f2f2f2;
  }

  .code {
    /deep/ .ant-input-suffix {
      right: -88px;
      width: 50px;
      border-radius: 6px;
      height: 30px;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #02a7f0;
    }
  }

  // 影像信息
  .Imageinformation,
  .mate,
  .Operatingentity {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    font-weight: 600;

    span {
      font-weight: normal;
      margin-left: 5px;
    }

    .fill {
      margin-left: 20px;
      height: 24px;
    }
  }

  .Imageinfor {
    /deep/ .ant-form-item-control-wrapper {
      border-top: 1px solid #d7d7d7;
    }
  }

  .pricebox {
    width: 100%;

    /deep/ .ant-form-item-label {
      min-height: 180px;
      padding-left: 30px;
      display: flex;
      align-items: center;
    }

    /deep/ .ant-form-item-control-wrapper {
      min-height: 180px;
    }

    .row {
      display: flex;

      .item {
        position: relative;
        margin-left: 50px;
        display: flex;
        align-items: center;
        height: 122px;


        span {
          line-height: 25px;
          text-align: center;
        }

        /deep/ .ant-upload {
          width: 200px;
          height: 122px;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          cursor: pointer;
          padding: 0px;
          overflow: hidden;

          .anticon-plus {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }

        .erero {
          position: absolute;
          bottom: -30px;
          left: 100px;
          color: #f5222d;
        }

        .ant-upload.ant-upload-select-picture-card {
          border: 1px solid #ccc;
        }

        .ant-upload.ant-upload-select-picture-card > .ant-upload {
          padding: 0;
        }

        .ant-upload-select-picture {
          width: 200px;
          height: 122px;
          border: 0;
        }
      }
    }
  }

  /deep/ .ant-form-item {
    background-color: #f2f2f2;
    min-height: 50px;
    margin-bottom: 0px !important;
  }

  /deep/ .ant-form-item-control-wrapper {
    padding-left: 35px;
    padding-left: 20px;
    background-color: #fff;
    border-bottom: 1px solid #d7d7d7;
    border-left: none;
    display: flex;
    min-height: 50px;
    align-items: center;

    .ant-form-item-control {
      min-width: 200px;
    }
  }
}

.confirmloading {
  color: #333333;

  .text {
    padding: 20px 10px;
    text-align: center;
    font-size: 16px;
    color: #494949;
  }

  .canceltext {
    width: 200px;
    margin: auto;
    text-align: center;
  }

  .custom-footer {
    width: 100%;

    button {
      &:first-child {
        color: #fff;
        margin-right: 80px !important;
      }

      &:last-child {
        background-color: #f2f2f2;
        color: #494949;
      }
    }
  }
}
</style>
