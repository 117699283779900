<template>
  <!-- 归集协议理列表页 -->
  <div>
    <a-card title="归集协议管理" style="margin: 20px">
      <!-- 头部筛选区域 -->
      <div class="selected-box">

        <!-- 机构ID -->
        <div class="selected-box-item">
          <span class="left-item">机构ID</span>
          <DepartmentChoose :department-code.sync="selectedData.departmentCode" @change="handleQuery"></DepartmentChoose>
        </div>
        <!-- 收款账号 -->
        <div class="selected-box-item">
          <span class="left-item">归集账号</span>
          <a-input v-model.trim="selectedData.oppAccountNo" placeholder="请输入归集账号"></a-input>
        </div>

        <!-- 付款账号 -->
        <div class="selected-box-item">
          <span class="left-item">代扣账号</span>
          <a-input v-model.trim="selectedData.accountNo" placeholder="请输入代扣账号"></a-input>
        </div>


        <div class="selected-box-item" style="display: block">
          <a-button type="primary" @click="handleQuery">查询</a-button>
          <a-button style="margin-left: 20px" @click="handleReset">重置</a-button>
        </div>
      </div>
      <!-- 表格区域 -->
      <div class="table-box">
        <div class="table-box-handle">
          <a-button type="primary" v-if="$authority('payWithholdAdd')">
            <router-link :to="{ name: 'payWithholdAdd'}" target="_self" rel="opener">
              <a-icon type="book"/>
              签约
            </router-link>
          </a-button>

          <a-popover>
            <template slot="content">
              对所有已认证、已签约的账户
            </template>
            <a-button type="danger" @click="showKeyConfirm" v-if="$authority('payWithholdDeal')"
                      icon="transaction"
                      :disabled="selectedRowKeys.length===0">
              归集选中({{selectedRowKeys.length}})
            </a-button>
          </a-popover>

          <a-button type="link" @click="showLumpedRecord()">
            <a-icon type="history"></a-icon>
            归集记录
          </a-button>

        </div>
        <div class="table-main">
          <a-table size="small" :row-selection="{
            selectedRowKeys: selectedRowKeys,
            onChange: onSelectChange,
          }" :customRow="clickrow" :columns="columns" :data-source="data" :pagination="false"
                   :rowKey="(record, index) => `${record.id}`">

            <template slot="action" slot-scope="text, record">
              <a-button type="link" icon="transaction" v-if="$authority('payWithholdDeal')" @click="handleSingle(record)">
                归集
                <a-icon type="network" theme="twoTone" two-tone-color="blue"/>
              </a-button>
              <a-button type="link" @click="showDeleteConfirm(record)" v-if="$authority('payWithholdDel')">
                <a-icon type="delete" theme="twoTone" two-tone-color="red"/>
              </a-button>
              <a-button type="link" @click="showLumpedRecord(record)">
                <a-icon type="history"/>
              </a-button>
              <a-button type="link" @click="details(record)" v-if="$authority('payWithholdDetail')">
                <a-icon type="eye"/>
              </a-button>
            </template>
          </a-table>
        </div>
      </div>
      <!-- 分页部分 -->
      <div class="paginationBox">
        <a-button class="Homepage" :disabled="Number(pageNo) == 1" @click="Homepage">
          首页
        </a-button>
        <a-pagination v-model.trim="pageNo" :page-size-options="pageSizeOptions" :total="total"
                      show-size-changer :page-size="pageSize" @change="pagechange" @showSizeChange="onShowSizeChange"
                      :item-render="itemRender">
          <!-- <template slot="buildOptionText" slot-scope="props">
              <span>{{ props.value }}条/页</span>
            </template> -->
        </a-pagination>
        <a-button @click="Trailingpage" :disabled="pageNo == Math.ceil(Number(total) / Number(pageSize))"
                  class="Trailingpage">
          尾页
        </a-button>
      </div>
      <!-- 按钮弹框 -->
      <div class="del-dialog">
        <a-modal
            title="确认删除"
            :visible="isModalVisible"
            @ok="handleOk"
            @cancel="handleCancel"
        >
          <p>确定要删除这条记录吗？</p>
        </a-modal>
      </div>


      <div class="del-dialog">
        <a-modal
            title="确认归集"
            :visible="isModalKeyVisible"
            @ok="handleKeyOk"
            @cancel="handleKeyCancel"
        >
          <p style="font-size: 20px">即将归集以下{{ selectedRows.length }}个账户资金，请确认：</p>
          <p v-for="(item,index) in selectedRows" :key="index">
            <WalletInfo :account-name="item.accountName" :account-no="item.accountNo"
                        :show-account-name="true" :show-account-no="false"
                        :account-class="item.accountClass"></WalletInfo>
          </p>
        </a-modal>
      </div>

      <div>
        <a-modal title="归集记录" :visible="isModalLumpedVisible" @ok="handlePayWithholdItemOk" @cancel="handlePayWithholdItemOk" width="75%">
          <div class="selected-box">
            <!-- 付款账号 -->
            <div class="selected-box-item">
              <span class="left-item">代扣账号</span>
              <a-input v-model.trim="selectedItemData.accountNo" placeholder="请输入代扣账号"></a-input>
            </div>
            <!-- 收款账号 -->
            <div class="selected-box-item">
              <span class="left-item">归集账号</span>
              <a-input v-model.trim="selectedItemData.oppAccountNo" placeholder="请输入归集账号"></a-input>
            </div>
            <div class="selected-box-item">
              <span class="left-item">归集结果</span>
              <a-select v-model.trim="selectedItemData.payStatus" placeholder="全部">
                <a-select-option value=""> 全部</a-select-option>
                <a-select-option value="1"> 成功</a-select-option>
                <a-select-option value="0"> 失败</a-select-option>
              </a-select>
            </div>
            <div class="selected-box-item" v-if="$authority('mchPayFee')">
              <span class="left-item">服务费收取状态</span>
              <a-select v-model.trim="selectedItemData.received" placeholder="全部">
                <a-select-option value=""> 全部</a-select-option>
                <a-select-option value="1"> 成功</a-select-option>
                <a-select-option value="0"> 失败</a-select-option>
                <a-select-option value="-1"> 未收取</a-select-option>
              </a-select>
            </div>

            <div class="selected-box-item">
              <span class="left-item">归集时间</span>
              <a-range-picker v-model.trim="selectedRange" valueFormat="YYYY-MM-DD"/>
            </div>


            <div class="selected-box-item" style="display: block">
              <a-button type="primary" @click="handleselectedItemDataQuery">查询</a-button>
              <a-button style="margin-left: 20px" @click="handleselectedItemDataReset">重置</a-button>
              <a-button type="primary" @click="handleselectedItemDataExport">导出</a-button>
            </div>
          </div>
          <template>
            <div class="table-main">
              <a-table :columns="columnItems" :pagination="false" :data-source="itemData" :row-key="record => record.id">
              </a-table>
            </div>
            <!-- 分页部分 -->
            <div class="paginationBox">
              <a-button class="Homepage" :disabled="Number(pageItemNo) == 1" @click="HomeItempage">
                首页
              </a-button>
              <a-pagination v-model.trim="pageItemNo" :page-size-options="pageSizeOptions" :total="totalItem"
                            show-size-changer :page-size="pageItemSize" @change="pagechangeItem" @showSizeChange="onShowSizeChangeItem"
                            :item-render="itemRender">
              </a-pagination>
              <a-button @click="TrailingpageItem" :disabled="pageNo == Math.ceil(Number(totalItem) / Number(pageItemSize))"
                        class="Trailingpage">
                尾页
              </a-button>
            </div>
          </template>
        </a-modal>
      </div>
      <a-modal
          title="归集详情"
          :visible="ismodalDetailsVisible"
          @ok="ismodalDetailsVisible=false"
          @cancel="ismodalDetailsVisible=false"
      >

        <div class="container">
          <a-row>
            <a-col :span="12">
              <div class="container-item">
                <a-row>
                  <a-col :span="8">代扣账户:</a-col>
                  <a-col :span="16">{{ detail.accountName }}</a-col>
                </a-row>
              </div>
              <div class="container-item">
                <a-row>
                  <a-col :span="8">代扣账号:</a-col>
                  <a-col :span="16">{{ detail.accountNo }}</a-col>
                </a-row>
              </div>

            </a-col>
            <a-col :span="12">

              <div class="container-item">
                <a-row>
                  <a-col :span="8">归集账户:</a-col>
                  <a-col :span="16">{{ detail.oppAccountName }}</a-col>
                </a-row>
              </div>
              <div class="container-item">
                <a-row>
                  <a-col :span="8">归集账号:</a-col>
                  <a-col :span="16">{{ detail.oppAccountNo }}</a-col>
                </a-row>
              </div>

            </a-col>
          </a-row>

          <a-tabs default-active-key="1">
            <a-tab-pane key="1" tab="手续费">
              <a-table :columns="feeColumns" :data-source="feeList" size="small" :pagination="false" rowkey="id">
              </a-table>
            </a-tab-pane>

          </a-tabs>


        </div>
      </a-modal>
    </a-card>
  </div>
</template>

<script>
import WalletInfo from '@/view/components/WalletInfo.vue'
import {message} from "ant-design-vue";
import Balance from "@/view/components/Balance.vue";
import dayjs from "dayjs";
import authority from "@/utils/authority";
import DepartmentChoose from "@/view/components/DepartmentChoose.vue";

const data = []

export default {
  name: 'payWithhold',
  props: {},
  components: {DepartmentChoose, WalletInfo, Balance},
  data() {
    return {
      // 绑定时间范围
      selectedRange: [],
      // 表格数据
      data,
      accountNames: '',
      itemData: [],
      detail: {},
      feeList: [],

      selectedItemData: {
        withholdId: '',
        accountNo: '',
        oppAccountNo: '',
        payStatus: '1',
        received: '',
        // 开始时间
        startTime: '',
        // 结束时间
        endTime: '',
        pageItemNo: 1,
        // 一页显示多少条
        pageItemSize: 10,
      },
      feeColumns: [
        {
          title: '收费账户',
          dataIndex: 'feeAccountNo',
          key: 'feeAccountNo',
          align: 'center',
          customRender: (text, record) => {
            return <WalletInfo accountName={record.feeAccountName} accountNo={record.feeAccountNo} accountNo={text} showAccountName={true}
                               showAccountNo={false}/>
          }
        }, {
          title: '服务费规则',
          dataIndex: 'feeRuleName',
          key: 'feeRuleName',
          align: 'center'
        }, {
          title: '服务费规则编号',
          dataIndex: 'feeRuleNo',
          key: 'feeRuleNo',
          align: 'center'
        },

      ],
      columnItems: [
        {
          title: '归集时间',
          dataIndex: 'createTime',
          key: 'createTime',
          align: 'center',
          ellipsis: true,
          customRender: (text) => {
            return text ? dayjs(text).format('YYYY-MM-DD HH:mm:ss') : ''
          }
        },
        {
          title: '代扣账户',
          dataIndex: 'accountNo',
          align: 'center',
          customRender: (text, record) => {
            return <WalletInfo accountName={record.accountName} accountNo={record.accountNo} accountNo={text} showAccountName={true} showAccountNo={false}/>
          }
        },
        {
          title: '归集账户',
          dataIndex: 'oppAccountNo',
          key: 'oppAccountNo',
          align: 'center',
          customRender: (text, record) => {
            return <WalletInfo accountName={record.oppAccountName} accountNo={record.oppAccountNo} accountNo={text} showAccountName={true}
                               showAccountNo={false}/>
          }
        },
        {
          title: '归集金额',
          dataIndex: 'amount',
          key: 'amount',
          align: 'center',
          ellipsis: true,
          customRender: (text, record) => {
            return record.payStatus === 1 ? <Balance amount={text} dcflag="d"/> :
                <Balance amount={text} dcflag="c"/>
          }
        },
        {
          title: '归集结果',
          dataIndex: 'payStatus',
          key: 'payStatus',
          align: 'center',
          ellipsis: true,
          customRender: (text, record) => {
            return (
                <span>{text === 0 ?
                    (
                        <a-tooltip title={record.failMsg}>
                          <span style={{color: 'red'}}>失败</span>
                        </a-tooltip>
                    ) : (
                        <span style={{color: 'green'}}>成功</span>
                    )}</span>
            );
          }
        },
        ...(authority('mchPayFee') ? [
          {
            title: '服务费',
            key: 'serviceFeeStatusAmount',
            align: 'center',
            width: 200,
            customRender: (text, record) => {
              // 默认显示的金额内容
              const amountContent = (record.received === '1' || record.receivableFee === 0) ?
                  <Balance amount={record.receivableFee} dcflag="d"/> :
                  <Balance amount={record.receivableFee} dcflag="c"/>
              // 判断是否需要显示刷新按钮
              const showReloadButton = (record.received === '0' || record.received === '-1') && record.receivableFee > 0;

              return (
                  <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    {amountContent}
                    {showReloadButton && (
                        <a-button type="link" onClick={() => this.withholdRetryServiceFee(record)}>
                          <a-icon type="reload"/>
                        </a-button>
                    )}
                  </div>
              );
            }
          }
        ] : [])
      ],
      columns: [
        {
          title: 'No',
          customRender: (text, record, index) =>
              `${Number(this.pageNo - 1) * Number(this.pageSize) +
              Number(index + 1)
              }`,
          align: 'center',
          key: 'sort',
        },
        {
          title: '代扣账户',
          dataIndex: 'accountNo',
          align: 'center',
          customRender: (text, record) => {
            return (
                <div>
                  <WalletInfo
                      accountName={record.accountName}
                      showAccountName={true}
                      showAccountNo={false}
                      accountNo={text}
                      accountClass={record.accountClass}
                  />
                </div>
            );
          }

        },
        {
          title: '归集账户',
          dataIndex: 'oppAccountNo',
          key: 'oppAccountNo',
          align: 'center',
          customRender: (text, record) => {
            return <WalletInfo accountName={record.oppAccountName}
                               showAccountName={true}
                               showAccountNo={false}
                               accountClass={record.oppAccountClass}
                               accountNo={text}
            ></WalletInfo>
          }
        },
        {
          title: "签约状态",
          dataIndex: "checkStatus",
          align: "center",
          customRender: (text, record) => {
            if (text === '1') {
              return <a-tag color="green">已签约</a-tag>
            } else {
              return <span>
              <a-tag color="red">未签约</a-tag>
              <a-button
                  type="link"
                  icon="reload"
                  onClick={() => this.handleApprovalRefresh(record)}
                  style={{marginLeft: '8px'}}
              ></a-button>
              </span>
            }
          },
        },
        {
          title: "小额认证",
          dataIndex: "auth",
          align: "center",
          customRender: (text, record) => {
            if (text === 1) {
              return <a-tag color="green">已认证</a-tag>
            } else {
              return <span>
                  <a-tag color="red">未认证</a-tag>
                  <a-button
                      type="link"
                      icon="reload"
                      onClick={() => this.handleAuthenticationRefresh(record)}
                      style={{marginLeft: '8px'}}
                  ></a-button>
                  </span>
            }
          },
        },
        {
          title: "操作",
          dataIndex: "action",
          align: "center",
          scopedSlots: {customRender: 'action'},
        },

      ],
      selectedRowKeys: [],
      // 筛选参数
      selectedData: {
        // 渠道编号
        channelNo: undefined,
        // 机构ID
        departmentCode: undefined,
        // 收款账号
        oppAccountNo: '',
        // 收款户名
        oppAccountName: '',

        // 付款账号
        accountNo: '',
        //付款户名
        accountName: '',
      },
      // 当前页
      pageNo: 1,
      // 一页显示多少条
      pageSize: 10,
      // 总数
      total: 0,
      // 当前页
      pageItemNo: 1,
      // 一页显示多少条
      pageItemSize: 10,
      // 总数
      totalItem: 0,
      //分页大小
      pageSizeOptions: ['10', '20', '30', '40', '50'],
      isShowDelDialog: false,
      isModalVisible: false,
      isModalKeyVisible: false,
      isModalLumpedVisible: false,
      ismodalDetailsVisible: false,
      currentRecord: null,
    }
  },
  watch: {},
  created() {
    this.payWithholdContractList()
  },
  mounted() {

    if (sessionStorage.getItem('msgInfo')) {
      this.selectedData = this.$store.state.searchMsgInfo
    }
    // 获取列表
    this.payWithholdContractList()
  },
  activated() {
    this.payWithholdContractList()
  },
  destroyed() {
    sessionStorage.removeItem('msgInfo')
  },
  methods: {
    async retryServiceFee(record) {
      this.api.retryServiceFeeApi([record.id]).then(res => {
        if (res.code == 200) {
          this.$message.success('重试成功')
          this.payOrdersList()
        } else {
          this.$message.error(res.info)
        }
      })

    },
    findItemById(dataObj, idToFind) {
      // 基本情况：如果当前数据对象具有匹配的ID，返回其名称
      if (dataObj == {}) return
      if (dataObj.coreDepartment?.id === idToFind) {
        return dataObj.coreDepartment.name
      }
      // 递归情况：在子数组中搜索
      if (dataObj.children && dataObj.children.length > 0) {
        for (const child of dataObj.children) {
          const foundName = this.findItemById(child, idToFind)
          if (foundName) {
            return foundName
            // 如果在子节点中找到，则返回名称
          }
        }
      }
      // 如果在数据对象中未找到匹配的ID，则返回null
      return null
    },

    // 获取列表函数
    async payWithholdContractList() {
      let data = JSON.parse(JSON.stringify(this.selectedData))
      data.pageNo = this.pageNo
      data.pageSize = this.pageSize
      // obj是个对象

      let res = await this.api.payWithholdContractList(data)
      if (res.code == 200) {
        this.data = res.data.records
        this.pageNo = res.data.current
        this.pageSize = res.data.size
        this.total = res.data.total
      } else {
        this.$message.error(res.info)
      }
    },
    // 查询
    handleQuery() {
      this.pageNo = 1
      this.selectedRowKeys = []
      this.payWithholdContractList()
    },
    // 查询
    handleselectedItemDataQuery() {
      this.pageNo = 1
      this.selectedRowKeys = []
      this.getPayWithholdItem()
    },

    // 导出
    async handleselectedItemDataExport() {
      if (this.selectedRange.length != 0) {
        this.selectedItemData.startTime = this.selectedRange[0] + " 00:00:00"
        this.selectedItemData.endTime = this.selectedRange[1] + " 23:59:59"
      }
      let param = {
        withholdId: this.selectedItemData.withholdId,
        accountNo: this.selectedItemData.accountNo,
        oppAccountNo: this.selectedItemData.oppAccountNo,
        payStatus: this.selectedItemData.payStatus,
        received: this.selectedItemData.received,
        startTime: this.selectedItemData.startTime,
        endTime: this.selectedItemData.endTime,
      }

      const rsp = await this.api.payWithholdContractItemExport(param)
      this.$uploadfile.ExcelDownload("归集明细记录", rsp);


    },

    // 重置
    handleReset() {
      this.selectedRowKeys = []
      this.pageNo = 1
      this.pageSize = 10
      this.selectedData = {
        // 机构ID
        departmentId: '',
        // 电商编号
        mchNo: '',
        // 电商名称
        mchName: '',
        // 收款账号
        oppAccountNo: '',
        // 收款户名
        oppAccountName: '',
        // 渠道编号
        channelNo: '',
        // 应用编号
        appNo: '',
        // 付款账号
        accountNo: '',
        //付款户名
        accountName: '',
      }
      this.payWithholdContractList()
    },
    // 重置
    handleselectedItemDataReset() {

      this.selectedItemData = {
        oppAccountNo: '',
        accountNo: '',
      }
      this.showLumpedRecord()
    },

    // 改变pagesize的事件函数
    onShowSizeChange(current, pageSize) {
      this.pageNo = current
      this.pageSize = pageSize
      this.payWithholdContractList()
    }, // 改变pagesize的事件函数
    onShowSizeChangeItem(current, pageSize) {
      this.pageItemNo = current
      this.pageItemSize = pageSize
      this.getPayWithholdItem()
    },
    // 改变当前页的事件
    handleChangePage(page) {
      this.pageNo = page
      this.payWithholdContractList()
    },
    // 修改上一页和下一页为文字链接
    itemRender(current, type, originalElement) {
      if (type === 'prev') {
        return <a>上一页</a>
      } else if (type === 'next') {
        return <a>下一页</a>
      }
      return originalElement
    },
    // 首页
    Homepage() {
      if (this.total == 0) {
        return
      }
      this.pageNo = 1,
          this.payWithholdContractList()
    },
    // 首页
    HomeItempage() {
      if (this.total == 0) {
        return
      }
      this.pageItemNo = 1,
          this.getPayWithholdItem()
    },

    // 尾页
    Trailingpage() {
      if (this.total == 0) {
        return
      }
      this.pageNo = Math.ceil(Number(this.total) / Number(this.pageSize))
      this.payWithholdContractList()
    },    // 尾页
    TrailingpageItem() {
      if (this.total == 0) {
        return
      }
      this.pageItemNo = Math.ceil(Number(this.totalItem) / Number(this.pageItemSize))
      this.getPayWithholdItem()
    },
    pagechange(val) {
      this.pageNo = val
      this.payWithholdContractList()
    },
    pagechangeItem(val) {
      this.pageItemNo = val
      this.getPayWithholdItem()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys;
    },

    clickrow(record) {
      return {
        on: {
          click: () => {
            let id = record.id.toString();
            if(this.selectedRowKeys.indexOf(id)>=0){
              this.selectedRowKeys = this.selectedRowKeys.filter(item=>String(item)!==id)
            }else{
              this.selectedRowKeys.push(id)
            }
          }
        }
      }
    },


    showDeleteConfirm(record) {
      this.currentRecord = record;
      this.isModalVisible = true;
    },

    async showLumpedRecord(record) {
      if (record) {
        this.currentRecord = record;
        this.selectedItemData.withholdId = this.currentRecord.id;
      }
      this.getPayWithholdItem()
      this.isModalLumpedVisible = true;
    },


    async getPayWithholdItem() {
      console.log("selectedRange", this.selectedRange)
      if (this.selectedRange.length != 0) {
        this.selectedItemData.startTime = this.selectedRange[0] + " 00:00:00"
        this.selectedItemData.endTime = this.selectedRange[1] + " 23:59:59"
      }
      let param = {
        withholdId: this.selectedItemData.withholdId,
        accountNo: this.selectedItemData.accountNo,
        oppAccountNo: this.selectedItemData.oppAccountNo,
        payStatus: this.selectedItemData.payStatus,
        received: this.selectedItemData.received,
        startTime: this.selectedItemData.startTime,
        endTime: this.selectedItemData.endTime,
        pageNo: this.pageItemNo,
        pageSize: this.pageItemSize,
      }

      const rsp = await this.api.payWithholdContractItemList(param)
      if (rsp.code == 200) {
        this.itemData = rsp.data.records
        this.pageItemNo = rsp.data.current
        this.pageItemSize = rsp.data.size
        this.totalItem = rsp.data.total
      } else {
        this.itemData = []
        this.$message.error(rsp.info)
      }

    },

    async details(record) {
      const rsp = await this.api.payWithholdDetailApi(record.id)
      if (rsp.code == 200) {
        this.detail = rsp.data
        this.feeList=[]
        if (rsp.data.feeRuleNo) {
          this.feeList.push({
            feeAccountClass: rsp.data.feeAccountClass,
            feeAccountName: rsp.data.feeAccountName,
            feeAccountNo: rsp.data.feeAccountNo,
            feeRuleName: rsp.data.feeRuleName,
            feeRuleNo: rsp.data.feeRuleNo,
          })
        }

        if (rsp.data.feeRuleNo2) {
          this.feeList.push({
            feeAccountClass: rsp.data.feeAccountClass2,
            feeAccountName: rsp.data.feeAccountName2,
            feeAccountNo: rsp.data.feeAccountNo2,
            feeRuleName: rsp.data.feeRuleName2,
            feeRuleNo: rsp.data.feeRuleNo2,
          })
        }

      } else {
        this.$message.error(rsp.info)
      }
      this.ismodalDetailsVisible = true;
    },


    async withholdRetryServiceFee(record) {
      this.api.payWithholdRetryServiceFee(record.id).then(res => {
        if (res.code == 200) {
          this.$message.success('重试成功')
          this.showLumpedRecord()
        } else {
          this.$message.error(res.info)
        }
      })
    },

    handlePayWithholdItemOk() {
      this.isModalLumpedVisible = false
      this.selectedItemData = {}
    },

    handleOk() {
      // 执行删除操作
      this.data = this.data.filter(item => item.key !== this.currentRecord.key);
      this.api.payWithholdContractDelete(this.currentRecord.id).then(res => {
        if (res.code == 200) {
          this.$message.success('删除成功')
          this.payWithholdContractList()
        } else {
          this.$message.error(res.info)
        }
      })
      this.isModalVisible = false;

    },
    handleCancel() {
      this.isModalVisible = false;
      this.currentRecord = null;
    },


    // 一键归集
    showKeyConfirm() {
      if (this.selectedRowKeys.length === 0) {
        return message.warning('请选择需要归集的记录!')
      }

      this.isModalKeyVisible = true;
    },
    handleBatch(ids){
      return this.api.payWithholdContractOneKeyCollection(ids).then(res => {
        if (res.code === 200) {
          //归集操作完成，成功x笔，失败y笔，失败名单：aaa,bbb,ccc
          this.$msgbox({
            type: "info",
            customClass: 'msg-box-wide',
            message: <a-descriptions title="归集结果" bordered>
              <a-descriptions-item label="归集笔数" span="3">
                {res.data.successNum}
              </a-descriptions-item>
              <a-descriptions-item label="归集金额" span="2">
                <Balance amount={res.data.successTotalAmount}/>
              </a-descriptions-item>
              <a-descriptions-item label="技术服务费">
                <Balance amount={res.data.totalFeeAmount} dcflag="c"/>
              </a-descriptions-item>
              <a-descriptions-item label="失败笔数" span="3">
                {res.data.failfulNum}
              </a-descriptions-item>
              <a-descriptions-item label="失败明细">
                {res.data.failfulAccount}
              </a-descriptions-item>
            </a-descriptions>,
            callback: () => {
            }
          })

          this.isModalKeyVisible = false;
          this.payWithholdContractList();
        } else {
          this.$message.error(res.info);
        }
      });
    },
     handleSingle(record){
      let that = this
      this.$confirm({
        title: "确认归集以下账号？",
        content: record.accountName,
        onOk(){
          return that.handleBatch([record.id])
        }
      })
    },
    handleKeyOk() {
      return this.handleBatch(this.selectedRowKeys)
    },
    handleKeyCancel() {
      this.isModalKeyVisible = false;
    },
    //签约
    handleApprovalRefresh(record) {
      this.api.payWithholdContractApproval(record.id).then(res => {
        if (res.code == 200) {
          this.$message.success('签约成功')
          this.isModalVisible = false;
          this.payWithholdContractList()
        } else {
          this.$message.error(res.info)
        }
      })
    },
    //认证
    handleAuthenticationRefresh(record) {
      this.api.payWithholdContractAuthentication(record.id).then(res => {
        if (res.code == 200) {
          this.$message.success('认证成功')
          this.isModalVisible = false;
          this.payWithholdContractList()
        } else {
          this.$message.error(res.info)
        }
      })
    },
  },

  computed:{
    selectedRows(){
      return this.data.filter(item=>this.selectedRowKeys.indexOf(String(item.id))>=0)
    }
  }
}
</script>

<style lang="less" scoped>
.selected-box {
  display: flex;
  flex-wrap: wrap;

  /deep/ .selected-box-item {
    margin-right: 20px;
    display: flex;
    justify-content: space-between;
    width: 280px;
    margin-bottom: 20px;
    align-items: center;

    .left-item {
      margin-right: 15px;
    }

    .ant-input {
      width: 200px;
    }

    .ant-select-selection--single {
      width: 200px;
    }
  }
}

.table-box {
  .table-box-handle {
    margin-bottom: 20px;
  }
}

.paginationBox {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;

  // .ant-pagination-options {
  //   float: left;
  // }

  .ant-pagination-prev,
  .ant-pagination-next,
  .ant-pagination-jump-prev,
  .ant-pagination-jump-next {
    border: 1px solid #d9d9d9;
    width: 60px;
  }
}

@media print {
  @page {
    size: landscape; // 强制横向打印
    margin: 0
  }

  body {
    margin: 0.5cm;
  }

  .no-print {
    display: none;
  }
}

/deep/ .ant-modal {
  min-width: 1000px !important;

  .ant-modal-content {
    width: 100% !important;
  }

  .ant-row {
    display: flex;
    align-items: center;
  }
}

.control-box {
  img {
    width: 25px;
    height: 25px;
    cursor: pointer;
    margin-right: 5px;
  }


}

.control-box.canot-click {
  display: flex;
  align-items: center;
  pointer-events: none;
}


.container {
  .container-item {
    min-height: 20px;
    margin-bottom: 10px;
    // font-weight: bold;
    color: #2b2b2b;


    .ant-input,
    .ant-select,
    .ant-select-selection--single {
      width: 200px;
      height: 24px;
    }

    .ant-col-8,
    .ant-col-6,
    .ant-col-4 {
      text-align: right;
      padding-right: 20px;
      font-weight: bold;
    }
  }
}
</style>
<style>

</style>