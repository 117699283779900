<script>

import DepartmentChoose from "@/view/components/DepartmentChoose.vue";
import Balance from "@/view/components/Balance.vue";
import dayjs from "dayjs";

export default {
  components: {Balance, DepartmentChoose},
  data() {
    return {
      attachments: false,
      modalWidth: document.body.clientWidth - 200,  // 初始宽度
      attachmentsPath: '',  // 初始宽度
      loading: false,
      fileList: [],
      fileNos: [],
      total: 0,
      pageSizeOptions: ['2', '10', '20', '30', '40', '50'],
      searchForm: {
        pageNo: 1,
        pageSize: 10,
        departmentCode: undefined,
        mchNo: undefined,
        orderNo: undefined,
        checkStatus: this.$authority("orderDraft_AuditBtn")? ["0"]:["-1","0","1","2"]
      },
      mchNolist: [],
      columns: [
        {
          title: '状态',
          dataIndex: 'checkStatus',
          width: 80,
          align: 'center',
          scopedSlots: {customRender: 'status'}
        },
        {
          title: '单号',
          dataIndex: 'orderNo',
          width: 220,
          align: 'center'
        },
        {
          title: '金额',
          dataIndex: 'orderAmount',
          width: 140,
          align: 'center',
          scopedSlots: {customRender: 'orderAmount'}
        },
        {
          title: '制单人',
          dataIndex: 'creater',
          width: 120,
          align: 'center'
        },
        {
          title: '制单时间',
          dataIndex: 'createTime',
          width: 180,
          align: 'center',
          customRender: (text) => {
            return dayjs(text).format('YYYY-MM-DD HH:mm:ss')
          },
        },
        {
          title: '审批意见',
          dataIndex: 'failMsg',
          width: 200,
          ellipsis: true,
          scopedSlots: {customRender: 'failmsg'}
        },
        {
          title: '审批时间',
          dataIndex: 'checkerTime',
          width: 180,
          align: 'center',
          customRender: (text) => {
            return dayjs(text).format('YYYY-MM-DD HH:mm:ss')
          },
        },
        {
          title: '备注',
          dataIndex: 'remark',
          align: 'left',
          ellipsis: true,
        },
        {
          title: '操作',
          key: 'action',
          scopedSlots: {customRender: 'action'},
          fixed: 'right',
          align: 'left',
          width: 380
        }
      ],
      dataSource: [],
      editDialogVisible: false,
      editForm: {},
      rules:{
        orderNo:[{required: true, message: '请输入单号', trigger: 'blur'}],
        orderAmount:[{required: true, message: '请输入金额', trigger: 'blur'}]
      },
      approveDialogVisible: false,
      approvingRow: {
        fileUrls: [],
        fileNo: [],
      },
      approveForm: {
        failMsg: "",
      },
    }
  },
  methods: {
    onOrderAmountBlur() {
      if (isNaN(this.editForm.orderAmount)) {
        this.$message.error("请输入正确的金额")
        this.editForm.orderAmount = "0.00"
      } else {
        this.editForm.orderAmount = Number(this.editForm.orderAmount).toFixed(2)
      }
    },

    attachmentsM(index) {
      this.attachmentsPath = this.approvingRow.fileUrls[index]
      this.attachments = true
    },
    async onDepartmentChange() {
      await this.getMchList()
      if (this.mchNolist.length > 0) {
        this.searchForm.mchNo = this.mchNolist[0].value
      } else {
        this.searchForm.mchNo = undefined
      }
      await this.onMchChange()
    },
    async checkStatusChange(){
      if(this.searchForm.checkStatus.length === 0){
        if(this.$authority("orderDraft_InputBtn")){
          this.searchForm.checkStatus = ['-1','0','1','2','4']
        }else if(this.$authority("orderDraft_AuditBtn")){
          this.searchForm.checkStatus = ['0','1','2']
        }
      }
      await this.loadTableData()
    },
    async onMchChange() {
      await this.loadTableData()
    },
    async loadTableData() {
      if (this.searchForm.mchNo) {
        const resp = await this.api.orderDraftList(this.searchForm)
        if (resp.code === 200) {
          this.dataSource = resp.data.records
          this.total = resp.data.total
          this.searchForm.pageNo = resp.data.current
          this.searchForm.pageSize = resp.data.size
        } else {
          this.$message.error(resp.info)
        }
      } else {
        this.$message.info("请先选择商户")
      }
    },
    reset() {
      this.searchForm.pageSize = 10
      this.searchForm.pageNo = 1
      this.searchForm.checkStatus = this.$authority("orderDraft_AuditBtn")? ["0"]:["-1","0","1","2"]
      this.searchForm.orderNo = undefined
      this.loadTableData()
      // 重置
    },
    // 改变当前页的事件函数
    onShowSizeChange(current, pageSize) {
      this.searchForm.pageSize = pageSize
      this.loadTableData()
    },
    // 修改上一页和下一页为文字链接
    itemRender(current, type, originalElement) {
      if (type === 'prev') {
        return <a>上一页</a>
      } else if (type === 'next') {
        return <a>下一页</a>
      }
      return originalElement
    },
    // 首页
    Homepage() {
      if (this.total == 0) {
        return
      }
      this.searchForm.pageNo = 1
      this.loadTableData()
    },
    // 尾页
    Trailingpage() {
      if (this.total == 0) {
        return
      }
      this.searchForm.pageNo = Math.ceil(Number(this.total) / Number(this.searchForm.pageSize))

      this.loadTableData()
    },
    pagechange(val) {
      this.searchForm.pageNo = val
      this.loadTableData()
    },

    // 获取店铺信息列表
    async getMchList() {
      this.mchNolist = [];
      const res = await this.api.mchshopsApi({
        pageNo: 1,
        pageSize: 10000,
        mchType: 1, //默认融资电商
        departmentCode: this.searchForm.departmentCode,
      });
      if (res.code !== 200) {
        this.$message.error(res.info);
        return;
      }
      // mchNolist
      this.mchNolist = res.data.records.map(x => Object.assign(x, {
        label: x.mchName,
        value: x.mchNo
      }))
    },

    filterOption(input, option) {
      if (input.length < 2) return false;
      const children = option.componentOptions.children[0].children.map(m => m.text).filter(m => m)

      return (
          children.find(m => m.toLowerCase().indexOf(input.toLowerCase()) >= 0)
      );
    },
    rowAdd() {
      this.editForm = {
        id: undefined,
        mchNo: this.searchForm.mchNo,
        orderNo: undefined,
        orderTime: dayjs().format('YYYY-MM-DD 00:00:00'),
        orderAmount: undefined,
        remark: undefined,
        shopNo: '',
      }
      this.editDialogVisible = true
    },
    rowEdit: async function (row) {
      this.editForm = {
        id: row.id,
        mchNo: row.mchNo,
        orderNo: row.orderNo,
        orderTime: row.orderTime,
        orderAmount: row.orderAmount,
        remark: row.remark,
        shopNo: row.shopNo,
        fileNo: row.fileNo,
      }
      const resp = await this.api.orderDraftDetail(this.editForm.id)
      if (resp.code === 200) {
        if (resp.data.fileUrls) {

          this.fileList = resp.data.fileUrls.map((url, index) => {
            const fileName = url.substring(url.lastIndexOf('/') + 1, url.indexOf('?')); // 从URL中提取文件名
            this.fileNos.push(resp.data.fileNo.split(',')[index])
            return {
              uid: `-${index + 1}`, // 设置一个唯一的ID
              name: fileName, // 提取的文件名
              status: 'done', // 假设文件已经上传完成
              url: url, // 直接使用fileUrls中的URL
              thumbUrl: url, // 如果是图片文件，thumbUrl也可以使用url
            };
          });

          console.log(this.fileList);
          console.log(this.fileNos);


        }

      }
      this.editDialogVisible = true
    },
    editCancel() {
      this.editDialogVisible = false
    },
    async editSubmit() {
      let resp = undefined
      this.editForm.fileNo = this.fileNos.join(",")
      if (this.editForm.id) {
        resp = await this.api.orderDraftEdit(this.editForm)
      } else {
        resp = await this.api.orderDraftAdd(this.editForm)
      }
      if (resp.code !== 200) {
        return this.$message.error(resp.info)
      } else {
        this.fileNos = []
        this.fileList = []
        this.$message.success("已保存,请在列表中提交审批")
        this.editDialogVisible = false
        await this.loadTableData()
      }
    },
    rowTruncate(row) {
      this.$confirm({
        title: "请确认",
        content: "作废后无法恢复，确定要作废吗？",
        okText: "作废",
        cancelText: "取消",
        onOk: async () => {
          const res = await this.api.orderDraftInvalid(row.id)
          if (res.code === 200) {
            this.$message.success("已作废")
            await this.loadTableData()
          } else {
            this.$message.error(res.info)
          }
        }
      })
    },
    async rowCallback(row){
      const res = await this.api.orderDraftRevoke(row.id)
      if (res.code === 200) {
        this.$message.success("已撤回,可重新提交审批")
        await this.loadTableData()
      } else {
        this.$message.error(res.info)
      }
    },
    rowSubmit(row) {
      console.info(row)
      this.$confirm({
        title: "请确认",
        content: "是否提交审批，提交后订单无法修改",
        okText: "提交",
        cancelText: "取消",
        onOk: async () => {
          const res = await this.api.orderDraftSubmit(row.id)
          if (res.code === 200) {
            this.$message.success("已提交")
            await this.loadTableData()
          } else {
            this.$message.error(res.info)
          }
        }
      })
    },
    async rowAudit(row) {
      this.approvingRow = row
      this.approveDialogVisible = true
      const resp = await this.api.orderDraftDetail(row.id)

      if (resp.code === 200) {
        if (resp.data.fileNo) {
          this.approvingRow.fileUrls = resp.data.fileUrls
          this.approvingRow.fileNos = resp.data.fileNo.split(",")
        }
        this.approveForm.failMsg = resp.data.failMsg ?? ""
      }
      console.log(" this.approvingRow", this.approvingRow)
    },

    async rowApprove(status) {
      const res = await this.api.orderDraftApprove({
        id: this.approvingRow.id,
        checkStatus: status,
        failMsg: this.approveForm.failMsg
      })
      if (res.code === 200) {
        this.$message.success("审批完成")
        this.approveDialogVisible = false
        await this.loadTableData()
      } else {
        this.$message.error(res.info)
      }
    },

    //删除上传文件
    handleRemove(file) {
      this.fileNos = this.fileNos.filter((_, index) => this.fileList[index] !== file);
      this.fileList = this.fileList.filter(item => item !== file);
      console.log(this.fileList)
      console.log(this.fileNos)

    },


    beforeUpload: async function (info) {
      const formData = new FormData();
      formData.append('channel_file_no', 'orderDraft');
      formData.append('file', info.file);
      const res = await this.api.filesUploadApi(formData);
      if (res.code === 200) {
        console.log(info.file)
        // this.fileList.push(info.file);
        const ress = await this.api.downloadImage(res.data.file_no);
        this.fileList.push({
          uid: info.file.uid,  // 使用索引生成唯一的uid
          name: info.file.name,        // 提取的文件名
          status: 'done',        // 假设文件上传完成
          url: ress.data,              // 文件URL
          thumbUrl: ress.data,         // 如果是图片，直接使用url作为缩略图URL
        });
        this.fileNos.push(res.data.file_no);
        this.$message.success("上传成功")
        console.log(this.fileList)
        console.log(this.fileNos)
      } else {
        this.$message.error(res.info)
      }

    },


  }
  ,
  async created() {
    await this.onDepartmentChange()
  }
}
</script>

<template>
  <div>
    <a-card style="margin: 20px">
      <template #title>
        <div class="left">
          <span>订单导入</span>
        </div>
        <div class="right">
          <!-- 请选择机构 -->
          <!-- 所属机构 -->
          <DepartmentChoose :department-code.sync="searchForm.departmentCode"
                            @change="onDepartmentChange"></DepartmentChoose>
          <!--选择电商-->
          <a-select :dropdownMatchSelectWidth="false" placeholder="请选择商户" @change="onMchChange"
                    :filter-option="filterOption" :show-search="true" v-model.trim="searchForm.mchNo"
                    style="min-width: 200px;">
            <a-select-option v-for="item in mchNolist" :key="item.value" :value="item.value">
                <span><a-tag color="purple">融</a-tag>{{ item.value }}-{{ item.label }}<br/>
                  <a-tag color="blue" v-if="item.fatherName">控</a-tag>{{ item.fatherName || "" }}</span>
            </a-select-option>
          </a-select>
        </div>
      </template>
      <div class="search-form">
        <a-form layout="inline">
          <a-form-item label="审批进度">
            <a-select placeholder="选择审批进度" v-model="searchForm.checkStatus" style="min-width: 220px;max-width: 450px;"
                      @change="checkStatusChange" mode="multiple">
              <a-select-option value="-1" v-if="$authority('orderDraft_InputBtn')">
                <a-tag color="black">草稿</a-tag>
              </a-select-option>
              <a-select-option value="0">
                <a-tag color="blue">审批中</a-tag>
              </a-select-option>
              <a-select-option value="1">
                <a-tag color="green">通过</a-tag>
              </a-select-option>
              <a-select-option value="2">
                <a-tag color="red">驳回</a-tag>
              </a-select-option>
              <a-select-option value="4" v-if="$authority('orderDraft_InputBtn')">
                <a-tag color="grey">作废</a-tag>
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="单号">
            <a-input v-model="searchForm.orderNo" placeholder="请输入单号"></a-input>
          </a-form-item>
          <a-form-item>
            <a-button type="primary" icon="search" @click="loadTableData">查询</a-button>
            <a-button @click="reset">重置</a-button>
          </a-form-item>
        </a-form>
      </div>
      <div class="top-btn">
        <a-button icon="plus" @click="rowAdd" type="primary" v-if="$authority('orderDraft_InputBtn')">录入订单
        </a-button>
      </div>
      <div class="table-area">
        <a-table :columns="columns" :dataSource="dataSource" :rowKey="(record, index) => `${record.id}`"
                 :pagination="false"
                 :scroll="{ x: 1800 }" size="small">
          <template v-slot:status="text, record">
            <a-tag color="black" v-if="record.checkStatus === '-1'">草稿</a-tag>
            <a-tag color="blue" v-if="record.checkStatus === '0'">审批中</a-tag>
            <a-tag color="green" v-if="record.checkStatus === '1'">通过</a-tag>
            <a-tag color="red" v-if="record.checkStatus === '2'">驳回</a-tag>
            <a-tag color="grey" v-if="record.checkStatus === '4'">作废</a-tag>
          </template>
          <template v-slot:orderAmount="text, record">
            <Balance :amount="Number(text)"></Balance>
          </template>
          <template v-slot:failmsg="text, record">
            <p v-if="record.checker">
              {{ record.checker }} : {{ record.failMsg }}
            </p>
          </template>
          <template v-slot:action="text, record" class="action">
            <div class="action">
              <a-button type="link" icon="caret-right"
                        @click="rowSubmit(record)" v-if="['-1','2'].indexOf(record.checkStatus)>=0">提交
              </a-button>
              <a-button type="link" icon="rollback"
                        @click="rowCallback(record)" v-if="$authority('orderDraft_InputBtn') && ['0'].indexOf(record.checkStatus)>=0">撤回
              </a-button>
              <a-button type="link" icon="edit" title="修改"
                        @click="rowEdit(record)"
                        v-if="$authority('orderDraft_InputBtn') && ['-1','2'].indexOf(record.checkStatus)>=0">编辑
              </a-button>
              <a-button type="link" icon="delete" title="作废" :style="{ color: 'red' }"
                        @click="rowTruncate(record)"
                        v-if="$authority('orderDraft_InputBtn') && ['-1'].indexOf(record.checkStatus)>=0">作废
              </a-button>
              <a-button type="link" icon="audit" title="审批"
                        @click="rowAudit(record)"
                        v-if="$authority('orderDraft_AuditBtn') && ['0'].indexOf(record.checkStatus)>=0">审批
              </a-button>
            </div>
          </template>
        </a-table>

      </div>
      <div class="paginationBox">
        <a-button class="Homepage" :disabled="Number(searchForm.pageNo) == 1" @click="Homepage">
          首页
        </a-button>
        <a-pagination v-model.trim="searchForm.pageNo" :page-size-options="pageSizeOptions" :total="total"
                      show-size-changer :page-size="searchForm.pageSize" @change="pagechange"
                      @showSizeChange="onShowSizeChange"
                      :item-render="itemRender">
        </a-pagination>
        <a-button @click="Trailingpage"
                  :disabled="searchForm.pageNo == Math.ceil(Number(total) / Number(searchForm.pageSize))"
                  class="Trailingpage">
          尾页
        </a-button>
      </div>
    </a-card>

    <a-modal :title="this.editForm.id?'订单修改':'订单录入'" :visible="editDialogVisible" centered
             @cancel="editDialogVisible = false">
      <a-form-model layout="horizontal" :rules="rules" :model="editForm"
              :label-col="{ span: 4 }" :wrapper-col="{ span: 18}">
        <a-form-model-item label="订单日期">
          <a-date-picker v-model="editForm.orderTime" placeholder="请选择日期"
                         valueFormat="YYYY-MM-DD 00:00:00" :allowClear="false"></a-date-picker>
        </a-form-model-item>
        <a-form-model-item label="单号" prop="orderNo">
          <a-input v-model="editForm.orderNo" :disabled="!!editForm.id" placeholder="请输入单号"></a-input>
        </a-form-model-item>
        <a-form-model-item label="金额" prop="orderAmount">
          <a-input v-model="editForm.orderAmount" placeholder="请输入订单金额"  suffix="元"
                   @blur="onOrderAmountBlur"></a-input>
        </a-form-model-item>
        <a-form-model-item label="备注">
          <a-textarea rows=3 v-model="editForm.remark" placeholder="备注"></a-textarea>
        </a-form-model-item>
        <a-form-model-item label="附件">
          <a-upload-dragger :fileList="fileList"
                            list-type="picture"
                            :remove="handleRemove"
                            :customRequest="beforeUpload"
                            :show-upload-list="true"
                            class="invoice-uploader"
                            accept="image/*"
          >
            <div>
              <p class="ant-upload-drag-icon">
                <a-icon :type="loading ? 'loading' : 'inbox'"/>
              </p>
              <div class="ant-upload-text">
                拖入或点击上传图片
              </div>
            </div>
          </a-upload-dragger>
        </a-form-model-item>
      </a-form-model>
      <template slot="footer">
        <a-button key="back" @click="editCancel">
          取消
        </a-button>
        <a-button key="submit" type="primary" @click="editSubmit">
          提交
        </a-button>
      </template>
    </a-modal>
    <a-modal title="审批" :visible="approveDialogVisible" cntered closable @cancel="approveDialogVisible = false">
      <a-descriptions :column="1" bordered size="small">
        <a-descriptions-item label="商户">{{ approvingRow.mchName }}</a-descriptions-item>
        <a-descriptions-item label="商户号">{{ approvingRow.mchNo }}</a-descriptions-item>
        <a-descriptions-item label="制单人">{{ approvingRow.creator }}</a-descriptions-item>
        <a-descriptions-item label="制单时间">{{ approvingRow.createTime }}</a-descriptions-item>
        <a-descriptions-item label="单号">{{ approvingRow.orderNo }}</a-descriptions-item>
        <a-descriptions-item label="金额">
          <Balance :amount="approvingRow.orderAmount" :align-right="false"></Balance>
        </a-descriptions-item>
        <a-descriptions-item label="备注" :span="2">
          {{ approvingRow.remark }}
        </a-descriptions-item>
        <a-descriptions-item label="附件" :span="2">
          <div v-for="(url, index) in approvingRow.fileUrls" :key="index">
            <img style=" width: 200px;height: 122px;cursor: pointer ;object-fit: contain;"
                 :src="url"
                 alt="展示图片"
                 @click="attachmentsM(index)"
            />
            <br>
          </div>

        </a-descriptions-item>
        <a-descriptions-item label="审批意见">
          <a-textarea v-model="approveForm.failMsg" :rows="3" placeholder="审批意见"></a-textarea>
        </a-descriptions-item>
      </a-descriptions>

      <template #footer>
        <a-button icon="check" type="primary" key="submit" @click="rowApprove('1')" :style="{backgroundColor: 'green'}">
          通过
        </a-button>
        <a-button icon="close" type="danger" @click="rowApprove('2')"> 拒绝</a-button>
      </template>
    </a-modal>

    <a-modal
        title="附件展示"
        :visible.sync="attachments"
        @cancel="attachments = false"
        @ok="attachments = false"
        :width="'60vw'"
        :bodyStyle="{ padding: 0, height: '80vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }"
        centered
    >
      <img
          style="max-width: 100%; max-height: 100%; height: auto; object-fit: contain;"
          :src="attachmentsPath"
          alt="展示图片"
      />
    </a-modal>



  </div>
</template>

<style scoped lang="less">
.left {
  float: left;
}

.right {
  float: right;
}

.top-btn {
  padding-bottom: 5px;
}

/deep/ .action {
  i {
    font-size: 20px;

    * {
      font-size: 20px;
    }
  }

  .ant-btn {
    margin: 0 8px;
    padding: 0;

    /deep/ span {
      margin: 0 !important;
    }
  }
}

.paginationBox {
  display: flex;
  margin-top: 20px;
  justify-content: center;

  // .ant-pagination-options {
  //   float: left;
  // }

  .ant-pagination-prev,
  .ant-pagination-next,
  .ant-pagination-jump-prev,
  .ant-pagination-jump-next {
    border: 1px solid #d9d9d9;
    width: 60px;
  }
}
.ant-select{
  /deep/ .ant-tag{
    margin: 0;
    border: none;
  }
  /deep/ .ant-select-selection__choice{
    padding: 0 20px 0 0;
  }
}


</style>