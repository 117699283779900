export const dict_rawdata_cascade = {
  region_raw:[
    { p: '北京市', c: '北京市', d: '北京市', r: '110000' },
    { p: '北京市', c: '北京市', d: '东城区', r: '110101' },
    { p: '北京市', c: '北京市', d: '西城区', r: '110102' },
    { p: '北京市', c: '北京市', d: '朝阳区', r: '110105' },
    { p: '北京市', c: '北京市', d: '丰台区', r: '110106' },
    { p: '北京市', c: '北京市', d: '石景山区', r: '110107' },
    { p: '北京市', c: '北京市', d: '海淀区', r: '110108' },
    { p: '北京市', c: '北京市', d: '门头沟区', r: '110109' },
    { p: '北京市', c: '北京市', d: '房山区', r: '110111' },
    { p: '北京市', c: '北京市', d: '通州区', r: '110112' },
    { p: '北京市', c: '北京市', d: '顺义区', r: '110113' },
    { p: '北京市', c: '北京市', d: '昌平区', r: '110114' },
    { p: '北京市', c: '北京市', d: '大兴区', r: '110115' },
    { p: '北京市', c: '北京市', d: '怀柔区', r: '110116' },
    { p: '北京市', c: '北京市', d: '平谷区', r: '110117' },
    { p: '北京市', c: '北京市', d: '密云区', r: '110118' },
    { p: '北京市', c: '北京市', d: '延庆区', r: '110119' },
    { p: '天津市', c: '天津市', d: '天津市', r: '120000' },
    { p: '天津市', c: '天津市', d: '和平区', r: '120101' },
    { p: '天津市', c: '天津市', d: '河东区', r: '120102' },
    { p: '天津市', c: '天津市', d: '河西区', r: '120103' },
    { p: '天津市', c: '天津市', d: '南开区', r: '120104' },
    { p: '天津市', c: '天津市', d: '河北区', r: '120105' },
    { p: '天津市', c: '天津市', d: '红桥区', r: '120106' },
    { p: '天津市', c: '天津市', d: '东丽区', r: '120110' },
    { p: '天津市', c: '天津市', d: '西青区', r: '120111' },
    { p: '天津市', c: '天津市', d: '津南区', r: '120112' },
    { p: '天津市', c: '天津市', d: '北辰区', r: '120113' },
    { p: '天津市', c: '天津市', d: '武清区', r: '120114' },
    { p: '天津市', c: '天津市', d: '宝坻区', r: '120115' },
    { p: '天津市', c: '天津市', d: '滨海新区', r: '120116' },
    { p: '天津市', c: '天津市', d: '宁河区', r: '120117' },
    { p: '天津市', c: '天津市', d: '静海区', r: '120118' },
    { p: '天津市', c: '天津市', d: '蓟州区', r: '120119' },
    { p: '河北省', c: '河北省', d: '河北省', r: '130000' },
    { p: '河北省', c: '石家庄市', d: '石家庄市', r: '130100' },
    { p: '河北省', c: '石家庄市', d: '长安区', r: '130102' },
    { p: '河北省', c: '石家庄市', d: '桥西区', r: '130104' },
    { p: '河北省', c: '石家庄市', d: '新华区', r: '130105' },
    { p: '河北省', c: '石家庄市', d: '井陉矿区', r: '130107' },
    { p: '河北省', c: '石家庄市', d: '裕华区', r: '130108' },
    { p: '河北省', c: '石家庄市', d: '藁城区', r: '130109' },
    { p: '河北省', c: '石家庄市', d: '鹿泉区', r: '130110' },
    { p: '河北省', c: '石家庄市', d: '栾城区', r: '130111' },
    { p: '河北省', c: '石家庄市', d: '井陉县', r: '130121' },
    { p: '河北省', c: '石家庄市', d: '正定县', r: '130123' },
    { p: '河北省', c: '石家庄市', d: '行唐县', r: '130125' },
    { p: '河北省', c: '石家庄市', d: '灵寿县', r: '130126' },
    { p: '河北省', c: '石家庄市', d: '高邑县', r: '130127' },
    { p: '河北省', c: '石家庄市', d: '深泽县', r: '130128' },
    { p: '河北省', c: '石家庄市', d: '赞皇县', r: '130129' },
    { p: '河北省', c: '石家庄市', d: '无极县', r: '130130' },
    { p: '河北省', c: '石家庄市', d: '平山县', r: '130131' },
    { p: '河北省', c: '石家庄市', d: '元氏县', r: '130132' },
    { p: '河北省', c: '石家庄市', d: '赵县', r: '130133' },
    { p: '河北省', c: '石家庄市', d: '辛集市', r: '130181' },
    { p: '河北省', c: '石家庄市', d: '晋州市', r: '130183' },
    { p: '河北省', c: '石家庄市', d: '新乐市', r: '130184' },
    { p: '河北省', c: '唐山市', d: '唐山市', r: '130200' },
    { p: '河北省', c: '唐山市', d: '路南区', r: '130202' },
    { p: '河北省', c: '唐山市', d: '路北区', r: '130203' },
    { p: '河北省', c: '唐山市', d: '古冶区', r: '130204' },
    { p: '河北省', c: '唐山市', d: '开平区', r: '130205' },
    { p: '河北省', c: '唐山市', d: '丰南区', r: '130207' },
    { p: '河北省', c: '唐山市', d: '丰润区', r: '130208' },
    { p: '河北省', c: '唐山市', d: '曹妃甸区', r: '130209' },
    { p: '河北省', c: '唐山市', d: '滦南县', r: '130224' },
    { p: '河北省', c: '唐山市', d: '乐亭县', r: '130225' },
    { p: '河北省', c: '唐山市', d: '迁西县', r: '130227' },
    { p: '河北省', c: '唐山市', d: '玉田县', r: '130229' },
    { p: '河北省', c: '唐山市', d: '遵化市', r: '130281' },
    { p: '河北省', c: '唐山市', d: '迁安市', r: '130283' },
    { p: '河北省', c: '唐山市', d: '滦州市', r: '130284' },
    { p: '河北省', c: '秦皇岛市', d: '秦皇岛市', r: '130300' },
    { p: '河北省', c: '秦皇岛市', d: '海港区', r: '130302' },
    { p: '河北省', c: '秦皇岛市', d: '山海关区', r: '130303' },
    { p: '河北省', c: '秦皇岛市', d: '北戴河区', r: '130304' },
    { p: '河北省', c: '秦皇岛市', d: '抚宁区', r: '130306' },
    { p: '河北省', c: '秦皇岛市', d: '青龙满族自治县', r: '130321' },
    { p: '河北省', c: '秦皇岛市', d: '昌黎县', r: '130322' },
    { p: '河北省', c: '秦皇岛市', d: '卢龙县', r: '130324' },
    { p: '河北省', c: '邯郸市', d: '邯郸市', r: '130400' },
    { p: '河北省', c: '邯郸市', d: '邯山区', r: '130402' },
    { p: '河北省', c: '邯郸市', d: '丛台区', r: '130403' },
    { p: '河北省', c: '邯郸市', d: '复兴区', r: '130404' },
    { p: '河北省', c: '邯郸市', d: '峰峰矿区', r: '130406' },
    { p: '河北省', c: '邯郸市', d: '肥乡区', r: '130407' },
    { p: '河北省', c: '邯郸市', d: '永年区', r: '130408' },
    { p: '河北省', c: '邯郸市', d: '临漳县', r: '130423' },
    { p: '河北省', c: '邯郸市', d: '成安县', r: '130424' },
    { p: '河北省', c: '邯郸市', d: '大名县', r: '130425' },
    { p: '河北省', c: '邯郸市', d: '涉县', r: '130426' },
    { p: '河北省', c: '邯郸市', d: '磁县', r: '130427' },
    { p: '河北省', c: '邯郸市', d: '邱县', r: '130430' },
    { p: '河北省', c: '邯郸市', d: '鸡泽县', r: '130431' },
    { p: '河北省', c: '邯郸市', d: '广平县', r: '130432' },
    { p: '河北省', c: '邯郸市', d: '馆陶县', r: '130433' },
    { p: '河北省', c: '邯郸市', d: '魏县', r: '130434' },
    { p: '河北省', c: '邯郸市', d: '曲周县', r: '130435' },
    { p: '河北省', c: '邯郸市', d: '武安市', r: '130481' },
    { p: '河北省', c: '邢台市', d: '邢台市', r: '130500' },
    { p: '河北省', c: '邢台市', d: '襄都区', r: '130502' },
    { p: '河北省', c: '邢台市', d: '信都区', r: '130503' },
    { p: '河北省', c: '邢台市', d: '任泽区', r: '130505' },
    { p: '河北省', c: '邢台市', d: '南和区', r: '130506' },
    { p: '河北省', c: '邢台市', d: '临城县', r: '130522' },
    { p: '河北省', c: '邢台市', d: '内丘县', r: '130523' },
    { p: '河北省', c: '邢台市', d: '柏乡县', r: '130524' },
    { p: '河北省', c: '邢台市', d: '隆尧县', r: '130525' },
    { p: '河北省', c: '邢台市', d: '宁晋县', r: '130528' },
    { p: '河北省', c: '邢台市', d: '巨鹿县', r: '130529' },
    { p: '河北省', c: '邢台市', d: '新河县', r: '130530' },
    { p: '河北省', c: '邢台市', d: '广宗县', r: '130531' },
    { p: '河北省', c: '邢台市', d: '平乡县', r: '130532' },
    { p: '河北省', c: '邢台市', d: '威县', r: '130533' },
    { p: '河北省', c: '邢台市', d: '清河县', r: '130534' },
    { p: '河北省', c: '邢台市', d: '临西县', r: '130535' },
    { p: '河北省', c: '邢台市', d: '南宫市', r: '130581' },
    { p: '河北省', c: '邢台市', d: '沙河市', r: '130582' },
    { p: '河北省', c: '保定市', d: '保定市', r: '130600' },
    { p: '河北省', c: '保定市', d: '竞秀区', r: '130602' },
    { p: '河北省', c: '保定市', d: '莲池区', r: '130606' },
    { p: '河北省', c: '保定市', d: '满城区', r: '130607' },
    { p: '河北省', c: '保定市', d: '清苑区', r: '130608' },
    { p: '河北省', c: '保定市', d: '徐水区', r: '130609' },
    { p: '河北省', c: '保定市', d: '涞水县', r: '130623' },
    { p: '河北省', c: '保定市', d: '阜平县', r: '130624' },
    { p: '河北省', c: '保定市', d: '定兴县', r: '130626' },
    { p: '河北省', c: '保定市', d: '唐县', r: '130627' },
    { p: '河北省', c: '保定市', d: '高阳县', r: '130628' },
    { p: '河北省', c: '保定市', d: '容城县', r: '130629' },
    { p: '河北省', c: '保定市', d: '涞源县', r: '130630' },
    { p: '河北省', c: '保定市', d: '望都县', r: '130631' },
    { p: '河北省', c: '保定市', d: '安新县', r: '130632' },
    { p: '河北省', c: '保定市', d: '易县', r: '130633' },
    { p: '河北省', c: '保定市', d: '曲阳县', r: '130634' },
    { p: '河北省', c: '保定市', d: '蠡县', r: '130635' },
    { p: '河北省', c: '保定市', d: '顺平县', r: '130636' },
    { p: '河北省', c: '保定市', d: '博野县', r: '130637' },
    { p: '河北省', c: '保定市', d: '雄县', r: '130638' },
    { p: '河北省', c: '保定市', d: '涿州市', r: '130681' },
    { p: '河北省', c: '保定市', d: '定州市', r: '130682' },
    { p: '河北省', c: '保定市', d: '安国市', r: '130683' },
    { p: '河北省', c: '保定市', d: '高碑店市', r: '130684' },
    { p: '河北省', c: '张家口市', d: '张家口市', r: '130700' },
    { p: '河北省', c: '张家口市', d: '桥东区', r: '130702' },
    { p: '河北省', c: '张家口市', d: '桥西区', r: '130703' },
    { p: '河北省', c: '张家口市', d: '宣化区', r: '130705' },
    { p: '河北省', c: '张家口市', d: '下花园区', r: '130706' },
    { p: '河北省', c: '张家口市', d: '万全区', r: '130708' },
    { p: '河北省', c: '张家口市', d: '崇礼区', r: '130709' },
    { p: '河北省', c: '张家口市', d: '张北县', r: '130722' },
    { p: '河北省', c: '张家口市', d: '康保县', r: '130723' },
    { p: '河北省', c: '张家口市', d: '沽源县', r: '130724' },
    { p: '河北省', c: '张家口市', d: '尚义县', r: '130725' },
    { p: '河北省', c: '张家口市', d: '蔚县', r: '130726' },
    { p: '河北省', c: '张家口市', d: '阳原县', r: '130727' },
    { p: '河北省', c: '张家口市', d: '怀安县', r: '130728' },
    { p: '河北省', c: '张家口市', d: '怀来县', r: '130730' },
    { p: '河北省', c: '张家口市', d: '涿鹿县', r: '130731' },
    { p: '河北省', c: '张家口市', d: '赤城县', r: '130732' },
    { p: '河北省', c: '承德市', d: '承德市', r: '130800' },
    { p: '河北省', c: '承德市', d: '双桥区', r: '130802' },
    { p: '河北省', c: '承德市', d: '双滦区', r: '130803' },
    { p: '河北省', c: '承德市', d: '鹰手营子矿区', r: '130804' },
    { p: '河北省', c: '承德市', d: '承德县', r: '130821' },
    { p: '河北省', c: '承德市', d: '兴隆县', r: '130822' },
    { p: '河北省', c: '承德市', d: '滦平县', r: '130824' },
    { p: '河北省', c: '承德市', d: '隆化县', r: '130825' },
    { p: '河北省', c: '承德市', d: '丰宁满族自治县', r: '130826' },
    { p: '河北省', c: '承德市', d: '宽城满族自治县', r: '130827' },
    { p: '河北省', c: '承德市', d: '围场满族蒙古族自治县', r: '130828' },
    { p: '河北省', c: '承德市', d: '平泉市', r: '130881' },
    { p: '河北省', c: '沧州市', d: '沧州市', r: '130900' },
    { p: '河北省', c: '沧州市', d: '新华区', r: '130902' },
    { p: '河北省', c: '沧州市', d: '运河区', r: '130903' },
    { p: '河北省', c: '沧州市', d: '沧县', r: '130921' },
    { p: '河北省', c: '沧州市', d: '青县', r: '130922' },
    { p: '河北省', c: '沧州市', d: '东光县', r: '130923' },
    { p: '河北省', c: '沧州市', d: '海兴县', r: '130924' },
    { p: '河北省', c: '沧州市', d: '盐山县', r: '130925' },
    { p: '河北省', c: '沧州市', d: '肃宁县', r: '130926' },
    { p: '河北省', c: '沧州市', d: '南皮县', r: '130927' },
    { p: '河北省', c: '沧州市', d: '吴桥县', r: '130928' },
    { p: '河北省', c: '沧州市', d: '献县', r: '130929' },
    { p: '河北省', c: '沧州市', d: '孟村回族自治县', r: '130930' },
    { p: '河北省', c: '沧州市', d: '泊头市', r: '130981' },
    { p: '河北省', c: '沧州市', d: '任丘市', r: '130982' },
    { p: '河北省', c: '沧州市', d: '黄骅市', r: '130983' },
    { p: '河北省', c: '沧州市', d: '河间市', r: '130984' },
    { p: '河北省', c: '廊坊市', d: '廊坊市', r: '131000' },
    { p: '河北省', c: '廊坊市', d: '安次区', r: '131002' },
    { p: '河北省', c: '廊坊市', d: '广阳区', r: '131003' },
    { p: '河北省', c: '廊坊市', d: '固安县', r: '131022' },
    { p: '河北省', c: '廊坊市', d: '永清县', r: '131023' },
    { p: '河北省', c: '廊坊市', d: '香河县', r: '131024' },
    { p: '河北省', c: '廊坊市', d: '大城县', r: '131025' },
    { p: '河北省', c: '廊坊市', d: '文安县', r: '131026' },
    { p: '河北省', c: '廊坊市', d: '大厂回族自治县', r: '131028' },
    { p: '河北省', c: '廊坊市', d: '霸州市', r: '131081' },
    { p: '河北省', c: '廊坊市', d: '三河市', r: '131082' },
    { p: '河北省', c: '衡水市', d: '衡水市', r: '131100' },
    { p: '河北省', c: '衡水市', d: '桃城区', r: '131102' },
    { p: '河北省', c: '衡水市', d: '冀州区', r: '131103' },
    { p: '河北省', c: '衡水市', d: '枣强县', r: '131121' },
    { p: '河北省', c: '衡水市', d: '武邑县', r: '131122' },
    { p: '河北省', c: '衡水市', d: '武强县', r: '131123' },
    { p: '河北省', c: '衡水市', d: '饶阳县', r: '131124' },
    { p: '河北省', c: '衡水市', d: '安平县', r: '131125' },
    { p: '河北省', c: '衡水市', d: '故城县', r: '131126' },
    { p: '河北省', c: '衡水市', d: '景县', r: '131127' },
    { p: '河北省', c: '衡水市', d: '阜城县', r: '131128' },
    { p: '河北省', c: '衡水市', d: '深州市', r: '131182' },
    { p: '山西省', c: '山西省', d: '山西省', r: '140000' },
    { p: '山西省', c: '太原市', d: '太原市', r: '140100' },
    { p: '山西省', c: '太原市', d: '小店区', r: '140105' },
    { p: '山西省', c: '太原市', d: '迎泽区', r: '140106' },
    { p: '山西省', c: '太原市', d: '杏花岭区', r: '140107' },
    { p: '山西省', c: '太原市', d: '尖草坪区', r: '140108' },
    { p: '山西省', c: '太原市', d: '万柏林区', r: '140109' },
    { p: '山西省', c: '太原市', d: '晋源区', r: '140110' },
    { p: '山西省', c: '太原市', d: '清徐县', r: '140121' },
    { p: '山西省', c: '太原市', d: '阳曲县', r: '140122' },
    { p: '山西省', c: '太原市', d: '娄烦县', r: '140123' },
    { p: '山西省', c: '太原市', d: '古交市', r: '140181' },
    { p: '山西省', c: '大同市', d: '大同市', r: '140200' },
    { p: '山西省', c: '大同市', d: '新荣区', r: '140212' },
    { p: '山西省', c: '大同市', d: '平城区', r: '140213' },
    { p: '山西省', c: '大同市', d: '云冈区', r: '140214' },
    { p: '山西省', c: '大同市', d: '云州区', r: '140215' },
    { p: '山西省', c: '大同市', d: '阳高县', r: '140221' },
    { p: '山西省', c: '大同市', d: '天镇县', r: '140222' },
    { p: '山西省', c: '大同市', d: '广灵县', r: '140223' },
    { p: '山西省', c: '大同市', d: '灵丘县', r: '140224' },
    { p: '山西省', c: '大同市', d: '浑源县', r: '140225' },
    { p: '山西省', c: '大同市', d: '左云县', r: '140226' },
    { p: '山西省', c: '阳泉市', d: '阳泉市', r: '140300' },
    { p: '山西省', c: '阳泉市', d: '城区', r: '140302' },
    { p: '山西省', c: '阳泉市', d: '矿区', r: '140303' },
    { p: '山西省', c: '阳泉市', d: '郊区', r: '140311' },
    { p: '山西省', c: '阳泉市', d: '平定县', r: '140321' },
    { p: '山西省', c: '阳泉市', d: '盂县', r: '140322' },
    { p: '山西省', c: '长治市', d: '长治市', r: '140400' },
    { p: '山西省', c: '长治市', d: '潞州区', r: '140403' },
    { p: '山西省', c: '长治市', d: '上党区', r: '140404' },
    { p: '山西省', c: '长治市', d: '屯留区', r: '140405' },
    { p: '山西省', c: '长治市', d: '潞城区', r: '140406' },
    { p: '山西省', c: '长治市', d: '襄垣县', r: '140423' },
    { p: '山西省', c: '长治市', d: '平顺县', r: '140425' },
    { p: '山西省', c: '长治市', d: '黎城县', r: '140426' },
    { p: '山西省', c: '长治市', d: '壶关县', r: '140427' },
    { p: '山西省', c: '长治市', d: '长子县', r: '140428' },
    { p: '山西省', c: '长治市', d: '武乡县', r: '140429' },
    { p: '山西省', c: '长治市', d: '沁县', r: '140430' },
    { p: '山西省', c: '长治市', d: '沁源县', r: '140431' },
    { p: '山西省', c: '晋城市', d: '晋城市', r: '140500' },
    { p: '山西省', c: '晋城市', d: '城区', r: '140502' },
    { p: '山西省', c: '晋城市', d: '沁水县', r: '140521' },
    { p: '山西省', c: '晋城市', d: '阳城县', r: '140522' },
    { p: '山西省', c: '晋城市', d: '陵川县', r: '140524' },
    { p: '山西省', c: '晋城市', d: '泽州县', r: '140525' },
    { p: '山西省', c: '晋城市', d: '高平市', r: '140581' },
    { p: '山西省', c: '朔州市', d: '朔州市', r: '140600' },
    { p: '山西省', c: '朔州市', d: '朔城区', r: '140602' },
    { p: '山西省', c: '朔州市', d: '平鲁区', r: '140603' },
    { p: '山西省', c: '朔州市', d: '山阴县', r: '140621' },
    { p: '山西省', c: '朔州市', d: '应县', r: '140622' },
    { p: '山西省', c: '朔州市', d: '右玉县', r: '140623' },
    { p: '山西省', c: '朔州市', d: '怀仁市', r: '140681' },
    { p: '山西省', c: '晋中市', d: '晋中市', r: '140700' },
    { p: '山西省', c: '晋中市', d: '榆次区', r: '140702' },
    { p: '山西省', c: '晋中市', d: '太谷区', r: '140703' },
    { p: '山西省', c: '晋中市', d: '榆社县', r: '140721' },
    { p: '山西省', c: '晋中市', d: '左权县', r: '140722' },
    { p: '山西省', c: '晋中市', d: '和顺县', r: '140723' },
    { p: '山西省', c: '晋中市', d: '昔阳县', r: '140724' },
    { p: '山西省', c: '晋中市', d: '寿阳县', r: '140725' },
    { p: '山西省', c: '晋中市', d: '祁县', r: '140727' },
    { p: '山西省', c: '晋中市', d: '平遥县', r: '140728' },
    { p: '山西省', c: '晋中市', d: '灵石县', r: '140729' },
    { p: '山西省', c: '晋中市', d: '介休市', r: '140781' },
    { p: '山西省', c: '运城市', d: '运城市', r: '140800' },
    { p: '山西省', c: '运城市', d: '盐湖区', r: '140802' },
    { p: '山西省', c: '运城市', d: '临猗县', r: '140821' },
    { p: '山西省', c: '运城市', d: '万荣县', r: '140822' },
    { p: '山西省', c: '运城市', d: '闻喜县', r: '140823' },
    { p: '山西省', c: '运城市', d: '稷山县', r: '140824' },
    { p: '山西省', c: '运城市', d: '新绛县', r: '140825' },
    { p: '山西省', c: '运城市', d: '绛县', r: '140826' },
    { p: '山西省', c: '运城市', d: '垣曲县', r: '140827' },
    { p: '山西省', c: '运城市', d: '夏县', r: '140828' },
    { p: '山西省', c: '运城市', d: '平陆县', r: '140829' },
    { p: '山西省', c: '运城市', d: '芮城县', r: '140830' },
    { p: '山西省', c: '运城市', d: '永济市', r: '140881' },
    { p: '山西省', c: '运城市', d: '河津市', r: '140882' },
    { p: '山西省', c: '忻州市', d: '忻州市', r: '140900' },
    { p: '山西省', c: '忻州市', d: '忻府区', r: '140902' },
    { p: '山西省', c: '忻州市', d: '定襄县', r: '140921' },
    { p: '山西省', c: '忻州市', d: '五台县', r: '140922' },
    { p: '山西省', c: '忻州市', d: '代县', r: '140923' },
    { p: '山西省', c: '忻州市', d: '繁峙县', r: '140924' },
    { p: '山西省', c: '忻州市', d: '宁武县', r: '140925' },
    { p: '山西省', c: '忻州市', d: '静乐县', r: '140926' },
    { p: '山西省', c: '忻州市', d: '神池县', r: '140927' },
    { p: '山西省', c: '忻州市', d: '五寨县', r: '140928' },
    { p: '山西省', c: '忻州市', d: '岢岚县', r: '140929' },
    { p: '山西省', c: '忻州市', d: '河曲县', r: '140930' },
    { p: '山西省', c: '忻州市', d: '保德县', r: '140931' },
    { p: '山西省', c: '忻州市', d: '偏关县', r: '140932' },
    { p: '山西省', c: '忻州市', d: '原平市', r: '140981' },
    { p: '山西省', c: '临汾市', d: '临汾市', r: '141000' },
    { p: '山西省', c: '临汾市', d: '尧都区', r: '141002' },
    { p: '山西省', c: '临汾市', d: '曲沃县', r: '141021' },
    { p: '山西省', c: '临汾市', d: '翼城县', r: '141022' },
    { p: '山西省', c: '临汾市', d: '襄汾县', r: '141023' },
    { p: '山西省', c: '临汾市', d: '洪洞县', r: '141024' },
    { p: '山西省', c: '临汾市', d: '古县', r: '141025' },
    { p: '山西省', c: '临汾市', d: '安泽县', r: '141026' },
    { p: '山西省', c: '临汾市', d: '浮山县', r: '141027' },
    { p: '山西省', c: '临汾市', d: '吉县', r: '141028' },
    { p: '山西省', c: '临汾市', d: '乡宁县', r: '141029' },
    { p: '山西省', c: '临汾市', d: '大宁县', r: '141030' },
    { p: '山西省', c: '临汾市', d: '隰县', r: '141031' },
    { p: '山西省', c: '临汾市', d: '永和县', r: '141032' },
    { p: '山西省', c: '临汾市', d: '蒲县', r: '141033' },
    { p: '山西省', c: '临汾市', d: '汾西县', r: '141034' },
    { p: '山西省', c: '临汾市', d: '侯马市', r: '141081' },
    { p: '山西省', c: '临汾市', d: '霍州市', r: '141082' },
    { p: '山西省', c: '吕梁市', d: '吕梁市', r: '141100' },
    { p: '山西省', c: '吕梁市', d: '离石区', r: '141102' },
    { p: '山西省', c: '吕梁市', d: '文水县', r: '141121' },
    { p: '山西省', c: '吕梁市', d: '交城县', r: '141122' },
    { p: '山西省', c: '吕梁市', d: '兴县', r: '141123' },
    { p: '山西省', c: '吕梁市', d: '临县', r: '141124' },
    { p: '山西省', c: '吕梁市', d: '柳林县', r: '141125' },
    { p: '山西省', c: '吕梁市', d: '石楼县', r: '141126' },
    { p: '山西省', c: '吕梁市', d: '岚县', r: '141127' },
    { p: '山西省', c: '吕梁市', d: '方山县', r: '141128' },
    { p: '山西省', c: '吕梁市', d: '中阳县', r: '141129' },
    { p: '山西省', c: '吕梁市', d: '交口县', r: '141130' },
    { p: '山西省', c: '吕梁市', d: '孝义市', r: '141181' },
    { p: '山西省', c: '吕梁市', d: '汾阳市', r: '141182' },
    { p: '内蒙古自治区', c: '内蒙古自治区', d: '内蒙古自治区', r: '150000' },
    { p: '内蒙古自治区', c: '呼和浩特市', d: '呼和浩特市', r: '150100' },
    { p: '内蒙古自治区', c: '呼和浩特市', d: '新城区', r: '150102' },
    { p: '内蒙古自治区', c: '呼和浩特市', d: '回民区', r: '150103' },
    { p: '内蒙古自治区', c: '呼和浩特市', d: '玉泉区', r: '150104' },
    { p: '内蒙古自治区', c: '呼和浩特市', d: '赛罕区', r: '150105' },
    { p: '内蒙古自治区', c: '呼和浩特市', d: '土默特左旗', r: '150121' },
    { p: '内蒙古自治区', c: '呼和浩特市', d: '托克托县', r: '150122' },
    { p: '内蒙古自治区', c: '呼和浩特市', d: '和林格尔县', r: '150123' },
    { p: '内蒙古自治区', c: '呼和浩特市', d: '清水河县', r: '150124' },
    { p: '内蒙古自治区', c: '呼和浩特市', d: '武川县', r: '150125' },
    { p: '内蒙古自治区', c: '包头市', d: '包头市', r: '150200' },
    { p: '内蒙古自治区', c: '包头市', d: '东河区', r: '150202' },
    { p: '内蒙古自治区', c: '包头市', d: '昆都仑区', r: '150203' },
    { p: '内蒙古自治区', c: '包头市', d: '青山区', r: '150204' },
    { p: '内蒙古自治区', c: '包头市', d: '石拐区', r: '150205' },
    { p: '内蒙古自治区', c: '包头市', d: '白云鄂博矿区', r: '150206' },
    { p: '内蒙古自治区', c: '包头市', d: '九原区', r: '150207' },
    { p: '内蒙古自治区', c: '包头市', d: '土默特右旗', r: '150221' },
    { p: '内蒙古自治区', c: '包头市', d: '固阳县', r: '150222' },
    { p: '内蒙古自治区', c: '包头市', d: '达尔罕茂明安联合旗', r: '150223' },
    { p: '内蒙古自治区', c: '乌海市', d: '乌海市', r: '150300' },
    { p: '内蒙古自治区', c: '乌海市', d: '海勃湾区', r: '150302' },
    { p: '内蒙古自治区', c: '乌海市', d: '海南区', r: '150303' },
    { p: '内蒙古自治区', c: '乌海市', d: '乌达区', r: '150304' },
    { p: '内蒙古自治区', c: '赤峰市', d: '赤峰市', r: '150400' },
    { p: '内蒙古自治区', c: '赤峰市', d: '红山区', r: '150402' },
    { p: '内蒙古自治区', c: '赤峰市', d: '元宝山区', r: '150403' },
    { p: '内蒙古自治区', c: '赤峰市', d: '松山区', r: '150404' },
    { p: '内蒙古自治区', c: '赤峰市', d: '阿鲁科尔沁旗', r: '150421' },
    { p: '内蒙古自治区', c: '赤峰市', d: '巴林左旗', r: '150422' },
    { p: '内蒙古自治区', c: '赤峰市', d: '巴林右旗', r: '150423' },
    { p: '内蒙古自治区', c: '赤峰市', d: '林西县', r: '150424' },
    { p: '内蒙古自治区', c: '赤峰市', d: '克什克腾旗', r: '150425' },
    { p: '内蒙古自治区', c: '赤峰市', d: '翁牛特旗', r: '150426' },
    { p: '内蒙古自治区', c: '赤峰市', d: '喀喇沁旗', r: '150428' },
    { p: '内蒙古自治区', c: '赤峰市', d: '宁城县', r: '150429' },
    { p: '内蒙古自治区', c: '赤峰市', d: '敖汉旗', r: '150430' },
    { p: '内蒙古自治区', c: '通辽市', d: '通辽市', r: '150500' },
    { p: '内蒙古自治区', c: '通辽市', d: '科尔沁区', r: '150502' },
    { p: '内蒙古自治区', c: '通辽市', d: '科尔沁左翼中旗', r: '150521' },
    { p: '内蒙古自治区', c: '通辽市', d: '科尔沁左翼后旗', r: '150522' },
    { p: '内蒙古自治区', c: '通辽市', d: '开鲁县', r: '150523' },
    { p: '内蒙古自治区', c: '通辽市', d: '库伦旗', r: '150524' },
    { p: '内蒙古自治区', c: '通辽市', d: '奈曼旗', r: '150525' },
    { p: '内蒙古自治区', c: '通辽市', d: '扎鲁特旗', r: '150526' },
    { p: '内蒙古自治区', c: '通辽市', d: '霍林郭勒市', r: '150581' },
    { p: '内蒙古自治区', c: '鄂尔多斯市', d: '鄂尔多斯市', r: '150600' },
    { p: '内蒙古自治区', c: '鄂尔多斯市', d: '东胜区', r: '150602' },
    { p: '内蒙古自治区', c: '鄂尔多斯市', d: '康巴什区', r: '150603' },
    { p: '内蒙古自治区', c: '鄂尔多斯市', d: '达拉特旗', r: '150621' },
    { p: '内蒙古自治区', c: '鄂尔多斯市', d: '准格尔旗', r: '150622' },
    { p: '内蒙古自治区', c: '鄂尔多斯市', d: '鄂托克前旗', r: '150623' },
    { p: '内蒙古自治区', c: '鄂尔多斯市', d: '鄂托克旗', r: '150624' },
    { p: '内蒙古自治区', c: '鄂尔多斯市', d: '杭锦旗', r: '150625' },
    { p: '内蒙古自治区', c: '鄂尔多斯市', d: '乌审旗', r: '150626' },
    { p: '内蒙古自治区', c: '鄂尔多斯市', d: '伊金霍洛旗', r: '150627' },
    { p: '内蒙古自治区', c: '呼伦贝尔市', d: '呼伦贝尔市', r: '150700' },
    { p: '内蒙古自治区', c: '呼伦贝尔市', d: '海拉尔区', r: '150702' },
    { p: '内蒙古自治区', c: '呼伦贝尔市', d: '扎赉诺尔区', r: '150703' },
    { p: '内蒙古自治区', c: '呼伦贝尔市', d: '阿荣旗', r: '150721' },
    { p: '内蒙古自治区', c: '呼伦贝尔市', d: '莫力达瓦达斡尔族自治旗', r: '150722' },
    { p: '内蒙古自治区', c: '呼伦贝尔市', d: '鄂伦春自治旗', r: '150723' },
    { p: '内蒙古自治区', c: '呼伦贝尔市', d: '鄂温克族自治旗', r: '150724' },
    { p: '内蒙古自治区', c: '呼伦贝尔市', d: '陈巴尔虎旗', r: '150725' },
    { p: '内蒙古自治区', c: '呼伦贝尔市', d: '新巴尔虎左旗', r: '150726' },
    { p: '内蒙古自治区', c: '呼伦贝尔市', d: '新巴尔虎右旗', r: '150727' },
    { p: '内蒙古自治区', c: '呼伦贝尔市', d: '满洲里市', r: '150781' },
    { p: '内蒙古自治区', c: '呼伦贝尔市', d: '牙克石市', r: '150782' },
    { p: '内蒙古自治区', c: '呼伦贝尔市', d: '扎兰屯市', r: '150783' },
    { p: '内蒙古自治区', c: '呼伦贝尔市', d: '额尔古纳市', r: '150784' },
    { p: '内蒙古自治区', c: '呼伦贝尔市', d: '根河市', r: '150785' },
    { p: '内蒙古自治区', c: '巴彦淖尔市', d: '巴彦淖尔市', r: '150800' },
    { p: '内蒙古自治区', c: '巴彦淖尔市', d: '临河区', r: '150802' },
    { p: '内蒙古自治区', c: '巴彦淖尔市', d: '五原县', r: '150821' },
    { p: '内蒙古自治区', c: '巴彦淖尔市', d: '磴口县', r: '150822' },
    { p: '内蒙古自治区', c: '巴彦淖尔市', d: '乌拉特前旗', r: '150823' },
    { p: '内蒙古自治区', c: '巴彦淖尔市', d: '乌拉特中旗', r: '150824' },
    { p: '内蒙古自治区', c: '巴彦淖尔市', d: '乌拉特后旗', r: '150825' },
    { p: '内蒙古自治区', c: '巴彦淖尔市', d: '杭锦后旗', r: '150826' },
    { p: '内蒙古自治区', c: '乌兰察布市', d: '乌兰察布市', r: '150900' },
    { p: '内蒙古自治区', c: '乌兰察布市', d: '集宁区', r: '150902' },
    { p: '内蒙古自治区', c: '乌兰察布市', d: '卓资县', r: '150921' },
    { p: '内蒙古自治区', c: '乌兰察布市', d: '化德县', r: '150922' },
    { p: '内蒙古自治区', c: '乌兰察布市', d: '商都县', r: '150923' },
    { p: '内蒙古自治区', c: '乌兰察布市', d: '兴和县', r: '150924' },
    { p: '内蒙古自治区', c: '乌兰察布市', d: '凉城县', r: '150925' },
    { p: '内蒙古自治区', c: '乌兰察布市', d: '察哈尔右翼前旗', r: '150926' },
    { p: '内蒙古自治区', c: '乌兰察布市', d: '察哈尔右翼中旗', r: '150927' },
    { p: '内蒙古自治区', c: '乌兰察布市', d: '察哈尔右翼后旗', r: '150928' },
    { p: '内蒙古自治区', c: '乌兰察布市', d: '四子王旗', r: '150929' },
    { p: '内蒙古自治区', c: '乌兰察布市', d: '丰镇市', r: '150981' },
    { p: '内蒙古自治区', c: '兴安盟', d: '兴安盟', r: '152200' },
    { p: '内蒙古自治区', c: '兴安盟', d: '乌兰浩特市', r: '152201' },
    { p: '内蒙古自治区', c: '兴安盟', d: '阿尔山市', r: '152202' },
    { p: '内蒙古自治区', c: '兴安盟', d: '科尔沁右翼前旗', r: '152221' },
    { p: '内蒙古自治区', c: '兴安盟', d: '科尔沁右翼中旗', r: '152222' },
    { p: '内蒙古自治区', c: '兴安盟', d: '扎赉特旗', r: '152223' },
    { p: '内蒙古自治区', c: '兴安盟', d: '突泉县', r: '152224' },
    { p: '内蒙古自治区', c: '锡林郭勒盟', d: '锡林郭勒盟', r: '152500' },
    { p: '内蒙古自治区', c: '锡林郭勒盟', d: '二连浩特市', r: '152501' },
    { p: '内蒙古自治区', c: '锡林郭勒盟', d: '锡林浩特市', r: '152502' },
    { p: '内蒙古自治区', c: '锡林郭勒盟', d: '阿巴嘎旗', r: '152522' },
    { p: '内蒙古自治区', c: '锡林郭勒盟', d: '苏尼特左旗', r: '152523' },
    { p: '内蒙古自治区', c: '锡林郭勒盟', d: '苏尼特右旗', r: '152524' },
    { p: '内蒙古自治区', c: '锡林郭勒盟', d: '东乌珠穆沁旗', r: '152525' },
    { p: '内蒙古自治区', c: '锡林郭勒盟', d: '西乌珠穆沁旗', r: '152526' },
    { p: '内蒙古自治区', c: '锡林郭勒盟', d: '太仆寺旗', r: '152527' },
    { p: '内蒙古自治区', c: '锡林郭勒盟', d: '镶黄旗', r: '152528' },
    { p: '内蒙古自治区', c: '锡林郭勒盟', d: '正镶白旗', r: '152529' },
    { p: '内蒙古自治区', c: '锡林郭勒盟', d: '正蓝旗', r: '152530' },
    { p: '内蒙古自治区', c: '锡林郭勒盟', d: '多伦县', r: '152531' },
    { p: '内蒙古自治区', c: '阿拉善盟', d: '阿拉善盟', r: '152900' },
    { p: '内蒙古自治区', c: '阿拉善盟', d: '阿拉善左旗', r: '152921' },
    { p: '内蒙古自治区', c: '阿拉善盟', d: '阿拉善右旗', r: '152922' },
    { p: '内蒙古自治区', c: '阿拉善盟', d: '额济纳旗', r: '152923' },
    { p: '辽宁省', c: '辽宁省', d: '辽宁省', r: '210000' },
    { p: '辽宁省', c: '沈阳市', d: '沈阳市', r: '210100' },
    { p: '辽宁省', c: '沈阳市', d: '和平区', r: '210102' },
    { p: '辽宁省', c: '沈阳市', d: '沈河区', r: '210103' },
    { p: '辽宁省', c: '沈阳市', d: '大东区', r: '210104' },
    { p: '辽宁省', c: '沈阳市', d: '皇姑区', r: '210105' },
    { p: '辽宁省', c: '沈阳市', d: '铁西区', r: '210106' },
    { p: '辽宁省', c: '沈阳市', d: '苏家屯区', r: '210111' },
    { p: '辽宁省', c: '沈阳市', d: '浑南区', r: '210112' },
    { p: '辽宁省', c: '沈阳市', d: '沈北新区', r: '210113' },
    { p: '辽宁省', c: '沈阳市', d: '于洪区', r: '210114' },
    { p: '辽宁省', c: '沈阳市', d: '辽中区', r: '210115' },
    { p: '辽宁省', c: '沈阳市', d: '康平县', r: '210123' },
    { p: '辽宁省', c: '沈阳市', d: '法库县', r: '210124' },
    { p: '辽宁省', c: '沈阳市', d: '新民市', r: '210181' },
    { p: '辽宁省', c: '大连市', d: '大连市', r: '210200' },
    { p: '辽宁省', c: '大连市', d: '中山区', r: '210202' },
    { p: '辽宁省', c: '大连市', d: '西岗区', r: '210203' },
    { p: '辽宁省', c: '大连市', d: '沙河口区', r: '210204' },
    { p: '辽宁省', c: '大连市', d: '甘井子区', r: '210211' },
    { p: '辽宁省', c: '大连市', d: '旅顺口区', r: '210212' },
    { p: '辽宁省', c: '大连市', d: '金州区', r: '210213' },
    { p: '辽宁省', c: '大连市', d: '普兰店区', r: '210214' },
    { p: '辽宁省', c: '大连市', d: '长海县', r: '210224' },
    { p: '辽宁省', c: '大连市', d: '瓦房店市', r: '210281' },
    { p: '辽宁省', c: '大连市', d: '庄河市', r: '210283' },
    { p: '辽宁省', c: '鞍山市', d: '鞍山市', r: '210300' },
    { p: '辽宁省', c: '鞍山市', d: '铁东区', r: '210302' },
    { p: '辽宁省', c: '鞍山市', d: '铁西区', r: '210303' },
    { p: '辽宁省', c: '鞍山市', d: '立山区', r: '210304' },
    { p: '辽宁省', c: '鞍山市', d: '千山区', r: '210311' },
    { p: '辽宁省', c: '鞍山市', d: '台安县', r: '210321' },
    { p: '辽宁省', c: '鞍山市', d: '岫岩满族自治县', r: '210323' },
    { p: '辽宁省', c: '鞍山市', d: '海城市', r: '210381' },
    { p: '辽宁省', c: '抚顺市', d: '抚顺市', r: '210400' },
    { p: '辽宁省', c: '抚顺市', d: '新抚区', r: '210402' },
    { p: '辽宁省', c: '抚顺市', d: '东洲区', r: '210403' },
    { p: '辽宁省', c: '抚顺市', d: '望花区', r: '210404' },
    { p: '辽宁省', c: '抚顺市', d: '顺城区', r: '210411' },
    { p: '辽宁省', c: '抚顺市', d: '抚顺县', r: '210421' },
    { p: '辽宁省', c: '抚顺市', d: '新宾满族自治县', r: '210422' },
    { p: '辽宁省', c: '抚顺市', d: '清原满族自治县', r: '210423' },
    { p: '辽宁省', c: '本溪市', d: '本溪市', r: '210500' },
    { p: '辽宁省', c: '本溪市', d: '平山区', r: '210502' },
    { p: '辽宁省', c: '本溪市', d: '溪湖区', r: '210503' },
    { p: '辽宁省', c: '本溪市', d: '明山区', r: '210504' },
    { p: '辽宁省', c: '本溪市', d: '南芬区', r: '210505' },
    { p: '辽宁省', c: '本溪市', d: '本溪满族自治县', r: '210521' },
    { p: '辽宁省', c: '本溪市', d: '桓仁满族自治县', r: '210522' },
    { p: '辽宁省', c: '丹东市', d: '丹东市', r: '210600' },
    { p: '辽宁省', c: '丹东市', d: '元宝区', r: '210602' },
    { p: '辽宁省', c: '丹东市', d: '振兴区', r: '210603' },
    { p: '辽宁省', c: '丹东市', d: '振安区', r: '210604' },
    { p: '辽宁省', c: '丹东市', d: '宽甸满族自治县', r: '210624' },
    { p: '辽宁省', c: '丹东市', d: '东港市', r: '210681' },
    { p: '辽宁省', c: '丹东市', d: '凤城市', r: '210682' },
    { p: '辽宁省', c: '锦州市', d: '锦州市', r: '210700' },
    { p: '辽宁省', c: '锦州市', d: '古塔区', r: '210702' },
    { p: '辽宁省', c: '锦州市', d: '凌河区', r: '210703' },
    { p: '辽宁省', c: '锦州市', d: '太和区', r: '210711' },
    { p: '辽宁省', c: '锦州市', d: '黑山县', r: '210726' },
    { p: '辽宁省', c: '锦州市', d: '义县', r: '210727' },
    { p: '辽宁省', c: '锦州市', d: '凌海市', r: '210781' },
    { p: '辽宁省', c: '锦州市', d: '北镇市', r: '210782' },
    { p: '辽宁省', c: '营口市', d: '营口市', r: '210800' },
    { p: '辽宁省', c: '营口市', d: '站前区', r: '210802' },
    { p: '辽宁省', c: '营口市', d: '西市区', r: '210803' },
    { p: '辽宁省', c: '营口市', d: '鲅鱼圈区', r: '210804' },
    { p: '辽宁省', c: '营口市', d: '老边区', r: '210811' },
    { p: '辽宁省', c: '营口市', d: '盖州市', r: '210881' },
    { p: '辽宁省', c: '营口市', d: '大石桥市', r: '210882' },
    { p: '辽宁省', c: '阜新市', d: '阜新市', r: '210900' },
    { p: '辽宁省', c: '阜新市', d: '海州区', r: '210902' },
    { p: '辽宁省', c: '阜新市', d: '新邱区', r: '210903' },
    { p: '辽宁省', c: '阜新市', d: '太平区', r: '210904' },
    { p: '辽宁省', c: '阜新市', d: '清河门区', r: '210905' },
    { p: '辽宁省', c: '阜新市', d: '细河区', r: '210911' },
    { p: '辽宁省', c: '阜新市', d: '阜新蒙古族自治县', r: '210921' },
    { p: '辽宁省', c: '阜新市', d: '彰武县', r: '210922' },
    { p: '辽宁省', c: '辽阳市', d: '辽阳市', r: '211000' },
    { p: '辽宁省', c: '辽阳市', d: '白塔区', r: '211002' },
    { p: '辽宁省', c: '辽阳市', d: '文圣区', r: '211003' },
    { p: '辽宁省', c: '辽阳市', d: '宏伟区', r: '211004' },
    { p: '辽宁省', c: '辽阳市', d: '弓长岭区', r: '211005' },
    { p: '辽宁省', c: '辽阳市', d: '太子河区', r: '211011' },
    { p: '辽宁省', c: '辽阳市', d: '辽阳县', r: '211021' },
    { p: '辽宁省', c: '辽阳市', d: '灯塔市', r: '211081' },
    { p: '辽宁省', c: '盘锦市', d: '盘锦市', r: '211100' },
    { p: '辽宁省', c: '盘锦市', d: '双台子区', r: '211102' },
    { p: '辽宁省', c: '盘锦市', d: '兴隆台区', r: '211103' },
    { p: '辽宁省', c: '盘锦市', d: '大洼区', r: '211104' },
    { p: '辽宁省', c: '盘锦市', d: '盘山县', r: '211122' },
    { p: '辽宁省', c: '铁岭市', d: '铁岭市', r: '211200' },
    { p: '辽宁省', c: '铁岭市', d: '银州区', r: '211202' },
    { p: '辽宁省', c: '铁岭市', d: '清河区', r: '211204' },
    { p: '辽宁省', c: '铁岭市', d: '铁岭县', r: '211221' },
    { p: '辽宁省', c: '铁岭市', d: '西丰县', r: '211223' },
    { p: '辽宁省', c: '铁岭市', d: '昌图县', r: '211224' },
    { p: '辽宁省', c: '铁岭市', d: '调兵山市', r: '211281' },
    { p: '辽宁省', c: '铁岭市', d: '开原市', r: '211282' },
    { p: '辽宁省', c: '朝阳市', d: '朝阳市', r: '211300' },
    { p: '辽宁省', c: '朝阳市', d: '双塔区', r: '211302' },
    { p: '辽宁省', c: '朝阳市', d: '龙城区', r: '211303' },
    { p: '辽宁省', c: '朝阳市', d: '朝阳县', r: '211321' },
    { p: '辽宁省', c: '朝阳市', d: '建平县', r: '211322' },
    { p: '辽宁省', c: '朝阳市', d: '喀喇沁左翼蒙古族自治县', r: '211324' },
    { p: '辽宁省', c: '朝阳市', d: '北票市', r: '211381' },
    { p: '辽宁省', c: '朝阳市', d: '凌源市', r: '211382' },
    { p: '辽宁省', c: '葫芦岛市', d: '葫芦岛市', r: '211400' },
    { p: '辽宁省', c: '葫芦岛市', d: '连山区', r: '211402' },
    { p: '辽宁省', c: '葫芦岛市', d: '龙港区', r: '211403' },
    { p: '辽宁省', c: '葫芦岛市', d: '南票区', r: '211404' },
    { p: '辽宁省', c: '葫芦岛市', d: '绥中县', r: '211421' },
    { p: '辽宁省', c: '葫芦岛市', d: '建昌县', r: '211422' },
    { p: '辽宁省', c: '葫芦岛市', d: '兴城市', r: '211481' },
    { p: '吉林省', c: '吉林省', d: '吉林省', r: '220000' },
    { p: '吉林省', c: '长春市', d: '长春市', r: '220100' },
    { p: '吉林省', c: '长春市', d: '南关区', r: '220102' },
    { p: '吉林省', c: '长春市', d: '宽城区', r: '220103' },
    { p: '吉林省', c: '长春市', d: '朝阳区', r: '220104' },
    { p: '吉林省', c: '长春市', d: '二道区', r: '220105' },
    { p: '吉林省', c: '长春市', d: '绿园区', r: '220106' },
    { p: '吉林省', c: '长春市', d: '双阳区', r: '220112' },
    { p: '吉林省', c: '长春市', d: '九台区', r: '220113' },
    { p: '吉林省', c: '长春市', d: '农安县', r: '220122' },
    { p: '吉林省', c: '长春市', d: '榆树市', r: '220182' },
    { p: '吉林省', c: '长春市', d: '德惠市', r: '220183' },
    { p: '吉林省', c: '长春市', d: '公主岭市', r: '220184' },
    { p: '吉林省', c: '吉林市', d: '吉林市', r: '220200' },
    { p: '吉林省', c: '吉林市', d: '昌邑区', r: '220202' },
    { p: '吉林省', c: '吉林市', d: '龙潭区', r: '220203' },
    { p: '吉林省', c: '吉林市', d: '船营区', r: '220204' },
    { p: '吉林省', c: '吉林市', d: '丰满区', r: '220211' },
    { p: '吉林省', c: '吉林市', d: '永吉县', r: '220221' },
    { p: '吉林省', c: '吉林市', d: '蛟河市', r: '220281' },
    { p: '吉林省', c: '吉林市', d: '桦甸市', r: '220282' },
    { p: '吉林省', c: '吉林市', d: '舒兰市', r: '220283' },
    { p: '吉林省', c: '吉林市', d: '磐石市', r: '220284' },
    { p: '吉林省', c: '四平市', d: '四平市', r: '220300' },
    { p: '吉林省', c: '四平市', d: '铁西区', r: '220302' },
    { p: '吉林省', c: '四平市', d: '铁东区', r: '220303' },
    { p: '吉林省', c: '四平市', d: '梨树县', r: '220322' },
    { p: '吉林省', c: '四平市', d: '伊通满族自治县', r: '220323' },
    { p: '吉林省', c: '四平市', d: '双辽市', r: '220382' },
    { p: '吉林省', c: '辽源市', d: '辽源市', r: '220400' },
    { p: '吉林省', c: '辽源市', d: '龙山区', r: '220402' },
    { p: '吉林省', c: '辽源市', d: '西安区', r: '220403' },
    { p: '吉林省', c: '辽源市', d: '东丰县', r: '220421' },
    { p: '吉林省', c: '辽源市', d: '东辽县', r: '220422' },
    { p: '吉林省', c: '通化市', d: '通化市', r: '220500' },
    { p: '吉林省', c: '通化市', d: '东昌区', r: '220502' },
    { p: '吉林省', c: '通化市', d: '二道江区', r: '220503' },
    { p: '吉林省', c: '通化市', d: '通化县', r: '220521' },
    { p: '吉林省', c: '通化市', d: '辉南县', r: '220523' },
    { p: '吉林省', c: '通化市', d: '柳河县', r: '220524' },
    { p: '吉林省', c: '通化市', d: '梅河口市', r: '220581' },
    { p: '吉林省', c: '通化市', d: '集安市', r: '220582' },
    { p: '吉林省', c: '白山市', d: '白山市', r: '220600' },
    { p: '吉林省', c: '白山市', d: '浑江区', r: '220602' },
    { p: '吉林省', c: '白山市', d: '江源区', r: '220605' },
    { p: '吉林省', c: '白山市', d: '抚松县', r: '220621' },
    { p: '吉林省', c: '白山市', d: '靖宇县', r: '220622' },
    { p: '吉林省', c: '白山市', d: '长白朝鲜族自治县', r: '220623' },
    { p: '吉林省', c: '白山市', d: '临江市', r: '220681' },
    { p: '吉林省', c: '松原市', d: '松原市', r: '220700' },
    { p: '吉林省', c: '松原市', d: '宁江区', r: '220702' },
    { p: '吉林省', c: '松原市', d: '前郭尔罗斯蒙古族自治县', r: '220721' },
    { p: '吉林省', c: '松原市', d: '长岭县', r: '220722' },
    { p: '吉林省', c: '松原市', d: '乾安县', r: '220723' },
    { p: '吉林省', c: '松原市', d: '扶余市', r: '220781' },
    { p: '吉林省', c: '白城市', d: '白城市', r: '220800' },
    { p: '吉林省', c: '白城市', d: '洮北区', r: '220802' },
    { p: '吉林省', c: '白城市', d: '镇赉县', r: '220821' },
    { p: '吉林省', c: '白城市', d: '通榆县', r: '220822' },
    { p: '吉林省', c: '白城市', d: '洮南市', r: '220881' },
    { p: '吉林省', c: '白城市', d: '大安市', r: '220882' },
    { p: '吉林省', c: '延边朝鲜族自治州', d: '延边朝鲜族自治州', r: '222400' },
    { p: '吉林省', c: '延边朝鲜族自治州', d: '延吉市', r: '222401' },
    { p: '吉林省', c: '延边朝鲜族自治州', d: '图们市', r: '222402' },
    { p: '吉林省', c: '延边朝鲜族自治州', d: '敦化市', r: '222403' },
    { p: '吉林省', c: '延边朝鲜族自治州', d: '珲春市', r: '222404' },
    { p: '吉林省', c: '延边朝鲜族自治州', d: '龙井市', r: '222405' },
    { p: '吉林省', c: '延边朝鲜族自治州', d: '和龙市', r: '222406' },
    { p: '吉林省', c: '延边朝鲜族自治州', d: '汪清县', r: '222424' },
    { p: '吉林省', c: '延边朝鲜族自治州', d: '安图县', r: '222426' },
    { p: '黑龙江省', c: '黑龙江省', d: '黑龙江省', r: '230000' },
    { p: '黑龙江省', c: '哈尔滨市', d: '哈尔滨市', r: '230100' },
    { p: '黑龙江省', c: '哈尔滨市', d: '道里区', r: '230102' },
    { p: '黑龙江省', c: '哈尔滨市', d: '南岗区', r: '230103' },
    { p: '黑龙江省', c: '哈尔滨市', d: '道外区', r: '230104' },
    { p: '黑龙江省', c: '哈尔滨市', d: '平房区', r: '230108' },
    { p: '黑龙江省', c: '哈尔滨市', d: '松北区', r: '230109' },
    { p: '黑龙江省', c: '哈尔滨市', d: '香坊区', r: '230110' },
    { p: '黑龙江省', c: '哈尔滨市', d: '呼兰区', r: '230111' },
    { p: '黑龙江省', c: '哈尔滨市', d: '阿城区', r: '230112' },
    { p: '黑龙江省', c: '哈尔滨市', d: '双城区', r: '230113' },
    { p: '黑龙江省', c: '哈尔滨市', d: '依兰县', r: '230123' },
    { p: '黑龙江省', c: '哈尔滨市', d: '方正县', r: '230124' },
    { p: '黑龙江省', c: '哈尔滨市', d: '宾县', r: '230125' },
    { p: '黑龙江省', c: '哈尔滨市', d: '巴彦县', r: '230126' },
    { p: '黑龙江省', c: '哈尔滨市', d: '木兰县', r: '230127' },
    { p: '黑龙江省', c: '哈尔滨市', d: '通河县', r: '230128' },
    { p: '黑龙江省', c: '哈尔滨市', d: '延寿县', r: '230129' },
    { p: '黑龙江省', c: '哈尔滨市', d: '尚志市', r: '230183' },
    { p: '黑龙江省', c: '哈尔滨市', d: '五常市', r: '230184' },
    { p: '黑龙江省', c: '齐齐哈尔市', d: '齐齐哈尔市', r: '230200' },
    { p: '黑龙江省', c: '齐齐哈尔市', d: '龙沙区', r: '230202' },
    { p: '黑龙江省', c: '齐齐哈尔市', d: '建华区', r: '230203' },
    { p: '黑龙江省', c: '齐齐哈尔市', d: '铁锋区', r: '230204' },
    { p: '黑龙江省', c: '齐齐哈尔市', d: '昂昂溪区', r: '230205' },
    { p: '黑龙江省', c: '齐齐哈尔市', d: '富拉尔基区', r: '230206' },
    { p: '黑龙江省', c: '齐齐哈尔市', d: '碾子山区', r: '230207' },
    { p: '黑龙江省', c: '齐齐哈尔市', d: '梅里斯达斡尔族区', r: '230208' },
    { p: '黑龙江省', c: '齐齐哈尔市', d: '龙江县', r: '230221' },
    { p: '黑龙江省', c: '齐齐哈尔市', d: '依安县', r: '230223' },
    { p: '黑龙江省', c: '齐齐哈尔市', d: '泰来县', r: '230224' },
    { p: '黑龙江省', c: '齐齐哈尔市', d: '甘南县', r: '230225' },
    { p: '黑龙江省', c: '齐齐哈尔市', d: '富裕县', r: '230227' },
    { p: '黑龙江省', c: '齐齐哈尔市', d: '克山县', r: '230229' },
    { p: '黑龙江省', c: '齐齐哈尔市', d: '克东县', r: '230230' },
    { p: '黑龙江省', c: '齐齐哈尔市', d: '拜泉县', r: '230231' },
    { p: '黑龙江省', c: '齐齐哈尔市', d: '讷河市', r: '230281' },
    { p: '黑龙江省', c: '鸡西市', d: '鸡西市', r: '230300' },
    { p: '黑龙江省', c: '鸡西市', d: '鸡冠区', r: '230302' },
    { p: '黑龙江省', c: '鸡西市', d: '恒山区', r: '230303' },
    { p: '黑龙江省', c: '鸡西市', d: '滴道区', r: '230304' },
    { p: '黑龙江省', c: '鸡西市', d: '梨树区', r: '230305' },
    { p: '黑龙江省', c: '鸡西市', d: '城子河区', r: '230306' },
    { p: '黑龙江省', c: '鸡西市', d: '麻山区', r: '230307' },
    { p: '黑龙江省', c: '鸡西市', d: '鸡东县', r: '230321' },
    { p: '黑龙江省', c: '鸡西市', d: '虎林市', r: '230381' },
    { p: '黑龙江省', c: '鸡西市', d: '密山市', r: '230382' },
    { p: '黑龙江省', c: '鹤岗市', d: '鹤岗市', r: '230400' },
    { p: '黑龙江省', c: '鹤岗市', d: '向阳区', r: '230402' },
    { p: '黑龙江省', c: '鹤岗市', d: '工农区', r: '230403' },
    { p: '黑龙江省', c: '鹤岗市', d: '南山区', r: '230404' },
    { p: '黑龙江省', c: '鹤岗市', d: '兴安区', r: '230405' },
    { p: '黑龙江省', c: '鹤岗市', d: '东山区', r: '230406' },
    { p: '黑龙江省', c: '鹤岗市', d: '兴山区', r: '230407' },
    { p: '黑龙江省', c: '鹤岗市', d: '萝北县', r: '230421' },
    { p: '黑龙江省', c: '鹤岗市', d: '绥滨县', r: '230422' },
    { p: '黑龙江省', c: '双鸭山市', d: '双鸭山市', r: '230500' },
    { p: '黑龙江省', c: '双鸭山市', d: '尖山区', r: '230502' },
    { p: '黑龙江省', c: '双鸭山市', d: '岭东区', r: '230503' },
    { p: '黑龙江省', c: '双鸭山市', d: '四方台区', r: '230505' },
    { p: '黑龙江省', c: '双鸭山市', d: '宝山区', r: '230506' },
    { p: '黑龙江省', c: '双鸭山市', d: '集贤县', r: '230521' },
    { p: '黑龙江省', c: '双鸭山市', d: '友谊县', r: '230522' },
    { p: '黑龙江省', c: '双鸭山市', d: '宝清县', r: '230523' },
    { p: '黑龙江省', c: '双鸭山市', d: '饶河县', r: '230524' },
    { p: '黑龙江省', c: '大庆市', d: '大庆市', r: '230600' },
    { p: '黑龙江省', c: '大庆市', d: '萨尔图区', r: '230602' },
    { p: '黑龙江省', c: '大庆市', d: '龙凤区', r: '230603' },
    { p: '黑龙江省', c: '大庆市', d: '让胡路区', r: '230604' },
    { p: '黑龙江省', c: '大庆市', d: '红岗区', r: '230605' },
    { p: '黑龙江省', c: '大庆市', d: '大同区', r: '230606' },
    { p: '黑龙江省', c: '大庆市', d: '肇州县', r: '230621' },
    { p: '黑龙江省', c: '大庆市', d: '肇源县', r: '230622' },
    { p: '黑龙江省', c: '大庆市', d: '林甸县', r: '230623' },
    { p: '黑龙江省', c: '大庆市', d: '杜尔伯特蒙古族自治县', r: '230624' },
    { p: '黑龙江省', c: '伊春市', d: '伊春市', r: '230700' },
    { p: '黑龙江省', c: '伊春市', d: '伊美区', r: '230717' },
    { p: '黑龙江省', c: '伊春市', d: '乌翠区', r: '230718' },
    { p: '黑龙江省', c: '伊春市', d: '友好区', r: '230719' },
    { p: '黑龙江省', c: '伊春市', d: '嘉荫县', r: '230722' },
    { p: '黑龙江省', c: '伊春市', d: '汤旺县', r: '230723' },
    { p: '黑龙江省', c: '伊春市', d: '丰林县', r: '230724' },
    { p: '黑龙江省', c: '伊春市', d: '大箐山县', r: '230725' },
    { p: '黑龙江省', c: '伊春市', d: '南岔县', r: '230726' },
    { p: '黑龙江省', c: '伊春市', d: '金林区', r: '230751' },
    { p: '黑龙江省', c: '伊春市', d: '铁力市', r: '230781' },
    { p: '黑龙江省', c: '佳木斯市', d: '佳木斯市', r: '230800' },
    { p: '黑龙江省', c: '佳木斯市', d: '向阳区', r: '230803' },
    { p: '黑龙江省', c: '佳木斯市', d: '前进区', r: '230804' },
    { p: '黑龙江省', c: '佳木斯市', d: '东风区', r: '230805' },
    { p: '黑龙江省', c: '佳木斯市', d: '郊区', r: '230811' },
    { p: '黑龙江省', c: '佳木斯市', d: '桦南县', r: '230822' },
    { p: '黑龙江省', c: '佳木斯市', d: '桦川县', r: '230826' },
    { p: '黑龙江省', c: '佳木斯市', d: '汤原县', r: '230828' },
    { p: '黑龙江省', c: '佳木斯市', d: '同江市', r: '230881' },
    { p: '黑龙江省', c: '佳木斯市', d: '富锦市', r: '230882' },
    { p: '黑龙江省', c: '佳木斯市', d: '抚远市', r: '230883' },
    { p: '黑龙江省', c: '七台河市', d: '七台河市', r: '230900' },
    { p: '黑龙江省', c: '七台河市', d: '新兴区', r: '230902' },
    { p: '黑龙江省', c: '七台河市', d: '桃山区', r: '230903' },
    { p: '黑龙江省', c: '七台河市', d: '茄子河区', r: '230904' },
    { p: '黑龙江省', c: '七台河市', d: '勃利县', r: '230921' },
    { p: '黑龙江省', c: '牡丹江市', d: '牡丹江市', r: '231000' },
    { p: '黑龙江省', c: '牡丹江市', d: '东安区', r: '231002' },
    { p: '黑龙江省', c: '牡丹江市', d: '阳明区', r: '231003' },
    { p: '黑龙江省', c: '牡丹江市', d: '爱民区', r: '231004' },
    { p: '黑龙江省', c: '牡丹江市', d: '西安区', r: '231005' },
    { p: '黑龙江省', c: '牡丹江市', d: '林口县', r: '231025' },
    { p: '黑龙江省', c: '牡丹江市', d: '绥芬河市', r: '231081' },
    { p: '黑龙江省', c: '牡丹江市', d: '海林市', r: '231083' },
    { p: '黑龙江省', c: '牡丹江市', d: '宁安市', r: '231084' },
    { p: '黑龙江省', c: '牡丹江市', d: '穆棱市', r: '231085' },
    { p: '黑龙江省', c: '牡丹江市', d: '东宁市', r: '231086' },
    { p: '黑龙江省', c: '黑河市', d: '黑河市', r: '231100' },
    { p: '黑龙江省', c: '黑河市', d: '爱辉区', r: '231102' },
    { p: '黑龙江省', c: '黑河市', d: '逊克县', r: '231123' },
    { p: '黑龙江省', c: '黑河市', d: '孙吴县', r: '231124' },
    { p: '黑龙江省', c: '黑河市', d: '北安市', r: '231181' },
    { p: '黑龙江省', c: '黑河市', d: '五大连池市', r: '231182' },
    { p: '黑龙江省', c: '黑河市', d: '嫩江市', r: '231183' },
    { p: '黑龙江省', c: '绥化市', d: '绥化市', r: '231200' },
    { p: '黑龙江省', c: '绥化市', d: '北林区', r: '231202' },
    { p: '黑龙江省', c: '绥化市', d: '望奎县', r: '231221' },
    { p: '黑龙江省', c: '绥化市', d: '兰西县', r: '231222' },
    { p: '黑龙江省', c: '绥化市', d: '青冈县', r: '231223' },
    { p: '黑龙江省', c: '绥化市', d: '庆安县', r: '231224' },
    { p: '黑龙江省', c: '绥化市', d: '明水县', r: '231225' },
    { p: '黑龙江省', c: '绥化市', d: '绥棱县', r: '231226' },
    { p: '黑龙江省', c: '绥化市', d: '安达市', r: '231281' },
    { p: '黑龙江省', c: '绥化市', d: '肇东市', r: '231282' },
    { p: '黑龙江省', c: '绥化市', d: '海伦市', r: '231283' },
    { p: '黑龙江省', c: '大兴安岭地区', d: '大兴安岭地区', r: '232700' },
    { p: '黑龙江省', c: '大兴安岭地区', d: '漠河市', r: '232701' },
    { p: '黑龙江省', c: '大兴安岭地区', d: '呼玛县', r: '232721' },
    { p: '黑龙江省', c: '大兴安岭地区', d: '塔河县', r: '232722' },
    { p: '上海市', c: '上海市', d: '上海市', r: '310000' },
    { p: '上海市', c: '上海市', d: '黄浦区', r: '310101' },
    { p: '上海市', c: '上海市', d: '徐汇区', r: '310104' },
    { p: '上海市', c: '上海市', d: '长宁区', r: '310105' },
    { p: '上海市', c: '上海市', d: '静安区', r: '310106' },
    { p: '上海市', c: '上海市', d: '普陀区', r: '310107' },
    { p: '上海市', c: '上海市', d: '虹口区', r: '310109' },
    { p: '上海市', c: '上海市', d: '杨浦区', r: '310110' },
    { p: '上海市', c: '上海市', d: '闵行区', r: '310112' },
    { p: '上海市', c: '上海市', d: '宝山区', r: '310113' },
    { p: '上海市', c: '上海市', d: '嘉定区', r: '310114' },
    { p: '上海市', c: '上海市', d: '浦东新区', r: '310115' },
    { p: '上海市', c: '上海市', d: '金山区', r: '310116' },
    { p: '上海市', c: '上海市', d: '松江区', r: '310117' },
    { p: '上海市', c: '上海市', d: '青浦区', r: '310118' },
    { p: '上海市', c: '上海市', d: '奉贤区', r: '310120' },
    { p: '上海市', c: '上海市', d: '崇明区', r: '310151' },
    { p: '江苏省', c: '江苏省', d: '江苏省', r: '320000' },
    { p: '江苏省', c: '南京市', d: '南京市', r: '320100' },
    { p: '江苏省', c: '南京市', d: '玄武区', r: '320102' },
    { p: '江苏省', c: '南京市', d: '秦淮区', r: '320104' },
    { p: '江苏省', c: '南京市', d: '建邺区', r: '320105' },
    { p: '江苏省', c: '南京市', d: '鼓楼区', r: '320106' },
    { p: '江苏省', c: '南京市', d: '浦口区', r: '320111' },
    { p: '江苏省', c: '南京市', d: '栖霞区', r: '320113' },
    { p: '江苏省', c: '南京市', d: '雨花台区', r: '320114' },
    { p: '江苏省', c: '南京市', d: '江宁区', r: '320115' },
    { p: '江苏省', c: '南京市', d: '六合区', r: '320116' },
    { p: '江苏省', c: '南京市', d: '溧水区', r: '320117' },
    { p: '江苏省', c: '南京市', d: '高淳区', r: '320118' },
    { p: '江苏省', c: '无锡市', d: '无锡市', r: '320200' },
    { p: '江苏省', c: '无锡市', d: '锡山区', r: '320205' },
    { p: '江苏省', c: '无锡市', d: '惠山区', r: '320206' },
    { p: '江苏省', c: '无锡市', d: '滨湖区', r: '320211' },
    { p: '江苏省', c: '无锡市', d: '梁溪区', r: '320213' },
    { p: '江苏省', c: '无锡市', d: '新吴区', r: '320214' },
    { p: '江苏省', c: '无锡市', d: '江阴市', r: '320281' },
    { p: '江苏省', c: '无锡市', d: '宜兴市', r: '320282' },
    { p: '江苏省', c: '徐州市', d: '徐州市', r: '320300' },
    { p: '江苏省', c: '徐州市', d: '鼓楼区', r: '320302' },
    { p: '江苏省', c: '徐州市', d: '云龙区', r: '320303' },
    { p: '江苏省', c: '徐州市', d: '贾汪区', r: '320305' },
    { p: '江苏省', c: '徐州市', d: '泉山区', r: '320311' },
    { p: '江苏省', c: '徐州市', d: '铜山区', r: '320312' },
    { p: '江苏省', c: '徐州市', d: '丰县', r: '320321' },
    { p: '江苏省', c: '徐州市', d: '沛县', r: '320322' },
    { p: '江苏省', c: '徐州市', d: '睢宁县', r: '320324' },
    { p: '江苏省', c: '徐州市', d: '新沂市', r: '320381' },
    { p: '江苏省', c: '徐州市', d: '邳州市', r: '320382' },
    { p: '江苏省', c: '常州市', d: '常州市', r: '320400' },
    { p: '江苏省', c: '常州市', d: '天宁区', r: '320402' },
    { p: '江苏省', c: '常州市', d: '钟楼区', r: '320404' },
    { p: '江苏省', c: '常州市', d: '新北区', r: '320411' },
    { p: '江苏省', c: '常州市', d: '武进区', r: '320412' },
    { p: '江苏省', c: '常州市', d: '金坛区', r: '320413' },
    { p: '江苏省', c: '常州市', d: '溧阳市', r: '320481' },
    { p: '江苏省', c: '苏州市', d: '苏州市', r: '320500' },
    { p: '江苏省', c: '苏州市', d: '虎丘区', r: '320505' },
    { p: '江苏省', c: '苏州市', d: '吴中区', r: '320506' },
    { p: '江苏省', c: '苏州市', d: '相城区', r: '320507' },
    { p: '江苏省', c: '苏州市', d: '姑苏区', r: '320508' },
    { p: '江苏省', c: '苏州市', d: '吴江区', r: '320509' },
    { p: '江苏省', c: '苏州市', d: '常熟市', r: '320581' },
    { p: '江苏省', c: '苏州市', d: '张家港市', r: '320582' },
    { p: '江苏省', c: '苏州市', d: '昆山市', r: '320583' },
    { p: '江苏省', c: '苏州市', d: '太仓市', r: '320585' },
    { p: '江苏省', c: '南通市', d: '南通市', r: '320600' },
    { p: '江苏省', c: '南通市', d: '通州区', r: '320612' },
    { p: '江苏省', c: '南通市', d: '崇川区', r: '320613' },
    { p: '江苏省', c: '南通市', d: '海门区', r: '320614' },
    { p: '江苏省', c: '南通市', d: '如东县', r: '320623' },
    { p: '江苏省', c: '南通市', d: '启东市', r: '320681' },
    { p: '江苏省', c: '南通市', d: '如皋市', r: '320682' },
    { p: '江苏省', c: '南通市', d: '海安市', r: '320685' },
    { p: '江苏省', c: '连云港市', d: '连云港市', r: '320700' },
    { p: '江苏省', c: '连云港市', d: '连云区', r: '320703' },
    { p: '江苏省', c: '连云港市', d: '海州区', r: '320706' },
    { p: '江苏省', c: '连云港市', d: '赣榆区', r: '320707' },
    { p: '江苏省', c: '连云港市', d: '东海县', r: '320722' },
    { p: '江苏省', c: '连云港市', d: '灌云县', r: '320723' },
    { p: '江苏省', c: '连云港市', d: '灌南县', r: '320724' },
    { p: '江苏省', c: '淮安市', d: '淮安市', r: '320800' },
    { p: '江苏省', c: '淮安市', d: '淮安区', r: '320803' },
    { p: '江苏省', c: '淮安市', d: '淮阴区', r: '320804' },
    { p: '江苏省', c: '淮安市', d: '清江浦区', r: '320812' },
    { p: '江苏省', c: '淮安市', d: '洪泽区', r: '320813' },
    { p: '江苏省', c: '淮安市', d: '涟水县', r: '320826' },
    { p: '江苏省', c: '淮安市', d: '盱眙县', r: '320830' },
    { p: '江苏省', c: '淮安市', d: '金湖县', r: '320831' },
    { p: '江苏省', c: '盐城市', d: '盐城市', r: '320900' },
    { p: '江苏省', c: '盐城市', d: '亭湖区', r: '320902' },
    { p: '江苏省', c: '盐城市', d: '盐都区', r: '320903' },
    { p: '江苏省', c: '盐城市', d: '大丰区', r: '320904' },
    { p: '江苏省', c: '盐城市', d: '响水县', r: '320921' },
    { p: '江苏省', c: '盐城市', d: '滨海县', r: '320922' },
    { p: '江苏省', c: '盐城市', d: '阜宁县', r: '320923' },
    { p: '江苏省', c: '盐城市', d: '射阳县', r: '320924' },
    { p: '江苏省', c: '盐城市', d: '建湖县', r: '320925' },
    { p: '江苏省', c: '盐城市', d: '东台市', r: '320981' },
    { p: '江苏省', c: '扬州市', d: '扬州市', r: '321000' },
    { p: '江苏省', c: '扬州市', d: '广陵区', r: '321002' },
    { p: '江苏省', c: '扬州市', d: '邗江区', r: '321003' },
    { p: '江苏省', c: '扬州市', d: '江都区', r: '321012' },
    { p: '江苏省', c: '扬州市', d: '宝应县', r: '321023' },
    { p: '江苏省', c: '扬州市', d: '仪征市', r: '321081' },
    { p: '江苏省', c: '扬州市', d: '高邮市', r: '321084' },
    { p: '江苏省', c: '镇江市', d: '镇江市', r: '321100' },
    { p: '江苏省', c: '镇江市', d: '京口区', r: '321102' },
    { p: '江苏省', c: '镇江市', d: '润州区', r: '321111' },
    { p: '江苏省', c: '镇江市', d: '丹徒区', r: '321112' },
    { p: '江苏省', c: '镇江市', d: '丹阳市', r: '321181' },
    { p: '江苏省', c: '镇江市', d: '扬中市', r: '321182' },
    { p: '江苏省', c: '镇江市', d: '句容市', r: '321183' },
    { p: '江苏省', c: '泰州市', d: '泰州市', r: '321200' },
    { p: '江苏省', c: '泰州市', d: '海陵区', r: '321202' },
    { p: '江苏省', c: '泰州市', d: '高港区', r: '321203' },
    { p: '江苏省', c: '泰州市', d: '姜堰区', r: '321204' },
    { p: '江苏省', c: '泰州市', d: '兴化市', r: '321281' },
    { p: '江苏省', c: '泰州市', d: '靖江市', r: '321282' },
    { p: '江苏省', c: '泰州市', d: '泰兴市', r: '321283' },
    { p: '江苏省', c: '宿迁市', d: '宿迁市', r: '321300' },
    { p: '江苏省', c: '宿迁市', d: '宿城区', r: '321302' },
    { p: '江苏省', c: '宿迁市', d: '宿豫区', r: '321311' },
    { p: '江苏省', c: '宿迁市', d: '沭阳县', r: '321322' },
    { p: '江苏省', c: '宿迁市', d: '泗阳县', r: '321323' },
    { p: '江苏省', c: '宿迁市', d: '泗洪县', r: '321324' },
    { p: '浙江省', c: '浙江省', d: '浙江省', r: '330000' },
    { p: '浙江省', c: '杭州市', d: '杭州市', r: '330100' },
    { p: '浙江省', c: '杭州市', d: '上城区', r: '330102' },
    { p: '浙江省', c: '杭州市', d: '拱墅区', r: '330105' },
    { p: '浙江省', c: '杭州市', d: '西湖区', r: '330106' },
    { p: '浙江省', c: '杭州市', d: '滨江区', r: '330108' },
    { p: '浙江省', c: '杭州市', d: '萧山区', r: '330109' },
    { p: '浙江省', c: '杭州市', d: '余杭区', r: '330110' },
    { p: '浙江省', c: '杭州市', d: '富阳区', r: '330111' },
    { p: '浙江省', c: '杭州市', d: '临安区', r: '330112' },
    { p: '浙江省', c: '杭州市', d: '临平区', r: '330113' },
    { p: '浙江省', c: '杭州市', d: '钱塘区', r: '330114' },
    { p: '浙江省', c: '杭州市', d: '桐庐县', r: '330122' },
    { p: '浙江省', c: '杭州市', d: '淳安县', r: '330127' },
    { p: '浙江省', c: '杭州市', d: '建德市', r: '330182' },
    { p: '浙江省', c: '宁波市', d: '宁波市', r: '330200' },
    { p: '浙江省', c: '宁波市', d: '海曙区', r: '330203' },
    { p: '浙江省', c: '宁波市', d: '江北区', r: '330205' },
    { p: '浙江省', c: '宁波市', d: '北仑区', r: '330206' },
    { p: '浙江省', c: '宁波市', d: '镇海区', r: '330211' },
    { p: '浙江省', c: '宁波市', d: '鄞州区', r: '330212' },
    { p: '浙江省', c: '宁波市', d: '奉化区', r: '330213' },
    { p: '浙江省', c: '宁波市', d: '象山县', r: '330225' },
    { p: '浙江省', c: '宁波市', d: '宁海县', r: '330226' },
    { p: '浙江省', c: '宁波市', d: '余姚市', r: '330281' },
    { p: '浙江省', c: '宁波市', d: '慈溪市', r: '330282' },
    { p: '浙江省', c: '温州市', d: '温州市', r: '330300' },
    { p: '浙江省', c: '温州市', d: '鹿城区', r: '330302' },
    { p: '浙江省', c: '温州市', d: '龙湾区', r: '330303' },
    { p: '浙江省', c: '温州市', d: '瓯海区', r: '330304' },
    { p: '浙江省', c: '温州市', d: '洞头区', r: '330305' },
    { p: '浙江省', c: '温州市', d: '永嘉县', r: '330324' },
    { p: '浙江省', c: '温州市', d: '平阳县', r: '330326' },
    { p: '浙江省', c: '温州市', d: '苍南县', r: '330327' },
    { p: '浙江省', c: '温州市', d: '文成县', r: '330328' },
    { p: '浙江省', c: '温州市', d: '泰顺县', r: '330329' },
    { p: '浙江省', c: '温州市', d: '瑞安市', r: '330381' },
    { p: '浙江省', c: '温州市', d: '乐清市', r: '330382' },
    { p: '浙江省', c: '温州市', d: '龙港市', r: '330383' },
    { p: '浙江省', c: '嘉兴市', d: '嘉兴市', r: '330400' },
    { p: '浙江省', c: '嘉兴市', d: '南湖区', r: '330402' },
    { p: '浙江省', c: '嘉兴市', d: '秀洲区', r: '330411' },
    { p: '浙江省', c: '嘉兴市', d: '嘉善县', r: '330421' },
    { p: '浙江省', c: '嘉兴市', d: '海盐县', r: '330424' },
    { p: '浙江省', c: '嘉兴市', d: '海宁市', r: '330481' },
    { p: '浙江省', c: '嘉兴市', d: '平湖市', r: '330482' },
    { p: '浙江省', c: '嘉兴市', d: '桐乡市', r: '330483' },
    { p: '浙江省', c: '湖州市', d: '湖州市', r: '330500' },
    { p: '浙江省', c: '湖州市', d: '吴兴区', r: '330502' },
    { p: '浙江省', c: '湖州市', d: '南浔区', r: '330503' },
    { p: '浙江省', c: '湖州市', d: '德清县', r: '330521' },
    { p: '浙江省', c: '湖州市', d: '长兴县', r: '330522' },
    { p: '浙江省', c: '湖州市', d: '安吉县', r: '330523' },
    { p: '浙江省', c: '绍兴市', d: '绍兴市', r: '330600' },
    { p: '浙江省', c: '绍兴市', d: '越城区', r: '330602' },
    { p: '浙江省', c: '绍兴市', d: '柯桥区', r: '330603' },
    { p: '浙江省', c: '绍兴市', d: '上虞区', r: '330604' },
    { p: '浙江省', c: '绍兴市', d: '新昌县', r: '330624' },
    { p: '浙江省', c: '绍兴市', d: '诸暨市', r: '330681' },
    { p: '浙江省', c: '绍兴市', d: '嵊州市', r: '330683' },
    { p: '浙江省', c: '金华市', d: '金华市', r: '330700' },
    { p: '浙江省', c: '金华市', d: '婺城区', r: '330702' },
    { p: '浙江省', c: '金华市', d: '金东区', r: '330703' },
    { p: '浙江省', c: '金华市', d: '武义县', r: '330723' },
    { p: '浙江省', c: '金华市', d: '浦江县', r: '330726' },
    { p: '浙江省', c: '金华市', d: '磐安县', r: '330727' },
    { p: '浙江省', c: '金华市', d: '兰溪市', r: '330781' },
    { p: '浙江省', c: '金华市', d: '义乌市', r: '330782' },
    { p: '浙江省', c: '金华市', d: '东阳市', r: '330783' },
    { p: '浙江省', c: '金华市', d: '永康市', r: '330784' },
    { p: '浙江省', c: '衢州市', d: '衢州市', r: '330800' },
    { p: '浙江省', c: '衢州市', d: '柯城区', r: '330802' },
    { p: '浙江省', c: '衢州市', d: '衢江区', r: '330803' },
    { p: '浙江省', c: '衢州市', d: '常山县', r: '330822' },
    { p: '浙江省', c: '衢州市', d: '开化县', r: '330824' },
    { p: '浙江省', c: '衢州市', d: '龙游县', r: '330825' },
    { p: '浙江省', c: '衢州市', d: '江山市', r: '330881' },
    { p: '浙江省', c: '舟山市', d: '舟山市', r: '330900' },
    { p: '浙江省', c: '舟山市', d: '定海区', r: '330902' },
    { p: '浙江省', c: '舟山市', d: '普陀区', r: '330903' },
    { p: '浙江省', c: '舟山市', d: '岱山县', r: '330921' },
    { p: '浙江省', c: '舟山市', d: '嵊泗县', r: '330922' },
    { p: '浙江省', c: '台州市', d: '台州市', r: '331000' },
    { p: '浙江省', c: '台州市', d: '椒江区', r: '331002' },
    { p: '浙江省', c: '台州市', d: '黄岩区', r: '331003' },
    { p: '浙江省', c: '台州市', d: '路桥区', r: '331004' },
    { p: '浙江省', c: '台州市', d: '三门县', r: '331022' },
    { p: '浙江省', c: '台州市', d: '天台县', r: '331023' },
    { p: '浙江省', c: '台州市', d: '仙居县', r: '331024' },
    { p: '浙江省', c: '台州市', d: '温岭市', r: '331081' },
    { p: '浙江省', c: '台州市', d: '临海市', r: '331082' },
    { p: '浙江省', c: '台州市', d: '玉环市', r: '331083' },
    { p: '浙江省', c: '丽水市', d: '丽水市', r: '331100' },
    { p: '浙江省', c: '丽水市', d: '莲都区', r: '331102' },
    { p: '浙江省', c: '丽水市', d: '青田县', r: '331121' },
    { p: '浙江省', c: '丽水市', d: '缙云县', r: '331122' },
    { p: '浙江省', c: '丽水市', d: '遂昌县', r: '331123' },
    { p: '浙江省', c: '丽水市', d: '松阳县', r: '331124' },
    { p: '浙江省', c: '丽水市', d: '云和县', r: '331125' },
    { p: '浙江省', c: '丽水市', d: '庆元县', r: '331126' },
    { p: '浙江省', c: '丽水市', d: '景宁畲族自治县', r: '331127' },
    { p: '浙江省', c: '丽水市', d: '龙泉市', r: '331181' },
    { p: '安徽省', c: '安徽省', d: '安徽省', r: '340000' },
    { p: '安徽省', c: '合肥市', d: '合肥市', r: '340100' },
    { p: '安徽省', c: '合肥市', d: '瑶海区', r: '340102' },
    { p: '安徽省', c: '合肥市', d: '庐阳区', r: '340103' },
    { p: '安徽省', c: '合肥市', d: '蜀山区', r: '340104' },
    { p: '安徽省', c: '合肥市', d: '包河区', r: '340111' },
    { p: '安徽省', c: '合肥市', d: '长丰县', r: '340121' },
    { p: '安徽省', c: '合肥市', d: '肥东县', r: '340122' },
    { p: '安徽省', c: '合肥市', d: '肥西县', r: '340123' },
    { p: '安徽省', c: '合肥市', d: '庐江县', r: '340124' },
    { p: '安徽省', c: '合肥市', d: '巢湖市', r: '340181' },
    { p: '安徽省', c: '芜湖市', d: '芜湖市', r: '340200' },
    { p: '安徽省', c: '芜湖市', d: '镜湖区', r: '340202' },
    { p: '安徽省', c: '芜湖市', d: '鸠江区', r: '340207' },
    { p: '安徽省', c: '芜湖市', d: '弋江区', r: '340209' },
    { p: '安徽省', c: '芜湖市', d: '湾沚区', r: '340210' },
    { p: '安徽省', c: '芜湖市', d: '繁昌区', r: '340212' },
    { p: '安徽省', c: '芜湖市', d: '南陵县', r: '340223' },
    { p: '安徽省', c: '芜湖市', d: '无为市', r: '340281' },
    { p: '安徽省', c: '蚌埠市', d: '蚌埠市', r: '340300' },
    { p: '安徽省', c: '蚌埠市', d: '龙子湖区', r: '340302' },
    { p: '安徽省', c: '蚌埠市', d: '蚌山区', r: '340303' },
    { p: '安徽省', c: '蚌埠市', d: '禹会区', r: '340304' },
    { p: '安徽省', c: '蚌埠市', d: '淮上区', r: '340311' },
    { p: '安徽省', c: '蚌埠市', d: '怀远县', r: '340321' },
    { p: '安徽省', c: '蚌埠市', d: '五河县', r: '340322' },
    { p: '安徽省', c: '蚌埠市', d: '固镇县', r: '340323' },
    { p: '安徽省', c: '淮南市', d: '淮南市', r: '340400' },
    { p: '安徽省', c: '淮南市', d: '大通区', r: '340402' },
    { p: '安徽省', c: '淮南市', d: '田家庵区', r: '340403' },
    { p: '安徽省', c: '淮南市', d: '谢家集区', r: '340404' },
    { p: '安徽省', c: '淮南市', d: '八公山区', r: '340405' },
    { p: '安徽省', c: '淮南市', d: '潘集区', r: '340406' },
    { p: '安徽省', c: '淮南市', d: '凤台县', r: '340421' },
    { p: '安徽省', c: '淮南市', d: '寿县', r: '340422' },
    { p: '安徽省', c: '马鞍山市', d: '马鞍山市', r: '340500' },
    { p: '安徽省', c: '马鞍山市', d: '花山区', r: '340503' },
    { p: '安徽省', c: '马鞍山市', d: '雨山区', r: '340504' },
    { p: '安徽省', c: '马鞍山市', d: '博望区', r: '340506' },
    { p: '安徽省', c: '马鞍山市', d: '当涂县', r: '340521' },
    { p: '安徽省', c: '马鞍山市', d: '含山县', r: '340522' },
    { p: '安徽省', c: '马鞍山市', d: '和县', r: '340523' },
    { p: '安徽省', c: '淮北市', d: '淮北市', r: '340600' },
    { p: '安徽省', c: '淮北市', d: '杜集区', r: '340602' },
    { p: '安徽省', c: '淮北市', d: '相山区', r: '340603' },
    { p: '安徽省', c: '淮北市', d: '烈山区', r: '340604' },
    { p: '安徽省', c: '淮北市', d: '濉溪县', r: '340621' },
    { p: '安徽省', c: '铜陵市', d: '铜陵市', r: '340700' },
    { p: '安徽省', c: '铜陵市', d: '铜官区', r: '340705' },
    { p: '安徽省', c: '铜陵市', d: '义安区', r: '340706' },
    { p: '安徽省', c: '铜陵市', d: '郊区', r: '340711' },
    { p: '安徽省', c: '铜陵市', d: '枞阳县', r: '340722' },
    { p: '安徽省', c: '安庆市', d: '安庆市', r: '340800' },
    { p: '安徽省', c: '安庆市', d: '迎江区', r: '340802' },
    { p: '安徽省', c: '安庆市', d: '大观区', r: '340803' },
    { p: '安徽省', c: '安庆市', d: '宜秀区', r: '340811' },
    { p: '安徽省', c: '安庆市', d: '怀宁县', r: '340822' },
    { p: '安徽省', c: '安庆市', d: '太湖县', r: '340825' },
    { p: '安徽省', c: '安庆市', d: '宿松县', r: '340826' },
    { p: '安徽省', c: '安庆市', d: '望江县', r: '340827' },
    { p: '安徽省', c: '安庆市', d: '岳西县', r: '340828' },
    { p: '安徽省', c: '安庆市', d: '桐城市', r: '340881' },
    { p: '安徽省', c: '安庆市', d: '潜山市', r: '340882' },
    { p: '安徽省', c: '黄山市', d: '黄山市', r: '341000' },
    { p: '安徽省', c: '黄山市', d: '屯溪区', r: '341002' },
    { p: '安徽省', c: '黄山市', d: '黄山区', r: '341003' },
    { p: '安徽省', c: '黄山市', d: '徽州区', r: '341004' },
    { p: '安徽省', c: '黄山市', d: '歙县', r: '341021' },
    { p: '安徽省', c: '黄山市', d: '休宁县', r: '341022' },
    { p: '安徽省', c: '黄山市', d: '黟县', r: '341023' },
    { p: '安徽省', c: '黄山市', d: '祁门县', r: '341024' },
    { p: '安徽省', c: '滁州市', d: '滁州市', r: '341100' },
    { p: '安徽省', c: '滁州市', d: '琅琊区', r: '341102' },
    { p: '安徽省', c: '滁州市', d: '南谯区', r: '341103' },
    { p: '安徽省', c: '滁州市', d: '来安县', r: '341122' },
    { p: '安徽省', c: '滁州市', d: '全椒县', r: '341124' },
    { p: '安徽省', c: '滁州市', d: '定远县', r: '341125' },
    { p: '安徽省', c: '滁州市', d: '凤阳县', r: '341126' },
    { p: '安徽省', c: '滁州市', d: '天长市', r: '341181' },
    { p: '安徽省', c: '滁州市', d: '明光市', r: '341182' },
    { p: '安徽省', c: '阜阳市', d: '阜阳市', r: '341200' },
    { p: '安徽省', c: '阜阳市', d: '颍州区', r: '341202' },
    { p: '安徽省', c: '阜阳市', d: '颍东区', r: '341203' },
    { p: '安徽省', c: '阜阳市', d: '颍泉区', r: '341204' },
    { p: '安徽省', c: '阜阳市', d: '临泉县', r: '341221' },
    { p: '安徽省', c: '阜阳市', d: '太和县', r: '341222' },
    { p: '安徽省', c: '阜阳市', d: '阜南县', r: '341225' },
    { p: '安徽省', c: '阜阳市', d: '颍上县', r: '341226' },
    { p: '安徽省', c: '阜阳市', d: '界首市', r: '341282' },
    { p: '安徽省', c: '宿州市', d: '宿州市', r: '341300' },
    { p: '安徽省', c: '宿州市', d: '埇桥区', r: '341302' },
    { p: '安徽省', c: '宿州市', d: '砀山县', r: '341321' },
    { p: '安徽省', c: '宿州市', d: '萧县', r: '341322' },
    { p: '安徽省', c: '宿州市', d: '灵璧县', r: '341323' },
    { p: '安徽省', c: '宿州市', d: '泗县', r: '341324' },
    { p: '安徽省', c: '六安市', d: '六安市', r: '341500' },
    { p: '安徽省', c: '六安市', d: '金安区', r: '341502' },
    { p: '安徽省', c: '六安市', d: '裕安区', r: '341503' },
    { p: '安徽省', c: '六安市', d: '叶集区', r: '341504' },
    { p: '安徽省', c: '六安市', d: '霍邱县', r: '341522' },
    { p: '安徽省', c: '六安市', d: '舒城县', r: '341523' },
    { p: '安徽省', c: '六安市', d: '金寨县', r: '341524' },
    { p: '安徽省', c: '六安市', d: '霍山县', r: '341525' },
    { p: '安徽省', c: '亳州市', d: '亳州市', r: '341600' },
    { p: '安徽省', c: '亳州市', d: '谯城区', r: '341602' },
    { p: '安徽省', c: '亳州市', d: '涡阳县', r: '341621' },
    { p: '安徽省', c: '亳州市', d: '蒙城县', r: '341622' },
    { p: '安徽省', c: '亳州市', d: '利辛县', r: '341623' },
    { p: '安徽省', c: '池州市', d: '池州市', r: '341700' },
    { p: '安徽省', c: '池州市', d: '贵池区', r: '341702' },
    { p: '安徽省', c: '池州市', d: '东至县', r: '341721' },
    { p: '安徽省', c: '池州市', d: '石台县', r: '341722' },
    { p: '安徽省', c: '池州市', d: '青阳县', r: '341723' },
    { p: '安徽省', c: '宣城市', d: '宣城市', r: '341800' },
    { p: '安徽省', c: '宣城市', d: '宣州区', r: '341802' },
    { p: '安徽省', c: '宣城市', d: '郎溪县', r: '341821' },
    { p: '安徽省', c: '宣城市', d: '泾县', r: '341823' },
    { p: '安徽省', c: '宣城市', d: '绩溪县', r: '341824' },
    { p: '安徽省', c: '宣城市', d: '旌德县', r: '341825' },
    { p: '安徽省', c: '宣城市', d: '宁国市', r: '341881' },
    { p: '安徽省', c: '宣城市', d: '广德市', r: '341882' },
    { p: '福建省', c: '福建省', d: '福建省', r: '350000' },
    { p: '福建省', c: '福州市', d: '福州市', r: '350100' },
    { p: '福建省', c: '福州市', d: '鼓楼区', r: '350102' },
    { p: '福建省', c: '福州市', d: '台江区', r: '350103' },
    { p: '福建省', c: '福州市', d: '仓山区', r: '350104' },
    { p: '福建省', c: '福州市', d: '马尾区', r: '350105' },
    { p: '福建省', c: '福州市', d: '晋安区', r: '350111' },
    { p: '福建省', c: '福州市', d: '长乐区', r: '350112' },
    { p: '福建省', c: '福州市', d: '闽侯县', r: '350121' },
    { p: '福建省', c: '福州市', d: '连江县', r: '350122' },
    { p: '福建省', c: '福州市', d: '罗源县', r: '350123' },
    { p: '福建省', c: '福州市', d: '闽清县', r: '350124' },
    { p: '福建省', c: '福州市', d: '永泰县', r: '350125' },
    { p: '福建省', c: '福州市', d: '平潭县', r: '350128' },
    { p: '福建省', c: '福州市', d: '福清市', r: '350181' },
    { p: '福建省', c: '厦门市', d: '厦门市', r: '350200' },
    { p: '福建省', c: '厦门市', d: '思明区', r: '350203' },
    { p: '福建省', c: '厦门市', d: '海沧区', r: '350205' },
    { p: '福建省', c: '厦门市', d: '湖里区', r: '350206' },
    { p: '福建省', c: '厦门市', d: '集美区', r: '350211' },
    { p: '福建省', c: '厦门市', d: '同安区', r: '350212' },
    { p: '福建省', c: '厦门市', d: '翔安区', r: '350213' },
    { p: '福建省', c: '莆田市', d: '莆田市', r: '350300' },
    { p: '福建省', c: '莆田市', d: '城厢区', r: '350302' },
    { p: '福建省', c: '莆田市', d: '涵江区', r: '350303' },
    { p: '福建省', c: '莆田市', d: '荔城区', r: '350304' },
    { p: '福建省', c: '莆田市', d: '秀屿区', r: '350305' },
    { p: '福建省', c: '莆田市', d: '仙游县', r: '350322' },
    { p: '福建省', c: '三明市', d: '三明市', r: '350400' },
    { p: '福建省', c: '三明市', d: '三元区', r: '350404' },
    { p: '福建省', c: '三明市', d: '沙县区', r: '350405' },
    { p: '福建省', c: '三明市', d: '明溪县', r: '350421' },
    { p: '福建省', c: '三明市', d: '清流县', r: '350423' },
    { p: '福建省', c: '三明市', d: '宁化县', r: '350424' },
    { p: '福建省', c: '三明市', d: '大田县', r: '350425' },
    { p: '福建省', c: '三明市', d: '尤溪县', r: '350426' },
    { p: '福建省', c: '三明市', d: '将乐县', r: '350428' },
    { p: '福建省', c: '三明市', d: '泰宁县', r: '350429' },
    { p: '福建省', c: '三明市', d: '建宁县', r: '350430' },
    { p: '福建省', c: '三明市', d: '永安市', r: '350481' },
    { p: '福建省', c: '泉州市', d: '泉州市', r: '350500' },
    { p: '福建省', c: '泉州市', d: '鲤城区', r: '350502' },
    { p: '福建省', c: '泉州市', d: '丰泽区', r: '350503' },
    { p: '福建省', c: '泉州市', d: '洛江区', r: '350504' },
    { p: '福建省', c: '泉州市', d: '泉港区', r: '350505' },
    { p: '福建省', c: '泉州市', d: '惠安县', r: '350521' },
    { p: '福建省', c: '泉州市', d: '安溪县', r: '350524' },
    { p: '福建省', c: '泉州市', d: '永春县', r: '350525' },
    { p: '福建省', c: '泉州市', d: '德化县', r: '350526' },
    { p: '福建省', c: '泉州市', d: '金门县', r: '350527' },
    { p: '福建省', c: '泉州市', d: '石狮市', r: '350581' },
    { p: '福建省', c: '泉州市', d: '晋江市', r: '350582' },
    { p: '福建省', c: '泉州市', d: '南安市', r: '350583' },
    { p: '福建省', c: '漳州市', d: '漳州市', r: '350600' },
    { p: '福建省', c: '漳州市', d: '芗城区', r: '350602' },
    { p: '福建省', c: '漳州市', d: '龙文区', r: '350603' },
    { p: '福建省', c: '漳州市', d: '龙海区', r: '350604' },
    { p: '福建省', c: '漳州市', d: '长泰区', r: '350605' },
    { p: '福建省', c: '漳州市', d: '云霄县', r: '350622' },
    { p: '福建省', c: '漳州市', d: '漳浦县', r: '350623' },
    { p: '福建省', c: '漳州市', d: '诏安县', r: '350624' },
    { p: '福建省', c: '漳州市', d: '东山县', r: '350626' },
    { p: '福建省', c: '漳州市', d: '南靖县', r: '350627' },
    { p: '福建省', c: '漳州市', d: '平和县', r: '350628' },
    { p: '福建省', c: '漳州市', d: '华安县', r: '350629' },
    { p: '福建省', c: '南平市', d: '南平市', r: '350700' },
    { p: '福建省', c: '南平市', d: '延平区', r: '350702' },
    { p: '福建省', c: '南平市', d: '建阳区', r: '350703' },
    { p: '福建省', c: '南平市', d: '顺昌县', r: '350721' },
    { p: '福建省', c: '南平市', d: '浦城县', r: '350722' },
    { p: '福建省', c: '南平市', d: '光泽县', r: '350723' },
    { p: '福建省', c: '南平市', d: '松溪县', r: '350724' },
    { p: '福建省', c: '南平市', d: '政和县', r: '350725' },
    { p: '福建省', c: '南平市', d: '邵武市', r: '350781' },
    { p: '福建省', c: '南平市', d: '武夷山市', r: '350782' },
    { p: '福建省', c: '南平市', d: '建瓯市', r: '350783' },
    { p: '福建省', c: '龙岩市', d: '龙岩市', r: '350800' },
    { p: '福建省', c: '龙岩市', d: '新罗区', r: '350802' },
    { p: '福建省', c: '龙岩市', d: '永定区', r: '350803' },
    { p: '福建省', c: '龙岩市', d: '长汀县', r: '350821' },
    { p: '福建省', c: '龙岩市', d: '上杭县', r: '350823' },
    { p: '福建省', c: '龙岩市', d: '武平县', r: '350824' },
    { p: '福建省', c: '龙岩市', d: '连城县', r: '350825' },
    { p: '福建省', c: '龙岩市', d: '漳平市', r: '350881' },
    { p: '福建省', c: '宁德市', d: '宁德市', r: '350900' },
    { p: '福建省', c: '宁德市', d: '蕉城区', r: '350902' },
    { p: '福建省', c: '宁德市', d: '霞浦县', r: '350921' },
    { p: '福建省', c: '宁德市', d: '古田县', r: '350922' },
    { p: '福建省', c: '宁德市', d: '屏南县', r: '350923' },
    { p: '福建省', c: '宁德市', d: '寿宁县', r: '350924' },
    { p: '福建省', c: '宁德市', d: '周宁县', r: '350925' },
    { p: '福建省', c: '宁德市', d: '柘荣县', r: '350926' },
    { p: '福建省', c: '宁德市', d: '福安市', r: '350981' },
    { p: '福建省', c: '宁德市', d: '福鼎市', r: '350982' },
    { p: '江西省', c: '江西省', d: '江西省', r: '360000' },
    { p: '江西省', c: '南昌市', d: '南昌市', r: '360100' },
    { p: '江西省', c: '南昌市', d: '东湖区', r: '360102' },
    { p: '江西省', c: '南昌市', d: '西湖区', r: '360103' },
    { p: '江西省', c: '南昌市', d: '青云谱区', r: '360104' },
    { p: '江西省', c: '南昌市', d: '青山湖区', r: '360111' },
    { p: '江西省', c: '南昌市', d: '新建区', r: '360112' },
    { p: '江西省', c: '南昌市', d: '红谷滩区', r: '360113' },
    { p: '江西省', c: '南昌市', d: '南昌县', r: '360121' },
    { p: '江西省', c: '南昌市', d: '安义县', r: '360123' },
    { p: '江西省', c: '南昌市', d: '进贤县', r: '360124' },
    { p: '江西省', c: '景德镇市', d: '景德镇市', r: '360200' },
    { p: '江西省', c: '景德镇市', d: '昌江区', r: '360202' },
    { p: '江西省', c: '景德镇市', d: '珠山区', r: '360203' },
    { p: '江西省', c: '景德镇市', d: '浮梁县', r: '360222' },
    { p: '江西省', c: '景德镇市', d: '乐平市', r: '360281' },
    { p: '江西省', c: '萍乡市', d: '萍乡市', r: '360300' },
    { p: '江西省', c: '萍乡市', d: '安源区', r: '360302' },
    { p: '江西省', c: '萍乡市', d: '湘东区', r: '360313' },
    { p: '江西省', c: '萍乡市', d: '莲花县', r: '360321' },
    { p: '江西省', c: '萍乡市', d: '上栗县', r: '360322' },
    { p: '江西省', c: '萍乡市', d: '芦溪县', r: '360323' },
    { p: '江西省', c: '九江市', d: '九江市', r: '360400' },
    { p: '江西省', c: '九江市', d: '濂溪区', r: '360402' },
    { p: '江西省', c: '九江市', d: '浔阳区', r: '360403' },
    { p: '江西省', c: '九江市', d: '柴桑区', r: '360404' },
    { p: '江西省', c: '九江市', d: '武宁县', r: '360423' },
    { p: '江西省', c: '九江市', d: '修水县', r: '360424' },
    { p: '江西省', c: '九江市', d: '永修县', r: '360425' },
    { p: '江西省', c: '九江市', d: '德安县', r: '360426' },
    { p: '江西省', c: '九江市', d: '都昌县', r: '360428' },
    { p: '江西省', c: '九江市', d: '湖口县', r: '360429' },
    { p: '江西省', c: '九江市', d: '彭泽县', r: '360430' },
    { p: '江西省', c: '九江市', d: '瑞昌市', r: '360481' },
    { p: '江西省', c: '九江市', d: '共青城市', r: '360482' },
    { p: '江西省', c: '九江市', d: '庐山市', r: '360483' },
    { p: '江西省', c: '新余市', d: '新余市', r: '360500' },
    { p: '江西省', c: '新余市', d: '渝水区', r: '360502' },
    { p: '江西省', c: '新余市', d: '分宜县', r: '360521' },
    { p: '江西省', c: '鹰潭市', d: '鹰潭市', r: '360600' },
    { p: '江西省', c: '鹰潭市', d: '月湖区', r: '360602' },
    { p: '江西省', c: '鹰潭市', d: '余江区', r: '360603' },
    { p: '江西省', c: '鹰潭市', d: '贵溪市', r: '360681' },
    { p: '江西省', c: '赣州市', d: '赣州市', r: '360700' },
    { p: '江西省', c: '赣州市', d: '章贡区', r: '360702' },
    { p: '江西省', c: '赣州市', d: '南康区', r: '360703' },
    { p: '江西省', c: '赣州市', d: '赣县区', r: '360704' },
    { p: '江西省', c: '赣州市', d: '信丰县', r: '360722' },
    { p: '江西省', c: '赣州市', d: '大余县', r: '360723' },
    { p: '江西省', c: '赣州市', d: '上犹县', r: '360724' },
    { p: '江西省', c: '赣州市', d: '崇义县', r: '360725' },
    { p: '江西省', c: '赣州市', d: '安远县', r: '360726' },
    { p: '江西省', c: '赣州市', d: '定南县', r: '360728' },
    { p: '江西省', c: '赣州市', d: '全南县', r: '360729' },
    { p: '江西省', c: '赣州市', d: '宁都县', r: '360730' },
    { p: '江西省', c: '赣州市', d: '于都县', r: '360731' },
    { p: '江西省', c: '赣州市', d: '兴国县', r: '360732' },
    { p: '江西省', c: '赣州市', d: '会昌县', r: '360733' },
    { p: '江西省', c: '赣州市', d: '寻乌县', r: '360734' },
    { p: '江西省', c: '赣州市', d: '石城县', r: '360735' },
    { p: '江西省', c: '赣州市', d: '瑞金市', r: '360781' },
    { p: '江西省', c: '赣州市', d: '龙南市', r: '360783' },
    { p: '江西省', c: '吉安市', d: '吉安市', r: '360800' },
    { p: '江西省', c: '吉安市', d: '吉州区', r: '360802' },
    { p: '江西省', c: '吉安市', d: '青原区', r: '360803' },
    { p: '江西省', c: '吉安市', d: '吉安县', r: '360821' },
    { p: '江西省', c: '吉安市', d: '吉水县', r: '360822' },
    { p: '江西省', c: '吉安市', d: '峡江县', r: '360823' },
    { p: '江西省', c: '吉安市', d: '新干县', r: '360824' },
    { p: '江西省', c: '吉安市', d: '永丰县', r: '360825' },
    { p: '江西省', c: '吉安市', d: '泰和县', r: '360826' },
    { p: '江西省', c: '吉安市', d: '遂川县', r: '360827' },
    { p: '江西省', c: '吉安市', d: '万安县', r: '360828' },
    { p: '江西省', c: '吉安市', d: '安福县', r: '360829' },
    { p: '江西省', c: '吉安市', d: '永新县', r: '360830' },
    { p: '江西省', c: '吉安市', d: '井冈山市', r: '360881' },
    { p: '江西省', c: '宜春市', d: '宜春市', r: '360900' },
    { p: '江西省', c: '宜春市', d: '袁州区', r: '360902' },
    { p: '江西省', c: '宜春市', d: '奉新县', r: '360921' },
    { p: '江西省', c: '宜春市', d: '万载县', r: '360922' },
    { p: '江西省', c: '宜春市', d: '上高县', r: '360923' },
    { p: '江西省', c: '宜春市', d: '宜丰县', r: '360924' },
    { p: '江西省', c: '宜春市', d: '靖安县', r: '360925' },
    { p: '江西省', c: '宜春市', d: '铜鼓县', r: '360926' },
    { p: '江西省', c: '宜春市', d: '丰城市', r: '360981' },
    { p: '江西省', c: '宜春市', d: '樟树市', r: '360982' },
    { p: '江西省', c: '宜春市', d: '高安市', r: '360983' },
    { p: '江西省', c: '抚州市', d: '抚州市', r: '361000' },
    { p: '江西省', c: '抚州市', d: '临川区', r: '361002' },
    { p: '江西省', c: '抚州市', d: '东乡区', r: '361003' },
    { p: '江西省', c: '抚州市', d: '南城县', r: '361021' },
    { p: '江西省', c: '抚州市', d: '黎川县', r: '361022' },
    { p: '江西省', c: '抚州市', d: '南丰县', r: '361023' },
    { p: '江西省', c: '抚州市', d: '崇仁县', r: '361024' },
    { p: '江西省', c: '抚州市', d: '乐安县', r: '361025' },
    { p: '江西省', c: '抚州市', d: '宜黄县', r: '361026' },
    { p: '江西省', c: '抚州市', d: '金溪县', r: '361027' },
    { p: '江西省', c: '抚州市', d: '资溪县', r: '361028' },
    { p: '江西省', c: '抚州市', d: '广昌县', r: '361030' },
    { p: '江西省', c: '上饶市', d: '上饶市', r: '361100' },
    { p: '江西省', c: '上饶市', d: '信州区', r: '361102' },
    { p: '江西省', c: '上饶市', d: '广丰区', r: '361103' },
    { p: '江西省', c: '上饶市', d: '广信区', r: '361104' },
    { p: '江西省', c: '上饶市', d: '玉山县', r: '361123' },
    { p: '江西省', c: '上饶市', d: '铅山县', r: '361124' },
    { p: '江西省', c: '上饶市', d: '横峰县', r: '361125' },
    { p: '江西省', c: '上饶市', d: '弋阳县', r: '361126' },
    { p: '江西省', c: '上饶市', d: '余干县', r: '361127' },
    { p: '江西省', c: '上饶市', d: '鄱阳县', r: '361128' },
    { p: '江西省', c: '上饶市', d: '万年县', r: '361129' },
    { p: '江西省', c: '上饶市', d: '婺源县', r: '361130' },
    { p: '江西省', c: '上饶市', d: '德兴市', r: '361181' },
    { p: '山东省', c: '山东省', d: '山东省', r: '370000' },
    { p: '山东省', c: '济南市', d: '济南市', r: '370100' },
    { p: '山东省', c: '济南市', d: '历下区', r: '370102' },
    { p: '山东省', c: '济南市', d: '市中区', r: '370103' },
    { p: '山东省', c: '济南市', d: '槐荫区', r: '370104' },
    { p: '山东省', c: '济南市', d: '天桥区', r: '370105' },
    { p: '山东省', c: '济南市', d: '历城区', r: '370112' },
    { p: '山东省', c: '济南市', d: '长清区', r: '370113' },
    { p: '山东省', c: '济南市', d: '章丘区', r: '370114' },
    { p: '山东省', c: '济南市', d: '济阳区', r: '370115' },
    { p: '山东省', c: '济南市', d: '莱芜区', r: '370116' },
    { p: '山东省', c: '济南市', d: '钢城区', r: '370117' },
    { p: '山东省', c: '济南市', d: '平阴县', r: '370124' },
    { p: '山东省', c: '济南市', d: '商河县', r: '370126' },
    { p: '山东省', c: '青岛市', d: '青岛市', r: '370200' },
    { p: '山东省', c: '青岛市', d: '市南区', r: '370202' },
    { p: '山东省', c: '青岛市', d: '市北区', r: '370203' },
    { p: '山东省', c: '青岛市', d: '黄岛区', r: '370211' },
    { p: '山东省', c: '青岛市', d: '崂山区', r: '370212' },
    { p: '山东省', c: '青岛市', d: '李沧区', r: '370213' },
    { p: '山东省', c: '青岛市', d: '城阳区', r: '370214' },
    { p: '山东省', c: '青岛市', d: '即墨区', r: '370215' },
    { p: '山东省', c: '青岛市', d: '胶州市', r: '370281' },
    { p: '山东省', c: '青岛市', d: '平度市', r: '370283' },
    { p: '山东省', c: '青岛市', d: '莱西市', r: '370285' },
    { p: '山东省', c: '淄博市', d: '淄博市', r: '370300' },
    { p: '山东省', c: '淄博市', d: '淄川区', r: '370302' },
    { p: '山东省', c: '淄博市', d: '张店区', r: '370303' },
    { p: '山东省', c: '淄博市', d: '博山区', r: '370304' },
    { p: '山东省', c: '淄博市', d: '临淄区', r: '370305' },
    { p: '山东省', c: '淄博市', d: '周村区', r: '370306' },
    { p: '山东省', c: '淄博市', d: '桓台县', r: '370321' },
    { p: '山东省', c: '淄博市', d: '高青县', r: '370322' },
    { p: '山东省', c: '淄博市', d: '沂源县', r: '370323' },
    { p: '山东省', c: '枣庄市', d: '枣庄市', r: '370400' },
    { p: '山东省', c: '枣庄市', d: '市中区', r: '370402' },
    { p: '山东省', c: '枣庄市', d: '薛城区', r: '370403' },
    { p: '山东省', c: '枣庄市', d: '峄城区', r: '370404' },
    { p: '山东省', c: '枣庄市', d: '台儿庄区', r: '370405' },
    { p: '山东省', c: '枣庄市', d: '山亭区', r: '370406' },
    { p: '山东省', c: '枣庄市', d: '滕州市', r: '370481' },
    { p: '山东省', c: '东营市', d: '东营市', r: '370500' },
    { p: '山东省', c: '东营市', d: '东营区', r: '370502' },
    { p: '山东省', c: '东营市', d: '河口区', r: '370503' },
    { p: '山东省', c: '东营市', d: '垦利区', r: '370505' },
    { p: '山东省', c: '东营市', d: '利津县', r: '370522' },
    { p: '山东省', c: '东营市', d: '广饶县', r: '370523' },
    { p: '山东省', c: '烟台市', d: '烟台市', r: '370600' },
    { p: '山东省', c: '烟台市', d: '芝罘区', r: '370602' },
    { p: '山东省', c: '烟台市', d: '福山区', r: '370611' },
    { p: '山东省', c: '烟台市', d: '牟平区', r: '370612' },
    { p: '山东省', c: '烟台市', d: '莱山区', r: '370613' },
    { p: '山东省', c: '烟台市', d: '蓬莱区', r: '370614' },
    { p: '山东省', c: '烟台市', d: '龙口市', r: '370681' },
    { p: '山东省', c: '烟台市', d: '莱阳市', r: '370682' },
    { p: '山东省', c: '烟台市', d: '莱州市', r: '370683' },
    { p: '山东省', c: '烟台市', d: '招远市', r: '370685' },
    { p: '山东省', c: '烟台市', d: '栖霞市', r: '370686' },
    { p: '山东省', c: '烟台市', d: '海阳市', r: '370687' },
    { p: '山东省', c: '潍坊市', d: '潍坊市', r: '370700' },
    { p: '山东省', c: '潍坊市', d: '潍城区', r: '370702' },
    { p: '山东省', c: '潍坊市', d: '寒亭区', r: '370703' },
    { p: '山东省', c: '潍坊市', d: '坊子区', r: '370704' },
    { p: '山东省', c: '潍坊市', d: '奎文区', r: '370705' },
    { p: '山东省', c: '潍坊市', d: '临朐县', r: '370724' },
    { p: '山东省', c: '潍坊市', d: '昌乐县', r: '370725' },
    { p: '山东省', c: '潍坊市', d: '青州市', r: '370781' },
    { p: '山东省', c: '潍坊市', d: '诸城市', r: '370782' },
    { p: '山东省', c: '潍坊市', d: '寿光市', r: '370783' },
    { p: '山东省', c: '潍坊市', d: '安丘市', r: '370784' },
    { p: '山东省', c: '潍坊市', d: '高密市', r: '370785' },
    { p: '山东省', c: '潍坊市', d: '昌邑市', r: '370786' },
    { p: '山东省', c: '济宁市', d: '济宁市', r: '370800' },
    { p: '山东省', c: '济宁市', d: '任城区', r: '370811' },
    { p: '山东省', c: '济宁市', d: '兖州区', r: '370812' },
    { p: '山东省', c: '济宁市', d: '微山县', r: '370826' },
    { p: '山东省', c: '济宁市', d: '鱼台县', r: '370827' },
    { p: '山东省', c: '济宁市', d: '金乡县', r: '370828' },
    { p: '山东省', c: '济宁市', d: '嘉祥县', r: '370829' },
    { p: '山东省', c: '济宁市', d: '汶上县', r: '370830' },
    { p: '山东省', c: '济宁市', d: '泗水县', r: '370831' },
    { p: '山东省', c: '济宁市', d: '梁山县', r: '370832' },
    { p: '山东省', c: '济宁市', d: '曲阜市', r: '370881' },
    { p: '山东省', c: '济宁市', d: '邹城市', r: '370883' },
    { p: '山东省', c: '泰安市', d: '泰安市', r: '370900' },
    { p: '山东省', c: '泰安市', d: '泰山区', r: '370902' },
    { p: '山东省', c: '泰安市', d: '岱岳区', r: '370911' },
    { p: '山东省', c: '泰安市', d: '宁阳县', r: '370921' },
    { p: '山东省', c: '泰安市', d: '东平县', r: '370923' },
    { p: '山东省', c: '泰安市', d: '新泰市', r: '370982' },
    { p: '山东省', c: '泰安市', d: '肥城市', r: '370983' },
    { p: '山东省', c: '威海市', d: '威海市', r: '371000' },
    { p: '山东省', c: '威海市', d: '环翠区', r: '371002' },
    { p: '山东省', c: '威海市', d: '文登区', r: '371003' },
    { p: '山东省', c: '威海市', d: '荣成市', r: '371082' },
    { p: '山东省', c: '威海市', d: '乳山市', r: '371083' },
    { p: '山东省', c: '日照市', d: '日照市', r: '371100' },
    { p: '山东省', c: '日照市', d: '东港区', r: '371102' },
    { p: '山东省', c: '日照市', d: '岚山区', r: '371103' },
    { p: '山东省', c: '日照市', d: '五莲县', r: '371121' },
    { p: '山东省', c: '日照市', d: '莒县', r: '371122' },
    { p: '山东省', c: '临沂市', d: '临沂市', r: '371300' },
    { p: '山东省', c: '临沂市', d: '兰山区', r: '371302' },
    { p: '山东省', c: '临沂市', d: '罗庄区', r: '371311' },
    { p: '山东省', c: '临沂市', d: '河东区', r: '371312' },
    { p: '山东省', c: '临沂市', d: '沂南县', r: '371321' },
    { p: '山东省', c: '临沂市', d: '郯城县', r: '371322' },
    { p: '山东省', c: '临沂市', d: '沂水县', r: '371323' },
    { p: '山东省', c: '临沂市', d: '兰陵县', r: '371324' },
    { p: '山东省', c: '临沂市', d: '费县', r: '371325' },
    { p: '山东省', c: '临沂市', d: '平邑县', r: '371326' },
    { p: '山东省', c: '临沂市', d: '莒南县', r: '371327' },
    { p: '山东省', c: '临沂市', d: '蒙阴县', r: '371328' },
    { p: '山东省', c: '临沂市', d: '临沭县', r: '371329' },
    { p: '山东省', c: '德州市', d: '德州市', r: '371400' },
    { p: '山东省', c: '德州市', d: '德城区', r: '371402' },
    { p: '山东省', c: '德州市', d: '陵城区', r: '371403' },
    { p: '山东省', c: '德州市', d: '宁津县', r: '371422' },
    { p: '山东省', c: '德州市', d: '庆云县', r: '371423' },
    { p: '山东省', c: '德州市', d: '临邑县', r: '371424' },
    { p: '山东省', c: '德州市', d: '齐河县', r: '371425' },
    { p: '山东省', c: '德州市', d: '平原县', r: '371426' },
    { p: '山东省', c: '德州市', d: '夏津县', r: '371427' },
    { p: '山东省', c: '德州市', d: '武城县', r: '371428' },
    { p: '山东省', c: '德州市', d: '乐陵市', r: '371481' },
    { p: '山东省', c: '德州市', d: '禹城市', r: '371482' },
    { p: '山东省', c: '聊城市', d: '聊城市', r: '371500' },
    { p: '山东省', c: '聊城市', d: '东昌府区', r: '371502' },
    { p: '山东省', c: '聊城市', d: '茌平区', r: '371503' },
    { p: '山东省', c: '聊城市', d: '阳谷县', r: '371521' },
    { p: '山东省', c: '聊城市', d: '莘县', r: '371522' },
    { p: '山东省', c: '聊城市', d: '东阿县', r: '371524' },
    { p: '山东省', c: '聊城市', d: '冠县', r: '371525' },
    { p: '山东省', c: '聊城市', d: '高唐县', r: '371526' },
    { p: '山东省', c: '聊城市', d: '临清市', r: '371581' },
    { p: '山东省', c: '滨州市', d: '滨州市', r: '371600' },
    { p: '山东省', c: '滨州市', d: '滨城区', r: '371602' },
    { p: '山东省', c: '滨州市', d: '沾化区', r: '371603' },
    { p: '山东省', c: '滨州市', d: '惠民县', r: '371621' },
    { p: '山东省', c: '滨州市', d: '阳信县', r: '371622' },
    { p: '山东省', c: '滨州市', d: '无棣县', r: '371623' },
    { p: '山东省', c: '滨州市', d: '博兴县', r: '371625' },
    { p: '山东省', c: '滨州市', d: '邹平市', r: '371681' },
    { p: '山东省', c: '菏泽市', d: '菏泽市', r: '371700' },
    { p: '山东省', c: '菏泽市', d: '牡丹区', r: '371702' },
    { p: '山东省', c: '菏泽市', d: '定陶区', r: '371703' },
    { p: '山东省', c: '菏泽市', d: '曹县', r: '371721' },
    { p: '山东省', c: '菏泽市', d: '单县', r: '371722' },
    { p: '山东省', c: '菏泽市', d: '成武县', r: '371723' },
    { p: '山东省', c: '菏泽市', d: '巨野县', r: '371724' },
    { p: '山东省', c: '菏泽市', d: '郓城县', r: '371725' },
    { p: '山东省', c: '菏泽市', d: '鄄城县', r: '371726' },
    { p: '山东省', c: '菏泽市', d: '东明县', r: '371728' },
    { p: '河南省', c: '河南省', d: '河南省', r: '410000' },
    { p: '河南省', c: '郑州市', d: '郑州市', r: '410100' },
    { p: '河南省', c: '郑州市', d: '中原区', r: '410102' },
    { p: '河南省', c: '郑州市', d: '二七区', r: '410103' },
    { p: '河南省', c: '郑州市', d: '管城回族区', r: '410104' },
    { p: '河南省', c: '郑州市', d: '金水区', r: '410105' },
    { p: '河南省', c: '郑州市', d: '上街区', r: '410106' },
    { p: '河南省', c: '郑州市', d: '惠济区', r: '410108' },
    { p: '河南省', c: '郑州市', d: '中牟县', r: '410122' },
    { p: '河南省', c: '郑州市', d: '巩义市', r: '410181' },
    { p: '河南省', c: '郑州市', d: '荥阳市', r: '410182' },
    { p: '河南省', c: '郑州市', d: '新密市', r: '410183' },
    { p: '河南省', c: '郑州市', d: '新郑市', r: '410184' },
    { p: '河南省', c: '郑州市', d: '登封市', r: '410185' },
    { p: '河南省', c: '开封市', d: '开封市', r: '410200' },
    { p: '河南省', c: '开封市', d: '龙亭区', r: '410202' },
    { p: '河南省', c: '开封市', d: '顺河回族区', r: '410203' },
    { p: '河南省', c: '开封市', d: '鼓楼区', r: '410204' },
    { p: '河南省', c: '开封市', d: '禹王台区', r: '410205' },
    { p: '河南省', c: '开封市', d: '祥符区', r: '410212' },
    { p: '河南省', c: '开封市', d: '杞县', r: '410221' },
    { p: '河南省', c: '开封市', d: '通许县', r: '410222' },
    { p: '河南省', c: '开封市', d: '尉氏县', r: '410223' },
    { p: '河南省', c: '开封市', d: '兰考县', r: '410225' },
    { p: '河南省', c: '洛阳市', d: '洛阳市', r: '410300' },
    { p: '河南省', c: '洛阳市', d: '老城区', r: '410302' },
    { p: '河南省', c: '洛阳市', d: '西工区', r: '410303' },
    { p: '河南省', c: '洛阳市', d: '瀍河回族区', r: '410304' },
    { p: '河南省', c: '洛阳市', d: '涧西区', r: '410305' },
    { p: '河南省', c: '洛阳市', d: '偃师区', r: '410307' },
    { p: '河南省', c: '洛阳市', d: '孟津区', r: '410308' },
    { p: '河南省', c: '洛阳市', d: '洛龙区', r: '410311' },
    { p: '河南省', c: '洛阳市', d: '新安县', r: '410323' },
    { p: '河南省', c: '洛阳市', d: '栾川县', r: '410324' },
    { p: '河南省', c: '洛阳市', d: '嵩县', r: '410325' },
    { p: '河南省', c: '洛阳市', d: '汝阳县', r: '410326' },
    { p: '河南省', c: '洛阳市', d: '宜阳县', r: '410327' },
    { p: '河南省', c: '洛阳市', d: '洛宁县', r: '410328' },
    { p: '河南省', c: '洛阳市', d: '伊川县', r: '410329' },
    { p: '河南省', c: '平顶山市', d: '平顶山市', r: '410400' },
    { p: '河南省', c: '平顶山市', d: '新华区', r: '410402' },
    { p: '河南省', c: '平顶山市', d: '卫东区', r: '410403' },
    { p: '河南省', c: '平顶山市', d: '石龙区', r: '410404' },
    { p: '河南省', c: '平顶山市', d: '湛河区', r: '410411' },
    { p: '河南省', c: '平顶山市', d: '宝丰县', r: '410421' },
    { p: '河南省', c: '平顶山市', d: '叶县', r: '410422' },
    { p: '河南省', c: '平顶山市', d: '鲁山县', r: '410423' },
    { p: '河南省', c: '平顶山市', d: '郏县', r: '410425' },
    { p: '河南省', c: '平顶山市', d: '舞钢市', r: '410481' },
    { p: '河南省', c: '平顶山市', d: '汝州市', r: '410482' },
    { p: '河南省', c: '安阳市', d: '安阳市', r: '410500' },
    { p: '河南省', c: '安阳市', d: '文峰区', r: '410502' },
    { p: '河南省', c: '安阳市', d: '北关区', r: '410503' },
    { p: '河南省', c: '安阳市', d: '殷都区', r: '410505' },
    { p: '河南省', c: '安阳市', d: '龙安区', r: '410506' },
    { p: '河南省', c: '安阳市', d: '安阳县', r: '410522' },
    { p: '河南省', c: '安阳市', d: '汤阴县', r: '410523' },
    { p: '河南省', c: '安阳市', d: '滑县', r: '410526' },
    { p: '河南省', c: '安阳市', d: '内黄县', r: '410527' },
    { p: '河南省', c: '安阳市', d: '林州市', r: '410581' },
    { p: '河南省', c: '鹤壁市', d: '鹤壁市', r: '410600' },
    { p: '河南省', c: '鹤壁市', d: '鹤山区', r: '410602' },
    { p: '河南省', c: '鹤壁市', d: '山城区', r: '410603' },
    { p: '河南省', c: '鹤壁市', d: '淇滨区', r: '410611' },
    { p: '河南省', c: '鹤壁市', d: '浚县', r: '410621' },
    { p: '河南省', c: '鹤壁市', d: '淇县', r: '410622' },
    { p: '河南省', c: '新乡市', d: '新乡市', r: '410700' },
    { p: '河南省', c: '新乡市', d: '红旗区', r: '410702' },
    { p: '河南省', c: '新乡市', d: '卫滨区', r: '410703' },
    { p: '河南省', c: '新乡市', d: '凤泉区', r: '410704' },
    { p: '河南省', c: '新乡市', d: '牧野区', r: '410711' },
    { p: '河南省', c: '新乡市', d: '新乡县', r: '410721' },
    { p: '河南省', c: '新乡市', d: '获嘉县', r: '410724' },
    { p: '河南省', c: '新乡市', d: '原阳县', r: '410725' },
    { p: '河南省', c: '新乡市', d: '延津县', r: '410726' },
    { p: '河南省', c: '新乡市', d: '封丘县', r: '410727' },
    { p: '河南省', c: '新乡市', d: '卫辉市', r: '410781' },
    { p: '河南省', c: '新乡市', d: '辉县市', r: '410782' },
    { p: '河南省', c: '新乡市', d: '长垣市', r: '410783' },
    { p: '河南省', c: '焦作市', d: '焦作市', r: '410800' },
    { p: '河南省', c: '焦作市', d: '解放区', r: '410802' },
    { p: '河南省', c: '焦作市', d: '中站区', r: '410803' },
    { p: '河南省', c: '焦作市', d: '马村区', r: '410804' },
    { p: '河南省', c: '焦作市', d: '山阳区', r: '410811' },
    { p: '河南省', c: '焦作市', d: '修武县', r: '410821' },
    { p: '河南省', c: '焦作市', d: '博爱县', r: '410822' },
    { p: '河南省', c: '焦作市', d: '武陟县', r: '410823' },
    { p: '河南省', c: '焦作市', d: '温县', r: '410825' },
    { p: '河南省', c: '焦作市', d: '沁阳市', r: '410882' },
    { p: '河南省', c: '焦作市', d: '孟州市', r: '410883' },
    { p: '河南省', c: '濮阳市', d: '濮阳市', r: '410900' },
    { p: '河南省', c: '濮阳市', d: '华龙区', r: '410902' },
    { p: '河南省', c: '濮阳市', d: '清丰县', r: '410922' },
    { p: '河南省', c: '濮阳市', d: '南乐县', r: '410923' },
    { p: '河南省', c: '濮阳市', d: '范县', r: '410926' },
    { p: '河南省', c: '濮阳市', d: '台前县', r: '410927' },
    { p: '河南省', c: '濮阳市', d: '濮阳县', r: '410928' },
    { p: '河南省', c: '许昌市', d: '许昌市', r: '411000' },
    { p: '河南省', c: '许昌市', d: '魏都区', r: '411002' },
    { p: '河南省', c: '许昌市', d: '建安区', r: '411003' },
    { p: '河南省', c: '许昌市', d: '鄢陵县', r: '411024' },
    { p: '河南省', c: '许昌市', d: '襄城县', r: '411025' },
    { p: '河南省', c: '许昌市', d: '禹州市', r: '411081' },
    { p: '河南省', c: '许昌市', d: '长葛市', r: '411082' },
    { p: '河南省', c: '漯河市', d: '漯河市', r: '411100' },
    { p: '河南省', c: '漯河市', d: '源汇区', r: '411102' },
    { p: '河南省', c: '漯河市', d: '郾城区', r: '411103' },
    { p: '河南省', c: '漯河市', d: '召陵区', r: '411104' },
    { p: '河南省', c: '漯河市', d: '舞阳县', r: '411121' },
    { p: '河南省', c: '漯河市', d: '临颍县', r: '411122' },
    { p: '河南省', c: '三门峡市', d: '三门峡市', r: '411200' },
    { p: '河南省', c: '三门峡市', d: '湖滨区', r: '411202' },
    { p: '河南省', c: '三门峡市', d: '陕州区', r: '411203' },
    { p: '河南省', c: '三门峡市', d: '渑池县', r: '411221' },
    { p: '河南省', c: '三门峡市', d: '卢氏县', r: '411224' },
    { p: '河南省', c: '三门峡市', d: '义马市', r: '411281' },
    { p: '河南省', c: '三门峡市', d: '灵宝市', r: '411282' },
    { p: '河南省', c: '南阳市', d: '南阳市', r: '411300' },
    { p: '河南省', c: '南阳市', d: '宛城区', r: '411302' },
    { p: '河南省', c: '南阳市', d: '卧龙区', r: '411303' },
    { p: '河南省', c: '南阳市', d: '南召县', r: '411321' },
    { p: '河南省', c: '南阳市', d: '方城县', r: '411322' },
    { p: '河南省', c: '南阳市', d: '西峡县', r: '411323' },
    { p: '河南省', c: '南阳市', d: '镇平县', r: '411324' },
    { p: '河南省', c: '南阳市', d: '内乡县', r: '411325' },
    { p: '河南省', c: '南阳市', d: '淅川县', r: '411326' },
    { p: '河南省', c: '南阳市', d: '社旗县', r: '411327' },
    { p: '河南省', c: '南阳市', d: '唐河县', r: '411328' },
    { p: '河南省', c: '南阳市', d: '新野县', r: '411329' },
    { p: '河南省', c: '南阳市', d: '桐柏县', r: '411330' },
    { p: '河南省', c: '南阳市', d: '邓州市', r: '411381' },
    { p: '河南省', c: '商丘市', d: '商丘市', r: '411400' },
    { p: '河南省', c: '商丘市', d: '梁园区', r: '411402' },
    { p: '河南省', c: '商丘市', d: '睢阳区', r: '411403' },
    { p: '河南省', c: '商丘市', d: '民权县', r: '411421' },
    { p: '河南省', c: '商丘市', d: '睢县', r: '411422' },
    { p: '河南省', c: '商丘市', d: '宁陵县', r: '411423' },
    { p: '河南省', c: '商丘市', d: '柘城县', r: '411424' },
    { p: '河南省', c: '商丘市', d: '虞城县', r: '411425' },
    { p: '河南省', c: '商丘市', d: '夏邑县', r: '411426' },
    { p: '河南省', c: '商丘市', d: '永城市', r: '411481' },
    { p: '河南省', c: '信阳市', d: '信阳市', r: '411500' },
    { p: '河南省', c: '信阳市', d: '浉河区', r: '411502' },
    { p: '河南省', c: '信阳市', d: '平桥区', r: '411503' },
    { p: '河南省', c: '信阳市', d: '罗山县', r: '411521' },
    { p: '河南省', c: '信阳市', d: '光山县', r: '411522' },
    { p: '河南省', c: '信阳市', d: '新县', r: '411523' },
    { p: '河南省', c: '信阳市', d: '商城县', r: '411524' },
    { p: '河南省', c: '信阳市', d: '固始县', r: '411525' },
    { p: '河南省', c: '信阳市', d: '潢川县', r: '411526' },
    { p: '河南省', c: '信阳市', d: '淮滨县', r: '411527' },
    { p: '河南省', c: '信阳市', d: '息县', r: '411528' },
    { p: '河南省', c: '周口市', d: '周口市', r: '411600' },
    { p: '河南省', c: '周口市', d: '川汇区', r: '411602' },
    { p: '河南省', c: '周口市', d: '淮阳区', r: '411603' },
    { p: '河南省', c: '周口市', d: '扶沟县', r: '411621' },
    { p: '河南省', c: '周口市', d: '西华县', r: '411622' },
    { p: '河南省', c: '周口市', d: '商水县', r: '411623' },
    { p: '河南省', c: '周口市', d: '沈丘县', r: '411624' },
    { p: '河南省', c: '周口市', d: '郸城县', r: '411625' },
    { p: '河南省', c: '周口市', d: '太康县', r: '411627' },
    { p: '河南省', c: '周口市', d: '鹿邑县', r: '411628' },
    { p: '河南省', c: '周口市', d: '项城市', r: '411681' },
    { p: '河南省', c: '驻马店市', d: '驻马店市', r: '411700' },
    { p: '河南省', c: '驻马店市', d: '驿城区', r: '411702' },
    { p: '河南省', c: '驻马店市', d: '西平县', r: '411721' },
    { p: '河南省', c: '驻马店市', d: '上蔡县', r: '411722' },
    { p: '河南省', c: '驻马店市', d: '平舆县', r: '411723' },
    { p: '河南省', c: '驻马店市', d: '正阳县', r: '411724' },
    { p: '河南省', c: '驻马店市', d: '确山县', r: '411725' },
    { p: '河南省', c: '驻马店市', d: '泌阳县', r: '411726' },
    { p: '河南省', c: '驻马店市', d: '汝南县', r: '411727' },
    { p: '河南省', c: '驻马店市', d: '遂平县', r: '411728' },
    { p: '河南省', c: '驻马店市', d: '新蔡县', r: '411729' },
    { p: '河南省', c: '', d: '济源市', r: '419001' },
    { p: '湖北省', c: '湖北省', d: '湖北省', r: '420000' },
    { p: '湖北省', c: '武汉市', d: '武汉市', r: '420100' },
    { p: '湖北省', c: '武汉市', d: '江岸区', r: '420102' },
    { p: '湖北省', c: '武汉市', d: '江汉区', r: '420103' },
    { p: '湖北省', c: '武汉市', d: '硚口区', r: '420104' },
    { p: '湖北省', c: '武汉市', d: '汉阳区', r: '420105' },
    { p: '湖北省', c: '武汉市', d: '武昌区', r: '420106' },
    { p: '湖北省', c: '武汉市', d: '青山区', r: '420107' },
    { p: '湖北省', c: '武汉市', d: '洪山区', r: '420111' },
    { p: '湖北省', c: '武汉市', d: '东西湖区', r: '420112' },
    { p: '湖北省', c: '武汉市', d: '汉南区', r: '420113' },
    { p: '湖北省', c: '武汉市', d: '蔡甸区', r: '420114' },
    { p: '湖北省', c: '武汉市', d: '江夏区', r: '420115' },
    { p: '湖北省', c: '武汉市', d: '黄陂区', r: '420116' },
    { p: '湖北省', c: '武汉市', d: '新洲区', r: '420117' },
    { p: '湖北省', c: '黄石市', d: '黄石市', r: '420200' },
    { p: '湖北省', c: '黄石市', d: '黄石港区', r: '420202' },
    { p: '湖北省', c: '黄石市', d: '西塞山区', r: '420203' },
    { p: '湖北省', c: '黄石市', d: '下陆区', r: '420204' },
    { p: '湖北省', c: '黄石市', d: '铁山区', r: '420205' },
    { p: '湖北省', c: '黄石市', d: '阳新县', r: '420222' },
    { p: '湖北省', c: '黄石市', d: '大冶市', r: '420281' },
    { p: '湖北省', c: '十堰市', d: '十堰市', r: '420300' },
    { p: '湖北省', c: '十堰市', d: '茅箭区', r: '420302' },
    { p: '湖北省', c: '十堰市', d: '张湾区', r: '420303' },
    { p: '湖北省', c: '十堰市', d: '郧阳区', r: '420304' },
    { p: '湖北省', c: '十堰市', d: '郧西县', r: '420322' },
    { p: '湖北省', c: '十堰市', d: '竹山县', r: '420323' },
    { p: '湖北省', c: '十堰市', d: '竹溪县', r: '420324' },
    { p: '湖北省', c: '十堰市', d: '房县', r: '420325' },
    { p: '湖北省', c: '十堰市', d: '丹江口市', r: '420381' },
    { p: '湖北省', c: '宜昌市', d: '宜昌市', r: '420500' },
    { p: '湖北省', c: '宜昌市', d: '西陵区', r: '420502' },
    { p: '湖北省', c: '宜昌市', d: '伍家岗区', r: '420503' },
    { p: '湖北省', c: '宜昌市', d: '点军区', r: '420504' },
    { p: '湖北省', c: '宜昌市', d: '猇亭区', r: '420505' },
    { p: '湖北省', c: '宜昌市', d: '夷陵区', r: '420506' },
    { p: '湖北省', c: '宜昌市', d: '远安县', r: '420525' },
    { p: '湖北省', c: '宜昌市', d: '兴山县', r: '420526' },
    { p: '湖北省', c: '宜昌市', d: '秭归县', r: '420527' },
    { p: '湖北省', c: '宜昌市', d: '长阳土家族自治县', r: '420528' },
    { p: '湖北省', c: '宜昌市', d: '五峰土家族自治县', r: '420529' },
    { p: '湖北省', c: '宜昌市', d: '宜都市', r: '420581' },
    { p: '湖北省', c: '宜昌市', d: '当阳市', r: '420582' },
    { p: '湖北省', c: '宜昌市', d: '枝江市', r: '420583' },
    { p: '湖北省', c: '襄阳市', d: '襄阳市', r: '420600' },
    { p: '湖北省', c: '襄阳市', d: '襄城区', r: '420602' },
    { p: '湖北省', c: '襄阳市', d: '樊城区', r: '420606' },
    { p: '湖北省', c: '襄阳市', d: '襄州区', r: '420607' },
    { p: '湖北省', c: '襄阳市', d: '南漳县', r: '420624' },
    { p: '湖北省', c: '襄阳市', d: '谷城县', r: '420625' },
    { p: '湖北省', c: '襄阳市', d: '保康县', r: '420626' },
    { p: '湖北省', c: '襄阳市', d: '老河口市', r: '420682' },
    { p: '湖北省', c: '襄阳市', d: '枣阳市', r: '420683' },
    { p: '湖北省', c: '襄阳市', d: '宜城市', r: '420684' },
    { p: '湖北省', c: '鄂州市', d: '鄂州市', r: '420700' },
    { p: '湖北省', c: '鄂州市', d: '梁子湖区', r: '420702' },
    { p: '湖北省', c: '鄂州市', d: '华容区', r: '420703' },
    { p: '湖北省', c: '鄂州市', d: '鄂城区', r: '420704' },
    { p: '湖北省', c: '荆门市', d: '荆门市', r: '420800' },
    { p: '湖北省', c: '荆门市', d: '东宝区', r: '420802' },
    { p: '湖北省', c: '荆门市', d: '掇刀区', r: '420804' },
    { p: '湖北省', c: '荆门市', d: '沙洋县', r: '420822' },
    { p: '湖北省', c: '荆门市', d: '钟祥市', r: '420881' },
    { p: '湖北省', c: '荆门市', d: '京山市', r: '420882' },
    { p: '湖北省', c: '孝感市', d: '孝感市', r: '420900' },
    { p: '湖北省', c: '孝感市', d: '孝南区', r: '420902' },
    { p: '湖北省', c: '孝感市', d: '孝昌县', r: '420921' },
    { p: '湖北省', c: '孝感市', d: '大悟县', r: '420922' },
    { p: '湖北省', c: '孝感市', d: '云梦县', r: '420923' },
    { p: '湖北省', c: '孝感市', d: '应城市', r: '420981' },
    { p: '湖北省', c: '孝感市', d: '安陆市', r: '420982' },
    { p: '湖北省', c: '孝感市', d: '汉川市', r: '420984' },
    { p: '湖北省', c: '荆州市', d: '荆州市', r: '421000' },
    { p: '湖北省', c: '荆州市', d: '沙市区', r: '421002' },
    { p: '湖北省', c: '荆州市', d: '荆州区', r: '421003' },
    { p: '湖北省', c: '荆州市', d: '公安县', r: '421022' },
    { p: '湖北省', c: '荆州市', d: '江陵县', r: '421024' },
    { p: '湖北省', c: '荆州市', d: '石首市', r: '421081' },
    { p: '湖北省', c: '荆州市', d: '洪湖市', r: '421083' },
    { p: '湖北省', c: '荆州市', d: '松滋市', r: '421087' },
    { p: '湖北省', c: '荆州市', d: '监利市', r: '421088' },
    { p: '湖北省', c: '黄冈市', d: '黄冈市', r: '421100' },
    { p: '湖北省', c: '黄冈市', d: '黄州区', r: '421102' },
    { p: '湖北省', c: '黄冈市', d: '团风县', r: '421121' },
    { p: '湖北省', c: '黄冈市', d: '红安县', r: '421122' },
    { p: '湖北省', c: '黄冈市', d: '罗田县', r: '421123' },
    { p: '湖北省', c: '黄冈市', d: '英山县', r: '421124' },
    { p: '湖北省', c: '黄冈市', d: '浠水县', r: '421125' },
    { p: '湖北省', c: '黄冈市', d: '蕲春县', r: '421126' },
    { p: '湖北省', c: '黄冈市', d: '黄梅县', r: '421127' },
    { p: '湖北省', c: '黄冈市', d: '麻城市', r: '421181' },
    { p: '湖北省', c: '黄冈市', d: '武穴市', r: '421182' },
    { p: '湖北省', c: '咸宁市', d: '咸宁市', r: '421200' },
    { p: '湖北省', c: '咸宁市', d: '咸安区', r: '421202' },
    { p: '湖北省', c: '咸宁市', d: '嘉鱼县', r: '421221' },
    { p: '湖北省', c: '咸宁市', d: '通城县', r: '421222' },
    { p: '湖北省', c: '咸宁市', d: '崇阳县', r: '421223' },
    { p: '湖北省', c: '咸宁市', d: '通山县', r: '421224' },
    { p: '湖北省', c: '咸宁市', d: '赤壁市', r: '421281' },
    { p: '湖北省', c: '随州市', d: '随州市', r: '421300' },
    { p: '湖北省', c: '随州市', d: '曾都区', r: '421303' },
    { p: '湖北省', c: '随州市', d: '随县', r: '421321' },
    { p: '湖北省', c: '随州市', d: '广水市', r: '421381' },
    { p: '湖北省', c: '恩施土家族苗族自治州', d: '恩施土家族苗族自治州', r: '422800' },
    { p: '湖北省', c: '恩施土家族苗族自治州', d: '恩施市', r: '422801' },
    { p: '湖北省', c: '恩施土家族苗族自治州', d: '利川市', r: '422802' },
    { p: '湖北省', c: '恩施土家族苗族自治州', d: '建始县', r: '422822' },
    { p: '湖北省', c: '恩施土家族苗族自治州', d: '巴东县', r: '422823' },
    { p: '湖北省', c: '恩施土家族苗族自治州', d: '宣恩县', r: '422825' },
    { p: '湖北省', c: '恩施土家族苗族自治州', d: '咸丰县', r: '422826' },
    { p: '湖北省', c: '恩施土家族苗族自治州', d: '来凤县', r: '422827' },
    { p: '湖北省', c: '恩施土家族苗族自治州', d: '鹤峰县', r: '422828' },
    { p: '湖北省', c: '恩施土家族苗族自治州', d: '仙桃市', r: '429004' },
    { p: '湖北省', c: '恩施土家族苗族自治州', d: '潜江市', r: '429005' },
    { p: '湖北省', c: '恩施土家族苗族自治州', d: '天门市', r: '429006' },
    { p: '湖北省', c: '恩施土家族苗族自治州', d: '神农架林区', r: '429021' },
    { p: '湖南省', c: '湖南省', d: '湖南省', r: '430000' },
    { p: '湖南省', c: '长沙市', d: '长沙市', r: '430100' },
    { p: '湖南省', c: '长沙市', d: '芙蓉区', r: '430102' },
    { p: '湖南省', c: '长沙市', d: '天心区', r: '430103' },
    { p: '湖南省', c: '长沙市', d: '岳麓区', r: '430104' },
    { p: '湖南省', c: '长沙市', d: '开福区', r: '430105' },
    { p: '湖南省', c: '长沙市', d: '雨花区', r: '430111' },
    { p: '湖南省', c: '长沙市', d: '望城区', r: '430112' },
    { p: '湖南省', c: '长沙市', d: '长沙县', r: '430121' },
    { p: '湖南省', c: '长沙市', d: '浏阳市', r: '430181' },
    { p: '湖南省', c: '长沙市', d: '宁乡市', r: '430182' },
    { p: '湖南省', c: '株洲市', d: '株洲市', r: '430200' },
    { p: '湖南省', c: '株洲市', d: '荷塘区', r: '430202' },
    { p: '湖南省', c: '株洲市', d: '芦淞区', r: '430203' },
    { p: '湖南省', c: '株洲市', d: '石峰区', r: '430204' },
    { p: '湖南省', c: '株洲市', d: '天元区', r: '430211' },
    { p: '湖南省', c: '株洲市', d: '渌口区', r: '430212' },
    { p: '湖南省', c: '株洲市', d: '攸县', r: '430223' },
    { p: '湖南省', c: '株洲市', d: '茶陵县', r: '430224' },
    { p: '湖南省', c: '株洲市', d: '炎陵县', r: '430225' },
    { p: '湖南省', c: '株洲市', d: '醴陵市', r: '430281' },
    { p: '湖南省', c: '湘潭市', d: '湘潭市', r: '430300' },
    { p: '湖南省', c: '湘潭市', d: '雨湖区', r: '430302' },
    { p: '湖南省', c: '湘潭市', d: '岳塘区', r: '430304' },
    { p: '湖南省', c: '湘潭市', d: '湘潭县', r: '430321' },
    { p: '湖南省', c: '湘潭市', d: '湘乡市', r: '430381' },
    { p: '湖南省', c: '湘潭市', d: '韶山市', r: '430382' },
    { p: '湖南省', c: '衡阳市', d: '衡阳市', r: '430400' },
    { p: '湖南省', c: '衡阳市', d: '珠晖区', r: '430405' },
    { p: '湖南省', c: '衡阳市', d: '雁峰区', r: '430406' },
    { p: '湖南省', c: '衡阳市', d: '石鼓区', r: '430407' },
    { p: '湖南省', c: '衡阳市', d: '蒸湘区', r: '430408' },
    { p: '湖南省', c: '衡阳市', d: '南岳区', r: '430412' },
    { p: '湖南省', c: '衡阳市', d: '衡阳县', r: '430421' },
    { p: '湖南省', c: '衡阳市', d: '衡南县', r: '430422' },
    { p: '湖南省', c: '衡阳市', d: '衡山县', r: '430423' },
    { p: '湖南省', c: '衡阳市', d: '衡东县', r: '430424' },
    { p: '湖南省', c: '衡阳市', d: '祁东县', r: '430426' },
    { p: '湖南省', c: '衡阳市', d: '耒阳市', r: '430481' },
    { p: '湖南省', c: '衡阳市', d: '常宁市', r: '430482' },
    { p: '湖南省', c: '邵阳市', d: '邵阳市', r: '430500' },
    { p: '湖南省', c: '邵阳市', d: '双清区', r: '430502' },
    { p: '湖南省', c: '邵阳市', d: '大祥区', r: '430503' },
    { p: '湖南省', c: '邵阳市', d: '北塔区', r: '430511' },
    { p: '湖南省', c: '邵阳市', d: '新邵县', r: '430522' },
    { p: '湖南省', c: '邵阳市', d: '邵阳县', r: '430523' },
    { p: '湖南省', c: '邵阳市', d: '隆回县', r: '430524' },
    { p: '湖南省', c: '邵阳市', d: '洞口县', r: '430525' },
    { p: '湖南省', c: '邵阳市', d: '绥宁县', r: '430527' },
    { p: '湖南省', c: '邵阳市', d: '新宁县', r: '430528' },
    { p: '湖南省', c: '邵阳市', d: '城步苗族自治县', r: '430529' },
    { p: '湖南省', c: '邵阳市', d: '武冈市', r: '430581' },
    { p: '湖南省', c: '邵阳市', d: '邵东市', r: '430582' },
    { p: '湖南省', c: '岳阳市', d: '岳阳市', r: '430600' },
    { p: '湖南省', c: '岳阳市', d: '岳阳楼区', r: '430602' },
    { p: '湖南省', c: '岳阳市', d: '云溪区', r: '430603' },
    { p: '湖南省', c: '岳阳市', d: '君山区', r: '430611' },
    { p: '湖南省', c: '岳阳市', d: '岳阳县', r: '430621' },
    { p: '湖南省', c: '岳阳市', d: '华容县', r: '430623' },
    { p: '湖南省', c: '岳阳市', d: '湘阴县', r: '430624' },
    { p: '湖南省', c: '岳阳市', d: '平江县', r: '430626' },
    { p: '湖南省', c: '岳阳市', d: '汨罗市', r: '430681' },
    { p: '湖南省', c: '岳阳市', d: '临湘市', r: '430682' },
    { p: '湖南省', c: '常德市', d: '常德市', r: '430700' },
    { p: '湖南省', c: '常德市', d: '武陵区', r: '430702' },
    { p: '湖南省', c: '常德市', d: '鼎城区', r: '430703' },
    { p: '湖南省', c: '常德市', d: '安乡县', r: '430721' },
    { p: '湖南省', c: '常德市', d: '汉寿县', r: '430722' },
    { p: '湖南省', c: '常德市', d: '澧县', r: '430723' },
    { p: '湖南省', c: '常德市', d: '临澧县', r: '430724' },
    { p: '湖南省', c: '常德市', d: '桃源县', r: '430725' },
    { p: '湖南省', c: '常德市', d: '石门县', r: '430726' },
    { p: '湖南省', c: '常德市', d: '津市市', r: '430781' },
    { p: '湖南省', c: '张家界市', d: '张家界市', r: '430800' },
    { p: '湖南省', c: '张家界市', d: '永定区', r: '430802' },
    { p: '湖南省', c: '张家界市', d: '武陵源区', r: '430811' },
    { p: '湖南省', c: '张家界市', d: '慈利县', r: '430821' },
    { p: '湖南省', c: '张家界市', d: '桑植县', r: '430822' },
    { p: '湖南省', c: '益阳市', d: '益阳市', r: '430900' },
    { p: '湖南省', c: '益阳市', d: '资阳区', r: '430902' },
    { p: '湖南省', c: '益阳市', d: '赫山区', r: '430903' },
    { p: '湖南省', c: '益阳市', d: '南县', r: '430921' },
    { p: '湖南省', c: '益阳市', d: '桃江县', r: '430922' },
    { p: '湖南省', c: '益阳市', d: '安化县', r: '430923' },
    { p: '湖南省', c: '益阳市', d: '沅江市', r: '430981' },
    { p: '湖南省', c: '郴州市', d: '郴州市', r: '431000' },
    { p: '湖南省', c: '郴州市', d: '北湖区', r: '431002' },
    { p: '湖南省', c: '郴州市', d: '苏仙区', r: '431003' },
    { p: '湖南省', c: '郴州市', d: '桂阳县', r: '431021' },
    { p: '湖南省', c: '郴州市', d: '宜章县', r: '431022' },
    { p: '湖南省', c: '郴州市', d: '永兴县', r: '431023' },
    { p: '湖南省', c: '郴州市', d: '嘉禾县', r: '431024' },
    { p: '湖南省', c: '郴州市', d: '临武县', r: '431025' },
    { p: '湖南省', c: '郴州市', d: '汝城县', r: '431026' },
    { p: '湖南省', c: '郴州市', d: '桂东县', r: '431027' },
    { p: '湖南省', c: '郴州市', d: '安仁县', r: '431028' },
    { p: '湖南省', c: '郴州市', d: '资兴市', r: '431081' },
    { p: '湖南省', c: '永州市', d: '永州市', r: '431100' },
    { p: '湖南省', c: '永州市', d: '零陵区', r: '431102' },
    { p: '湖南省', c: '永州市', d: '冷水滩区', r: '431103' },
    { p: '湖南省', c: '永州市', d: '东安县', r: '431122' },
    { p: '湖南省', c: '永州市', d: '双牌县', r: '431123' },
    { p: '湖南省', c: '永州市', d: '道县', r: '431124' },
    { p: '湖南省', c: '永州市', d: '江永县', r: '431125' },
    { p: '湖南省', c: '永州市', d: '宁远县', r: '431126' },
    { p: '湖南省', c: '永州市', d: '蓝山县', r: '431127' },
    { p: '湖南省', c: '永州市', d: '新田县', r: '431128' },
    { p: '湖南省', c: '永州市', d: '江华瑶族自治县', r: '431129' },
    { p: '湖南省', c: '永州市', d: '祁阳市', r: '431181' },
    { p: '湖南省', c: '怀化市', d: '怀化市', r: '431200' },
    { p: '湖南省', c: '怀化市', d: '鹤城区', r: '431202' },
    { p: '湖南省', c: '怀化市', d: '中方县', r: '431221' },
    { p: '湖南省', c: '怀化市', d: '沅陵县', r: '431222' },
    { p: '湖南省', c: '怀化市', d: '辰溪县', r: '431223' },
    { p: '湖南省', c: '怀化市', d: '溆浦县', r: '431224' },
    { p: '湖南省', c: '怀化市', d: '会同县', r: '431225' },
    { p: '湖南省', c: '怀化市', d: '麻阳苗族自治县', r: '431226' },
    { p: '湖南省', c: '怀化市', d: '新晃侗族自治县', r: '431227' },
    { p: '湖南省', c: '怀化市', d: '芷江侗族自治县', r: '431228' },
    { p: '湖南省', c: '怀化市', d: '靖州苗族侗族自治县', r: '431229' },
    { p: '湖南省', c: '怀化市', d: '通道侗族自治县', r: '431230' },
    { p: '湖南省', c: '怀化市', d: '洪江市', r: '431281' },
    { p: '湖南省', c: '娄底市', d: '娄底市', r: '431300' },
    { p: '湖南省', c: '娄底市', d: '娄星区', r: '431302' },
    { p: '湖南省', c: '娄底市', d: '双峰县', r: '431321' },
    { p: '湖南省', c: '娄底市', d: '新化县', r: '431322' },
    { p: '湖南省', c: '娄底市', d: '冷水江市', r: '431381' },
    { p: '湖南省', c: '娄底市', d: '涟源市', r: '431382' },
    { p: '湖南省', c: '湘西土家族苗族自治州', d: '湘西土家族苗族自治州', r: '433100' },
    { p: '湖南省', c: '湘西土家族苗族自治州', d: '吉首市', r: '433101' },
    { p: '湖南省', c: '湘西土家族苗族自治州', d: '泸溪县', r: '433122' },
    { p: '湖南省', c: '湘西土家族苗族自治州', d: '凤凰县', r: '433123' },
    { p: '湖南省', c: '湘西土家族苗族自治州', d: '花垣县', r: '433124' },
    { p: '湖南省', c: '湘西土家族苗族自治州', d: '保靖县', r: '433125' },
    { p: '湖南省', c: '湘西土家族苗族自治州', d: '古丈县', r: '433126' },
    { p: '湖南省', c: '湘西土家族苗族自治州', d: '永顺县', r: '433127' },
    { p: '湖南省', c: '湘西土家族苗族自治州', d: '龙山县', r: '433130' },
    { p: '广东省', c: '广东省', d: '广东省', r: '440000' },
    { p: '广东省', c: '广州市', d: '广州市', r: '440100' },
    { p: '广东省', c: '广州市', d: '荔湾区', r: '440103' },
    { p: '广东省', c: '广州市', d: '越秀区', r: '440104' },
    { p: '广东省', c: '广州市', d: '海珠区', r: '440105' },
    { p: '广东省', c: '广州市', d: '天河区', r: '440106' },
    { p: '广东省', c: '广州市', d: '白云区', r: '440111' },
    { p: '广东省', c: '广州市', d: '黄埔区', r: '440112' },
    { p: '广东省', c: '广州市', d: '番禺区', r: '440113' },
    { p: '广东省', c: '广州市', d: '花都区', r: '440114' },
    { p: '广东省', c: '广州市', d: '南沙区', r: '440115' },
    { p: '广东省', c: '广州市', d: '从化区', r: '440117' },
    { p: '广东省', c: '广州市', d: '增城区', r: '440118' },
    { p: '广东省', c: '韶关市', d: '韶关市', r: '440200' },
    { p: '广东省', c: '韶关市', d: '武江区', r: '440203' },
    { p: '广东省', c: '韶关市', d: '浈江区', r: '440204' },
    { p: '广东省', c: '韶关市', d: '曲江区', r: '440205' },
    { p: '广东省', c: '韶关市', d: '始兴县', r: '440222' },
    { p: '广东省', c: '韶关市', d: '仁化县', r: '440224' },
    { p: '广东省', c: '韶关市', d: '翁源县', r: '440229' },
    { p: '广东省', c: '韶关市', d: '乳源瑶族自治县', r: '440232' },
    { p: '广东省', c: '韶关市', d: '新丰县', r: '440233' },
    { p: '广东省', c: '韶关市', d: '乐昌市', r: '440281' },
    { p: '广东省', c: '韶关市', d: '南雄市', r: '440282' },
    { p: '广东省', c: '深圳市', d: '深圳市', r: '440300' },
    { p: '广东省', c: '深圳市', d: '罗湖区', r: '440303' },
    { p: '广东省', c: '深圳市', d: '福田区', r: '440304' },
    { p: '广东省', c: '深圳市', d: '南山区', r: '440305' },
    { p: '广东省', c: '深圳市', d: '宝安区', r: '440306' },
    { p: '广东省', c: '深圳市', d: '龙岗区', r: '440307' },
    { p: '广东省', c: '深圳市', d: '盐田区', r: '440308' },
    { p: '广东省', c: '深圳市', d: '龙华区', r: '440309' },
    { p: '广东省', c: '深圳市', d: '坪山区', r: '440310' },
    { p: '广东省', c: '深圳市', d: '光明区', r: '440311' },
    { p: '广东省', c: '珠海市', d: '珠海市', r: '440400' },
    { p: '广东省', c: '珠海市', d: '香洲区', r: '440402' },
    { p: '广东省', c: '珠海市', d: '斗门区', r: '440403' },
    { p: '广东省', c: '珠海市', d: '金湾区', r: '440404' },
    { p: '广东省', c: '汕头市', d: '汕头市', r: '440500' },
    { p: '广东省', c: '汕头市', d: '龙湖区', r: '440507' },
    { p: '广东省', c: '汕头市', d: '金平区', r: '440511' },
    { p: '广东省', c: '汕头市', d: '濠江区', r: '440512' },
    { p: '广东省', c: '汕头市', d: '潮阳区', r: '440513' },
    { p: '广东省', c: '汕头市', d: '潮南区', r: '440514' },
    { p: '广东省', c: '汕头市', d: '澄海区', r: '440515' },
    { p: '广东省', c: '汕头市', d: '南澳县', r: '440523' },
    { p: '广东省', c: '佛山市', d: '佛山市', r: '440600' },
    { p: '广东省', c: '佛山市', d: '禅城区', r: '440604' },
    { p: '广东省', c: '佛山市', d: '南海区', r: '440605' },
    { p: '广东省', c: '佛山市', d: '顺德区', r: '440606' },
    { p: '广东省', c: '佛山市', d: '三水区', r: '440607' },
    { p: '广东省', c: '佛山市', d: '高明区', r: '440608' },
    { p: '广东省', c: '江门市', d: '江门市', r: '440700' },
    { p: '广东省', c: '江门市', d: '蓬江区', r: '440703' },
    { p: '广东省', c: '江门市', d: '江海区', r: '440704' },
    { p: '广东省', c: '江门市', d: '新会区', r: '440705' },
    { p: '广东省', c: '江门市', d: '台山市', r: '440781' },
    { p: '广东省', c: '江门市', d: '开平市', r: '440783' },
    { p: '广东省', c: '江门市', d: '鹤山市', r: '440784' },
    { p: '广东省', c: '江门市', d: '恩平市', r: '440785' },
    { p: '广东省', c: '湛江市', d: '湛江市', r: '440800' },
    { p: '广东省', c: '湛江市', d: '赤坎区', r: '440802' },
    { p: '广东省', c: '湛江市', d: '霞山区', r: '440803' },
    { p: '广东省', c: '湛江市', d: '坡头区', r: '440804' },
    { p: '广东省', c: '湛江市', d: '麻章区', r: '440811' },
    { p: '广东省', c: '湛江市', d: '遂溪县', r: '440823' },
    { p: '广东省', c: '湛江市', d: '徐闻县', r: '440825' },
    { p: '广东省', c: '湛江市', d: '廉江市', r: '440881' },
    { p: '广东省', c: '湛江市', d: '雷州市', r: '440882' },
    { p: '广东省', c: '湛江市', d: '吴川市', r: '440883' },
    { p: '广东省', c: '茂名市', d: '茂名市', r: '440900' },
    { p: '广东省', c: '茂名市', d: '茂南区', r: '440902' },
    { p: '广东省', c: '茂名市', d: '电白区', r: '440904' },
    { p: '广东省', c: '茂名市', d: '高州市', r: '440981' },
    { p: '广东省', c: '茂名市', d: '化州市', r: '440982' },
    { p: '广东省', c: '茂名市', d: '信宜市', r: '440983' },
    { p: '广东省', c: '肇庆市', d: '肇庆市', r: '441200' },
    { p: '广东省', c: '肇庆市', d: '端州区', r: '441202' },
    { p: '广东省', c: '肇庆市', d: '鼎湖区', r: '441203' },
    { p: '广东省', c: '肇庆市', d: '高要区', r: '441204' },
    { p: '广东省', c: '肇庆市', d: '广宁县', r: '441223' },
    { p: '广东省', c: '肇庆市', d: '怀集县', r: '441224' },
    { p: '广东省', c: '肇庆市', d: '封开县', r: '441225' },
    { p: '广东省', c: '肇庆市', d: '德庆县', r: '441226' },
    { p: '广东省', c: '肇庆市', d: '四会市', r: '441284' },
    { p: '广东省', c: '惠州市', d: '惠州市', r: '441300' },
    { p: '广东省', c: '惠州市', d: '惠城区', r: '441302' },
    { p: '广东省', c: '惠州市', d: '惠阳区', r: '441303' },
    { p: '广东省', c: '惠州市', d: '博罗县', r: '441322' },
    { p: '广东省', c: '惠州市', d: '惠东县', r: '441323' },
    { p: '广东省', c: '惠州市', d: '龙门县', r: '441324' },
    { p: '广东省', c: '梅州市', d: '梅州市', r: '441400' },
    { p: '广东省', c: '梅州市', d: '梅江区', r: '441402' },
    { p: '广东省', c: '梅州市', d: '梅县区', r: '441403' },
    { p: '广东省', c: '梅州市', d: '大埔县', r: '441422' },
    { p: '广东省', c: '梅州市', d: '丰顺县', r: '441423' },
    { p: '广东省', c: '梅州市', d: '五华县', r: '441424' },
    { p: '广东省', c: '梅州市', d: '平远县', r: '441426' },
    { p: '广东省', c: '梅州市', d: '蕉岭县', r: '441427' },
    { p: '广东省', c: '梅州市', d: '兴宁市', r: '441481' },
    { p: '广东省', c: '汕尾市', d: '汕尾市', r: '441500' },
    { p: '广东省', c: '汕尾市', d: '城区', r: '441502' },
    { p: '广东省', c: '汕尾市', d: '海丰县', r: '441521' },
    { p: '广东省', c: '汕尾市', d: '陆河县', r: '441523' },
    { p: '广东省', c: '汕尾市', d: '陆丰市', r: '441581' },
    { p: '广东省', c: '河源市', d: '河源市', r: '441600' },
    { p: '广东省', c: '河源市', d: '源城区', r: '441602' },
    { p: '广东省', c: '河源市', d: '紫金县', r: '441621' },
    { p: '广东省', c: '河源市', d: '龙川县', r: '441622' },
    { p: '广东省', c: '河源市', d: '连平县', r: '441623' },
    { p: '广东省', c: '河源市', d: '和平县', r: '441624' },
    { p: '广东省', c: '河源市', d: '东源县', r: '441625' },
    { p: '广东省', c: '阳江市', d: '阳江市', r: '441700' },
    { p: '广东省', c: '阳江市', d: '江城区', r: '441702' },
    { p: '广东省', c: '阳江市', d: '阳东区', r: '441704' },
    { p: '广东省', c: '阳江市', d: '阳西县', r: '441721' },
    { p: '广东省', c: '阳江市', d: '阳春市', r: '441781' },
    { p: '广东省', c: '清远市', d: '清远市', r: '441800' },
    { p: '广东省', c: '清远市', d: '清城区', r: '441802' },
    { p: '广东省', c: '清远市', d: '清新区', r: '441803' },
    { p: '广东省', c: '清远市', d: '佛冈县', r: '441821' },
    { p: '广东省', c: '清远市', d: '阳山县', r: '441823' },
    { p: '广东省', c: '清远市', d: '连山壮族瑶族自治县', r: '441825' },
    { p: '广东省', c: '清远市', d: '连南瑶族自治县', r: '441826' },
    { p: '广东省', c: '清远市', d: '英德市', r: '441881' },
    { p: '广东省', c: '清远市', d: '连州市', r: '441882' },
    { p: '广东省', c: '东莞市', d: '东莞市', r: '441900' },
    { p: '广东省', c: '中山市', d: '中山市', r: '442000' },
    { p: '广东省', c: '潮州市', d: '潮州市', r: '445100' },
    { p: '广东省', c: '潮州市', d: '湘桥区', r: '445102' },
    { p: '广东省', c: '潮州市', d: '潮安区', r: '445103' },
    { p: '广东省', c: '潮州市', d: '饶平县', r: '445122' },
    { p: '广东省', c: '揭阳市', d: '揭阳市', r: '445200' },
    { p: '广东省', c: '揭阳市', d: '榕城区', r: '445202' },
    { p: '广东省', c: '揭阳市', d: '揭东区', r: '445203' },
    { p: '广东省', c: '揭阳市', d: '揭西县', r: '445222' },
    { p: '广东省', c: '揭阳市', d: '惠来县', r: '445224' },
    { p: '广东省', c: '揭阳市', d: '普宁市', r: '445281' },
    { p: '广东省', c: '云浮市', d: '云浮市', r: '445300' },
    { p: '广东省', c: '云浮市', d: '云城区', r: '445302' },
    { p: '广东省', c: '云浮市', d: '云安区', r: '445303' },
    { p: '广东省', c: '云浮市', d: '新兴县', r: '445321' },
    { p: '广东省', c: '云浮市', d: '郁南县', r: '445322' },
    { p: '广东省', c: '云浮市', d: '罗定市', r: '445381' },
    { p: '广西壮族自治区', c: '广西壮族自治区', d: '广西壮族自治区', r: '450000' },
    { p: '广西壮族自治区', c: '南宁市', d: '南宁市', r: '450100' },
    { p: '广西壮族自治区', c: '南宁市', d: '兴宁区', r: '450102' },
    { p: '广西壮族自治区', c: '南宁市', d: '青秀区', r: '450103' },
    { p: '广西壮族自治区', c: '南宁市', d: '江南区', r: '450105' },
    { p: '广西壮族自治区', c: '南宁市', d: '西乡塘区', r: '450107' },
    { p: '广西壮族自治区', c: '南宁市', d: '良庆区', r: '450108' },
    { p: '广西壮族自治区', c: '南宁市', d: '邕宁区', r: '450109' },
    { p: '广西壮族自治区', c: '南宁市', d: '武鸣区', r: '450110' },
    { p: '广西壮族自治区', c: '南宁市', d: '隆安县', r: '450123' },
    { p: '广西壮族自治区', c: '南宁市', d: '马山县', r: '450124' },
    { p: '广西壮族自治区', c: '南宁市', d: '上林县', r: '450125' },
    { p: '广西壮族自治区', c: '南宁市', d: '宾阳县', r: '450126' },
    { p: '广西壮族自治区', c: '南宁市', d: '横州市', r: '450181' },
    { p: '广西壮族自治区', c: '柳州市', d: '柳州市', r: '450200' },
    { p: '广西壮族自治区', c: '柳州市', d: '城中区', r: '450202' },
    { p: '广西壮族自治区', c: '柳州市', d: '鱼峰区', r: '450203' },
    { p: '广西壮族自治区', c: '柳州市', d: '柳南区', r: '450204' },
    { p: '广西壮族自治区', c: '柳州市', d: '柳北区', r: '450205' },
    { p: '广西壮族自治区', c: '柳州市', d: '柳江区', r: '450206' },
    { p: '广西壮族自治区', c: '柳州市', d: '柳城县', r: '450222' },
    { p: '广西壮族自治区', c: '柳州市', d: '鹿寨县', r: '450223' },
    { p: '广西壮族自治区', c: '柳州市', d: '融安县', r: '450224' },
    { p: '广西壮族自治区', c: '柳州市', d: '融水苗族自治县', r: '450225' },
    { p: '广西壮族自治区', c: '柳州市', d: '三江侗族自治县', r: '450226' },
    { p: '广西壮族自治区', c: '桂林市', d: '桂林市', r: '450300' },
    { p: '广西壮族自治区', c: '桂林市', d: '秀峰区', r: '450302' },
    { p: '广西壮族自治区', c: '桂林市', d: '叠彩区', r: '450303' },
    { p: '广西壮族自治区', c: '桂林市', d: '象山区', r: '450304' },
    { p: '广西壮族自治区', c: '桂林市', d: '七星区', r: '450305' },
    { p: '广西壮族自治区', c: '桂林市', d: '雁山区', r: '450311' },
    { p: '广西壮族自治区', c: '桂林市', d: '临桂区', r: '450312' },
    { p: '广西壮族自治区', c: '桂林市', d: '阳朔县', r: '450321' },
    { p: '广西壮族自治区', c: '桂林市', d: '灵川县', r: '450323' },
    { p: '广西壮族自治区', c: '桂林市', d: '全州县', r: '450324' },
    { p: '广西壮族自治区', c: '桂林市', d: '兴安县', r: '450325' },
    { p: '广西壮族自治区', c: '桂林市', d: '永福县', r: '450326' },
    { p: '广西壮族自治区', c: '桂林市', d: '灌阳县', r: '450327' },
    { p: '广西壮族自治区', c: '桂林市', d: '龙胜各族自治县', r: '450328' },
    { p: '广西壮族自治区', c: '桂林市', d: '资源县', r: '450329' },
    { p: '广西壮族自治区', c: '桂林市', d: '平乐县', r: '450330' },
    { p: '广西壮族自治区', c: '桂林市', d: '恭城瑶族自治县', r: '450332' },
    { p: '广西壮族自治区', c: '桂林市', d: '荔浦市', r: '450381' },
    { p: '广西壮族自治区', c: '梧州市', d: '梧州市', r: '450400' },
    { p: '广西壮族自治区', c: '梧州市', d: '万秀区', r: '450403' },
    { p: '广西壮族自治区', c: '梧州市', d: '长洲区', r: '450405' },
    { p: '广西壮族自治区', c: '梧州市', d: '龙圩区', r: '450406' },
    { p: '广西壮族自治区', c: '梧州市', d: '苍梧县', r: '450421' },
    { p: '广西壮族自治区', c: '梧州市', d: '藤县', r: '450422' },
    { p: '广西壮族自治区', c: '梧州市', d: '蒙山县', r: '450423' },
    { p: '广西壮族自治区', c: '梧州市', d: '岑溪市', r: '450481' },
    { p: '广西壮族自治区', c: '北海市', d: '北海市', r: '450500' },
    { p: '广西壮族自治区', c: '北海市', d: '海城区', r: '450502' },
    { p: '广西壮族自治区', c: '北海市', d: '银海区', r: '450503' },
    { p: '广西壮族自治区', c: '北海市', d: '铁山港区', r: '450512' },
    { p: '广西壮族自治区', c: '北海市', d: '合浦县', r: '450521' },
    { p: '广西壮族自治区', c: '防城港市', d: '防城港市', r: '450600' },
    { p: '广西壮族自治区', c: '防城港市', d: '港口区', r: '450602' },
    { p: '广西壮族自治区', c: '防城港市', d: '防城区', r: '450603' },
    { p: '广西壮族自治区', c: '防城港市', d: '上思县', r: '450621' },
    { p: '广西壮族自治区', c: '防城港市', d: '东兴市', r: '450681' },
    { p: '广西壮族自治区', c: '钦州市', d: '钦州市', r: '450700' },
    { p: '广西壮族自治区', c: '钦州市', d: '钦南区', r: '450702' },
    { p: '广西壮族自治区', c: '钦州市', d: '钦北区', r: '450703' },
    { p: '广西壮族自治区', c: '钦州市', d: '灵山县', r: '450721' },
    { p: '广西壮族自治区', c: '钦州市', d: '浦北县', r: '450722' },
    { p: '广西壮族自治区', c: '贵港市', d: '贵港市', r: '450800' },
    { p: '广西壮族自治区', c: '贵港市', d: '港北区', r: '450802' },
    { p: '广西壮族自治区', c: '贵港市', d: '港南区', r: '450803' },
    { p: '广西壮族自治区', c: '贵港市', d: '覃塘区', r: '450804' },
    { p: '广西壮族自治区', c: '贵港市', d: '平南县', r: '450821' },
    { p: '广西壮族自治区', c: '贵港市', d: '桂平市', r: '450881' },
    { p: '广西壮族自治区', c: '玉林市', d: '玉林市', r: '450900' },
    { p: '广西壮族自治区', c: '玉林市', d: '玉州区', r: '450902' },
    { p: '广西壮族自治区', c: '玉林市', d: '福绵区', r: '450903' },
    { p: '广西壮族自治区', c: '玉林市', d: '容县', r: '450921' },
    { p: '广西壮族自治区', c: '玉林市', d: '陆川县', r: '450922' },
    { p: '广西壮族自治区', c: '玉林市', d: '博白县', r: '450923' },
    { p: '广西壮族自治区', c: '玉林市', d: '兴业县', r: '450924' },
    { p: '广西壮族自治区', c: '玉林市', d: '北流市', r: '450981' },
    { p: '广西壮族自治区', c: '百色市', d: '百色市', r: '451000' },
    { p: '广西壮族自治区', c: '百色市', d: '右江区', r: '451002' },
    { p: '广西壮族自治区', c: '百色市', d: '田阳区', r: '451003' },
    { p: '广西壮族自治区', c: '百色市', d: '田东县', r: '451022' },
    { p: '广西壮族自治区', c: '百色市', d: '德保县', r: '451024' },
    { p: '广西壮族自治区', c: '百色市', d: '那坡县', r: '451026' },
    { p: '广西壮族自治区', c: '百色市', d: '凌云县', r: '451027' },
    { p: '广西壮族自治区', c: '百色市', d: '乐业县', r: '451028' },
    { p: '广西壮族自治区', c: '百色市', d: '田林县', r: '451029' },
    { p: '广西壮族自治区', c: '百色市', d: '西林县', r: '451030' },
    { p: '广西壮族自治区', c: '百色市', d: '隆林各族自治县', r: '451031' },
    { p: '广西壮族自治区', c: '百色市', d: '靖西市', r: '451081' },
    { p: '广西壮族自治区', c: '百色市', d: '平果市', r: '451082' },
    { p: '广西壮族自治区', c: '贺州市', d: '贺州市', r: '451100' },
    { p: '广西壮族自治区', c: '贺州市', d: '八步区', r: '451102' },
    { p: '广西壮族自治区', c: '贺州市', d: '平桂区', r: '451103' },
    { p: '广西壮族自治区', c: '贺州市', d: '昭平县', r: '451121' },
    { p: '广西壮族自治区', c: '贺州市', d: '钟山县', r: '451122' },
    { p: '广西壮族自治区', c: '贺州市', d: '富川瑶族自治县', r: '451123' },
    { p: '广西壮族自治区', c: '河池市', d: '河池市', r: '451200' },
    { p: '广西壮族自治区', c: '河池市', d: '金城江区', r: '451202' },
    { p: '广西壮族自治区', c: '河池市', d: '宜州区', r: '451203' },
    { p: '广西壮族自治区', c: '河池市', d: '南丹县', r: '451221' },
    { p: '广西壮族自治区', c: '河池市', d: '天峨县', r: '451222' },
    { p: '广西壮族自治区', c: '河池市', d: '凤山县', r: '451223' },
    { p: '广西壮族自治区', c: '河池市', d: '东兰县', r: '451224' },
    { p: '广西壮族自治区', c: '河池市', d: '罗城仫佬族自治县', r: '451225' },
    { p: '广西壮族自治区', c: '河池市', d: '环江毛南族自治县', r: '451226' },
    { p: '广西壮族自治区', c: '河池市', d: '巴马瑶族自治县', r: '451227' },
    { p: '广西壮族自治区', c: '河池市', d: '都安瑶族自治县', r: '451228' },
    { p: '广西壮族自治区', c: '河池市', d: '大化瑶族自治县', r: '451229' },
    { p: '广西壮族自治区', c: '来宾市', d: '来宾市', r: '451300' },
    { p: '广西壮族自治区', c: '来宾市', d: '兴宾区', r: '451302' },
    { p: '广西壮族自治区', c: '来宾市', d: '忻城县', r: '451321' },
    { p: '广西壮族自治区', c: '来宾市', d: '象州县', r: '451322' },
    { p: '广西壮族自治区', c: '来宾市', d: '武宣县', r: '451323' },
    { p: '广西壮族自治区', c: '来宾市', d: '金秀瑶族自治县', r: '451324' },
    { p: '广西壮族自治区', c: '来宾市', d: '合山市', r: '451381' },
    { p: '广西壮族自治区', c: '崇左市', d: '崇左市', r: '451400' },
    { p: '广西壮族自治区', c: '崇左市', d: '江州区', r: '451402' },
    { p: '广西壮族自治区', c: '崇左市', d: '扶绥县', r: '451421' },
    { p: '广西壮族自治区', c: '崇左市', d: '宁明县', r: '451422' },
    { p: '广西壮族自治区', c: '崇左市', d: '龙州县', r: '451423' },
    { p: '广西壮族自治区', c: '崇左市', d: '大新县', r: '451424' },
    { p: '广西壮族自治区', c: '崇左市', d: '天等县', r: '451425' },
    { p: '广西壮族自治区', c: '崇左市', d: '凭祥市', r: '451481' },
    { p: '海南省', c: '海南省', d: '海南省', r: '460000' },
    { p: '海南省', c: '海口市', d: '海口市', r: '460100' },
    { p: '海南省', c: '海口市', d: '秀英区', r: '460105' },
    { p: '海南省', c: '海口市', d: '龙华区', r: '460106' },
    { p: '海南省', c: '海口市', d: '琼山区', r: '460107' },
    { p: '海南省', c: '海口市', d: '美兰区', r: '460108' },
    { p: '海南省', c: '三亚市', d: '三亚市', r: '460200' },
    { p: '海南省', c: '三亚市', d: '海棠区', r: '460202' },
    { p: '海南省', c: '三亚市', d: '吉阳区', r: '460203' },
    { p: '海南省', c: '三亚市', d: '天涯区', r: '460204' },
    { p: '海南省', c: '三亚市', d: '崖州区', r: '460205' },
    { p: '海南省', c: '三沙市', d: '三沙市', r: '460300' },
    { p: '海南省', c: '三沙市', d: '西沙区', r: '460321' },
    { p: '海南省', c: '三沙市', d: '南沙区', r: '460322' },
    { p: '海南省', c: '', d: '儋州市', r: '460400' },
    { p: '海南省', c: '', d: '五指山市', r: '469001' },
    { p: '海南省', c: '', d: '琼海市', r: '469002' },
    { p: '海南省', c: '', d: '文昌市', r: '469005' },
    { p: '海南省', c: '', d: '万宁市', r: '469006' },
    { p: '海南省', c: '', d: '东方市', r: '469007' },
    { p: '海南省', c: '', d: '定安县', r: '469021' },
    { p: '海南省', c: '', d: '屯昌县', r: '469022' },
    { p: '海南省', c: '', d: '澄迈县', r: '469023' },
    { p: '海南省', c: '', d: '临高县', r: '469024' },
    { p: '海南省', c: '', d: '白沙黎族自治县', r: '469025' },
    { p: '海南省', c: '', d: '昌江黎族自治县', r: '469026' },
    { p: '海南省', c: '', d: '乐东黎族自治县', r: '469027' },
    { p: '海南省', c: '', d: '陵水黎族自治县', r: '469028' },
    { p: '海南省', c: '', d: '保亭黎族苗族自治县', r: '469029' },
    { p: '海南省', c: '', d: '琼中黎族苗族自治县', r: '469030' },
    { p: '重庆市', c: '重庆市', d: '重庆市', r: '500000' },
    { p: '重庆市', c: '重庆市', d: '万州区', r: '500101' },
    { p: '重庆市', c: '重庆市', d: '涪陵区', r: '500102' },
    { p: '重庆市', c: '重庆市', d: '渝中区', r: '500103' },
    { p: '重庆市', c: '重庆市', d: '大渡口区', r: '500104' },
    { p: '重庆市', c: '重庆市', d: '江北区', r: '500105' },
    { p: '重庆市', c: '重庆市', d: '沙坪坝区', r: '500106' },
    { p: '重庆市', c: '重庆市', d: '九龙坡区', r: '500107' },
    { p: '重庆市', c: '重庆市', d: '南岸区', r: '500108' },
    { p: '重庆市', c: '重庆市', d: '北碚区', r: '500109' },
    { p: '重庆市', c: '重庆市', d: '綦江区', r: '500110' },
    { p: '重庆市', c: '重庆市', d: '大足区', r: '500111' },
    { p: '重庆市', c: '重庆市', d: '渝北区', r: '500112' },
    { p: '重庆市', c: '重庆市', d: '巴南区', r: '500113' },
    { p: '重庆市', c: '重庆市', d: '黔江区', r: '500114' },
    { p: '重庆市', c: '重庆市', d: '长寿区', r: '500115' },
    { p: '重庆市', c: '重庆市', d: '江津区', r: '500116' },
    { p: '重庆市', c: '重庆市', d: '合川区', r: '500117' },
    { p: '重庆市', c: '重庆市', d: '永川区', r: '500118' },
    { p: '重庆市', c: '重庆市', d: '南川区', r: '500119' },
    { p: '重庆市', c: '重庆市', d: '璧山区', r: '500120' },
    { p: '重庆市', c: '重庆市', d: '铜梁区', r: '500151' },
    { p: '重庆市', c: '重庆市', d: '潼南区', r: '500152' },
    { p: '重庆市', c: '重庆市', d: '荣昌区', r: '500153' },
    { p: '重庆市', c: '重庆市', d: '开州区', r: '500154' },
    { p: '重庆市', c: '重庆市', d: '梁平区', r: '500155' },
    { p: '重庆市', c: '重庆市', d: '武隆区', r: '500156' },
    { p: '重庆市', c: '重庆市', d: '城口县', r: '500229' },
    { p: '重庆市', c: '重庆市', d: '丰都县', r: '500230' },
    { p: '重庆市', c: '重庆市', d: '垫江县', r: '500231' },
    { p: '重庆市', c: '重庆市', d: '忠县', r: '500233' },
    { p: '重庆市', c: '重庆市', d: '云阳县', r: '500235' },
    { p: '重庆市', c: '重庆市', d: '奉节县', r: '500236' },
    { p: '重庆市', c: '重庆市', d: '巫山县', r: '500237' },
    { p: '重庆市', c: '重庆市', d: '巫溪县', r: '500238' },
    { p: '重庆市', c: '重庆市', d: '石柱土家族自治县', r: '500240' },
    { p: '重庆市', c: '重庆市', d: '秀山土家族苗族自治县', r: '500241' },
    { p: '重庆市', c: '重庆市', d: '酉阳土家族苗族自治县', r: '500242' },
    { p: '重庆市', c: '重庆市', d: '彭水苗族土家族自治县', r: '500243' },
    { p: '四川省', c: '四川省', d: '四川省', r: '510000' },
    { p: '四川省', c: '成都市', d: '成都市', r: '510100' },
    { p: '四川省', c: '成都市', d: '锦江区', r: '510104' },
    { p: '四川省', c: '成都市', d: '青羊区', r: '510105' },
    { p: '四川省', c: '成都市', d: '金牛区', r: '510106' },
    { p: '四川省', c: '成都市', d: '武侯区', r: '510107' },
    { p: '四川省', c: '成都市', d: '成华区', r: '510108' },
    { p: '四川省', c: '成都市', d: '龙泉驿区', r: '510112' },
    { p: '四川省', c: '成都市', d: '青白江区', r: '510113' },
    { p: '四川省', c: '成都市', d: '新都区', r: '510114' },
    { p: '四川省', c: '成都市', d: '温江区', r: '510115' },
    { p: '四川省', c: '成都市', d: '双流区', r: '510116' },
    { p: '四川省', c: '成都市', d: '郫都区', r: '510117' },
    { p: '四川省', c: '成都市', d: '新津区', r: '510118' },
    { p: '四川省', c: '成都市', d: '金堂县', r: '510121' },
    { p: '四川省', c: '成都市', d: '大邑县', r: '510129' },
    { p: '四川省', c: '成都市', d: '蒲江县', r: '510131' },
    { p: '四川省', c: '成都市', d: '都江堰市', r: '510181' },
    { p: '四川省', c: '成都市', d: '彭州市', r: '510182' },
    { p: '四川省', c: '成都市', d: '邛崃市', r: '510183' },
    { p: '四川省', c: '成都市', d: '崇州市', r: '510184' },
    { p: '四川省', c: '成都市', d: '简阳市', r: '510185' },
    { p: '四川省', c: '自贡市', d: '自贡市', r: '510300' },
    { p: '四川省', c: '自贡市', d: '自流井区', r: '510302' },
    { p: '四川省', c: '自贡市', d: '贡井区', r: '510303' },
    { p: '四川省', c: '自贡市', d: '大安区', r: '510304' },
    { p: '四川省', c: '自贡市', d: '沿滩区', r: '510311' },
    { p: '四川省', c: '自贡市', d: '荣县', r: '510321' },
    { p: '四川省', c: '自贡市', d: '富顺县', r: '510322' },
    { p: '四川省', c: '攀枝花市', d: '攀枝花市', r: '510400' },
    { p: '四川省', c: '攀枝花市', d: '东区', r: '510402' },
    { p: '四川省', c: '攀枝花市', d: '西区', r: '510403' },
    { p: '四川省', c: '攀枝花市', d: '仁和区', r: '510411' },
    { p: '四川省', c: '攀枝花市', d: '米易县', r: '510421' },
    { p: '四川省', c: '攀枝花市', d: '盐边县', r: '510422' },
    { p: '四川省', c: '泸州市', d: '泸州市', r: '510500' },
    { p: '四川省', c: '泸州市', d: '江阳区', r: '510502' },
    { p: '四川省', c: '泸州市', d: '纳溪区', r: '510503' },
    { p: '四川省', c: '泸州市', d: '龙马潭区', r: '510504' },
    { p: '四川省', c: '泸州市', d: '泸县', r: '510521' },
    { p: '四川省', c: '泸州市', d: '合江县', r: '510522' },
    { p: '四川省', c: '泸州市', d: '叙永县', r: '510524' },
    { p: '四川省', c: '泸州市', d: '古蔺县', r: '510525' },
    { p: '四川省', c: '德阳市', d: '德阳市', r: '510600' },
    { p: '四川省', c: '德阳市', d: '旌阳区', r: '510603' },
    { p: '四川省', c: '德阳市', d: '罗江区', r: '510604' },
    { p: '四川省', c: '德阳市', d: '中江县', r: '510623' },
    { p: '四川省', c: '德阳市', d: '广汉市', r: '510681' },
    { p: '四川省', c: '德阳市', d: '什邡市', r: '510682' },
    { p: '四川省', c: '德阳市', d: '绵竹市', r: '510683' },
    { p: '四川省', c: '绵阳市', d: '绵阳市', r: '510700' },
    { p: '四川省', c: '绵阳市', d: '涪城区', r: '510703' },
    { p: '四川省', c: '绵阳市', d: '游仙区', r: '510704' },
    { p: '四川省', c: '绵阳市', d: '安州区', r: '510705' },
    { p: '四川省', c: '绵阳市', d: '三台县', r: '510722' },
    { p: '四川省', c: '绵阳市', d: '盐亭县', r: '510723' },
    { p: '四川省', c: '绵阳市', d: '梓潼县', r: '510725' },
    { p: '四川省', c: '绵阳市', d: '北川羌族自治县', r: '510726' },
    { p: '四川省', c: '绵阳市', d: '平武县', r: '510727' },
    { p: '四川省', c: '绵阳市', d: '江油市', r: '510781' },
    { p: '四川省', c: '广元市', d: '广元市', r: '510800' },
    { p: '四川省', c: '广元市', d: '利州区', r: '510802' },
    { p: '四川省', c: '广元市', d: '昭化区', r: '510811' },
    { p: '四川省', c: '广元市', d: '朝天区', r: '510812' },
    { p: '四川省', c: '广元市', d: '旺苍县', r: '510821' },
    { p: '四川省', c: '广元市', d: '青川县', r: '510822' },
    { p: '四川省', c: '广元市', d: '剑阁县', r: '510823' },
    { p: '四川省', c: '广元市', d: '苍溪县', r: '510824' },
    { p: '四川省', c: '遂宁市', d: '遂宁市', r: '510900' },
    { p: '四川省', c: '遂宁市', d: '船山区', r: '510903' },
    { p: '四川省', c: '遂宁市', d: '安居区', r: '510904' },
    { p: '四川省', c: '遂宁市', d: '蓬溪县', r: '510921' },
    { p: '四川省', c: '遂宁市', d: '大英县', r: '510923' },
    { p: '四川省', c: '遂宁市', d: '射洪市', r: '510981' },
    { p: '四川省', c: '内江市', d: '内江市', r: '511000' },
    { p: '四川省', c: '内江市', d: '市中区', r: '511002' },
    { p: '四川省', c: '内江市', d: '东兴区', r: '511011' },
    { p: '四川省', c: '内江市', d: '威远县', r: '511024' },
    { p: '四川省', c: '内江市', d: '资中县', r: '511025' },
    { p: '四川省', c: '内江市', d: '隆昌市', r: '511083' },
    { p: '四川省', c: '乐山市', d: '乐山市', r: '511100' },
    { p: '四川省', c: '乐山市', d: '市中区', r: '511102' },
    { p: '四川省', c: '乐山市', d: '沙湾区', r: '511111' },
    { p: '四川省', c: '乐山市', d: '五通桥区', r: '511112' },
    { p: '四川省', c: '乐山市', d: '金口河区', r: '511113' },
    { p: '四川省', c: '乐山市', d: '犍为县', r: '511123' },
    { p: '四川省', c: '乐山市', d: '井研县', r: '511124' },
    { p: '四川省', c: '乐山市', d: '夹江县', r: '511126' },
    { p: '四川省', c: '乐山市', d: '沐川县', r: '511129' },
    { p: '四川省', c: '乐山市', d: '峨边彝族自治县', r: '511132' },
    { p: '四川省', c: '乐山市', d: '马边彝族自治县', r: '511133' },
    { p: '四川省', c: '乐山市', d: '峨眉山市', r: '511181' },
    { p: '四川省', c: '南充市', d: '南充市', r: '511300' },
    { p: '四川省', c: '南充市', d: '顺庆区', r: '511302' },
    { p: '四川省', c: '南充市', d: '高坪区', r: '511303' },
    { p: '四川省', c: '南充市', d: '嘉陵区', r: '511304' },
    { p: '四川省', c: '南充市', d: '南部县', r: '511321' },
    { p: '四川省', c: '南充市', d: '营山县', r: '511322' },
    { p: '四川省', c: '南充市', d: '蓬安县', r: '511323' },
    { p: '四川省', c: '南充市', d: '仪陇县', r: '511324' },
    { p: '四川省', c: '南充市', d: '西充县', r: '511325' },
    { p: '四川省', c: '南充市', d: '阆中市', r: '511381' },
    { p: '四川省', c: '眉山市', d: '眉山市', r: '511400' },
    { p: '四川省', c: '眉山市', d: '东坡区', r: '511402' },
    { p: '四川省', c: '眉山市', d: '彭山区', r: '511403' },
    { p: '四川省', c: '眉山市', d: '仁寿县', r: '511421' },
    { p: '四川省', c: '眉山市', d: '洪雅县', r: '511423' },
    { p: '四川省', c: '眉山市', d: '丹棱县', r: '511424' },
    { p: '四川省', c: '眉山市', d: '青神县', r: '511425' },
    { p: '四川省', c: '宜宾市', d: '宜宾市', r: '511500' },
    { p: '四川省', c: '宜宾市', d: '翠屏区', r: '511502' },
    { p: '四川省', c: '宜宾市', d: '南溪区', r: '511503' },
    { p: '四川省', c: '宜宾市', d: '叙州区', r: '511504' },
    { p: '四川省', c: '宜宾市', d: '江安县', r: '511523' },
    { p: '四川省', c: '宜宾市', d: '长宁县', r: '511524' },
    { p: '四川省', c: '宜宾市', d: '高县', r: '511525' },
    { p: '四川省', c: '宜宾市', d: '珙县', r: '511526' },
    { p: '四川省', c: '宜宾市', d: '筠连县', r: '511527' },
    { p: '四川省', c: '宜宾市', d: '兴文县', r: '511528' },
    { p: '四川省', c: '宜宾市', d: '屏山县', r: '511529' },
    { p: '四川省', c: '广安市', d: '广安市', r: '511600' },
    { p: '四川省', c: '广安市', d: '广安区', r: '511602' },
    { p: '四川省', c: '广安市', d: '前锋区', r: '511603' },
    { p: '四川省', c: '广安市', d: '岳池县', r: '511621' },
    { p: '四川省', c: '广安市', d: '武胜县', r: '511622' },
    { p: '四川省', c: '广安市', d: '邻水县', r: '511623' },
    { p: '四川省', c: '广安市', d: '华蓥市', r: '511681' },
    { p: '四川省', c: '达州市', d: '达州市', r: '511700' },
    { p: '四川省', c: '达州市', d: '通川区', r: '511702' },
    { p: '四川省', c: '达州市', d: '达川区', r: '511703' },
    { p: '四川省', c: '达州市', d: '宣汉县', r: '511722' },
    { p: '四川省', c: '达州市', d: '开江县', r: '511723' },
    { p: '四川省', c: '达州市', d: '大竹县', r: '511724' },
    { p: '四川省', c: '达州市', d: '渠县', r: '511725' },
    { p: '四川省', c: '达州市', d: '万源市', r: '511781' },
    { p: '四川省', c: '雅安市', d: '雅安市', r: '511800' },
    { p: '四川省', c: '雅安市', d: '雨城区', r: '511802' },
    { p: '四川省', c: '雅安市', d: '名山区', r: '511803' },
    { p: '四川省', c: '雅安市', d: '荥经县', r: '511822' },
    { p: '四川省', c: '雅安市', d: '汉源县', r: '511823' },
    { p: '四川省', c: '雅安市', d: '石棉县', r: '511824' },
    { p: '四川省', c: '雅安市', d: '天全县', r: '511825' },
    { p: '四川省', c: '雅安市', d: '芦山县', r: '511826' },
    { p: '四川省', c: '雅安市', d: '宝兴县', r: '511827' },
    { p: '四川省', c: '巴中市', d: '巴中市', r: '511900' },
    { p: '四川省', c: '巴中市', d: '巴州区', r: '511902' },
    { p: '四川省', c: '巴中市', d: '恩阳区', r: '511903' },
    { p: '四川省', c: '巴中市', d: '通江县', r: '511921' },
    { p: '四川省', c: '巴中市', d: '南江县', r: '511922' },
    { p: '四川省', c: '巴中市', d: '平昌县', r: '511923' },
    { p: '四川省', c: '资阳市', d: '资阳市', r: '512000' },
    { p: '四川省', c: '资阳市', d: '雁江区', r: '512002' },
    { p: '四川省', c: '资阳市', d: '安岳县', r: '512021' },
    { p: '四川省', c: '资阳市', d: '乐至县', r: '512022' },
    { p: '四川省', c: '阿坝藏族羌族自治州', d: '阿坝藏族羌族自治州', r: '513200' },
    { p: '四川省', c: '阿坝藏族羌族自治州', d: '马尔康市', r: '513201' },
    { p: '四川省', c: '阿坝藏族羌族自治州', d: '汶川县', r: '513221' },
    { p: '四川省', c: '阿坝藏族羌族自治州', d: '理县', r: '513222' },
    { p: '四川省', c: '阿坝藏族羌族自治州', d: '茂县', r: '513223' },
    { p: '四川省', c: '阿坝藏族羌族自治州', d: '松潘县', r: '513224' },
    { p: '四川省', c: '阿坝藏族羌族自治州', d: '九寨沟县', r: '513225' },
    { p: '四川省', c: '阿坝藏族羌族自治州', d: '金川县', r: '513226' },
    { p: '四川省', c: '阿坝藏族羌族自治州', d: '小金县', r: '513227' },
    { p: '四川省', c: '阿坝藏族羌族自治州', d: '黑水县', r: '513228' },
    { p: '四川省', c: '阿坝藏族羌族自治州', d: '壤塘县', r: '513230' },
    { p: '四川省', c: '阿坝藏族羌族自治州', d: '阿坝县', r: '513231' },
    { p: '四川省', c: '阿坝藏族羌族自治州', d: '若尔盖县', r: '513232' },
    { p: '四川省', c: '阿坝藏族羌族自治州', d: '红原县', r: '513233' },
    { p: '四川省', c: '甘孜藏族自治州', d: '甘孜藏族自治州', r: '513300' },
    { p: '四川省', c: '甘孜藏族自治州', d: '康定市', r: '513301' },
    { p: '四川省', c: '甘孜藏族自治州', d: '泸定县', r: '513322' },
    { p: '四川省', c: '甘孜藏族自治州', d: '丹巴县', r: '513323' },
    { p: '四川省', c: '甘孜藏族自治州', d: '九龙县', r: '513324' },
    { p: '四川省', c: '甘孜藏族自治州', d: '雅江县', r: '513325' },
    { p: '四川省', c: '甘孜藏族自治州', d: '道孚县', r: '513326' },
    { p: '四川省', c: '甘孜藏族自治州', d: '炉霍县', r: '513327' },
    { p: '四川省', c: '甘孜藏族自治州', d: '甘孜县', r: '513328' },
    { p: '四川省', c: '甘孜藏族自治州', d: '新龙县', r: '513329' },
    { p: '四川省', c: '甘孜藏族自治州', d: '德格县', r: '513330' },
    { p: '四川省', c: '甘孜藏族自治州', d: '白玉县', r: '513331' },
    { p: '四川省', c: '甘孜藏族自治州', d: '石渠县', r: '513332' },
    { p: '四川省', c: '甘孜藏族自治州', d: '色达县', r: '513333' },
    { p: '四川省', c: '甘孜藏族自治州', d: '理塘县', r: '513334' },
    { p: '四川省', c: '甘孜藏族自治州', d: '巴塘县', r: '513335' },
    { p: '四川省', c: '甘孜藏族自治州', d: '乡城县', r: '513336' },
    { p: '四川省', c: '甘孜藏族自治州', d: '稻城县', r: '513337' },
    { p: '四川省', c: '甘孜藏族自治州', d: '得荣县', r: '513338' },
    { p: '四川省', c: '凉山彝族自治州', d: '凉山彝族自治州', r: '513400' },
    { p: '四川省', c: '凉山彝族自治州', d: '西昌市', r: '513401' },
    { p: '四川省', c: '凉山彝族自治州', d: '会理市', r: '513402' },
    { p: '四川省', c: '凉山彝族自治州', d: '木里藏族自治县', r: '513422' },
    { p: '四川省', c: '凉山彝族自治州', d: '盐源县', r: '513423' },
    { p: '四川省', c: '凉山彝族自治州', d: '德昌县', r: '513424' },
    { p: '四川省', c: '凉山彝族自治州', d: '会东县', r: '513426' },
    { p: '四川省', c: '凉山彝族自治州', d: '宁南县', r: '513427' },
    { p: '四川省', c: '凉山彝族自治州', d: '普格县', r: '513428' },
    { p: '四川省', c: '凉山彝族自治州', d: '布拖县', r: '513429' },
    { p: '四川省', c: '凉山彝族自治州', d: '金阳县', r: '513430' },
    { p: '四川省', c: '凉山彝族自治州', d: '昭觉县', r: '513431' },
    { p: '四川省', c: '凉山彝族自治州', d: '喜德县', r: '513432' },
    { p: '四川省', c: '凉山彝族自治州', d: '冕宁县', r: '513433' },
    { p: '四川省', c: '凉山彝族自治州', d: '越西县', r: '513434' },
    { p: '四川省', c: '凉山彝族自治州', d: '甘洛县', r: '513435' },
    { p: '四川省', c: '凉山彝族自治州', d: '美姑县', r: '513436' },
    { p: '四川省', c: '凉山彝族自治州', d: '雷波县', r: '513437' },
    { p: '贵州省', c: '贵州省', d: '贵州省', r: '520000' },
    { p: '贵州省', c: '贵阳市', d: '贵阳市', r: '520100' },
    { p: '贵州省', c: '贵阳市', d: '南明区', r: '520102' },
    { p: '贵州省', c: '贵阳市', d: '云岩区', r: '520103' },
    { p: '贵州省', c: '贵阳市', d: '花溪区', r: '520111' },
    { p: '贵州省', c: '贵阳市', d: '乌当区', r: '520112' },
    { p: '贵州省', c: '贵阳市', d: '白云区', r: '520113' },
    { p: '贵州省', c: '贵阳市', d: '观山湖区', r: '520115' },
    { p: '贵州省', c: '贵阳市', d: '开阳县', r: '520121' },
    { p: '贵州省', c: '贵阳市', d: '息烽县', r: '520122' },
    { p: '贵州省', c: '贵阳市', d: '修文县', r: '520123' },
    { p: '贵州省', c: '贵阳市', d: '清镇市', r: '520181' },
    { p: '贵州省', c: '六盘水市', d: '六盘水市', r: '520200' },
    { p: '贵州省', c: '六盘水市', d: '钟山区', r: '520201' },
    { p: '贵州省', c: '六盘水市', d: '六枝特区', r: '520203' },
    { p: '贵州省', c: '六盘水市', d: '水城区', r: '520204' },
    { p: '贵州省', c: '六盘水市', d: '盘州市', r: '520281' },
    { p: '贵州省', c: '遵义市', d: '遵义市', r: '520300' },
    { p: '贵州省', c: '遵义市', d: '红花岗区', r: '520302' },
    { p: '贵州省', c: '遵义市', d: '汇川区', r: '520303' },
    { p: '贵州省', c: '遵义市', d: '播州区', r: '520304' },
    { p: '贵州省', c: '遵义市', d: '桐梓县', r: '520322' },
    { p: '贵州省', c: '遵义市', d: '绥阳县', r: '520323' },
    { p: '贵州省', c: '遵义市', d: '正安县', r: '520324' },
    { p: '贵州省', c: '遵义市', d: '道真仡佬族苗族自治县', r: '520325' },
    { p: '贵州省', c: '遵义市', d: '务川仡佬族苗族自治县', r: '520326' },
    { p: '贵州省', c: '遵义市', d: '凤冈县', r: '520327' },
    { p: '贵州省', c: '遵义市', d: '湄潭县', r: '520328' },
    { p: '贵州省', c: '遵义市', d: '余庆县', r: '520329' },
    { p: '贵州省', c: '遵义市', d: '习水县', r: '520330' },
    { p: '贵州省', c: '遵义市', d: '赤水市', r: '520381' },
    { p: '贵州省', c: '遵义市', d: '仁怀市', r: '520382' },
    { p: '贵州省', c: '安顺市', d: '安顺市', r: '520400' },
    { p: '贵州省', c: '安顺市', d: '西秀区', r: '520402' },
    { p: '贵州省', c: '安顺市', d: '平坝区', r: '520403' },
    { p: '贵州省', c: '安顺市', d: '普定县', r: '520422' },
    { p: '贵州省', c: '安顺市', d: '镇宁布依族苗族自治县', r: '520423' },
    { p: '贵州省', c: '安顺市', d: '关岭布依族苗族自治县', r: '520424' },
    { p: '贵州省', c: '安顺市', d: '紫云苗族布依族自治县', r: '520425' },
    { p: '贵州省', c: '毕节市', d: '毕节市', r: '520500' },
    { p: '贵州省', c: '毕节市', d: '七星关区', r: '520502' },
    { p: '贵州省', c: '毕节市', d: '大方县', r: '520521' },
    { p: '贵州省', c: '毕节市', d: '金沙县', r: '520523' },
    { p: '贵州省', c: '毕节市', d: '织金县', r: '520524' },
    { p: '贵州省', c: '毕节市', d: '纳雍县', r: '520525' },
    { p: '贵州省', c: '毕节市', d: '威宁彝族回族苗族自治县', r: '520526' },
    { p: '贵州省', c: '毕节市', d: '赫章县', r: '520527' },
    { p: '贵州省', c: '毕节市', d: '黔西市', r: '520581' },
    { p: '贵州省', c: '铜仁市', d: '铜仁市', r: '520600' },
    { p: '贵州省', c: '铜仁市', d: '碧江区', r: '520602' },
    { p: '贵州省', c: '铜仁市', d: '万山区', r: '520603' },
    { p: '贵州省', c: '铜仁市', d: '江口县', r: '520621' },
    { p: '贵州省', c: '铜仁市', d: '玉屏侗族自治县', r: '520622' },
    { p: '贵州省', c: '铜仁市', d: '石阡县', r: '520623' },
    { p: '贵州省', c: '铜仁市', d: '思南县', r: '520624' },
    { p: '贵州省', c: '铜仁市', d: '印江土家族苗族自治县', r: '520625' },
    { p: '贵州省', c: '铜仁市', d: '德江县', r: '520626' },
    { p: '贵州省', c: '铜仁市', d: '沿河土家族自治县', r: '520627' },
    { p: '贵州省', c: '铜仁市', d: '松桃苗族自治县', r: '520628' },
    { p: '贵州省', c: '黔西南布依族苗族自治州', d: '黔西南布依族苗族自治州', r: '522300' },
    { p: '贵州省', c: '黔西南布依族苗族自治州', d: '兴义市', r: '522301' },
    { p: '贵州省', c: '黔西南布依族苗族自治州', d: '兴仁市', r: '522302' },
    { p: '贵州省', c: '黔西南布依族苗族自治州', d: '普安县', r: '522323' },
    { p: '贵州省', c: '黔西南布依族苗族自治州', d: '晴隆县', r: '522324' },
    { p: '贵州省', c: '黔西南布依族苗族自治州', d: '贞丰县', r: '522325' },
    { p: '贵州省', c: '黔西南布依族苗族自治州', d: '望谟县', r: '522326' },
    { p: '贵州省', c: '黔西南布依族苗族自治州', d: '册亨县', r: '522327' },
    { p: '贵州省', c: '黔西南布依族苗族自治州', d: '安龙县', r: '522328' },
    { p: '贵州省', c: '黔东南苗族侗族自治州', d: '黔东南苗族侗族自治州', r: '522600' },
    { p: '贵州省', c: '黔东南苗族侗族自治州', d: '凯里市', r: '522601' },
    { p: '贵州省', c: '黔东南苗族侗族自治州', d: '黄平县', r: '522622' },
    { p: '贵州省', c: '黔东南苗族侗族自治州', d: '施秉县', r: '522623' },
    { p: '贵州省', c: '黔东南苗族侗族自治州', d: '三穗县', r: '522624' },
    { p: '贵州省', c: '黔东南苗族侗族自治州', d: '镇远县', r: '522625' },
    { p: '贵州省', c: '黔东南苗族侗族自治州', d: '岑巩县', r: '522626' },
    { p: '贵州省', c: '黔东南苗族侗族自治州', d: '天柱县', r: '522627' },
    { p: '贵州省', c: '黔东南苗族侗族自治州', d: '锦屏县', r: '522628' },
    { p: '贵州省', c: '黔东南苗族侗族自治州', d: '剑河县', r: '522629' },
    { p: '贵州省', c: '黔东南苗族侗族自治州', d: '台江县', r: '522630' },
    { p: '贵州省', c: '黔东南苗族侗族自治州', d: '黎平县', r: '522631' },
    { p: '贵州省', c: '黔东南苗族侗族自治州', d: '榕江县', r: '522632' },
    { p: '贵州省', c: '黔东南苗族侗族自治州', d: '从江县', r: '522633' },
    { p: '贵州省', c: '黔东南苗族侗族自治州', d: '雷山县', r: '522634' },
    { p: '贵州省', c: '黔东南苗族侗族自治州', d: '麻江县', r: '522635' },
    { p: '贵州省', c: '黔东南苗族侗族自治州', d: '丹寨县', r: '522636' },
    { p: '贵州省', c: '黔南布依族苗族自治州', d: '黔南布依族苗族自治州', r: '522700' },
    { p: '贵州省', c: '黔南布依族苗族自治州', d: '都匀市', r: '522701' },
    { p: '贵州省', c: '黔南布依族苗族自治州', d: '福泉市', r: '522702' },
    { p: '贵州省', c: '黔南布依族苗族自治州', d: '荔波县', r: '522722' },
    { p: '贵州省', c: '黔南布依族苗族自治州', d: '贵定县', r: '522723' },
    { p: '贵州省', c: '黔南布依族苗族自治州', d: '瓮安县', r: '522725' },
    { p: '贵州省', c: '黔南布依族苗族自治州', d: '独山县', r: '522726' },
    { p: '贵州省', c: '黔南布依族苗族自治州', d: '平塘县', r: '522727' },
    { p: '贵州省', c: '黔南布依族苗族自治州', d: '罗甸县', r: '522728' },
    { p: '贵州省', c: '黔南布依族苗族自治州', d: '长顺县', r: '522729' },
    { p: '贵州省', c: '黔南布依族苗族自治州', d: '龙里县', r: '522730' },
    { p: '贵州省', c: '黔南布依族苗族自治州', d: '惠水县', r: '522731' },
    { p: '贵州省', c: '黔南布依族苗族自治州', d: '三都水族自治县', r: '522732' },
    { p: '云南省', c: '云南省', d: '云南省', r: '530000' },
    { p: '云南省', c: '昆明市', d: '昆明市', r: '530100' },
    { p: '云南省', c: '昆明市', d: '五华区', r: '530102' },
    { p: '云南省', c: '昆明市', d: '盘龙区', r: '530103' },
    { p: '云南省', c: '昆明市', d: '官渡区', r: '530111' },
    { p: '云南省', c: '昆明市', d: '西山区', r: '530112' },
    { p: '云南省', c: '昆明市', d: '东川区', r: '530113' },
    { p: '云南省', c: '昆明市', d: '呈贡区', r: '530114' },
    { p: '云南省', c: '昆明市', d: '晋宁区', r: '530115' },
    { p: '云南省', c: '昆明市', d: '富民县', r: '530124' },
    { p: '云南省', c: '昆明市', d: '宜良县', r: '530125' },
    { p: '云南省', c: '昆明市', d: '石林彝族自治县', r: '530126' },
    { p: '云南省', c: '昆明市', d: '嵩明县', r: '530127' },
    { p: '云南省', c: '昆明市', d: '禄劝彝族苗族自治县', r: '530128' },
    { p: '云南省', c: '昆明市', d: '寻甸回族彝族自治县', r: '530129' },
    { p: '云南省', c: '昆明市', d: '安宁市', r: '530181' },
    { p: '云南省', c: '曲靖市', d: '曲靖市', r: '530300' },
    { p: '云南省', c: '曲靖市', d: '麒麟区', r: '530302' },
    { p: '云南省', c: '曲靖市', d: '沾益区', r: '530303' },
    { p: '云南省', c: '曲靖市', d: '马龙区', r: '530304' },
    { p: '云南省', c: '曲靖市', d: '陆良县', r: '530322' },
    { p: '云南省', c: '曲靖市', d: '师宗县', r: '530323' },
    { p: '云南省', c: '曲靖市', d: '罗平县', r: '530324' },
    { p: '云南省', c: '曲靖市', d: '富源县', r: '530325' },
    { p: '云南省', c: '曲靖市', d: '会泽县', r: '530326' },
    { p: '云南省', c: '曲靖市', d: '宣威市', r: '530381' },
    { p: '云南省', c: '玉溪市', d: '玉溪市', r: '530400' },
    { p: '云南省', c: '玉溪市', d: '红塔区', r: '530402' },
    { p: '云南省', c: '玉溪市', d: '江川区', r: '530403' },
    { p: '云南省', c: '玉溪市', d: '通海县', r: '530423' },
    { p: '云南省', c: '玉溪市', d: '华宁县', r: '530424' },
    { p: '云南省', c: '玉溪市', d: '易门县', r: '530425' },
    { p: '云南省', c: '玉溪市', d: '峨山彝族自治县', r: '530426' },
    { p: '云南省', c: '玉溪市', d: '新平彝族傣族自治县', r: '530427' },
    { p: '云南省', c: '玉溪市', d: '元江哈尼族彝族傣族自治县', r: '530428' },
    { p: '云南省', c: '玉溪市', d: '澄江市', r: '530481' },
    { p: '云南省', c: '保山市', d: '保山市', r: '530500' },
    { p: '云南省', c: '保山市', d: '隆阳区', r: '530502' },
    { p: '云南省', c: '保山市', d: '施甸县', r: '530521' },
    { p: '云南省', c: '保山市', d: '龙陵县', r: '530523' },
    { p: '云南省', c: '保山市', d: '昌宁县', r: '530524' },
    { p: '云南省', c: '保山市', d: '腾冲市', r: '530581' },
    { p: '云南省', c: '昭通市', d: '昭通市', r: '530600' },
    { p: '云南省', c: '昭通市', d: '昭阳区', r: '530602' },
    { p: '云南省', c: '昭通市', d: '鲁甸县', r: '530621' },
    { p: '云南省', c: '昭通市', d: '巧家县', r: '530622' },
    { p: '云南省', c: '昭通市', d: '盐津县', r: '530623' },
    { p: '云南省', c: '昭通市', d: '大关县', r: '530624' },
    { p: '云南省', c: '昭通市', d: '永善县', r: '530625' },
    { p: '云南省', c: '昭通市', d: '绥江县', r: '530626' },
    { p: '云南省', c: '昭通市', d: '镇雄县', r: '530627' },
    { p: '云南省', c: '昭通市', d: '彝良县', r: '530628' },
    { p: '云南省', c: '昭通市', d: '威信县', r: '530629' },
    { p: '云南省', c: '昭通市', d: '水富市', r: '530681' },
    { p: '云南省', c: '丽江市', d: '丽江市', r: '530700' },
    { p: '云南省', c: '丽江市', d: '古城区', r: '530702' },
    { p: '云南省', c: '丽江市', d: '玉龙纳西族自治县', r: '530721' },
    { p: '云南省', c: '丽江市', d: '永胜县', r: '530722' },
    { p: '云南省', c: '丽江市', d: '华坪县', r: '530723' },
    { p: '云南省', c: '丽江市', d: '宁蒗彝族自治县', r: '530724' },
    { p: '云南省', c: '普洱市', d: '普洱市', r: '530800' },
    { p: '云南省', c: '普洱市', d: '思茅区', r: '530802' },
    { p: '云南省', c: '普洱市', d: '宁洱哈尼族彝族自治县', r: '530821' },
    { p: '云南省', c: '普洱市', d: '墨江哈尼族自治县', r: '530822' },
    { p: '云南省', c: '普洱市', d: '景东彝族自治县', r: '530823' },
    { p: '云南省', c: '普洱市', d: '景谷傣族彝族自治县', r: '530824' },
    { p: '云南省', c: '普洱市', d: '镇沅彝族哈尼族拉祜族自治县', r: '530825' },
    { p: '云南省', c: '普洱市', d: '江城哈尼族彝族自治县', r: '530826' },
    { p: '云南省', c: '普洱市', d: '孟连傣族拉祜族佤族自治县', r: '530827' },
    { p: '云南省', c: '普洱市', d: '澜沧拉祜族自治县', r: '530828' },
    { p: '云南省', c: '普洱市', d: '西盟佤族自治县', r: '530829' },
    { p: '云南省', c: '临沧市', d: '临沧市', r: '530900' },
    { p: '云南省', c: '临沧市', d: '临翔区', r: '530902' },
    { p: '云南省', c: '临沧市', d: '凤庆县', r: '530921' },
    { p: '云南省', c: '临沧市', d: '云县', r: '530922' },
    { p: '云南省', c: '临沧市', d: '永德县', r: '530923' },
    { p: '云南省', c: '临沧市', d: '镇康县', r: '530924' },
    { p: '云南省', c: '临沧市', d: '双江拉祜族佤族布朗族傣族自治县', r: '530925' },
    { p: '云南省', c: '临沧市', d: '耿马傣族佤族自治县', r: '530926' },
    { p: '云南省', c: '临沧市', d: '沧源佤族自治县', r: '530927' },
    { p: '云南省', c: '楚雄彝族自治州', d: '楚雄彝族自治州', r: '532300' },
    { p: '云南省', c: '楚雄彝族自治州', d: '楚雄市', r: '532301' },
    { p: '云南省', c: '楚雄彝族自治州', d: '禄丰市', r: '532302' },
    { p: '云南省', c: '楚雄彝族自治州', d: '双柏县', r: '532322' },
    { p: '云南省', c: '楚雄彝族自治州', d: '牟定县', r: '532323' },
    { p: '云南省', c: '楚雄彝族自治州', d: '南华县', r: '532324' },
    { p: '云南省', c: '楚雄彝族自治州', d: '姚安县', r: '532325' },
    { p: '云南省', c: '楚雄彝族自治州', d: '大姚县', r: '532326' },
    { p: '云南省', c: '楚雄彝族自治州', d: '永仁县', r: '532327' },
    { p: '云南省', c: '楚雄彝族自治州', d: '元谋县', r: '532328' },
    { p: '云南省', c: '楚雄彝族自治州', d: '武定县', r: '532329' },
    { p: '云南省', c: '红河哈尼族彝族自治州', d: '红河哈尼族彝族自治州', r: '532500' },
    { p: '云南省', c: '红河哈尼族彝族自治州', d: '个旧市', r: '532501' },
    { p: '云南省', c: '红河哈尼族彝族自治州', d: '开远市', r: '532502' },
    { p: '云南省', c: '红河哈尼族彝族自治州', d: '蒙自市', r: '532503' },
    { p: '云南省', c: '红河哈尼族彝族自治州', d: '弥勒市', r: '532504' },
    { p: '云南省', c: '红河哈尼族彝族自治州', d: '屏边苗族自治县', r: '532523' },
    { p: '云南省', c: '红河哈尼族彝族自治州', d: '建水县', r: '532524' },
    { p: '云南省', c: '红河哈尼族彝族自治州', d: '石屏县', r: '532525' },
    { p: '云南省', c: '红河哈尼族彝族自治州', d: '泸西县', r: '532527' },
    { p: '云南省', c: '红河哈尼族彝族自治州', d: '元阳县', r: '532528' },
    { p: '云南省', c: '红河哈尼族彝族自治州', d: '红河县', r: '532529' },
    { p: '云南省', c: '红河哈尼族彝族自治州', d: '金平苗族瑶族傣族自治县', r: '532530' },
    { p: '云南省', c: '红河哈尼族彝族自治州', d: '绿春县', r: '532531' },
    { p: '云南省', c: '红河哈尼族彝族自治州', d: '河口瑶族自治县', r: '532532' },
    { p: '云南省', c: '文山壮族苗族自治州', d: '文山壮族苗族自治州', r: '532600' },
    { p: '云南省', c: '文山壮族苗族自治州', d: '文山市', r: '532601' },
    { p: '云南省', c: '文山壮族苗族自治州', d: '砚山县', r: '532622' },
    { p: '云南省', c: '文山壮族苗族自治州', d: '西畴县', r: '532623' },
    { p: '云南省', c: '文山壮族苗族自治州', d: '麻栗坡县', r: '532624' },
    { p: '云南省', c: '文山壮族苗族自治州', d: '马关县', r: '532625' },
    { p: '云南省', c: '文山壮族苗族自治州', d: '丘北县', r: '532626' },
    { p: '云南省', c: '文山壮族苗族自治州', d: '广南县', r: '532627' },
    { p: '云南省', c: '文山壮族苗族自治州', d: '富宁县', r: '532628' },
    { p: '云南省', c: '西双版纳傣族自治州', d: '西双版纳傣族自治州', r: '532800' },
    { p: '云南省', c: '西双版纳傣族自治州', d: '景洪市', r: '532801' },
    { p: '云南省', c: '西双版纳傣族自治州', d: '勐海县', r: '532822' },
    { p: '云南省', c: '西双版纳傣族自治州', d: '勐腊县', r: '532823' },
    { p: '云南省', c: '大理白族自治州', d: '大理白族自治州', r: '532900' },
    { p: '云南省', c: '大理白族自治州', d: '大理市', r: '532901' },
    { p: '云南省', c: '大理白族自治州', d: '漾濞彝族自治县', r: '532922' },
    { p: '云南省', c: '大理白族自治州', d: '祥云县', r: '532923' },
    { p: '云南省', c: '大理白族自治州', d: '宾川县', r: '532924' },
    { p: '云南省', c: '大理白族自治州', d: '弥渡县', r: '532925' },
    { p: '云南省', c: '大理白族自治州', d: '南涧彝族自治县', r: '532926' },
    { p: '云南省', c: '大理白族自治州', d: '巍山彝族回族自治县', r: '532927' },
    { p: '云南省', c: '大理白族自治州', d: '永平县', r: '532928' },
    { p: '云南省', c: '大理白族自治州', d: '云龙县', r: '532929' },
    { p: '云南省', c: '大理白族自治州', d: '洱源县', r: '532930' },
    { p: '云南省', c: '大理白族自治州', d: '剑川县', r: '532931' },
    { p: '云南省', c: '大理白族自治州', d: '鹤庆县', r: '532932' },
    { p: '云南省', c: '德宏傣族景颇族自治州', d: '德宏傣族景颇族自治州', r: '533100' },
    { p: '云南省', c: '德宏傣族景颇族自治州', d: '瑞丽市', r: '533102' },
    { p: '云南省', c: '德宏傣族景颇族自治州', d: '芒市', r: '533103' },
    { p: '云南省', c: '德宏傣族景颇族自治州', d: '梁河县', r: '533122' },
    { p: '云南省', c: '德宏傣族景颇族自治州', d: '盈江县', r: '533123' },
    { p: '云南省', c: '德宏傣族景颇族自治州', d: '陇川县', r: '533124' },
    { p: '云南省', c: '怒江傈僳族自治州', d: '怒江傈僳族自治州', r: '533300' },
    { p: '云南省', c: '怒江傈僳族自治州', d: '泸水市', r: '533301' },
    { p: '云南省', c: '怒江傈僳族自治州', d: '福贡县', r: '533323' },
    { p: '云南省', c: '怒江傈僳族自治州', d: '贡山独龙族怒族自治县', r: '533324' },
    { p: '云南省', c: '怒江傈僳族自治州', d: '兰坪白族普米族自治县', r: '533325' },
    { p: '云南省', c: '迪庆藏族自治州', d: '迪庆藏族自治州', r: '533400' },
    { p: '云南省', c: '迪庆藏族自治州', d: '香格里拉市', r: '533401' },
    { p: '云南省', c: '迪庆藏族自治州', d: '德钦县', r: '533422' },
    { p: '云南省', c: '迪庆藏族自治州', d: '维西傈僳族自治县', r: '533423' },
    { p: '西藏自治区', c: '西藏自治区', d: '西藏自治区', r: '540000' },
    { p: '西藏自治区', c: '拉萨市', d: '拉萨市', r: '540100' },
    { p: '西藏自治区', c: '拉萨市', d: '城关区', r: '540102' },
    { p: '西藏自治区', c: '拉萨市', d: '堆龙德庆区', r: '540103' },
    { p: '西藏自治区', c: '拉萨市', d: '达孜区', r: '540104' },
    { p: '西藏自治区', c: '拉萨市', d: '林周县', r: '540121' },
    { p: '西藏自治区', c: '拉萨市', d: '当雄县', r: '540122' },
    { p: '西藏自治区', c: '拉萨市', d: '尼木县', r: '540123' },
    { p: '西藏自治区', c: '拉萨市', d: '曲水县', r: '540124' },
    { p: '西藏自治区', c: '拉萨市', d: '墨竹工卡县', r: '540127' },
    { p: '西藏自治区', c: '日喀则市', d: '日喀则市', r: '540200' },
    { p: '西藏自治区', c: '日喀则市', d: '桑珠孜区', r: '540202' },
    { p: '西藏自治区', c: '日喀则市', d: '南木林县', r: '540221' },
    { p: '西藏自治区', c: '日喀则市', d: '江孜县', r: '540222' },
    { p: '西藏自治区', c: '日喀则市', d: '定日县', r: '540223' },
    { p: '西藏自治区', c: '日喀则市', d: '萨迦县', r: '540224' },
    { p: '西藏自治区', c: '日喀则市', d: '拉孜县', r: '540225' },
    { p: '西藏自治区', c: '日喀则市', d: '昂仁县', r: '540226' },
    { p: '西藏自治区', c: '日喀则市', d: '谢通门县', r: '540227' },
    { p: '西藏自治区', c: '日喀则市', d: '白朗县', r: '540228' },
    { p: '西藏自治区', c: '日喀则市', d: '仁布县', r: '540229' },
    { p: '西藏自治区', c: '日喀则市', d: '康马县', r: '540230' },
    { p: '西藏自治区', c: '日喀则市', d: '定结县', r: '540231' },
    { p: '西藏自治区', c: '日喀则市', d: '仲巴县', r: '540232' },
    { p: '西藏自治区', c: '日喀则市', d: '亚东县', r: '540233' },
    { p: '西藏自治区', c: '日喀则市', d: '吉隆县', r: '540234' },
    { p: '西藏自治区', c: '日喀则市', d: '聂拉木县', r: '540235' },
    { p: '西藏自治区', c: '日喀则市', d: '萨嘎县', r: '540236' },
    { p: '西藏自治区', c: '日喀则市', d: '岗巴县', r: '540237' },
    { p: '西藏自治区', c: '昌都市', d: '昌都市', r: '540300' },
    { p: '西藏自治区', c: '昌都市', d: '卡若区', r: '540302' },
    { p: '西藏自治区', c: '昌都市', d: '江达县', r: '540321' },
    { p: '西藏自治区', c: '昌都市', d: '贡觉县', r: '540322' },
    { p: '西藏自治区', c: '昌都市', d: '类乌齐县', r: '540323' },
    { p: '西藏自治区', c: '昌都市', d: '丁青县', r: '540324' },
    { p: '西藏自治区', c: '昌都市', d: '察雅县', r: '540325' },
    { p: '西藏自治区', c: '昌都市', d: '八宿县', r: '540326' },
    { p: '西藏自治区', c: '昌都市', d: '左贡县', r: '540327' },
    { p: '西藏自治区', c: '昌都市', d: '芒康县', r: '540328' },
    { p: '西藏自治区', c: '昌都市', d: '洛隆县', r: '540329' },
    { p: '西藏自治区', c: '昌都市', d: '边坝县', r: '540330' },
    { p: '西藏自治区', c: '林芝市', d: '林芝市', r: '540400' },
    { p: '西藏自治区', c: '林芝市', d: '巴宜区', r: '540402' },
    { p: '西藏自治区', c: '林芝市', d: '工布江达县', r: '540421' },
    { p: '西藏自治区', c: '林芝市', d: '米林县', r: '540422' },
    { p: '西藏自治区', c: '林芝市', d: '墨脱县', r: '540423' },
    { p: '西藏自治区', c: '林芝市', d: '波密县', r: '540424' },
    { p: '西藏自治区', c: '林芝市', d: '察隅县', r: '540425' },
    { p: '西藏自治区', c: '林芝市', d: '朗县', r: '540426' },
    { p: '西藏自治区', c: '山南市', d: '山南市', r: '540500' },
    { p: '西藏自治区', c: '山南市', d: '乃东区', r: '540502' },
    { p: '西藏自治区', c: '山南市', d: '扎囊县', r: '540521' },
    { p: '西藏自治区', c: '山南市', d: '贡嘎县', r: '540522' },
    { p: '西藏自治区', c: '山南市', d: '桑日县', r: '540523' },
    { p: '西藏自治区', c: '山南市', d: '琼结县', r: '540524' },
    { p: '西藏自治区', c: '山南市', d: '曲松县', r: '540525' },
    { p: '西藏自治区', c: '山南市', d: '措美县', r: '540526' },
    { p: '西藏自治区', c: '山南市', d: '洛扎县', r: '540527' },
    { p: '西藏自治区', c: '山南市', d: '加查县', r: '540528' },
    { p: '西藏自治区', c: '山南市', d: '隆子县', r: '540529' },
    { p: '西藏自治区', c: '山南市', d: '错那县', r: '540530' },
    { p: '西藏自治区', c: '山南市', d: '浪卡子县', r: '540531' },
    { p: '西藏自治区', c: '那曲市', d: '那曲市', r: '540600' },
    { p: '西藏自治区', c: '那曲市', d: '色尼区', r: '540602' },
    { p: '西藏自治区', c: '那曲市', d: '嘉黎县', r: '540621' },
    { p: '西藏自治区', c: '那曲市', d: '比如县', r: '540622' },
    { p: '西藏自治区', c: '那曲市', d: '聂荣县', r: '540623' },
    { p: '西藏自治区', c: '那曲市', d: '安多县', r: '540624' },
    { p: '西藏自治区', c: '那曲市', d: '申扎县', r: '540625' },
    { p: '西藏自治区', c: '那曲市', d: '索县', r: '540626' },
    { p: '西藏自治区', c: '那曲市', d: '班戈县', r: '540627' },
    { p: '西藏自治区', c: '那曲市', d: '巴青县', r: '540628' },
    { p: '西藏自治区', c: '那曲市', d: '尼玛县', r: '540629' },
    { p: '西藏自治区', c: '那曲市', d: '双湖县', r: '540630' },
    { p: '西藏自治区', c: '阿里地区', d: '阿里地区', r: '542500' },
    { p: '西藏自治区', c: '阿里地区', d: '普兰县', r: '542521' },
    { p: '西藏自治区', c: '阿里地区', d: '札达县', r: '542522' },
    { p: '西藏自治区', c: '阿里地区', d: '噶尔县', r: '542523' },
    { p: '西藏自治区', c: '阿里地区', d: '日土县', r: '542524' },
    { p: '西藏自治区', c: '阿里地区', d: '革吉县', r: '542525' },
    { p: '西藏自治区', c: '阿里地区', d: '改则县', r: '542526' },
    { p: '西藏自治区', c: '阿里地区', d: '措勤县', r: '542527' },
    { p: '陕西省', c: '陕西省', d: '陕西省', r: '610000' },
    { p: '陕西省', c: '西安市', d: '西安市', r: '610100' },
    { p: '陕西省', c: '西安市', d: '新城区', r: '610102' },
    { p: '陕西省', c: '西安市', d: '碑林区', r: '610103' },
    { p: '陕西省', c: '西安市', d: '莲湖区', r: '610104' },
    { p: '陕西省', c: '西安市', d: '灞桥区', r: '610111' },
    { p: '陕西省', c: '西安市', d: '未央区', r: '610112' },
    { p: '陕西省', c: '西安市', d: '雁塔区', r: '610113' },
    { p: '陕西省', c: '西安市', d: '阎良区', r: '610114' },
    { p: '陕西省', c: '西安市', d: '临潼区', r: '610115' },
    { p: '陕西省', c: '西安市', d: '长安区', r: '610116' },
    { p: '陕西省', c: '西安市', d: '高陵区', r: '610117' },
    { p: '陕西省', c: '西安市', d: '鄠邑区', r: '610118' },
    { p: '陕西省', c: '西安市', d: '蓝田县', r: '610122' },
    { p: '陕西省', c: '西安市', d: '周至县', r: '610124' },
    { p: '陕西省', c: '铜川市', d: '铜川市', r: '610200' },
    { p: '陕西省', c: '铜川市', d: '王益区', r: '610202' },
    { p: '陕西省', c: '铜川市', d: '印台区', r: '610203' },
    { p: '陕西省', c: '铜川市', d: '耀州区', r: '610204' },
    { p: '陕西省', c: '铜川市', d: '宜君县', r: '610222' },
    { p: '陕西省', c: '宝鸡市', d: '宝鸡市', r: '610300' },
    { p: '陕西省', c: '宝鸡市', d: '渭滨区', r: '610302' },
    { p: '陕西省', c: '宝鸡市', d: '金台区', r: '610303' },
    { p: '陕西省', c: '宝鸡市', d: '陈仓区', r: '610304' },
    { p: '陕西省', c: '宝鸡市', d: '凤翔区', r: '610305' },
    { p: '陕西省', c: '宝鸡市', d: '岐山县', r: '610323' },
    { p: '陕西省', c: '宝鸡市', d: '扶风县', r: '610324' },
    { p: '陕西省', c: '宝鸡市', d: '眉县', r: '610326' },
    { p: '陕西省', c: '宝鸡市', d: '陇县', r: '610327' },
    { p: '陕西省', c: '宝鸡市', d: '千阳县', r: '610328' },
    { p: '陕西省', c: '宝鸡市', d: '麟游县', r: '610329' },
    { p: '陕西省', c: '宝鸡市', d: '凤县', r: '610330' },
    { p: '陕西省', c: '宝鸡市', d: '太白县', r: '610331' },
    { p: '陕西省', c: '咸阳市', d: '咸阳市', r: '610400' },
    { p: '陕西省', c: '咸阳市', d: '秦都区', r: '610402' },
    { p: '陕西省', c: '咸阳市', d: '杨陵区', r: '610403' },
    { p: '陕西省', c: '咸阳市', d: '渭城区', r: '610404' },
    { p: '陕西省', c: '咸阳市', d: '三原县', r: '610422' },
    { p: '陕西省', c: '咸阳市', d: '泾阳县', r: '610423' },
    { p: '陕西省', c: '咸阳市', d: '乾县', r: '610424' },
    { p: '陕西省', c: '咸阳市', d: '礼泉县', r: '610425' },
    { p: '陕西省', c: '咸阳市', d: '永寿县', r: '610426' },
    { p: '陕西省', c: '咸阳市', d: '长武县', r: '610428' },
    { p: '陕西省', c: '咸阳市', d: '旬邑县', r: '610429' },
    { p: '陕西省', c: '咸阳市', d: '淳化县', r: '610430' },
    { p: '陕西省', c: '咸阳市', d: '武功县', r: '610431' },
    { p: '陕西省', c: '咸阳市', d: '兴平市', r: '610481' },
    { p: '陕西省', c: '咸阳市', d: '彬州市', r: '610482' },
    { p: '陕西省', c: '渭南市', d: '渭南市', r: '610500' },
    { p: '陕西省', c: '渭南市', d: '临渭区', r: '610502' },
    { p: '陕西省', c: '渭南市', d: '华州区', r: '610503' },
    { p: '陕西省', c: '渭南市', d: '潼关县', r: '610522' },
    { p: '陕西省', c: '渭南市', d: '大荔县', r: '610523' },
    { p: '陕西省', c: '渭南市', d: '合阳县', r: '610524' },
    { p: '陕西省', c: '渭南市', d: '澄城县', r: '610525' },
    { p: '陕西省', c: '渭南市', d: '蒲城县', r: '610526' },
    { p: '陕西省', c: '渭南市', d: '白水县', r: '610527' },
    { p: '陕西省', c: '渭南市', d: '富平县', r: '610528' },
    { p: '陕西省', c: '渭南市', d: '韩城市', r: '610581' },
    { p: '陕西省', c: '渭南市', d: '华阴市', r: '610582' },
    { p: '陕西省', c: '延安市', d: '延安市', r: '610600' },
    { p: '陕西省', c: '延安市', d: '宝塔区', r: '610602' },
    { p: '陕西省', c: '延安市', d: '安塞区', r: '610603' },
    { p: '陕西省', c: '延安市', d: '延长县', r: '610621' },
    { p: '陕西省', c: '延安市', d: '延川县', r: '610622' },
    { p: '陕西省', c: '延安市', d: '志丹县', r: '610625' },
    { p: '陕西省', c: '延安市', d: '吴起县', r: '610626' },
    { p: '陕西省', c: '延安市', d: '甘泉县', r: '610627' },
    { p: '陕西省', c: '延安市', d: '富县', r: '610628' },
    { p: '陕西省', c: '延安市', d: '洛川县', r: '610629' },
    { p: '陕西省', c: '延安市', d: '宜川县', r: '610630' },
    { p: '陕西省', c: '延安市', d: '黄龙县', r: '610631' },
    { p: '陕西省', c: '延安市', d: '黄陵县', r: '610632' },
    { p: '陕西省', c: '延安市', d: '子长市', r: '610681' },
    { p: '陕西省', c: '汉中市', d: '汉中市', r: '610700' },
    { p: '陕西省', c: '汉中市', d: '汉台区', r: '610702' },
    { p: '陕西省', c: '汉中市', d: '南郑区', r: '610703' },
    { p: '陕西省', c: '汉中市', d: '城固县', r: '610722' },
    { p: '陕西省', c: '汉中市', d: '洋县', r: '610723' },
    { p: '陕西省', c: '汉中市', d: '西乡县', r: '610724' },
    { p: '陕西省', c: '汉中市', d: '勉县', r: '610725' },
    { p: '陕西省', c: '汉中市', d: '宁强县', r: '610726' },
    { p: '陕西省', c: '汉中市', d: '略阳县', r: '610727' },
    { p: '陕西省', c: '汉中市', d: '镇巴县', r: '610728' },
    { p: '陕西省', c: '汉中市', d: '留坝县', r: '610729' },
    { p: '陕西省', c: '汉中市', d: '佛坪县', r: '610730' },
    { p: '陕西省', c: '榆林市', d: '榆林市', r: '610800' },
    { p: '陕西省', c: '榆林市', d: '榆阳区', r: '610802' },
    { p: '陕西省', c: '榆林市', d: '横山区', r: '610803' },
    { p: '陕西省', c: '榆林市', d: '府谷县', r: '610822' },
    { p: '陕西省', c: '榆林市', d: '靖边县', r: '610824' },
    { p: '陕西省', c: '榆林市', d: '定边县', r: '610825' },
    { p: '陕西省', c: '榆林市', d: '绥德县', r: '610826' },
    { p: '陕西省', c: '榆林市', d: '米脂县', r: '610827' },
    { p: '陕西省', c: '榆林市', d: '佳县', r: '610828' },
    { p: '陕西省', c: '榆林市', d: '吴堡县', r: '610829' },
    { p: '陕西省', c: '榆林市', d: '清涧县', r: '610830' },
    { p: '陕西省', c: '榆林市', d: '子洲县', r: '610831' },
    { p: '陕西省', c: '榆林市', d: '神木市', r: '610881' },
    { p: '陕西省', c: '安康市', d: '安康市', r: '610900' },
    { p: '陕西省', c: '安康市', d: '汉滨区', r: '610902' },
    { p: '陕西省', c: '安康市', d: '汉阴县', r: '610921' },
    { p: '陕西省', c: '安康市', d: '石泉县', r: '610922' },
    { p: '陕西省', c: '安康市', d: '宁陕县', r: '610923' },
    { p: '陕西省', c: '安康市', d: '紫阳县', r: '610924' },
    { p: '陕西省', c: '安康市', d: '岚皋县', r: '610925' },
    { p: '陕西省', c: '安康市', d: '平利县', r: '610926' },
    { p: '陕西省', c: '安康市', d: '镇坪县', r: '610927' },
    { p: '陕西省', c: '安康市', d: '白河县', r: '610929' },
    { p: '陕西省', c: '安康市', d: '旬阳市', r: '610981' },
    { p: '陕西省', c: '商洛市', d: '商洛市', r: '611000' },
    { p: '陕西省', c: '商洛市', d: '商州区', r: '611002' },
    { p: '陕西省', c: '商洛市', d: '洛南县', r: '611021' },
    { p: '陕西省', c: '商洛市', d: '丹凤县', r: '611022' },
    { p: '陕西省', c: '商洛市', d: '商南县', r: '611023' },
    { p: '陕西省', c: '商洛市', d: '山阳县', r: '611024' },
    { p: '陕西省', c: '商洛市', d: '镇安县', r: '611025' },
    { p: '陕西省', c: '商洛市', d: '柞水县', r: '611026' },
    { p: '甘肃省', c: '甘肃省', d: '甘肃省', r: '620000' },
    { p: '甘肃省', c: '兰州市', d: '兰州市', r: '620100' },
    { p: '甘肃省', c: '兰州市', d: '城关区', r: '620102' },
    { p: '甘肃省', c: '兰州市', d: '七里河区', r: '620103' },
    { p: '甘肃省', c: '兰州市', d: '西固区', r: '620104' },
    { p: '甘肃省', c: '兰州市', d: '安宁区', r: '620105' },
    { p: '甘肃省', c: '兰州市', d: '红古区', r: '620111' },
    { p: '甘肃省', c: '兰州市', d: '永登县', r: '620121' },
    { p: '甘肃省', c: '兰州市', d: '皋兰县', r: '620122' },
    { p: '甘肃省', c: '兰州市', d: '榆中县', r: '620123' },
    { p: '甘肃省', c: '嘉峪关市', d: '嘉峪关市', r: '620200' },
    { p: '甘肃省', c: '金昌市', d: '金昌市', r: '620300' },
    { p: '甘肃省', c: '金昌市', d: '金川区', r: '620302' },
    { p: '甘肃省', c: '金昌市', d: '永昌县', r: '620321' },
    { p: '甘肃省', c: '白银市', d: '白银市', r: '620400' },
    { p: '甘肃省', c: '白银市', d: '白银区', r: '620402' },
    { p: '甘肃省', c: '白银市', d: '平川区', r: '620403' },
    { p: '甘肃省', c: '白银市', d: '靖远县', r: '620421' },
    { p: '甘肃省', c: '白银市', d: '会宁县', r: '620422' },
    { p: '甘肃省', c: '白银市', d: '景泰县', r: '620423' },
    { p: '甘肃省', c: '天水市', d: '天水市', r: '620500' },
    { p: '甘肃省', c: '天水市', d: '秦州区', r: '620502' },
    { p: '甘肃省', c: '天水市', d: '麦积区', r: '620503' },
    { p: '甘肃省', c: '天水市', d: '清水县', r: '620521' },
    { p: '甘肃省', c: '天水市', d: '秦安县', r: '620522' },
    { p: '甘肃省', c: '天水市', d: '甘谷县', r: '620523' },
    { p: '甘肃省', c: '天水市', d: '武山县', r: '620524' },
    { p: '甘肃省', c: '天水市', d: '张家川回族自治县', r: '620525' },
    { p: '甘肃省', c: '武威市', d: '武威市', r: '620600' },
    { p: '甘肃省', c: '武威市', d: '凉州区', r: '620602' },
    { p: '甘肃省', c: '武威市', d: '民勤县', r: '620621' },
    { p: '甘肃省', c: '武威市', d: '古浪县', r: '620622' },
    { p: '甘肃省', c: '武威市', d: '天祝藏族自治县', r: '620623' },
    { p: '甘肃省', c: '张掖市', d: '张掖市', r: '620700' },
    { p: '甘肃省', c: '张掖市', d: '甘州区', r: '620702' },
    { p: '甘肃省', c: '张掖市', d: '肃南裕固族自治县', r: '620721' },
    { p: '甘肃省', c: '张掖市', d: '民乐县', r: '620722' },
    { p: '甘肃省', c: '张掖市', d: '临泽县', r: '620723' },
    { p: '甘肃省', c: '张掖市', d: '高台县', r: '620724' },
    { p: '甘肃省', c: '张掖市', d: '山丹县', r: '620725' },
    { p: '甘肃省', c: '平凉市', d: '平凉市', r: '620800' },
    { p: '甘肃省', c: '平凉市', d: '崆峒区', r: '620802' },
    { p: '甘肃省', c: '平凉市', d: '泾川县', r: '620821' },
    { p: '甘肃省', c: '平凉市', d: '灵台县', r: '620822' },
    { p: '甘肃省', c: '平凉市', d: '崇信县', r: '620823' },
    { p: '甘肃省', c: '平凉市', d: '庄浪县', r: '620825' },
    { p: '甘肃省', c: '平凉市', d: '静宁县', r: '620826' },
    { p: '甘肃省', c: '平凉市', d: '华亭市', r: '620881' },
    { p: '甘肃省', c: '酒泉市', d: '酒泉市', r: '620900' },
    { p: '甘肃省', c: '酒泉市', d: '肃州区', r: '620902' },
    { p: '甘肃省', c: '酒泉市', d: '金塔县', r: '620921' },
    { p: '甘肃省', c: '酒泉市', d: '瓜州县', r: '620922' },
    { p: '甘肃省', c: '酒泉市', d: '肃北蒙古族自治县', r: '620923' },
    { p: '甘肃省', c: '酒泉市', d: '阿克塞哈萨克族自治县', r: '620924' },
    { p: '甘肃省', c: '酒泉市', d: '玉门市', r: '620981' },
    { p: '甘肃省', c: '酒泉市', d: '敦煌市', r: '620982' },
    { p: '甘肃省', c: '庆阳市', d: '庆阳市', r: '621000' },
    { p: '甘肃省', c: '庆阳市', d: '西峰区', r: '621002' },
    { p: '甘肃省', c: '庆阳市', d: '庆城县', r: '621021' },
    { p: '甘肃省', c: '庆阳市', d: '环县', r: '621022' },
    { p: '甘肃省', c: '庆阳市', d: '华池县', r: '621023' },
    { p: '甘肃省', c: '庆阳市', d: '合水县', r: '621024' },
    { p: '甘肃省', c: '庆阳市', d: '正宁县', r: '621025' },
    { p: '甘肃省', c: '庆阳市', d: '宁县', r: '621026' },
    { p: '甘肃省', c: '庆阳市', d: '镇原县', r: '621027' },
    { p: '甘肃省', c: '定西市', d: '定西市', r: '621100' },
    { p: '甘肃省', c: '定西市', d: '安定区', r: '621102' },
    { p: '甘肃省', c: '定西市', d: '通渭县', r: '621121' },
    { p: '甘肃省', c: '定西市', d: '陇西县', r: '621122' },
    { p: '甘肃省', c: '定西市', d: '渭源县', r: '621123' },
    { p: '甘肃省', c: '定西市', d: '临洮县', r: '621124' },
    { p: '甘肃省', c: '定西市', d: '漳县', r: '621125' },
    { p: '甘肃省', c: '定西市', d: '岷县', r: '621126' },
    { p: '甘肃省', c: '陇南市', d: '陇南市', r: '621200' },
    { p: '甘肃省', c: '陇南市', d: '武都区', r: '621202' },
    { p: '甘肃省', c: '陇南市', d: '成县', r: '621221' },
    { p: '甘肃省', c: '陇南市', d: '文县', r: '621222' },
    { p: '甘肃省', c: '陇南市', d: '宕昌县', r: '621223' },
    { p: '甘肃省', c: '陇南市', d: '康县', r: '621224' },
    { p: '甘肃省', c: '陇南市', d: '西和县', r: '621225' },
    { p: '甘肃省', c: '陇南市', d: '礼县', r: '621226' },
    { p: '甘肃省', c: '陇南市', d: '徽县', r: '621227' },
    { p: '甘肃省', c: '陇南市', d: '两当县', r: '621228' },
    { p: '甘肃省', c: '临夏回族自治州', d: '临夏回族自治州', r: '622900' },
    { p: '甘肃省', c: '临夏回族自治州', d: '临夏市', r: '622901' },
    { p: '甘肃省', c: '临夏回族自治州', d: '临夏县', r: '622921' },
    { p: '甘肃省', c: '临夏回族自治州', d: '康乐县', r: '622922' },
    { p: '甘肃省', c: '临夏回族自治州', d: '永靖县', r: '622923' },
    { p: '甘肃省', c: '临夏回族自治州', d: '广河县', r: '622924' },
    { p: '甘肃省', c: '临夏回族自治州', d: '和政县', r: '622925' },
    { p: '甘肃省', c: '临夏回族自治州', d: '东乡族自治县', r: '622926' },
    { p: '甘肃省', c: '临夏回族自治州', d: '积石山保安族东乡族撒拉族自治县', r: '622927' },
    { p: '甘肃省', c: '甘南藏族自治州', d: '甘南藏族自治州', r: '623000' },
    { p: '甘肃省', c: '甘南藏族自治州', d: '合作市', r: '623001' },
    { p: '甘肃省', c: '甘南藏族自治州', d: '临潭县', r: '623021' },
    { p: '甘肃省', c: '甘南藏族自治州', d: '卓尼县', r: '623022' },
    { p: '甘肃省', c: '甘南藏族自治州', d: '舟曲县', r: '623023' },
    { p: '甘肃省', c: '甘南藏族自治州', d: '迭部县', r: '623024' },
    { p: '甘肃省', c: '甘南藏族自治州', d: '玛曲县', r: '623025' },
    { p: '甘肃省', c: '甘南藏族自治州', d: '碌曲县', r: '623026' },
    { p: '甘肃省', c: '甘南藏族自治州', d: '夏河县', r: '623027' },
    { p: '青海省', c: '青海省', d: '青海省', r: '630000' },
    { p: '青海省', c: '西宁市', d: '西宁市', r: '630100' },
    { p: '青海省', c: '西宁市', d: '城东区', r: '630102' },
    { p: '青海省', c: '西宁市', d: '城中区', r: '630103' },
    { p: '青海省', c: '西宁市', d: '城西区', r: '630104' },
    { p: '青海省', c: '西宁市', d: '城北区', r: '630105' },
    { p: '青海省', c: '西宁市', d: '湟中区', r: '630106' },
    { p: '青海省', c: '西宁市', d: '大通回族土族自治县', r: '630121' },
    { p: '青海省', c: '西宁市', d: '湟源县', r: '630123' },
    { p: '青海省', c: '海东市', d: '海东市', r: '630200' },
    { p: '青海省', c: '海东市', d: '乐都区', r: '630202' },
    { p: '青海省', c: '海东市', d: '平安区', r: '630203' },
    { p: '青海省', c: '海东市', d: '民和回族土族自治县', r: '630222' },
    { p: '青海省', c: '海东市', d: '互助土族自治县', r: '630223' },
    { p: '青海省', c: '海东市', d: '化隆回族自治县', r: '630224' },
    { p: '青海省', c: '海东市', d: '循化撒拉族自治县', r: '630225' },
    { p: '青海省', c: '海北藏族自治州', d: '海北藏族自治州', r: '632200' },
    { p: '青海省', c: '海北藏族自治州', d: '门源回族自治县', r: '632221' },
    { p: '青海省', c: '海北藏族自治州', d: '祁连县', r: '632222' },
    { p: '青海省', c: '海北藏族自治州', d: '海晏县', r: '632223' },
    { p: '青海省', c: '海北藏族自治州', d: '刚察县', r: '632224' },
    { p: '青海省', c: '黄南藏族自治州', d: '黄南藏族自治州', r: '632300' },
    { p: '青海省', c: '黄南藏族自治州', d: '同仁市', r: '632301' },
    { p: '青海省', c: '黄南藏族自治州', d: '尖扎县', r: '632322' },
    { p: '青海省', c: '黄南藏族自治州', d: '泽库县', r: '632323' },
    { p: '青海省', c: '黄南藏族自治州', d: '河南蒙古族自治县', r: '632324' },
    { p: '青海省', c: '海南藏族自治州', d: '海南藏族自治州', r: '632500' },
    { p: '青海省', c: '海南藏族自治州', d: '共和县', r: '632521' },
    { p: '青海省', c: '海南藏族自治州', d: '同德县', r: '632522' },
    { p: '青海省', c: '海南藏族自治州', d: '贵德县', r: '632523' },
    { p: '青海省', c: '海南藏族自治州', d: '兴海县', r: '632524' },
    { p: '青海省', c: '海南藏族自治州', d: '贵南县', r: '632525' },
    { p: '青海省', c: '果洛藏族自治州', d: '果洛藏族自治州', r: '632600' },
    { p: '青海省', c: '果洛藏族自治州', d: '玛沁县', r: '632621' },
    { p: '青海省', c: '果洛藏族自治州', d: '班玛县', r: '632622' },
    { p: '青海省', c: '果洛藏族自治州', d: '甘德县', r: '632623' },
    { p: '青海省', c: '果洛藏族自治州', d: '达日县', r: '632624' },
    { p: '青海省', c: '果洛藏族自治州', d: '久治县', r: '632625' },
    { p: '青海省', c: '果洛藏族自治州', d: '玛多县', r: '632626' },
    { p: '青海省', c: '玉树藏族自治州', d: '玉树藏族自治州', r: '632700' },
    { p: '青海省', c: '玉树藏族自治州', d: '玉树市', r: '632701' },
    { p: '青海省', c: '玉树藏族自治州', d: '杂多县', r: '632722' },
    { p: '青海省', c: '玉树藏族自治州', d: '称多县', r: '632723' },
    { p: '青海省', c: '玉树藏族自治州', d: '治多县', r: '632724' },
    { p: '青海省', c: '玉树藏族自治州', d: '囊谦县', r: '632725' },
    { p: '青海省', c: '玉树藏族自治州', d: '曲麻莱县', r: '632726' },
    { p: '青海省', c: '海西蒙古族藏族自治州', d: '海西蒙古族藏族自治州', r: '632800' },
    { p: '青海省', c: '海西蒙古族藏族自治州', d: '格尔木市', r: '632801' },
    { p: '青海省', c: '海西蒙古族藏族自治州', d: '德令哈市', r: '632802' },
    { p: '青海省', c: '海西蒙古族藏族自治州', d: '茫崖市', r: '632803' },
    { p: '青海省', c: '海西蒙古族藏族自治州', d: '乌兰县', r: '632821' },
    { p: '青海省', c: '海西蒙古族藏族自治州', d: '都兰县', r: '632822' },
    { p: '青海省', c: '海西蒙古族藏族自治州', d: '天峻县', r: '632823' },
    { p: '宁夏回族自治区', c: '宁夏回族自治区', d: '宁夏回族自治区', r: '640000' },
    { p: '宁夏回族自治区', c: '银川市', d: '银川市', r: '640100' },
    { p: '宁夏回族自治区', c: '银川市', d: '兴庆区', r: '640104' },
    { p: '宁夏回族自治区', c: '银川市', d: '西夏区', r: '640105' },
    { p: '宁夏回族自治区', c: '银川市', d: '金凤区', r: '640106' },
    { p: '宁夏回族自治区', c: '银川市', d: '永宁县', r: '640121' },
    { p: '宁夏回族自治区', c: '银川市', d: '贺兰县', r: '640122' },
    { p: '宁夏回族自治区', c: '银川市', d: '灵武市', r: '640181' },
    { p: '宁夏回族自治区', c: '石嘴山市', d: '石嘴山市', r: '640200' },
    { p: '宁夏回族自治区', c: '石嘴山市', d: '大武口区', r: '640202' },
    { p: '宁夏回族自治区', c: '石嘴山市', d: '惠农区', r: '640205' },
    { p: '宁夏回族自治区', c: '石嘴山市', d: '平罗县', r: '640221' },
    { p: '宁夏回族自治区', c: '吴忠市', d: '吴忠市', r: '640300' },
    { p: '宁夏回族自治区', c: '吴忠市', d: '利通区', r: '640302' },
    { p: '宁夏回族自治区', c: '吴忠市', d: '红寺堡区', r: '640303' },
    { p: '宁夏回族自治区', c: '吴忠市', d: '盐池县', r: '640323' },
    { p: '宁夏回族自治区', c: '吴忠市', d: '同心县', r: '640324' },
    { p: '宁夏回族自治区', c: '吴忠市', d: '青铜峡市', r: '640381' },
    { p: '宁夏回族自治区', c: '固原市', d: '固原市', r: '640400' },
    { p: '宁夏回族自治区', c: '固原市', d: '原州区', r: '640402' },
    { p: '宁夏回族自治区', c: '固原市', d: '西吉县', r: '640422' },
    { p: '宁夏回族自治区', c: '固原市', d: '隆德县', r: '640423' },
    { p: '宁夏回族自治区', c: '固原市', d: '泾源县', r: '640424' },
    { p: '宁夏回族自治区', c: '固原市', d: '彭阳县', r: '640425' },
    { p: '宁夏回族自治区', c: '中卫市', d: '中卫市', r: '640500' },
    { p: '宁夏回族自治区', c: '中卫市', d: '沙坡头区', r: '640502' },
    { p: '宁夏回族自治区', c: '中卫市', d: '中宁县', r: '640521' },
    { p: '宁夏回族自治区', c: '中卫市', d: '海原县', r: '640522' },
    { p: '新疆维吾尔自治区', c: '新疆维吾尔自治区', d: '新疆维吾尔自治区', r: '650000' },
    { p: '新疆维吾尔自治区', c: '乌鲁木齐市', d: '乌鲁木齐市', r: '650100' },
    { p: '新疆维吾尔自治区', c: '乌鲁木齐市', d: '天山区', r: '650102' },
    { p: '新疆维吾尔自治区', c: '乌鲁木齐市', d: '沙依巴克区', r: '650103' },
    { p: '新疆维吾尔自治区', c: '乌鲁木齐市', d: '新市区', r: '650104' },
    { p: '新疆维吾尔自治区', c: '乌鲁木齐市', d: '水磨沟区', r: '650105' },
    { p: '新疆维吾尔自治区', c: '乌鲁木齐市', d: '头屯河区', r: '650106' },
    { p: '新疆维吾尔自治区', c: '乌鲁木齐市', d: '达坂城区', r: '650107' },
    { p: '新疆维吾尔自治区', c: '乌鲁木齐市', d: '米东区', r: '650109' },
    { p: '新疆维吾尔自治区', c: '乌鲁木齐市', d: '乌鲁木齐县', r: '650121' },
    { p: '新疆维吾尔自治区', c: '克拉玛依市', d: '克拉玛依市', r: '650200' },
    { p: '新疆维吾尔自治区', c: '克拉玛依市', d: '独山子区', r: '650202' },
    { p: '新疆维吾尔自治区', c: '克拉玛依市', d: '克拉玛依区', r: '650203' },
    { p: '新疆维吾尔自治区', c: '克拉玛依市', d: '白碱滩区', r: '650204' },
    { p: '新疆维吾尔自治区', c: '克拉玛依市', d: '乌尔禾区', r: '650205' },
    { p: '新疆维吾尔自治区', c: '吐鲁番市', d: '吐鲁番市', r: '650400' },
    { p: '新疆维吾尔自治区', c: '吐鲁番市', d: '高昌区', r: '650402' },
    { p: '新疆维吾尔自治区', c: '吐鲁番市', d: '鄯善县', r: '650421' },
    { p: '新疆维吾尔自治区', c: '吐鲁番市', d: '托克逊县', r: '650422' },
    { p: '新疆维吾尔自治区', c: '哈密市', d: '哈密市', r: '650500' },
    { p: '新疆维吾尔自治区', c: '哈密市', d: '伊州区', r: '650502' },
    { p: '新疆维吾尔自治区', c: '哈密市', d: '巴里坤哈萨克自治县', r: '650521' },
    { p: '新疆维吾尔自治区', c: '哈密市', d: '伊吾县', r: '650522' },
    { p: '新疆维吾尔自治区', c: '昌吉回族自治州', d: '昌吉回族自治州', r: '652300' },
    { p: '新疆维吾尔自治区', c: '昌吉回族自治州', d: '昌吉市', r: '652301' },
    { p: '新疆维吾尔自治区', c: '昌吉回族自治州', d: '阜康市', r: '652302' },
    { p: '新疆维吾尔自治区', c: '昌吉回族自治州', d: '呼图壁县', r: '652323' },
    { p: '新疆维吾尔自治区', c: '昌吉回族自治州', d: '玛纳斯县', r: '652324' },
    { p: '新疆维吾尔自治区', c: '昌吉回族自治州', d: '奇台县', r: '652325' },
    { p: '新疆维吾尔自治区', c: '昌吉回族自治州', d: '吉木萨尔县', r: '652327' },
    { p: '新疆维吾尔自治区', c: '昌吉回族自治州', d: '木垒哈萨克自治县', r: '652328' },
    { p: '新疆维吾尔自治区', c: '博尔塔拉蒙古自治州', d: '博尔塔拉蒙古自治州', r: '652700' },
    { p: '新疆维吾尔自治区', c: '博尔塔拉蒙古自治州', d: '博乐市', r: '652701' },
    { p: '新疆维吾尔自治区', c: '博尔塔拉蒙古自治州', d: '阿拉山口市', r: '652702' },
    { p: '新疆维吾尔自治区', c: '博尔塔拉蒙古自治州', d: '精河县', r: '652722' },
    { p: '新疆维吾尔自治区', c: '博尔塔拉蒙古自治州', d: '温泉县', r: '652723' },
    { p: '新疆维吾尔自治区', c: '巴音郭楞蒙古自治州', d: '巴音郭楞蒙古自治州', r: '652800' },
    { p: '新疆维吾尔自治区', c: '巴音郭楞蒙古自治州', d: '库尔勒市', r: '652801' },
    { p: '新疆维吾尔自治区', c: '巴音郭楞蒙古自治州', d: '轮台县', r: '652822' },
    { p: '新疆维吾尔自治区', c: '巴音郭楞蒙古自治州', d: '尉犁县', r: '652823' },
    { p: '新疆维吾尔自治区', c: '巴音郭楞蒙古自治州', d: '若羌县', r: '652824' },
    { p: '新疆维吾尔自治区', c: '巴音郭楞蒙古自治州', d: '且末县', r: '652825' },
    { p: '新疆维吾尔自治区', c: '巴音郭楞蒙古自治州', d: '焉耆回族自治县', r: '652826' },
    { p: '新疆维吾尔自治区', c: '巴音郭楞蒙古自治州', d: '和静县', r: '652827' },
    { p: '新疆维吾尔自治区', c: '巴音郭楞蒙古自治州', d: '和硕县', r: '652828' },
    { p: '新疆维吾尔自治区', c: '巴音郭楞蒙古自治州', d: '博湖县', r: '652829' },
    { p: '新疆维吾尔自治区', c: '阿克苏地区', d: '阿克苏地区', r: '652900' },
    { p: '新疆维吾尔自治区', c: '阿克苏地区', d: '阿克苏市', r: '652901' },
    { p: '新疆维吾尔自治区', c: '阿克苏地区', d: '库车市', r: '652902' },
    { p: '新疆维吾尔自治区', c: '阿克苏地区', d: '温宿县', r: '652922' },
    { p: '新疆维吾尔自治区', c: '阿克苏地区', d: '沙雅县', r: '652924' },
    { p: '新疆维吾尔自治区', c: '阿克苏地区', d: '新和县', r: '652925' },
    { p: '新疆维吾尔自治区', c: '阿克苏地区', d: '拜城县', r: '652926' },
    { p: '新疆维吾尔自治区', c: '阿克苏地区', d: '乌什县', r: '652927' },
    { p: '新疆维吾尔自治区', c: '阿克苏地区', d: '阿瓦提县', r: '652928' },
    { p: '新疆维吾尔自治区', c: '阿克苏地区', d: '柯坪县', r: '652929' },
    { p: '新疆维吾尔自治区', c: '克孜勒苏柯尔克孜自治州', d: '克孜勒苏柯尔克孜自治州', r: '653000' },
    { p: '新疆维吾尔自治区', c: '克孜勒苏柯尔克孜自治州', d: '阿图什市', r: '653001' },
    { p: '新疆维吾尔自治区', c: '克孜勒苏柯尔克孜自治州', d: '阿克陶县', r: '653022' },
    { p: '新疆维吾尔自治区', c: '克孜勒苏柯尔克孜自治州', d: '阿合奇县', r: '653023' },
    { p: '新疆维吾尔自治区', c: '克孜勒苏柯尔克孜自治州', d: '乌恰县', r: '653024' },
    { p: '新疆维吾尔自治区', c: '喀什地区', d: '喀什地区', r: '653100' },
    { p: '新疆维吾尔自治区', c: '喀什地区', d: '喀什市', r: '653101' },
    { p: '新疆维吾尔自治区', c: '喀什地区', d: '疏附县', r: '653121' },
    { p: '新疆维吾尔自治区', c: '喀什地区', d: '疏勒县', r: '653122' },
    { p: '新疆维吾尔自治区', c: '喀什地区', d: '英吉沙县', r: '653123' },
    { p: '新疆维吾尔自治区', c: '喀什地区', d: '泽普县', r: '653124' },
    { p: '新疆维吾尔自治区', c: '喀什地区', d: '莎车县', r: '653125' },
    { p: '新疆维吾尔自治区', c: '喀什地区', d: '叶城县', r: '653126' },
    { p: '新疆维吾尔自治区', c: '喀什地区', d: '麦盖提县', r: '653127' },
    { p: '新疆维吾尔自治区', c: '喀什地区', d: '岳普湖县', r: '653128' },
    { p: '新疆维吾尔自治区', c: '喀什地区', d: '伽师县', r: '653129' },
    { p: '新疆维吾尔自治区', c: '喀什地区', d: '巴楚县', r: '653130' },
    { p: '新疆维吾尔自治区', c: '喀什地区', d: '塔什库尔干塔吉克自治县', r: '653131' },
    { p: '新疆维吾尔自治区', c: '和田地区', d: '和田地区', r: '653200' },
    { p: '新疆维吾尔自治区', c: '和田地区', d: '和田市', r: '653201' },
    { p: '新疆维吾尔自治区', c: '和田地区', d: '和田县', r: '653221' },
    { p: '新疆维吾尔自治区', c: '和田地区', d: '墨玉县', r: '653222' },
    { p: '新疆维吾尔自治区', c: '和田地区', d: '皮山县', r: '653223' },
    { p: '新疆维吾尔自治区', c: '和田地区', d: '洛浦县', r: '653224' },
    { p: '新疆维吾尔自治区', c: '和田地区', d: '策勒县', r: '653225' },
    { p: '新疆维吾尔自治区', c: '和田地区', d: '于田县', r: '653226' },
    { p: '新疆维吾尔自治区', c: '和田地区', d: '民丰县', r: '653227' },
    { p: '新疆维吾尔自治区', c: '伊犁哈萨克自治州', d: '伊犁哈萨克自治州', r: '654000' },
    { p: '新疆维吾尔自治区', c: '伊犁哈萨克自治州', d: '伊宁市', r: '654002' },
    { p: '新疆维吾尔自治区', c: '伊犁哈萨克自治州', d: '奎屯市', r: '654003' },
    { p: '新疆维吾尔自治区', c: '伊犁哈萨克自治州', d: '霍尔果斯市', r: '654004' },
    { p: '新疆维吾尔自治区', c: '伊犁哈萨克自治州', d: '伊宁县', r: '654021' },
    { p: '新疆维吾尔自治区', c: '伊犁哈萨克自治州', d: '察布查尔锡伯自治县', r: '654022' },
    { p: '新疆维吾尔自治区', c: '伊犁哈萨克自治州', d: '霍城县', r: '654023' },
    { p: '新疆维吾尔自治区', c: '伊犁哈萨克自治州', d: '巩留县', r: '654024' },
    { p: '新疆维吾尔自治区', c: '伊犁哈萨克自治州', d: '新源县', r: '654025' },
    { p: '新疆维吾尔自治区', c: '伊犁哈萨克自治州', d: '昭苏县', r: '654026' },
    { p: '新疆维吾尔自治区', c: '伊犁哈萨克自治州', d: '特克斯县', r: '654027' },
    { p: '新疆维吾尔自治区', c: '伊犁哈萨克自治州', d: '尼勒克县', r: '654028' },
    { p: '新疆维吾尔自治区', c: '塔城地区', d: '塔城地区', r: '654200' },
    { p: '新疆维吾尔自治区', c: '塔城地区', d: '塔城市', r: '654201' },
    { p: '新疆维吾尔自治区', c: '塔城地区', d: '乌苏市', r: '654202' },
    { p: '新疆维吾尔自治区', c: '塔城地区', d: '沙湾市', r: '654203' },
    { p: '新疆维吾尔自治区', c: '塔城地区', d: '额敏县', r: '654221' },
    { p: '新疆维吾尔自治区', c: '塔城地区', d: '托里县', r: '654224' },
    { p: '新疆维吾尔自治区', c: '塔城地区', d: '裕民县', r: '654225' },
    { p: '新疆维吾尔自治区', c: '塔城地区', d: '和布克赛尔蒙古自治县', r: '654226' },
    { p: '新疆维吾尔自治区', c: '阿勒泰地区', d: '阿勒泰地区', r: '654300' },
    { p: '新疆维吾尔自治区', c: '阿勒泰地区', d: '阿勒泰市', r: '654301' },
    { p: '新疆维吾尔自治区', c: '阿勒泰地区', d: '布尔津县', r: '654321' },
    { p: '新疆维吾尔自治区', c: '阿勒泰地区', d: '富蕴县', r: '654322' },
    { p: '新疆维吾尔自治区', c: '阿勒泰地区', d: '福海县', r: '654323' },
    { p: '新疆维吾尔自治区', c: '阿勒泰地区', d: '哈巴河县', r: '654324' },
    { p: '新疆维吾尔自治区', c: '阿勒泰地区', d: '青河县', r: '654325' },
    { p: '新疆维吾尔自治区', c: '阿勒泰地区', d: '吉木乃县', r: '654326' },
    { p: '新疆维吾尔自治区', c: '', d: '石河子市', r: '659001' },
    { p: '新疆维吾尔自治区', c: '', d: '阿拉尔市', r: '659002' },
    { p: '新疆维吾尔自治区', c: '', d: '图木舒克市', r: '659003' },
    { p: '新疆维吾尔自治区', c: '', d: '五家渠市', r: '659004' },
    { p: '新疆维吾尔自治区', c: '', d: '北屯市', r: '659005' },
    { p: '新疆维吾尔自治区', c: '', d: '铁门关市', r: '659006' },
    { p: '新疆维吾尔自治区', c: '', d: '双河市', r: '659007' },
    { p: '新疆维吾尔自治区', c: '', d: '可克达拉市', r: '659008' },
    { p: '新疆维吾尔自治区', c: '', d: '昆玉市', r: '659009' },
    { p: '新疆维吾尔自治区', c: '', d: '胡杨河市', r: '659010' },
    { p: '新疆维吾尔自治区', c: '', d: '新星市', r: '659011' },
    { p: '台湾省', c: '', d: '台湾省', r: '710000' },
    { p: '香港特别行政区', c: '', d: '香港特别行政区', r: '810000' },
    { p: '澳门特别行政区', c: '', d: '澳门特别行政区', r: '820000' },
  ],
  job_raw: [
    {
      c: '10000',
      l: '党的机关、国家机关、群众团体和社会组织、企事业单位负责人',
    },
    { c: '20000', l: '专业技术人员' },
    { c: '30000', l: '办事人员和有关人员' },
    { c: '40000', l: '社会生产服务和生活服务人员' },
    { c: '50000', l: '农、林、牧、渔业生产及辅助人员' },
    { c: '60000', l: '生产制造及有关人员' },
    { c: '80000', l: '不便分类的其他从业人员' },
    { c: '10100', l: '中国共产党机关负责人' },
    { c: '10200', l: '国家机关负责人' },
    { c: '10300', l: '民主党派和工商联负责人' },
    { c: '10400', l: '人民团体和群众团体、社会组织及其他成员组织负责人' },
    { c: '10500', l: '基层群众自治组织负责人' },
    { c: '10600', l: '企事业单位负责人' },
    { c: '20100', l: '科学研究人员' },
    { c: '20200', l: '工程技术人员' },
    { c: '20300', l: '农业技术人员' },
    { c: '20400', l: '飞机和船舶技术人员' },
    { c: '20500', l: '卫生专业技术人员' },
    { c: '20600', l: '经济和金融专业人员' },
    { c: '20700', l: '法律、社会和宗教专业人员' },
    { c: '20800', l: '教学人员' },
    { c: '20900', l: '文学艺术、体育专业人员' },
    { c: '21000', l: '新闻出版、文化专业人员' },
    { c: '29900', l: '其他专业技术人员' },
    { c: '30100', l: '办事人员' },
    { c: '30200', l: '安全和消防人员' },
    { c: '39900', l: '其他办事人员和有关人员' },
    { c: '40100', l: '批发与零售服务人员' },
    { c: '40200', l: '交通运输、仓储和邮政业服务人员' },
    { c: '40300', l: '住宿和餐饮服务人员' },
    { c: '40400', l: '信息运输、软件和信息技术服务人员' },
    { c: '40500', l: '金融服务人员' },
    { c: '40600', l: '房地产服务人员' },
    { c: '40700', l: '租赁和商务服务人员' },
    { c: '40800', l: '技术辅助服务人员' },
    { c: '40900', l: '水利、环境和公共设施管理服务人员' },
    { c: '41000', l: '居民服务人员' },
    { c: '41100', l: '电力、燃气及水供应服务人员' },
    { c: '41200', l: '修理及制作服务人员' },
    { c: '41300', l: '文化、体育及娱乐服务人员' },
    { c: '41400', l: '健康服务人员' },
    { c: '49900', l: '其他社会生产和生活服务人员' },
    { c: '50100', l: '农业生产人员' },
    { c: '50200', l: '林业生产人员' },
    { c: '50300', l: '畜牧业生产人员' },
    { c: '50400', l: '渔业生产人员' },
    { c: '50500', l: '农林牧渔生产辅助人员' },
    { c: '59900', l: '其他农、林、牧、渔、水利业生产人员' },
    { c: '60100', l: '农副产品加工人员' },
    { c: '60200', l: '食品、饮料生产加工人员' },
    { c: '60300', l: '烟草及其制品加工人员' },
    { c: '60400', l: '纺织、针织、印染人员' },
    { c: '60500', l: '纺织品、服装和皮革、毛皮制品加工制作人员' },
    { c: '60600', l: '木材加工、家具与木制品制作人员' },
    { c: '60700', l: '纸及纸制品生产加工人员' },
    { c: '60800', l: '印刷和记录媒介复制人员' },
    { c: '60900', l: '文教、工美、体育和娱乐用品制作人员' },
    { c: '61000', l: '石油加工和炼焦、煤化工制作人员' },
    { c: '61100', l: '化学原料和化学制品制造人员' },
    { c: '61200', l: '医药制造人员' },
    { c: '61300', l: '化学纤维制造人员' },
    { c: '61400', l: '橡胶和塑料制品制造人员' },
    { c: '61500', l: '非金属矿物制品制造人员' },
    { c: '61600', l: '采矿人员' },
    { c: '61700', l: '金属冶炼和压延加工人员' },
    { c: '61800', l: '机械制造基础加工人员' },
    { c: '61900', l: '金属制品制造人员' },
    { c: '62000', l: '通用设备制造人员' },
    { c: '62100', l: '专用设备制造人员' },
    { c: '62200', l: '汽车制造人员' },
    { c: '62300', l: '铁路、船舶、航空设备制造人员' },
    { c: '62400', l: '电气机械和器材制造人员' },
    { c: '62500', l: '计算机、通信和其他电子设备制造人员' },
    { c: '62600', l: '仪器仪表制造人员' },
    { c: '62700', l: '废弃资源综合利用人员' },
    { c: '62800', l: '电力、热力、气体、水生产和输配人员' },
    { c: '62900', l: '建筑施工人员' },
    { c: '63000', l: '运输设备和通用工程机械操作人员及有关人员' },
    { c: '63100', l: '生产辅助人员' },
    { c: '69900', l: '其他生产制造及有关人员' },
    { c: '80100', l: '不便分类的其他从业人员' },
    { c: '10101', l: '中国共产党机关负责人' },
    { c: '10201', l: '国家权力机关负责人' },
    { c: '10202', l: '国家行政机关负责人' },
    { c: '10203', l: '人民政协机关负责人' },
    { c: '10204', l: '人民法院和人民检察院负责人' },
    { c: '10301', l: '民主党派和工商联负责人' },
    { c: '10401', l: '人民团体和群众团体负责人' },
    { c: '10402', l: '社会团体负责人' },
    { c: '10403', l: '民办非企业单位负责人' },
    { c: '10404', l: '社会中介组织负责人' },
    { c: '10405', l: '基金会负责人' },
    { c: '10406', l: '宗教组织负责人' },
    { c: '10501', l: '基层群众自治组织负责人' },
    { c: '10601', l: '企业负责人' },
    { c: '10602', l: '事业单位负责人' },
    { c: '20101', l: '哲学研究人员' },
    { c: '20102', l: '经济学研究人员' },
    { c: '20103', l: '法学研究人员' },
    { c: '20104', l: '教育学研究人员' },
    { c: '20105', l: '历史学研究人员' },
    { c: '20107', l: '农学研究人员' },
    { c: '20108', l: '医学研究人员' },
    { c: '20109', l: '管理学研究人员' },
    { c: '20111', l: '军事学研究人员' },
    { c: '20112', l: '文学研究人员' },
    { c: '20113', l: '理学研究人员' },
    { c: '20114', l: '工学研究人员' },
    { c: '20115', l: '艺术学科研究人员' },
    { c: '20199', l: '其他科学研究人员' },
    { c: '20201', l: '地质勘探工程技术人员' },
    { c: '20202', l: '测绘和地理信息工程技术人员' },
    { c: '20203', l: '矿山工程技术人员' },
    { c: '20204', l: '石油天然气工程技术人员' },
    { c: '20205', l: '冶金工程技术人员' },
    { c: '20206', l: '化工工程技术人员' },
    { c: '20207', l: '机械工程技术人员' },
    { c: '20208', l: '航空工程技术人员' },
    { c: '20209', l: '电子工程技术人员' },
    { c: '20210', l: '信息和通信工程技术人员' },
    { c: '20211', l: '电气工程技术人员' },
    { c: '20212', l: '电力工程技术人员' },
    { c: '20213', l: '邮政和快递工程技术人员' },
    { c: '20214', l: '广播电影电视及演艺设备工程技术人员' },
    { c: '20215', l: '交通和水上运输工程技术人员' },
    { c: '20216', l: '民用航空工程技术人员' },
    { c: '20217', l: '铁道工程技术人员' },
    { c: '20218', l: '建筑工程技术人员' },
    { c: '20219', l: '建材工程技术人员' },
    { c: '20220', l: '林业工程技术人员' },
    { c: '20221', l: '水利工程技术人员' },
    { c: '20222', l: '海洋工程技术人员' },
    { c: '20223', l: '纺织服装工程技术人员' },
    { c: '20224', l: '食品工程技术人员' },
    { c: '20225', l: '气象工程技术人员' },
    { c: '20226', l: '地震工程技术人员' },
    { c: '20227', l: '环境保护工程技术人员' },
    { c: '20228', l: '安全工程技术人员' },
    { c: '20229', l: '标准化、计量、质量和认证认可工程技术人员' },
    { c: '20230', l: '管理（工业）工程技术人员' },
    { c: '20231', l: '检验检疫工程技术人员' },
    { c: '20232', l: '制药工程技术人员' },
    { c: '20233', l: '印刷复制工程技术人员' },
    { c: '20234', l: '工业（产品）设计工程技术人员' },
    { c: '20235', l: '康复辅具工程技术人员' },
    { c: '20236', l: '轻工工程技术人员' },
    { c: '20237', l: '土地整治工程技术人员' },
    { c: '20290', l: '兵器工程技术人员' },
    { c: '20291', l: '航天工程技术人员' },
    { c: '20299', l: '其他工程技术人员' },
    { c: '20301', l: '土壤肥料技术人员' },
    { c: '20302', l: '农业技术指导人员' },
    { c: '20303', l: '植物保护技术人员' },
    { c: '20304', l: '园艺技术人员' },
    { c: '20305', l: '作物遗传育种栽培技术人员' },
    { c: '20306', l: '兽医兽药技术人员' },
    { c: '20307', l: '畜牧和草业技术人员' },
    { c: '20308', l: '水产技术人员' },
    { c: '20309', l: '农业工程技术人员' },
    { c: '20399', l: '其他农业技术人员' },
    { c: '20401', l: '飞行人员和领航人员' },
    { c: '20402', l: '船舶指挥和引航人员' },
    { c: '20499', l: '其他飞机和船舶技术人员' },
    { c: '20501', l: '西医医师' },
    { c: '20502', l: '中医医师' },
    { c: '20503', l: '中西医结合医师' },
    { c: '20504', l: '民族医医师' },
    { c: '20505', l: '公共卫生与健康医师' },
    { c: '20506', l: '药学技术人员' },
    { c: '20507', l: '医疗卫生技术人员' },
    { c: '20508', l: '护理人员' },
    { c: '20509', l: '乡村医生' },
    { c: '20599', l: '其他卫生专业技术人员' },
    { c: '20601', l: '经济专业人员' },
    { c: '20602', l: '统计专业人员' },
    { c: '20603', l: '会计专业人员' },
    { c: '20604', l: '审计专业人员' },
    { c: '20605', l: '税务专业人员' },
    { c: '20606', l: '评估专业人员' },
    { c: '20607', l: '商务专业人员' },
    { c: '20608', l: '人力资源专业人员' },
    { c: '20609', l: '银行专业人员' },
    { c: '20610', l: '保险专业人员' },
    { c: '20611', l: '证券专业人员' },
    { c: '20612', l: '知识产权专业人员' },
    { c: '20699', l: '其他经济和金融专业人员' },
    { c: '20701', l: '法官' },
    { c: '20702', l: '检察官' },
    { c: '20703', l: '律师' },
    { c: '20704', l: '公证员' },
    { c: '20705', l: '司法鉴定人员' },
    { c: '20706', l: '审判辅助人员' },
    { c: '20707', l: '法律顾问' },
    { c: '20708', l: '宗教教职人员' },
    { c: '20709', l: '社会工作专业人员' },
    { c: '20799', l: '其他法律、社会和宗教专业人员' },
    { c: '20801', l: '高等教育教师' },
    { c: '20802', l: '中等职业教育教师' },
    { c: '20803', l: '中小学教育教师' },
    { c: '20804', l: '幼儿教师' },
    { c: '20805', l: '特殊教育教师' },
    { c: '20899', l: '其他教学人员' },
    { c: '20901', l: '文艺创作与编导人员' },
    { c: '20902', l: '音乐指挥与演员' },
    { c: '20903', l: '电影电视制作专业人员' },
    { c: '20904', l: '舞台专业人员' },
    { c: '20905', l: '美术专业人员' },
    { c: '20906', l: '工艺美术与创意设计专业人员' },
    { c: '20907', l: '体育专业人员' },
    { c: '20999', l: '其他文学艺术、体育专业人员' },
    { c: '21001', l: '记者' },
    { c: '21002', l: '编辑' },
    { c: '21003', l: '校对员' },
    { c: '21004', l: '播音员及节目主持人' },
    { c: '21005', l: '翻译人员' },
    { c: '21006', l: '图书资料与微缩摄影专业人员' },
    { c: '21007', l: '档案专业人员' },
    { c: '21008', l: '考古及文物保护专业人员' },
    { c: '21099', l: '其他新闻出版式、文化工作人员' },
    { c: '29901', l: '其他专业技术人员' },
    { c: '30101', l: '行政业务办理人员' },
    { c: '30102', l: '行政事务处理人员' },
    { c: '30103', l: '行政执法和仲裁人员' },
    { c: '30199', l: '其他办事人员' },
    { c: '30201', l: '人民警察' },
    { c: '30202', l: '保卫人员' },
    { c: '30203', l: '消防和应急救援人员' },
    { c: '30299', l: '其他安全和消防人员' },
    { c: '39901', l: '其他办事人员和有关人员' },
    { c: '40101', l: '采购人员' },
    { c: '40102', l: '销售人员' },
    { c: '40103', l: '贸易经济代理人员' },
    { c: '40104', l: '再生物资回收人员' },
    { c: '40105', l: '特殊商品购销人员' },
    { c: '40199', l: '其他批发与零售服务人员' },
    { c: '40201', l: '轨道交通运输服务人员' },
    { c: '40202', l: '道路运输服务人员' },
    { c: '40203', l: '水上运输服务人员' },
    { c: '40204', l: '航空运输服务人员' },
    { c: '40205', l: '装卸搬运和运输代理服务人员' },
    { c: '40206', l: '仓储人员' },
    { c: '40207', l: '邮政和快递服务人员' },
    { c: '40299', l: '其他交通运输、仓储和邮政业服务人员' },
    { c: '40301', l: '住宿服务人员' },
    { c: '40302', l: '餐饮服务人员' },
    { c: '40399', l: '其他住宿和餐饮服务人员' },
    { c: '40401', l: '信息通信业务人员' },
    { c: '40402', l: '信息通信网络维护人员' },
    { c: '40403', l: '广播电视传输服务人员' },
    { c: '40404', l: '信息通信网络运行管理人员' },
    { c: '40405', l: '软件和信息技术服务人员' },
    { c: '40499', l: '其他信息运输、软件和信息技术服务人员' },
    { c: '40501', l: '银行服务人员' },
    { c: '40502', l: '证券服务人员' },
    { c: '40503', l: '期货服务人员' },
    { c: '40504', l: '保险服务人员' },
    { c: '40505', l: '典当服务人员' },
    { c: '40506', l: '信托服务人员' },
    { c: '40599', l: '其他金融服务人员' },
    { c: '40601', l: '物业管理服务人员' },
    { c: '40602', l: '房地产中介服务人员' },
    { c: '40609', l: '其他房地产服务人员' },
    { c: '40701', l: '租赁业务人员' },
    { c: '40702', l: '商务资讯服务人员' },
    { c: '40703', l: '人力资源服务人员' },
    { c: '40704', l: '旅游及公共游览场所服务人员' },
    { c: '40705', l: '安全保护服务人员' },
    { c: '40706', l: '市场管理服务人员' },
    { c: '40707', l: '会议及展览服务人员' },
    { c: '40799', l: '其他租赁和商务服务人员' },
    { c: '40801', l: '气象服务人员' },
    { c: '40802', l: '海洋服务人员' },
    { c: '40803', l: '测绘服务人员' },
    { c: '40804', l: '地理信息服务人员' },
    { c: '40805', l: '检验，检测和计量服务人员' },
    { c: '40806', l: '环境检测服务人员' },
    { c: '40807', l: '地质勘查人员' },
    { c: '40808', l: '专业化设计服务人员' },
    { c: '40809', l: '摄影扩印服务人员' },
    { c: '40899', l: '其他技术辅助服务人员' },
    { c: '40901', l: '水利设施管养人员' },
    { c: '40902', l: '水文服务人员' },
    { c: '40903', l: '水土保持人员' },
    { c: '40904', l: '农田灌排人员' },
    { c: '40905', l: '自然保护区和草地监护人员' },
    { c: '40906', l: '野生动植物保护人员' },
    { c: '40907', l: '环境治理服务人员' },
    { c: '40908', l: '环境卫生服务人员' },
    { c: '40909', l: '有害生物防制人员' },
    { c: '40910', l: '绿化与园艺服务人员' },
    { c: '40999', l: '其他水利、环境和公共设施管理服务人员' },
    { c: '41001', l: '生活照料服务人员' },
    { c: '41002', l: '服装裁剪和洗染织补人员' },
    { c: '41003', l: '美容美发和浴池服务人员' },
    { c: '41004', l: '保健服务人员' },
    { c: '41005', l: '婚姻服务人员' },
    { c: '41006', l: '殡葬服务人员' },
    { c: '41007', l: '宠物服务人员' },
    { c: '41099', l: '其他居民服务人员' },
    { c: '41101', l: '电力供应服务人员' },
    { c: '41102', l: '燃气供应服务人员' },
    { c: '41103', l: '水供应服务人员' },
    { c: '41199', l: '其他电力、燃气及水供应服务人员' },
    { c: '41201', l: '汽车摩托车修理技术服务人员' },
    { c: '41202', l: '计算机和办公设备维修人员' },
    { c: '41203', l: '家用电子电器产品维修人员' },
    { c: '41204', l: '日用产品修理服务人员' },
    { c: '41205', l: '乐器维修人员' },
    { c: '41206', l: '印章制作人员' },
    { c: '41299', l: '其他修理及制作服务人员' },
    { c: '41301', l: '群众文化活动服务人员' },
    { c: '41302', l: '广播、电视、电影和影视录音制作人员' },
    { c: '41303', l: '文物保护作业人员' },
    { c: '41304', l: '健身和娱乐场所服务人员' },
    { c: '41305', l: '文化、娱乐、体育经纪代理人员' },
    { c: '41399', l: '其他文化、体育及娱乐服务人员' },
    { c: '41401', l: '医疗辅助服务人员' },
    { c: '41402', l: '健康资讯服务人员' },
    { c: '41403', l: '康复矫正服务人员' },
    { c: '41404', l: '公共卫生辅助服务人员' },
    { c: '41499', l: '其他健康服务人员' },
    { c: '49901', l: '其他社会生产和生活服务人员' },
    { c: '50101', l: '作物种子（苗）繁育生产人员' },
    { c: '50102', l: '农作物生产人员' },
    { c: '50199', l: '其他农业生产人员' },
    { c: '50201', l: '林木种苗繁育人员' },
    { c: '50202', l: '营造林人员' },
    { c: '50203', l: '森林经营和管护人员' },
    { c: '50204', l: '木材采运人员' },
    { c: '50299', l: '其他林业生产人员' },
    { c: '50301', l: '畜禽种苗繁育人员' },
    { c: '50302', l: '畜禽饲养人员' },
    { c: '50303', l: '特种经济动物饲养人员' },
    { c: '50399', l: '其他畜牧业生产人员' },
    { c: '50401', l: '水产苗种繁育人员' },
    { c: '50402', l: '水产养殖人员' },
    { c: '50403', l: '水产捕捞及有关人员' },
    { c: '50499', l: '其他渔业生产人员' },
    { c: '50501', l: '农业生产服务人员' },
    { c: '50502', l: '动植物疫病防治人员' },
    { c: '50503', l: '农村能源利用人员' },
    { c: '50504', l: '农村环境保护人员' },
    { c: '50505', l: '农机化服务人员' },
    { c: '50506', l: '农副林特产品初加工人员' },
    { c: '50599', l: '其他农林牧渔生产辅助人员' },
    { c: '59901', l: '其他农、林、牧、渔、水利业生产人员' },
    { c: '60101', l: '粮油加工人员' },
    { c: '60102', l: '饲料加工人员' },
    { c: '60103', l: '制糖人员' },
    { c: '60104', l: '畜禽制品加工人员' },
    { c: '60105', l: '水产品加工人员' },
    { c: '60106', l: '果蔬和坚果加工人员' },
    { c: '60107', l: '淀粉和豆制品加工人员' },
    { c: '60199', l: '其他农副产品加工人员' },
    { c: '60201', l: '焙烤食品制造人员' },
    { c: '60202', l: '糖制品加工人员' },
    { c: '60203', l: '方便食品和罐头食品加工人员' },
    { c: '60204', l: '乳制品加工人员' },
    { c: '60205', l: '调味品及食品添加剂制作人员' },
    { c: '60206', l: '酒、饮料及精制茶制造人员' },
    { c: '60299', l: '其他食品、饮料生产加工人员' },
    { c: '60301', l: '烟叶初加工人员' },
    { c: '60302', l: '烟用材料生产人员' },
    { c: '60303', l: '烟草制品生产人员' },
    { c: '60399', l: '其他烟草及其制品加工人员' },
    { c: '60401', l: '纤维预处理人员' },
    { c: '60402', l: '纺纱人员' },
    { c: '60403', l: '织造人员' },
    { c: '60404', l: '针织人员' },
    { c: '60405', l: '非织造布制造人员' },
    { c: '60406', l: '印染人员' },
    { c: '60499', l: '其他纺织、针织、印染人员' },
    { c: '60501', l: '纺织品和服装剪裁缝纫人员' },
    { c: '60502', l: '皮革、毛皮及其制作加工人员' },
    { c: '60503', l: '羽绒羽毛加工及制品制造人员' },
    { c: '60504', l: '鞋帽制作人员' },
    { c: '60599', l: '其他纺织品、服装和皮革、毛皮制品加工制作人员' },
    { c: '60601', l: '木材加工人员' },
    { c: '60602', l: '人造板制造人员' },
    { c: '60603', l: '木制品制造人员' },
    { c: '60604', l: '家具制造人员' },
    { c: '60699', l: '其他木材加工、家具与木制品制作人员' },
    { c: '60701', l: '制浆造纸人员' },
    { c: '60702', l: '纸制品制作人员' },
    { c: '60799', l: '其他纸及纸制品生产加工人员' },
    { c: '60801', l: '印刷人员' },
    { c: '60802', l: '记录媒介复制人员' },
    { c: '60899', l: '其他印刷和记录媒介复制人员' },
    { c: '60901', l: '文教用品制作人员' },
    { c: '60902', l: '乐器制作人员' },
    { c: '60903', l: '工艺美术品制作人员' },
    { c: '60904', l: '体育用品制作人员' },
    { c: '60905', l: '玩具制作人员' },
    { c: '60999', l: '其他文教、工美、体育和娱乐用品制作人员' },
    { c: '61001', l: '石油炼制生产人员' },
    { c: '61002', l: '炼焦人员' },
    { c: '61003', l: '煤化工生产人员' },
    { c: '61099', l: '其他石油加工和炼焦、煤化工制作人员' },
    { c: '61101', l: '化工产品生产通用工艺人员' },
    { c: '61102', l: '基础化学原料制造人员' },
    { c: '61103', l: '化学肥料生产人员' },
    { c: '61104', l: '农药生产人员' },
    { c: '61105', l: '涂料、油墨、颜料及类似产品制造人员' },
    { c: '61106', l: '合成树脂生产人员' },
    { c: '61107', l: '合成橡胶生产人员' },
    { c: '61108', l: '专用化学产品生产人员' },
    { c: '61109', l: '火工品制造、保管、爆破及焰火产品制造人员' },
    { c: '61110', l: '日用化学品生产人员' },
    { c: '61199', l: '其他化学原料和化学制品制造人员' },
    { c: '61201', l: '化学药品原料药制造人员' },
    { c: '61202', l: '中药饮片加工人员' },
    { c: '61203', l: '药物制剂人员' },
    { c: '61204', l: '兽用药制造人员' },
    { c: '61205', l: '生物药品制造人员' },
    { c: '61299', l: '其他医药制造人员' },
    { c: '61301', l: '化学纤维原料制造人员' },
    { c: '61302', l: '化学纤维纺丝及后处理人员' },
    { c: '61399', l: '其他化学纤维制造人员' },
    { c: '61401', l: '橡胶制品生产人员' },
    { c: '61402', l: '塑料制品加工人员' },
    { c: '61499', l: '其他橡胶和塑料制品制造人员' },
    { c: '61501', l: '水泥、石灰、石膏及其制品制造人员' },
    { c: '61502', l: '砖瓦石材等建筑材料制造人员' },
    { c: '61503', l: '玻璃及玻璃制品生产加工人员' },
    { c: '61504', l: '玻璃纤维及玻璃纤维增强塑料制品制造人员' },
    { c: '61505', l: '陶瓷制品制造人员' },
    { c: '61506', l: '耐火材料制品生产人员' },
    { c: '61507', l: '石墨及炭素制品生产人员' },
    { c: '61508', l: '高岭土、珍珠岩等非金属矿物加工人员' },
    { c: '61599', l: '其他非金属矿物制品制造人员' },
    { c: '61601', l: '矿物采选人员' },
    { c: '61602', l: '石油和天然气开采与储运人员' },
    { c: '61603', l: '采盐人员' },
    { c: '61699', l: '其他采矿人员' },
    { c: '61701', l: '炼铁人员' },
    { c: '61702', l: '炼钢人员' },
    { c: '61703', l: '铸铁管人员' },
    { c: '61704', l: '铁合金冶炼人员' },
    { c: '61705', l: '重有色金属冶炼人员' },
    { c: '61706', l: '轻有色金属冶炼人员' },
    { c: '61707', l: '稀贵金属冶炼人员' },
    { c: '61708', l: '半导体材料制备人员' },
    { c: '61709', l: '金属轧制人员' },
    { c: '61710', l: '硬质合金生产人员' },
    { c: '61799', l: '其他金属冶炼和压延加工人员' },
    { c: '61801', l: '机械冷加工人员' },
    { c: '61802', l: '机械热加工人员' },
    { c: '61803', l: '机械表面处理加工人员' },
    { c: '61804', l: '工装工具制造加工人员' },
    { c: '61899', l: '其他机械制造基础加工人员' },
    { c: '61901', l: '五金制品制作装配人员' },
    { c: '61999', l: '其他金属制品制造人员' },
    { c: '62001', l: '通用基础件装配制造人员' },
    { c: '62002', l: '锅炉及原动设备制造人员' },
    { c: '62003', l: '金属加工机械制造人员' },
    { c: '62004', l: '物料搬运设备制造人员' },
    { c: '62005', l: '泵、压缩机、阀门及类似机械制造人员' },
    { c: '62006', l: '烘炉、水处理、衡器等设备制造人员' },
    { c: '62007', l: '文化办公机械制造人员' },
    { c: '62099', l: '其他通用设备制造人员' },
    { c: '62101', l: '采矿、建筑专用设备制造人员' },
    { c: '62102', l: '印刷生产专用设备制造人员' },
    { c: '62103', l: '纺织服装和皮革加工专用设备制造人员' },
    { c: '62104', l: '电子专用设备装配调试人员' },
    { c: '62105', l: '农业机械制造人员' },
    { c: '62106', l: '医疗器械制品和康复辅具生产人员' },
    { c: '62199', l: '其他专用设备制造人员' },
    { c: '62101', l: '采矿、建筑专用设备制造人员' },
    { c: '62102', l: '印刷生产专用设备制造人员' },
    { c: '62103', l: '纺织服装和皮革加工专用设备制造人员' },
    { c: '62104', l: '电子专用设备装配调试人员' },
    { c: '62105', l: '农业机械制造人员' },
    { c: '62106', l: '医疗器械制品和康复辅具生产人员' },
    { c: '62199', l: '其他专用设备制造人员' },
    { c: '62201', l: '汽车零部件、饰件生产加工人员' },
    { c: '62202', l: '汽车整车制造人员' },
    { c: '62299', l: '其他汽车制造人员' },
    { c: '62301', l: '轨道交通运输设备制造人员' },
    { c: '62302', l: '船舶制造人员' },
    { c: '62303', l: '航空产品装配、调试人员' },
    { c: '62304', l: '摩托车、自行车制造人员' },
    { c: '62399', l: '其他铁路、船舶、航空设备制造人员' },
    { c: '62401', l: '电机制造人员' },
    { c: '62402', l: '输配电及控制设备制造人员' },
    { c: '62403', l: '电线电缆、光纤光缆及电工器材制造人员' },
    { c: '62404', l: '电池制造人员' },
    { c: '62405', l: '家用电器器具制造人员' },
    { c: '62406', l: '非家用电器器具制造人员' },
    { c: '62407', l: '照明器具制造人员' },
    { c: '62408', l: '电气信号设备装置制造人员' },
    { c: '62499', l: '其他电气机械和器材制造人员' },
    { c: '62501', l: '电子元件制造人员' },
    { c: '62502', l: '电子器件制造人员' },
    { c: '62503', l: '计算机制造人员' },
    { c: '62504', l: '电子设备装配调试人员' },
    { c: '62599', l: '其他计算机、通信和其他电子设备制造人员' },
    { c: '62601', l: '仪器仪表装配人员' },
    { c: '62699', l: '其他仪器仪表制造人员' },
    { c: '62701', l: '废料和碎屑加工处理人员' },
    { c: '62799', l: '其他废弃资源综合利用人员' },
    { c: '62801', l: '电力、热力生产和供应人员' },
    { c: '62802', l: '气体生产、处理和输送人员' },
    { c: '62803', l: '水生产、输排和水处理人员' },
    { c: '62899', l: '其他电力、热力、气体、水生产和输配人员' },
    { c: '62901', l: '房屋建筑施工人员' },
    { c: '62902', l: '土木工程建筑施工人员' },
    { c: '62903', l: '建筑安装施工人员' },
    { c: '62904', l: '建筑装饰人员' },
    { c: '62905', l: '古建筑修建人员' },
    { c: '62999', l: '其他建筑施工人员' },
    { c: '63001', l: '专用车辆操作人员' },
    { c: '63002', l: '轨道交通运输机械设备操作人员' },
    { c: '63003', l: '民用航空设备操作及有关人员' },
    { c: '63004', l: '水上运输设备操作及有关人员' },
    { c: '63005', l: '通用工程机械操作人员' },
    { c: '63099', l: '其他运输设备和通用工程机械操作人员及有关人员' },
    { c: '63101', l: '机械设备修理人员' },
    { c: '63102', l: '船舶、民用航空器修理人员' },
    { c: '63103', l: '检验试验人员' },
    { c: '63104', l: '称重计量人员' },
    { c: '63105', l: '包装人员' },
    { c: '63106', l: '安全生产管理人员' },
    { c: '63199', l: '其他生产辅助人员' },
    { c: '69901', l: '其他生产制造及有关人员' },
    { c: '80101', l: '不便分类的其他从业人员' },
  ],
  direction_raw: [
    { v: 'A', l: '农、林、牧、渔业' },
    { v: 'A01', l: '农业' },
    { v: 'A011', l: '谷物种植' },
    { v: 'A0111', l: '稻谷种植' },
    { v: 'A0112', l: '小麦种植' },
    { v: 'A0113', l: '玉米种植' },
    { v: 'A0119', l: '其他谷物种植' },
    { v: 'A012', l: '豆类、油料和薯类种植' },
    { v: 'A0121', l: '豆类种植' },
    { v: 'A0122', l: '油料种植' },
    { v: 'A0123', l: '薯类种植' },
    { v: 'A013', l: '棉、麻、糖、烟草种植' },
    { v: 'A0131', l: '棉花种植' },
    { v: 'A0132', l: '麻类种植' },
    { v: 'A0133', l: '糖料种植' },
    { v: 'A0134', l: '烟草种植' },
    { v: 'A014', l: '蔬菜、食用菌及园艺作物种植' },
    { v: 'A0141', l: '蔬菜种植' },
    { v: 'A0142', l: '食用菌种植' },
    { v: 'A0143', l: '花卉种植' },
    { v: 'A0149', l: '其他园艺作物种植' },
    { v: 'A015', l: '水果种植' },
    { v: 'A0151', l: '仁果类和核果类水果种植' },
    { v: 'A0152', l: '葡萄种植' },
    { v: 'A0153', l: '柑橘类种植' },
    { v: 'A0154', l: '香蕉等亚热带水果种植' },
    { v: 'A0159', l: '其他水果种植' },
    { v: 'A016', l: '坚果、含油果、香料和饮料作物种植' },
    { v: 'A0161', l: '坚果种植' },
    { v: 'A0162', l: '含油果种植' },
    { v: 'A0163', l: '香料作物种植' },
    { v: 'A0164', l: '茶叶种植' },
    { v: 'A0169', l: '其他饮料作物种植' },
    { v: 'A017', l: '中药材种植' },
    { v: 'A0171', l: '中草药种植' },
    { v: 'A0179', l: '其他中药材种植' },
    { v: 'A018', l: '草种植及割草' },
    { v: 'A0181', l: '草种植' },
    { v: 'A0182', l: '天然草原割草' },
    { v: 'A019', l: '其他农业' },
    { v: 'A0190', l: '其他农业' },
    { v: 'A02', l: '林业' },
    { v: 'A021', l: '林木育种和育苗' },
    { v: 'A0211', l: '林木育种' },
    { v: 'A0212', l: '林木育苗' },
    { v: 'A022', l: '造林和更新' },
    { v: 'A0220', l: '造林和更新' },
    { v: 'A023', l: '森林经营、管护和改培' },
    { v: 'A0231', l: '森林经营和管护' },
    { v: 'A0232', l: '森林改培' },
    { v: 'A024', l: '木材和竹材采运' },
    { v: 'A0241', l: '木材采运' },
    { v: 'A0242', l: '竹材采运' },
    { v: 'A025', l: '林产品采集' },
    { v: 'A0251', l: '木竹材林产品采集' },
    { v: 'A0252', l: '非木竹材林产品采集' },
    { v: 'A03', l: '畜牧业' },
    { v: 'A031', l: '牲畜饲养' },
    { v: 'A0311', l: '牛的饲养' },
    { v: 'A0312', l: '马的饲养' },
    { v: 'A0313', l: '猪的饲养' },
    { v: 'A0314', l: '羊的饲养' },
    { v: 'A0315', l: '骆驼饲养' },
    { v: 'A0319', l: '其他牲畜饲养' },
    { v: 'A032', l: '家禽饲养' },
    { v: 'A0321', l: '鸡的饲养' },
    { v: 'A0322', l: '鸭的饲养' },
    { v: 'A0323', l: '鹅的饲养' },
    { v: 'A0329', l: '其他家禽饲养' },
    { v: 'A033', l: '狩猎和捕捉动物' },
    { v: 'A0330', l: '狩猎和捕捉动物' },
    { v: 'A039', l: '其他畜牧业' },
    { v: 'A0391', l: '兔的饲养' },
    { v: 'A0392', l: '蜜蜂饲养' },
    { v: 'A0399', l: '其他未列明畜牧业' },
    { v: 'A04', l: '渔业' },
    { v: 'A041', l: '水产养殖' },
    { v: 'A0411', l: '海水养殖' },
    { v: 'A0412', l: '内陆养殖' },
    { v: 'A042', l: '水产捕捞' },
    { v: 'A0421', l: '海水捕捞' },
    { v: 'A0422', l: '内陆捕捞' },
    { v: 'A05', l: '农、林、牧、渔专业及辅助性活动' },
    { v: 'A051', l: '农业专业及辅助性活动' },
    { v: 'A0511', l: '种子种苗培育活动' },
    { v: 'A0512', l: '农业机械活动' },
    { v: 'A0513', l: '灌溉活动' },
    { v: 'A0514', l: '农产品初加工活动' },
    { v: 'A0515', l: '农作物病虫害防治活动' },
    { v: 'A0519', l: '其他农业专业及辅助性活动' },
    { v: 'A052', l: '林业专业及辅助性活动' },
    { v: 'A0521', l: '林业有害生物防治活动' },
    { v: 'A0522', l: '森林防火活动' },
    { v: 'A0523', l: '林产品初级加工活动' },
    { v: 'A0529', l: '其他林业专业及辅助性活动' },
    { v: 'A053', l: '畜牧专业及辅助性活动' },
    { v: 'A0531', l: '畜牧良种繁殖活动' },
    { v: 'A0532', l: '畜禽粪污处理活动' },
    { v: 'A0539', l: '其他畜牧专业及辅助性活动' },
    { v: 'A054', l: '渔业专业及辅助性活动' },
    { v: 'A0541', l: '鱼苗及鱼种场活动' },
    { v: 'A0549', l: '其他渔业专业及辅助性活动' },
    { v: 'B', l: '采矿业' },
    { v: 'B06', l: '煤炭开采和洗选业' },
    { v: 'B061', l: '烟煤和无烟煤开采洗选' },
    { v: 'B0610', l: '烟煤和无烟煤开采洗选' },
    { v: 'B062', l: '褐煤开采洗选' },
    { v: 'B0620', l: '褐煤开采洗选' },
    { v: 'B069', l: '其他煤炭采选' },
    { v: 'B0690', l: '其他煤炭采选' },
    { v: 'B07', l: '石油和天然气开采业' },
    { v: 'B071', l: '石油开采' },
    { v: 'B0711', l: '陆地石油开采' },
    { v: 'B0712', l: '海洋石油开采' },
    { v: 'B072', l: '天然气开采' },
    { v: 'B0721', l: '陆地天然气开采' },
    { v: 'B0722', l: '海洋天然气及可燃冰开采' },
    { v: 'B08', l: '黑色金属矿采选业' },
    { v: 'B081', l: '铁矿采选' },
    { v: 'B0810', l: '铁矿采选' },
    { v: 'B082', l: '锰矿、铬矿采选' },
    { v: 'B0820', l: '锰矿、铬矿采选' },
    { v: 'B089', l: '其他黑色金属矿采选' },
    { v: 'B0890', l: '其他黑色金属矿采选' },
    { v: 'B09', l: '有色金属矿采选业' },
    { v: 'B091', l: '常用有色金属矿采选' },
    { v: 'B0911', l: '铜矿采选' },
    { v: 'B0912', l: '铅锌矿采选' },
    { v: 'B0913', l: '镍钴矿采选' },
    { v: 'B0914', l: '锡矿采选' },
    { v: 'B0915', l: '锑矿采选' },
    { v: 'B0916', l: '铝矿采选' },
    { v: 'B0917', l: '镁矿采选' },
    { v: 'B0919', l: '其他常用有色金属矿采选' },
    { v: 'B092', l: '贵金属矿采选' },
    { v: 'B0921', l: '金矿采选' },
    { v: 'B0922', l: '银矿采选' },
    { v: 'B0929', l: '其他贵金属矿采选' },
    { v: 'B093', l: '稀有稀土金属矿采选' },
    { v: 'B0931', l: '钨钼矿采选' },
    { v: 'B0932', l: '稀土金属矿采选' },
    { v: 'B0933', l: '放射性金属矿采选' },
    { v: 'B0939', l: '其他稀有金属矿采选' },
    { v: 'B10', l: '非金属矿采选业' },
    { v: 'B101', l: '土砂石开采' },
    { v: 'B1011', l: '石灰石、石膏开采' },
    { v: 'B1012', l: '建筑装饰用石开采' },
    { v: 'B1013', l: '耐火土石开采' },
    { v: 'B1019', l: '粘土及其他土砂石开采' },
    { v: 'B102', l: '化学矿开采' },
    { v: 'B1020', l: '化学矿开采' },
    { v: 'B103', l: '采盐' },
    { v: 'B1030', l: '采盐' },
    { v: 'B109', l: '石棉及其他非金属矿采选' },
    { v: 'B1091', l: '石棉、云母矿采选' },
    { v: 'B1092', l: '石墨、滑石采选' },
    { v: 'B1093', l: '宝石、玉石采选' },
    { v: 'B1099', l: '其他未列明非金属矿采选' },
    { v: 'B11', l: '开采专业及辅助性活动' },
    { v: 'B111', l: '煤炭开采和洗选专业及辅助性活动' },
    { v: 'B1110', l: '煤炭开采和洗选专业及辅助性活动' },
    { v: 'B112', l: '石油和天然气开采专业及辅助性活动' },
    { v: 'B1120', l: '石油和天然气开采专业及辅助性活动' },
    { v: 'B119', l: '其他开采专业及辅助性活动' },
    { v: 'B1190', l: '其他开采专业及辅助性活动' },
    { v: 'B12', l: '其他采矿业' },
    { v: 'B120', l: '其他采矿业' },
    { v: 'B1200', l: '其他采矿业' },
    { v: 'C', l: '制造业' },
    { v: 'C13', l: '农副食品加工业' },
    { v: 'C131', l: '谷物磨制' },
    { v: 'C1311', l: '稻谷加工' },
    { v: 'C1312', l: '小麦加工' },
    { v: 'C1313', l: '玉米加工' },
    { v: 'C1314', l: '杂粮加工' },
    { v: 'C1319', l: '其他谷物磨制' },
    { v: 'C132', l: '饲料加工' },
    { v: 'C1321', l: '宠物饲料加工' },
    { v: 'C1329', l: '其他饲料加工' },
    { v: 'C133', l: '植物油加工' },
    { v: 'C1331', l: '食用植物油加工' },
    { v: 'C1332', l: '非食用植物油加工' },
    { v: 'C134', l: '制糖业' },
    { v: 'C1340', l: '制糖业' },
    { v: 'C135', l: '屠宰及肉类加工' },
    { v: 'C1351', l: '牲畜屠宰' },
    { v: 'C1352', l: '禽类屠宰' },
    { v: 'C1353', l: '肉制品及副产品加工' },
    { v: 'C136', l: '水产品加工' },
    { v: 'C1361', l: '水产品冷冻加工' },
    { v: 'C1362', l: '鱼糜制品及水产品干腌制加工' },
    { v: 'C1363', l: '鱼油提取及制品制造' },
    { v: 'C1369', l: '其他水产品加工' },
    { v: 'C137', l: '蔬菜、菌类、水果和坚果加工' },
    { v: 'C1371', l: '蔬菜加工' },
    { v: 'C1372', l: '食用菌加工' },
    { v: 'C1373', l: '水果和坚果加工' },
    { v: 'C139', l: '其他农副食品加工' },
    { v: 'C1391', l: '淀粉及淀粉制品制造' },
    { v: 'C1392', l: '豆制品制造' },
    { v: 'C1393', l: '蛋品加工' },
    { v: 'C1399', l: '其他未列明农副食品加工' },
    { v: 'C14', l: '食品制造业' },
    { v: 'C141', l: '焙烤食品制造' },
    { v: 'C1411', l: '糕点、面包制造' },
    { v: 'C1419', l: '饼干及其他焙烤食品制造' },
    { v: 'C142', l: '糖果、巧克力及蜜饯制造' },
    { v: 'C1421', l: '糖果、巧克力制造' },
    { v: 'C1422', l: '蜜饯制作' },
    { v: 'C143', l: '方便食品制造' },
    { v: 'C1431', l: '米、面制品制造' },
    { v: 'C1432', l: '速冻食品制造' },
    { v: 'C1433', l: '方便面制造' },
    { v: 'C1439', l: '其他方便食品制造' },
    { v: 'C144', l: '乳制品制造' },
    { v: 'C1441', l: '液体乳制造' },
    { v: 'C1442', l: '乳粉制造' },
    { v: 'C1449', l: '其他乳制品制造' },
    { v: 'C145', l: '罐头食品制造' },
    { v: 'C1451', l: '肉、禽类罐头制造' },
    { v: 'C1452', l: '水产品罐头制造' },
    { v: 'C1453', l: '蔬菜、水果罐头制造' },
    { v: 'C1459', l: '其他罐头食品制造' },
    { v: 'C146', l: '调味品、发酵制品制造' },
    { v: 'C1461', l: '味精制造' },
    { v: 'C1462', l: '酱油、食醋及类似制品制造' },
    { v: 'C1469', l: '其他调味品、发酵制品制造' },
    { v: 'C149', l: '其他食品制造' },
    { v: 'C1491', l: '营养食品制造' },
    { v: 'C1492', l: '保健食品制造' },
    { v: 'C1493', l: '冷冻饮品及食用冰制造' },
    { v: 'C1494', l: '盐加工' },
    { v: 'C1495', l: '食品及饲料添加剂制造' },
    { v: 'C1499', l: '其他未列明食品制造' },
    { v: 'C15', l: '酒、饮料和精制茶制造业' },
    { v: 'C151', l: '酒的制造' },
    { v: 'C1511', l: '酒精制造' },
    { v: 'C1512', l: '白酒制造' },
    { v: 'C1513', l: '啤酒制造' },
    { v: 'C1514', l: '黄酒制造' },
    { v: 'C1515', l: '葡萄酒制造' },
    { v: 'C1519', l: '其他酒制造' },
    { v: 'C152', l: '饮料制造' },
    { v: 'C1521', l: '碳酸饮料制造' },
    { v: 'C1522', l: '瓶（罐）装饮用水制造' },
    { v: 'C1523', l: '果菜汁及果菜汁饮料制造' },
    { v: 'C1524', l: '含乳饮料和植物蛋白饮料制造' },
    { v: 'C1525', l: '固体饮料制造' },
    { v: 'C1529', l: '茶饮料及其他饮料制造' },
    { v: 'C153', l: '精制茶加工' },
    { v: 'C1530', l: '精制茶加工' },
    { v: 'C16', l: '烟草制品业' },
    { v: 'C161', l: '烟叶复烤' },
    { v: 'C1610', l: '烟叶复烤' },
    { v: 'C162', l: '卷烟制造' },
    { v: 'C1620', l: '卷烟制造' },
    { v: 'C169', l: '其他烟草制品制造' },
    { v: 'C1690', l: '其他烟草制品制造' },
    { v: 'C17', l: '纺织业' },
    { v: 'C171', l: '棉纺织及印染精加工' },
    { v: 'C1711', l: '棉纺纱加工' },
    { v: 'C1712', l: '棉织造加工' },
    { v: 'C1713', l: '棉印染精加工' },
    { v: 'C172', l: '毛纺织及染整精加工' },
    { v: 'C1721', l: '毛条和毛纱线加工' },
    { v: 'C1722', l: '毛织造加工' },
    { v: 'C1723', l: '毛染整精加工' },
    { v: 'C173', l: '麻纺织及染整精加工' },
    { v: 'C1731', l: '麻纤维纺前加工和纺纱' },
    { v: 'C1732', l: '麻织造加工' },
    { v: 'C1733', l: '麻染整精加工' },
    { v: 'C174', l: '丝绢纺织及印染精加工' },
    { v: 'C1741', l: '缫丝加工' },
    { v: 'C1742', l: '绢纺和丝织加工' },
    { v: 'C1743', l: '丝印染精加工' },
    { v: 'C175', l: '化纤织造及印染精加工' },
    { v: 'C1751', l: '化纤织造加工' },
    { v: 'C1752', l: '化纤织物染整精加工' },
    { v: 'C176', l: '针织或钩针编织物及其制品制造' },
    { v: 'C1761', l: '针织或钩针编织物织造' },
    { v: 'C1762', l: '针织或钩针编织物印染精加工' },
    { v: 'C1763', l: '针织或钩针编织品制造' },
    { v: 'C177', l: '家用纺织制成品制造' },
    { v: 'C1771', l: '床上用品制造' },
    { v: 'C1772', l: '毛巾类制品制造' },
    { v: 'C1773', l: '窗帘、布艺类产品制造' },
    { v: 'C1779', l: '其他家用纺织制成品制造' },
    { v: 'C178', l: '产业用纺织制成品制造' },
    { v: 'C1781', l: '非织造布制造' },
    { v: 'C1782', l: '绳、索、缆制造' },
    { v: 'C1783', l: '纺织带和帘子布制造' },
    { v: 'C1784', l: '篷、帆布制造' },
    { v: 'C1789', l: '其他产业用纺织制成品制造' },
    { v: 'C18', l: '纺织服装、服饰业' },
    { v: 'C181', l: '机织服装制造' },
    { v: 'C1811', l: '运动机织服装制造' },
    { v: 'C1819', l: '其他机织服装制造' },
    { v: 'C182', l: '针织或钩针编织服装制造' },
    { v: 'C1821', l: '运动休闲针织服装制造' },
    { v: 'C1829', l: '其他针织或钩针编织服装制造' },
    { v: 'C183', l: '服饰制造' },
    { v: 'C1830', l: '服饰制造' },
    { v: 'C19', l: '皮革、毛皮、羽毛及其制品和制鞋业' },
    { v: 'C191', l: '皮革鞣制加工' },
    { v: 'C1910', l: '皮革鞣制加工' },
    { v: 'C192', l: '皮革制品制造' },
    { v: 'C1921', l: '皮革服装制造' },
    { v: 'C1922', l: '皮箱、包(袋)制造' },
    { v: 'C1923', l: '皮手套及皮装饰制品制造' },
    { v: 'C1929', l: '其他皮革制品制造' },
    { v: 'C193', l: '毛皮鞣制及制品加工' },
    { v: 'C1931', l: '毛皮鞣制加工' },
    { v: 'C1932', l: '毛皮服装加工' },
    { v: 'C1939', l: '其他毛皮制品加工' },
    { v: 'C194', l: '羽毛(绒)加工及制品制造' },
    { v: 'C1941', l: '羽毛(绒)加工' },
    { v: 'C1942', l: '羽毛(绒)制品加工' },
    { v: 'C195', l: '制鞋业' },
    { v: 'C1951', l: '纺织面料鞋制造' },
    { v: 'C1952', l: '皮鞋制造' },
    { v: 'C1953', l: '塑料鞋制造' },
    { v: 'C1954', l: '橡胶鞋制造' },
    { v: 'C1959', l: '其他制鞋业' },
    { v: 'C20', l: '木材加工和木、竹、藤、棕、草制品业' },
    { v: 'C201', l: '木材加工' },
    { v: 'C2011', l: '锯材加工' },
    { v: 'C2012', l: '木片加工' },
    { v: 'C2013', l: '单板加工' },
    { v: 'C2019', l: '其他木材加工' },
    { v: 'C202', l: '人造板制造' },
    { v: 'C2021', l: '胶合板制造' },
    { v: 'C2022', l: '纤维板制造' },
    { v: 'C2023', l: '刨花板制造' },
    { v: 'C2029', l: '其他人造板制造' },
    { v: 'C203', l: '木制品制造' },
    { v: 'C2031', l: '建筑用木料及木材组件加工' },
    { v: 'C2032', l: '木门窗制造' },
    { v: 'C2033', l: '木楼梯制造' },
    { v: 'C2034', l: '木地板制造' },
    { v: 'C2035', l: '木制容器制造' },
    { v: 'C2039', l: '软木制品及其他木制品制造' },
    { v: 'C204', l: '竹、藤、棕、草制品制造' },
    { v: 'C2041', l: '竹制品制造' },
    { v: 'C2042', l: '藤制品制造' },
    { v: 'C2043', l: '棕制品制造' },
    { v: 'C2049', l: '草及其他制品制造' },
    { v: 'C21', l: '家具制造业' },
    { v: 'C211', l: '木质家具制造' },
    { v: 'C2110', l: '木质家具制造' },
    { v: 'C212', l: '竹、藤家具制造' },
    { v: 'C2120', l: '竹、藤家具制造' },
    { v: 'C213', l: '金属家具制造' },
    { v: 'C2130', l: '金属家具制造' },
    { v: 'C214', l: '塑料家具制造' },
    { v: 'C2140', l: '塑料家具制造' },
    { v: 'C219', l: '其他家具制造' },
    { v: 'C2190', l: '其他家具制造' },
    { v: 'C22', l: '造纸和纸制品业' },
    { v: 'C221', l: '纸浆制造' },
    { v: 'C2211', l: '木竹浆制造' },
    { v: 'C2212', l: '非木竹浆制造' },
    { v: 'C222', l: '造纸' },
    { v: 'C2221', l: '机制纸及纸板制造' },
    { v: 'C2222', l: '手工纸制造' },
    { v: 'C2223', l: '加工纸制造' },
    { v: 'C223', l: '纸制品制造' },
    { v: 'C2231', l: '纸和纸板容器制造' },
    { v: 'C2239', l: '其他纸制品制造' },
    { v: 'C23', l: '印刷和记录媒介复制业' },
    { v: 'C231', l: '印刷' },
    { v: 'C2311', l: '书、报刊印刷' },
    { v: 'C2312', l: '本册印制' },
    { v: 'C2319', l: '包装装潢及其他印刷' },
    { v: 'C232', l: '装订及印刷相关服务' },
    { v: 'C2320', l: '装订及印刷相关服务' },
    { v: 'C233', l: '记录媒介复制' },
    { v: 'C2330', l: '记录媒介复制' },
    { v: 'C24', l: '文教、工美、体育和娱乐用品制造业' },
    { v: 'C241', l: '文教办公用品制造' },
    { v: 'C2411', l: '文具制造' },
    { v: 'C2412', l: '笔的制造' },
    { v: 'C2413', l: '教学用模型及教具制造' },
    { v: 'C2414', l: '墨水、墨汁制造' },
    { v: 'C2419', l: '其他文教办公用品制造' },
    { v: 'C242', l: '乐器制造' },
    { v: 'C2421', l: '中乐器制造' },
    { v: 'C2422', l: '西乐器制造' },
    { v: 'C2423', l: '电子乐器制造' },
    { v: 'C2429', l: '其他乐器及零件制造' },
    { v: 'C243', l: '工艺美术及礼仪用品制造' },
    { v: 'C2431', l: '雕塑工艺品制造' },
    { v: 'C2432', l: '金属工艺品制造' },
    { v: 'C2433', l: '漆器工艺品制造' },
    { v: 'C2434', l: '花画工艺品制造' },
    { v: 'C2435', l: '天然植物纤维编织工艺品制造' },
    { v: 'C2436', l: '抽纱刺绣工艺品制造' },
    { v: 'C2437', l: '地毯、挂毯制造' },
    { v: 'C2438', l: '珠宝首饰及有关物品制造' },
    { v: 'C2439', l: '其他工艺美术及礼仪用品制造' },
    { v: 'C244', l: '体育用品制造' },
    { v: 'C2441', l: '球类制造' },
    { v: 'C2442', l: '专项运动器材及配件制造' },
    { v: 'C2443', l: '健身器材制造' },
    { v: 'C2444', l: '运动防护用具制造' },
    { v: 'C2449', l: '其他体育用品制造' },
    { v: 'C245', l: '玩具制造' },
    { v: 'C2451', l: '电玩具制造' },
    { v: 'C2452', l: '塑胶玩具制造' },
    { v: 'C2453', l: '金属玩具制造' },
    { v: 'C2454', l: '弹射玩具制造' },
    { v: 'C2455', l: '娃娃玩具制造' },
    { v: 'C2456', l: '儿童乘骑玩耍的童车类产品制造' },
    { v: 'C2459', l: '其他玩具制造' },
    { v: 'C246', l: '游艺器材及娱乐用品制造' },
    { v: 'C2461', l: '露天游乐场所游乐设备制造' },
    { v: 'C2462', l: '游艺用品及室内游艺器材制造' },
    { v: 'C2469', l: '其他娱乐用品制造' },
    { v: 'C25', l: '石油、煤炭及其他燃料加工业' },
    { v: 'C251', l: '精炼石油产品制造' },
    { v: 'C2511', l: '原油加工及石油制品制造' },
    { v: 'C2519', l: '其他原油制造' },
    { v: 'C252', l: '煤炭加工' },
    { v: 'C2521', l: '炼焦' },
    { v: 'C2522', l: '煤制合成气生产' },
    { v: 'C2523', l: '煤制液体燃料生产' },
    { v: 'C2524', l: '煤制品制造' },
    { v: 'C2529', l: '其他煤炭加工' },
    { v: 'C253', l: '核燃料加工' },
    { v: 'C2530', l: '核燃料加工' },
    { v: 'C254', l: '生物质燃料加工' },
    { v: 'C2541', l: '生物质液体燃料生产' },
    { v: 'C2542', l: '生物质致密成型燃料加工' },
    { v: 'C26', l: '化学原料和化学制品制造业' },
    { v: 'C261', l: '基础化学原料制造' },
    { v: 'C2611', l: '无机酸制造' },
    { v: 'C2612', l: '无机碱制造' },
    { v: 'C2613', l: '无机盐制造' },
    { v: 'C2614', l: '有机化学原料制造' },
    { v: 'C2619', l: '其他基础化学原料制造' },
    { v: 'C262', l: '肥料制造' },
    { v: 'C2621', l: '氮肥制造' },
    { v: 'C2622', l: '磷肥制造' },
    { v: 'C2623', l: '钾肥制造' },
    { v: 'C2624', l: '复混肥料制造' },
    { v: 'C2625', l: '有机肥料及微生物肥料制造' },
    { v: 'C2629', l: '其他肥料制造' },
    { v: 'C263', l: '农药制造' },
    { v: 'C2631', l: '化学农药制造' },
    { v: 'C2632', l: '生物化学农药及微生物农药制造' },
    { v: 'C264', l: '涂料、油墨、颜料及类似产品制造' },
    { v: 'C2641', l: '涂料制造' },
    { v: 'C2642', l: '油墨及类似产品制造' },
    { v: 'C2643', l: '工业颜料制造' },
    { v: 'C2644', l: '工艺美术颜料制造' },
    { v: 'C2645', l: '染料制造' },
    { v: 'C2646', l: '密封用填料及类似品制造' },
    { v: 'C265', l: '合成材料制造' },
    { v: 'C2651', l: '初级形态塑料及合成树脂制造' },
    { v: 'C2652', l: '合成橡胶制造' },
    { v: 'C2653', l: '合成纤维单(聚合)体制造' },
    { v: 'C2659', l: '其他合成材料制造' },
    { v: 'C266', l: '专用化学产品制造' },
    { v: 'C2661', l: '化学试剂和助剂制造' },
    { v: 'C2662', l: '专项化学用品制造' },
    { v: 'C2663', l: '林产化学产品制造' },
    { v: 'C2664', l: '文化用信息化学品制造' },
    { v: 'C2665', l: '医学生产用信息化学品制造' },
    { v: 'C2666', l: '环境污染处理专用药剂材料制造' },
    { v: 'C2667', l: '动物胶制造' },
    { v: 'C2669', l: '其他专用化学产品制造' },
    { v: 'C267', l: '炸药、火工及焰火产品制造' },
    { v: 'C2671', l: '炸药及火工产品制造' },
    { v: 'C2672', l: '焰火、鞭炮产品制造' },
    { v: 'C268', l: '日用化学产品制造' },
    { v: 'C2681', l: '肥皂及洗涤剂制造' },
    { v: 'C2682', l: '化妆品制造' },
    { v: 'C2683', l: '口腔清洁用品制造' },
    { v: 'C2684', l: '香料、香精制造' },
    { v: 'C2689', l: '其他日用化学产品制造' },
    { v: 'C27', l: '医药制造业' },
    { v: 'C271', l: '化学药品原料药制造' },
    { v: 'C2710', l: '化学药品原料药制造' },
    { v: 'C272', l: '化学药品制剂制造' },
    { v: 'C2720', l: '化学药品制剂制造' },
    { v: 'C273', l: '中药饮片加工' },
    { v: 'C2730', l: '中药饮片加工' },
    { v: 'C274', l: '中成药生产' },
    { v: 'C2740', l: '中成药生产' },
    { v: 'C275', l: '兽用药品制造' },
    { v: 'C2750', l: '兽用药品制造' },
    { v: 'C276', l: '生物药品制品制造' },
    { v: 'C2761', l: '生物药品制造' },
    { v: 'C2762', l: '基因工程药物和疫苗制造' },
    { v: 'C277', l: '卫生材料及医药用品制造' },
    { v: 'C2770', l: '卫生材料及医药用品制造' },
    { v: 'C278', l: '药用辅料及包装材料' },
    { v: 'C2780', l: '药用辅料及包装材料' },
    { v: 'C28', l: '化学纤维制造业' },
    { v: 'C281', l: '纤维素纤维原料及纤维制造' },
    { v: 'C2811', l: '化纤浆粕制造' },
    { v: 'C2812', l: '人造纤维（纤维素纤维）制造' },
    { v: 'C282', l: '合成纤维制造' },
    { v: 'C2821', l: '锦纶纤维制造' },
    { v: 'C2822', l: '涤纶纤维制造' },
    { v: 'C2823', l: '腈纶纤维制造' },
    { v: 'C2824', l: '维纶纤维制造' },
    { v: 'C2825', l: '丙纶纤维制造' },
    { v: 'C2826', l: '氨纶纤维制造' },
    { v: 'C2829', l: '其他合成纤维制造' },
    { v: 'C283', l: '生物基材料制造' },
    { v: 'C2831', l: '生物基化学纤维制造' },
    { v: 'C2832', l: '生物基、淀粉基新材料制造' },
    { v: 'C29', l: '橡胶和塑料制品业' },
    { v: 'C291', l: '橡胶制品业' },
    { v: 'C2911', l: '轮胎制造' },
    { v: 'C2912', l: '橡胶板、管、带制造' },
    { v: 'C2913', l: '橡胶零件制造' },
    { v: 'C2914', l: '再生橡胶制造' },
    { v: 'C2915', l: '日用及医用橡胶制品制造' },
    { v: 'C2916', l: '运动场地用塑胶制造' },
    { v: 'C2919', l: '其他橡胶制品制造' },
    { v: 'C292', l: '塑料制品业' },
    { v: 'C2921', l: '塑料薄膜制造' },
    { v: 'C2922', l: '塑料板、管、型材制造' },
    { v: 'C2923', l: '塑料丝、绳及编织品制造' },
    { v: 'C2924', l: '泡沫塑料制造' },
    { v: 'C2925', l: '塑料人造革、合成革制造' },
    { v: 'C2926', l: '塑料包装箱及容器制造' },
    { v: 'C2927', l: '日用塑料制品制造' },
    { v: 'C2928', l: '人造草坪制造' },
    { v: 'C2929', l: '塑料零件及其他塑料制品制造' },
    { v: 'C30', l: '非金属矿物制品业' },
    { v: 'C301', l: '水泥、石灰和石膏制造' },
    { v: 'C3011', l: '水泥制造' },
    { v: 'C3012', l: '石灰和石膏制造' },
    { v: 'C302', l: '石膏、水泥制品及类似制品制造' },
    { v: 'C3021', l: '水泥制品制造' },
    { v: 'C3022', l: '砼结构构件制造' },
    { v: 'C3023', l: '石棉水泥制品制造' },
    { v: 'C3024', l: '轻质建筑材料制造' },
    { v: 'C3029', l: '其他水泥类似制品制造' },
    { v: 'C303', l: '砖瓦、石材等建筑材料制造' },
    { v: 'C3031', l: '粘土砖瓦及建筑砌块制造' },
    { v: 'C3032', l: '建筑用石加工' },
    { v: 'C3033', l: '防水建筑材料制造' },
    { v: 'C3034', l: '隔热和隔音材料制造' },
    { v: 'C3039', l: '其他建筑材料制造' },
    { v: 'C304', l: '玻璃制造' },
    { v: 'C3041', l: '平板玻璃制造' },
    { v: 'C3042', l: '特种玻璃制造' },
    { v: 'C3049', l: '其他玻璃制造' },
    { v: 'C305', l: '玻璃制品制造' },
    { v: 'C3051', l: '技术玻璃制品制造' },
    { v: 'C3052', l: '光学玻璃制造' },
    { v: 'C3053', l: '玻璃仪器制造' },
    { v: 'C3054', l: '日用玻璃制品制造' },
    { v: 'C3055', l: '玻璃包装容器制造' },
    { v: 'C3056', l: '玻璃保温容器制造' },
    { v: 'C3057', l: '制镜及类似品加工' },
    { v: 'C3059', l: '其他玻璃制品制造' },
    { v: 'C306', l: '玻璃纤维和玻璃纤维增强塑料制品制造' },
    { v: 'C3061', l: '玻璃纤维及制品制造' },
    { v: 'C3062', l: '玻璃纤维增强塑料制品制造' },
    { v: 'C307', l: '陶瓷制品制造' },
    { v: 'C3071', l: '建筑陶瓷制品制造' },
    { v: 'C3072', l: '卫生陶瓷制品制造' },
    { v: 'C3073', l: '特种陶瓷制品制造' },
    { v: 'C3074', l: '日用陶瓷制品制造' },
    { v: 'C3075', l: '陈设艺术陶瓷制造' },
    { v: 'C3076', l: '园艺陶瓷制造' },
    { v: 'C3079', l: '其他陶瓷制品制造' },
    { v: 'C308', l: '耐火材料制品制造' },
    { v: 'C3081', l: '石棉制品制造' },
    { v: 'C3082', l: '云母制品制造' },
    { v: 'C3089', l: '耐火陶瓷制品及其他耐火材料制造' },
    { v: 'C309', l: '石墨及其他非金属矿物制品制造' },
    { v: 'C3091', l: '石墨及碳素制品制造' },
    { v: 'C3099', l: '其他非金属矿物制品制造' },
    { v: 'C31', l: '黑色金属冶炼和压延加工业' },
    { v: 'C311', l: '炼铁' },
    { v: 'C3110', l: '炼铁' },
    { v: 'C312', l: '炼钢' },
    { v: 'C3120', l: '炼钢' },
    { v: 'C313', l: '钢压延加工' },
    { v: 'C3130', l: '钢压延加工' },
    { v: 'C314', l: '铁合金冶炼' },
    { v: 'C3140', l: '铁合金冶炼' },
    { v: 'C32', l: '有色金属冶炼和压延加工业' },
    { v: 'C321', l: '常用有色金属冶炼' },
    { v: 'C3211', l: '铜冶炼' },
    { v: 'C3212', l: '铅锌冶炼' },
    { v: 'C3213', l: '镍钴冶炼' },
    { v: 'C3214', l: '锡冶炼' },
    { v: 'C3215', l: '锑冶炼' },
    { v: 'C3216', l: '铝冶炼' },
    { v: 'C3217', l: '镁冶炼' },
    { v: 'C3218', l: '硅冶炼' },
    { v: 'C3219', l: '其他常用有色金属冶炼' },
    { v: 'C322', l: '贵金属冶炼' },
    { v: 'C3221', l: '金冶炼' },
    { v: 'C3222', l: '银冶炼' },
    { v: 'C3229', l: '其他贵金属冶炼' },
    { v: 'C323', l: '稀有稀土金属冶炼' },
    { v: 'C3231', l: '钨钼冶炼' },
    { v: 'C3232', l: '稀土金属冶炼' },
    { v: 'C3239', l: '其他稀有金属冶炼' },
    { v: 'C324', l: '有色金属合金制造' },
    { v: 'C3240', l: '有色金属合金制造' },
    { v: 'C325', l: '有色金属压延加工' },
    { v: 'C3251', l: '铜压延加工' },
    { v: 'C3252', l: '铝压延加工' },
    { v: 'C3253', l: '贵金属压延加工' },
    { v: 'C3254', l: '稀有稀土金属压延加工' },
    { v: 'C3259', l: '其他有色金属压延加工' },
    { v: 'C33', l: '金属制品业' },
    { v: 'C331', l: '结构性金属制品制造' },
    { v: 'C3311', l: '金属结构制造' },
    { v: 'C3312', l: '金属门窗制造' },
    { v: 'C332', l: '金属工具制造' },
    { v: 'C3321', l: '切削工具制造' },
    { v: 'C3322', l: '手工具制造' },
    { v: 'C3323', l: '农用及园林用金属工具制造' },
    { v: 'C3324', l: '刀剪及类似日用金属工具制造' },
    { v: 'C3329', l: '其他金属工具制造' },
    { v: 'C333', l: '集装箱及金属包装容器制造' },
    { v: 'C3331', l: '集装箱制造' },
    { v: 'C3332', l: '金属压力容器制造' },
    { v: 'C3333', l: '金属包装容器及材料制造' },
    { v: 'C334', l: '金属丝绳及其制品制造' },
    { v: 'C3340', l: '金属丝绳及其制品制造' },
    { v: 'C335', l: '建筑、安全用金属制品制造' },
    { v: 'C3351', l: '建筑、家具用金属配件制造' },
    { v: 'C3352', l: '建筑装饰及水暖管道零件制造' },
    { v: 'C3353', l: '安全、消防用金属制品制造' },
    { v: 'C3359', l: '其他建筑、安全用金属制品制造' },
    { v: 'C336', l: '金属表面处理及热处理加工' },
    { v: 'C3360', l: '金属表面处理及热处理加工' },
    { v: 'C337', l: '搪瓷制品制造' },
    { v: 'C3371', l: '生产专用搪瓷制品制造' },
    { v: 'C3372', l: '建筑装饰搪瓷制品制造' },
    { v: 'C3373', l: '搪瓷卫生洁具制造' },
    { v: 'C3379', l: '搪瓷日用品及其他搪瓷制品制造' },
    { v: 'C338', l: '金属制日用品制造' },
    { v: 'C3381', l: '金属制厨房用器具制造' },
    { v: 'C3382', l: '金属制餐具和器皿制造' },
    { v: 'C3383', l: '金属制卫生器具制造' },
    { v: 'C3389', l: '其他金属制日用品制造' },
    { v: 'C339', l: '铸造及其他金属制品制造' },
    { v: 'C3391', l: '黑色金属铸造' },
    { v: 'C3392', l: '有色金属铸造' },
    { v: 'C3393', l: '锻件及粉末冶金制品制造' },
    { v: 'C3394', l: '交通及公共管理用金属标牌制造' },
    { v: 'C3399', l: '其他未列明金属制品制造' },
    { v: 'C34', l: '通用设备制造业' },
    { v: 'C341', l: '锅炉及原动设备制造' },
    { v: 'C3411', l: '锅炉及辅助设备制造' },
    { v: 'C3412', l: '内燃机及配件制造' },
    { v: 'C3413', l: '汽轮机及辅机制造' },
    { v: 'C3414', l: '水轮机及辅机制造' },
    { v: 'C3415', l: '风能原动设备制造' },
    { v: 'C3419', l: '其他原动设备制造' },
    { v: 'C342', l: '金属加工机械制造' },
    { v: 'C3421', l: '金属切削机床制造' },
    { v: 'C3422', l: '金属成形机床制造' },
    { v: 'C3423', l: '铸造机械制造' },
    { v: 'C3424', l: '金属切割及焊接设备制造' },
    { v: 'C3425', l: '机床功能部件及附件制造' },
    { v: 'C3429', l: '其他金属加工机械制造' },
    { v: 'C343', l: '物料搬运设备制造' },
    { v: 'C3431', l: '轻小型起重设备制造' },
    { v: 'C3432', l: '生产专用起重机制造' },
    { v: 'C3433', l: '生产专用车辆制造' },
    { v: 'C3434', l: '连续搬运设备制造' },
    { v: 'C3435', l: '电梯、自动扶梯及升降机制造' },
    { v: 'C3436', l: '客运索道制造' },
    { v: 'C3437', l: '机械式停车设备制造' },
    { v: 'C3439', l: '其他物料搬运设备制造' },
    { v: 'C344', l: '泵、阀门、压缩机及类似机械制造' },
    { v: 'C3441', l: '泵及真空设备制造' },
    { v: 'C3442', l: '气体压缩机械制造' },
    { v: 'C3443', l: '阀门和旋塞制造' },
    { v: 'C3444', l: '液压动力机械及元件制造' },
    { v: 'C3445', l: '液力动力机械元件制造' },
    { v: 'C3446', l: '气压动力机械及元件制造' },
    { v: 'C345', l: '轴承、齿轮和传动部件制造' },
    { v: 'C3451', l: '滚动轴承制造' },
    { v: 'C3452', l: '滑动轴承制造' },
    { v: 'C3453', l: '齿轮及齿轮减、变速箱制造' },
    { v: 'C3459', l: '其他传动部件制造' },
    { v: 'C346', l: '烘炉、风机、包装等设备制造' },
    { v: 'C3461', l: '烘炉、熔炉及电炉制造' },
    { v: 'C3462', l: '风机、风扇制造' },
    { v: 'C3463', l: '气体、液体分离及纯净设备制造' },
    { v: 'C3464', l: '制冷、空调设备制造' },
    { v: 'C3465', l: '风动和电动工具制造' },
    { v: 'C3466', l: '喷枪及类似器具制造' },
    { v: 'C3467', l: '包装专用设备制造' },
    { v: 'C347', l: '文化、办公用机械制造' },
    { v: 'C3471', l: '电影机械制造' },
    { v: 'C3472', l: '幻灯及投影设备制造' },
    { v: 'C3473', l: '照相机及器材制造' },
    { v: 'C3474', l: '复印和胶印设备制造' },
    { v: 'C3475', l: '计算器及货币专用设备制造' },
    { v: 'C3479', l: '其他文化、办公用机械制造' },
    { v: 'C348', l: '通用零部件制造' },
    { v: 'C3481', l: '金属密封件制造' },
    { v: 'C3482', l: '紧固件制造' },
    { v: 'C3483', l: '弹簧制造' },
    { v: 'C3484', l: '机械零部件加工' },
    { v: 'C3489', l: '其他通用零部件制造' },
    { v: 'C349', l: '其他通用设备制造' },
    { v: 'C3491', l: '工业机器人制造' },
    { v: 'C3492', l: '特殊作业机器人制造' },
    { v: 'C3493', l: '增材制造装备制造' },
    { v: 'C3499', l: '其他未列明通用设备制造业' },
    { v: 'C35', l: '专用设备制造业' },
    { v: 'C351', l: '采矿、冶金、建筑专用设备制造' },
    { v: 'C3511', l: '矿山机械制造' },
    { v: 'C3512', l: '石油钻采专用设备制造' },
    { v: 'C3513', l: '深海石油钻探设备制造' },
    { v: 'C3514', l: '建筑工程用机械制造' },
    { v: 'C3515', l: '建筑材料生产专用机械制造' },
    { v: 'C3516', l: '冶金专用设备制造' },
    { v: 'C3517', l: '隧道施工专用机械制造' },
    { v: 'C352', l: '化工、木材、非金属加工专用设备制造' },
    { v: 'C3521', l: '炼油、化工生产专用设备制造' },
    { v: 'C3522', l: '橡胶加工专用设备制造' },
    { v: 'C3523', l: '塑料加工专用设备制造' },
    { v: 'C3524', l: '木竹材加工机械制造' },
    { v: 'C3525', l: '模具制造' },
    { v: 'C3529', l: '其他非金属加工专用设备制造' },
    { v: 'C353', l: '食品、饮料、烟草及饲料生产专用设备制造' },
    { v: 'C3531', l: '食品、酒、饮料及茶生产专用设备制造' },
    { v: 'C3532', l: '农副食品加工专用设备制造' },
    { v: 'C3533', l: '烟草生产专用设备制造' },
    { v: 'C3534', l: '饲料生产专用设备制造' },
    { v: 'C354', l: '印刷、制药、日化及日用品生产专用设备制造' },
    { v: 'C3541', l: '制浆和造纸专用设备制造' },
    { v: 'C3542', l: '印刷专用设备制造' },
    { v: 'C3543', l: '日用化工专用设备制造' },
    { v: 'C3544', l: '制药专用设备制造' },
    { v: 'C3545', l: '照明器具生产专用设备制造' },
    { v: 'C3546', l: '玻璃、陶瓷和搪瓷制品生产专用设备制造' },
    { v: 'C3549', l: '其他日用品生产专用设备制造' },
    { v: 'C355', l: '纺织、服装和皮革加工专用设备制造' },
    { v: 'C3551', l: '纺织专用设备制造' },
    { v: 'C3552', l: '皮革、毛皮及其制品加工专用设备制造' },
    { v: 'C3553', l: '缝制机械制造' },
    { v: 'C3554', l: '洗涤机械制造' },
    { v: 'C356', l: '电子和电工机械专用设备制造' },
    { v: 'C3561', l: '电工机械专用设备制造' },
    { v: 'C3562', l: '半导体器件专用设备制造' },
    { v: 'C3563', l: '电子元器件与机电组件设备制造' },
    { v: 'C3569', l: '其他电子专用设备制造' },
    { v: 'C357', l: '农、林、牧、渔专用机械制造' },
    { v: 'C3571', l: '拖拉机制造' },
    { v: 'C3572', l: '机械化农业及园艺机具制造' },
    { v: 'C3573', l: '营林及木竹采伐机械制造' },
    { v: 'C3574', l: '畜牧机械制造' },
    { v: 'C3575', l: '渔业机械制造' },
    { v: 'C3576', l: '农林牧渔机械配件制造' },
    { v: 'C3577', l: '棉花加工机械制造' },
    { v: 'C3579', l: '其他农、林、牧、渔业机械制造' },
    { v: 'C358', l: '医疗仪器设备及器械制造' },
    { v: 'C3581', l: '医疗诊断、监护及治疗设备制造' },
    { v: 'C3582', l: '口腔科用设备及器具制造' },
    { v: 'C3583', l: '医疗实验室及医用消毒设备和器具制造' },
    { v: 'C3584', l: '医疗、外科及兽医用器械制造' },
    { v: 'C3585', l: '机械治疗及病房护理设备制造' },
    { v: 'C3586', l: '康复辅具制造' },
    { v: 'C3587', l: '眼镜制造' },
    { v: 'C3589', l: '其他医疗设备及器械制造' },
    { v: 'C359', l: '环保、邮政、社会公共服务及其他专用设备制造' },
    { v: 'C3591', l: '环境保护专用设备制造' },
    { v: 'C3592', l: '地质勘查专用设备制造' },
    { v: 'C3593', l: '邮政专用机械及器材制造' },
    { v: 'C3594', l: '商业、饮食、服务专用设备制造' },
    { v: 'C3595', l: '社会公共安全设备及器材制造' },
    { v: 'C3596', l: '交通安全、管制及类似专用设备制造' },
    { v: 'C3597', l: '水资源专用机械制造' },
    { v: 'C3599', l: '其他专用设备制造' },
    { v: 'C36', l: '汽车制造业' },
    { v: 'C361', l: '汽车整车制造' },
    { v: 'C3611', l: '汽柴油车整车制造' },
    { v: 'C3612', l: '新能源车整车制造' },
    { v: 'C362', l: '汽车用发动机制造' },
    { v: 'C3620', l: '汽车用发动机制造' },
    { v: 'C363', l: '改装汽车制造' },
    { v: 'C3630', l: '改装汽车制造' },
    { v: 'C364', l: '低速汽车制造' },
    { v: 'C3640', l: '低速汽车制造' },
    { v: 'C365', l: '电车制造' },
    { v: 'C3650', l: '电车制造' },
    { v: 'C366', l: '汽车车身、挂车制造' },
    { v: 'C3660', l: '汽车车身、挂车制造' },
    { v: 'C367', l: '汽车零部件及配件制造' },
    { v: 'C3670', l: '汽车零部件及配件制造' },
    { v: 'C37', l: '铁路、船舶、航空航天和其他运输设备制造业' },
    { v: 'C371', l: '铁路运输设备制造' },
    { v: 'C3711', l: '高铁车组制造' },
    { v: 'C3712', l: '铁路机车车辆制造' },
    { v: 'C3713', l: '窄轨机车车辆制造' },
    { v: 'C3714', l: '高铁设备、配件制造' },
    { v: 'C3715', l: '铁路机车车辆配件制造' },
    { v: 'C3716', l: '铁路专用设备及器材、配件制造' },
    { v: 'C3719', l: '其他铁路运输设备制造' },
    { v: 'C372', l: '城市轨道交通设备制造' },
    { v: 'C3720', l: '城市轨道交通设备制造' },
    { v: 'C373', l: '船舶及相关装置制造' },
    { v: 'C3731', l: '金属船舶制造' },
    { v: 'C3732', l: '非金属船舶制造' },
    { v: 'C3733', l: '娱乐船和运动船制造' },
    { v: 'C3734', l: '船用配套设备制造' },
    { v: 'C3735', l: '船舶改装' },
    { v: 'C3736', l: '船舶拆除' },
    { v: 'C3737', l: '海洋工程装备制造' },
    { v: 'C3739', l: '航标器材及其他相关装置制造' },
    { v: 'C374', l: '航空、航天器及设备制造' },
    { v: 'C3741', l: '飞机制造' },
    { v: 'C3742', l: '航天器及运载火箭制造' },
    { v: 'C3743', l: '航天相关设备制造' },
    { v: 'C3744', l: '航空相关设备制造' },
    { v: 'C3749', l: '其他航空航天器制造' },
    { v: 'C375', l: '摩托车制造' },
    { v: 'C3751', l: '摩托车整车制造' },
    { v: 'C3752', l: '摩托车零部件及配件制造' },
    { v: 'C376', l: '自行车和残疾人座车制造' },
    { v: 'C3761', l: '自行车制造' },
    { v: 'C3762', l: '残疾人座车制造' },
    { v: 'C377', l: '助动车制造' },
    { v: 'C3770', l: '助动车制造' },
    { v: 'C378', l: '非公路休闲车及零配件制造' },
    { v: 'C3780', l: '非公路休闲车及零配件制造' },
    { v: 'C379', l: '潜水救捞及其他未列明运输设备制造' },
    { v: 'C3791', l: '潜水装备制造' },
    { v: 'C3792', l: '水下救捞装备制造' },
    { v: 'C3799', l: '其他未列明运输设备制造' },
    { v: 'C38', l: '电气机械和器材制造业' },
    { v: 'C381', l: '电机制造' },
    { v: 'C3811', l: '发电机及发电机组制造' },
    { v: 'C3812', l: '电动机制造' },
    { v: 'C3813', l: '微特电机及组件制造' },
    { v: 'C3819', l: '其他电机制造' },
    { v: 'C382', l: '输配电及控制设备制造' },
    { v: 'C3821', l: '变压器、整流器和电感器制造' },
    { v: 'C3822', l: '电容器及其配套设备制造' },
    { v: 'C3823', l: '配电开关控制设备制造' },
    { v: 'C3824', l: '电力电子元器件制造' },
    { v: 'C3825', l: '光伏设备及元器件制造' },
    { v: 'C3829', l: '其他输配电及控制设备制造' },
    { v: 'C383', l: '电线、电缆、光缆及电工器材制造' },
    { v: 'C3831', l: '电线、电缆制造' },
    { v: 'C3832', l: '光纤制造' },
    { v: 'C3833', l: '光缆制造' },
    { v: 'C3834', l: '绝缘制品制造' },
    { v: 'C3839', l: '其他电工器材制造' },
    { v: 'C384', l: '电池制造' },
    { v: 'C3841', l: '锂离子电池制造' },
    { v: 'C3842', l: '镍氢电池制造' },
    { v: 'C3843', l: '铅蓄电池制造' },
    { v: 'C3844', l: '锌锰电池制造' },
    { v: 'C3849', l: '其他电池制造' },
    { v: 'C385', l: '家用电力器具制造' },
    { v: 'C3851', l: '家用制冷电器具制造' },
    { v: 'C3852', l: '家用空气调节器制造' },
    { v: 'C3853', l: '家用通风电器具制造' },
    { v: 'C3854', l: '家用厨房电器具制造' },
    { v: 'C3855', l: '家用清洁卫生电器具制造' },
    { v: 'C3856', l: '家用美容、保健护理电器具制造' },
    { v: 'C3857', l: '家用电力器具专用配件制造' },
    { v: 'C3859', l: '其他家用电力器具制造' },
    { v: 'C386', l: '非电力家用器具制造' },
    { v: 'C3861', l: '燃气及类似能源家用器具制造' },
    { v: 'C3862', l: '太阳能器具制造' },
    { v: 'C3869', l: '其他非电力家用器具制造' },
    { v: 'C387', l: '照明器具制造' },
    { v: 'C3871', l: '电光源制造' },
    { v: 'C3872', l: '照明灯具制造' },
    { v: 'C3873', l: '舞台及场地用灯制造' },
    { v: 'C3874', l: '智能照明器具制造' },
    { v: 'C3879', l: '灯用电器附件及其他照明器具制造' },
    { v: 'C389', l: '其他电气机械及器材制造' },
    { v: 'C3891', l: '电气信号设备装置制造' },
    { v: 'C3899', l: '其他未列明电气机械及器材制造' },
    { v: 'C39', l: '计算机、通信和其他电子设备制造业' },
    { v: 'C391', l: '计算机制造' },
    { v: 'C3911', l: '计算机整机制造' },
    { v: 'C3912', l: '计算机零部件制造' },
    { v: 'C3913', l: '计算机外围设备制造' },
    { v: 'C3914', l: '工业控制计算机及系统制造' },
    { v: 'C3915', l: '信息安全设备制造' },
    { v: 'C3919', l: '其他计算机制造' },
    { v: 'C392', l: '通信设备制造' },
    { v: 'C3921', l: '通信系统设备制造' },
    { v: 'C3922', l: '通信终端设备制造' },
    { v: 'C393', l: '广播电视设备制造' },
    { v: 'C3931', l: '广播电视节目制作及发射设备制造' },
    { v: 'C3932', l: '广播电视接收设备制造' },
    { v: 'C3933', l: '广播电视专用配件制造' },
    { v: 'C3934', l: '专业音响设备制造' },
    { v: 'C3939', l: '应用电视设备及其他广播电视设备制造' },
    { v: 'C394', l: '雷达及配套设备制造' },
    { v: 'C3940', l: '雷达及配套设备制造' },
    { v: 'C395', l: '视听设备制造' },
    { v: 'C3951', l: '电视机制造' },
    { v: 'C3952', l: '音响设备制造' },
    { v: 'C3953', l: '影视录放设备制造' },
    { v: 'C396', l: '智能消费设备制造' },
    { v: 'C3961', l: '可穿戴智能设备制造' },
    { v: 'C3962', l: '智能车载设备制造' },
    { v: 'C3963', l: '智能无人飞行器制造' },
    { v: 'C3964', l: '服务消费机器人制造' },
    { v: 'C3969', l: '其他智能消费设备制造' },
    { v: 'C397', l: '电子器件制造' },
    { v: 'C3971', l: '电子真空器件制造' },
    { v: 'C3972', l: '半导体分立器件制造' },
    { v: 'C3973', l: '集成电路制造' },
    { v: 'C3974', l: '显示器件制造' },
    { v: 'C3975', l: '半导体照明器件制造' },
    { v: 'C3976', l: '光电子器件制造' },
    { v: 'C3979', l: '其他电子器件制造' },
    { v: 'C398', l: '电子元件及电子专用材料制造' },
    { v: 'C3981', l: '电阻电容电感元件制造' },
    { v: 'C3982', l: '电子电路制造' },
    { v: 'C3983', l: '敏感元件及传感器制造' },
    { v: 'C3984', l: '电声器件及零件制造' },
    { v: 'C3985', l: '电子专用材料制造' },
    { v: 'C3989', l: '其他电子元件制造' },
    { v: 'C399', l: '其他电子设备制造' },
    { v: 'C3990', l: '其他电子设备制造' },
    { v: 'C40', l: '仪器仪表制造业' },
    { v: 'C401', l: '通用仪器仪表制造' },
    { v: 'C4011', l: '工业自动控制系统装置制造' },
    { v: 'C4012', l: '电工仪器仪表制造' },
    { v: 'C4013', l: '绘图、计算及测量仪器制造' },
    { v: 'C4014', l: '实验分析仪器制造' },
    { v: 'C4015', l: '试验机制造' },
    { v: 'C4016', l: '供应用仪器仪表制造' },
    { v: 'C4019', l: '其他通用仪器制造' },
    { v: 'C402', l: '专用仪器仪表制造' },
    { v: 'C4021', l: '环境监测专用仪器仪表制造' },
    { v: 'C4022', l: '运输设备及生产用计数仪表制造' },
    { v: 'C4023', l: '导航、测绘、气象及海洋专用仪器制造' },
    { v: 'C4024', l: '农林牧渔专用仪器仪表制造' },
    { v: 'C4025', l: '地质勘探和地震专用仪器制造' },
    { v: 'C4026', l: '教学专用仪器制造' },
    { v: 'C4027', l: '核子及核辐射测量仪器制造' },
    { v: 'C4028', l: '电子测量仪器制造' },
    { v: 'C4029', l: '其他专用仪器制造' },
    { v: 'C403', l: '钟表与计时仪器制造' },
    { v: 'C4030', l: '钟表与计时仪器制造' },
    { v: 'C404', l: '光学仪器制造' },
    { v: 'C4040', l: '光学仪器制造' },
    { v: 'C405', l: '衡器制造' },
    { v: 'C4050', l: '衡器制造' },
    { v: 'C409', l: '其他仪器仪表制造业' },
    { v: 'C4090', l: '其他仪器仪表制造业' },
    { v: 'C41', l: '其他制造业' },
    { v: 'C411', l: '日用杂品制造' },
    { v: 'C4111', l: '鬃毛加工、制刷及清扫工具制造' },
    { v: 'C4119', l: '其他日用杂品制造' },
    { v: 'C412', l: '核辐射加工' },
    { v: 'C4120', l: '核辐射加工' },
    { v: 'C419', l: '其他未列明制造业' },
    { v: 'C4190', l: '其他未列明制造业' },
    { v: 'C42', l: '废弃资源综合利用业' },
    { v: 'C421', l: '金属废料和碎屑加工处理' },
    { v: 'C4210', l: '金属废料和碎屑加工处理' },
    { v: 'C422', l: '非金属废料和碎屑加工处理' },
    { v: 'C4220', l: '非金属废料和碎屑加工处理' },
    { v: 'C43', l: '金属制品、机械和设备修理业' },
    { v: 'C431', l: '金属制品修理' },
    { v: 'C4310', l: '金属制品修理' },
    { v: 'C432', l: '通用设备修理' },
    { v: 'C4320', l: '通用设备修理' },
    { v: 'C433', l: '专用设备修理' },
    { v: 'C4330', l: '专用设备修理' },
    { v: 'C434', l: '铁路、船舶、航空航天等运输设备修理' },
    { v: 'C4341', l: '铁路运输设备修理' },
    { v: 'C4342', l: '船舶修理' },
    { v: 'C4343', l: '航空航天器修理' },
    { v: 'C4349', l: '其他运输设备修理' },
    { v: 'C435', l: '电气设备修理' },
    { v: 'C4350', l: '电气设备修理' },
    { v: 'C436', l: '仪器仪表修理' },
    { v: 'C4360', l: '仪器仪表修理' },
    { v: 'C439', l: '其他机械和设备修理业' },
    { v: 'C4390', l: '其他机械和设备修理业' },
    { v: 'D', l: '电力、热力、燃气及水生产和供应业' },
    { v: 'D44', l: '电力、热力生产和供应业' },
    { v: 'D441', l: '电力生产' },
    { v: 'D4411', l: '火力发电' },
    { v: 'D4412', l: '热电联产' },
    { v: 'D4413', l: '水力发电' },
    { v: 'D4414', l: '核力发电' },
    { v: 'D4415', l: '风力发电' },
    { v: 'D4416', l: '太阳能发电' },
    { v: 'D4417', l: '生物质能发电' },
    { v: 'D4419', l: '其他电力生产' },
    { v: 'D442', l: '电力供应' },
    { v: 'D4420', l: '电力供应' },
    { v: 'D443', l: '热力生产和供应' },
    { v: 'D4430', l: '热力生产和供应' },
    { v: 'D45', l: '燃气生产和供应业' },
    { v: 'D451', l: '燃气生产和供应业' },
    { v: 'D4511', l: '天然气生产和供应业' },
    { v: 'D4512', l: '液化石油气生产和供应业' },
    { v: 'D4513', l: '煤气生产和供应业' },
    { v: 'D452', l: '生物质燃气生产和供应业' },
    { v: 'D4520', l: '生物质燃气生产和供应业' },
    { v: 'D46', l: '水的生产和供应业' },
    { v: 'D461', l: '自来水生产和供应' },
    { v: 'D4610', l: '自来水生产和供应' },
    { v: 'D462', l: '污水处理及其再生利用' },
    { v: 'D4620', l: '污水处理及其再生利用' },
    { v: 'D463', l: '海水淡化处理' },
    { v: 'D4630', l: '海水淡化处理' },
    { v: 'D469', l: '其他水的处理、利用与分配' },
    { v: 'D4690', l: '其他水的处理、利用与分配' },
    { v: 'E', l: '建筑业' },
    { v: 'E47', l: '房屋建筑业' },
    { v: 'E471', l: '住宅房屋建筑' },
    { v: 'E4710', l: '住宅房屋建筑' },
    { v: 'E472', l: '体育场馆建筑' },
    { v: 'E4720', l: '体育场馆建筑' },
    { v: 'E479', l: '其他房屋建筑业' },
    { v: 'E4790', l: '其他房屋建筑业' },
    { v: 'E48', l: '土木工程建筑业' },
    { v: 'E481', l: '铁路、道路、隧道和桥梁工程建筑' },
    { v: 'E4811', l: '铁路工程建筑' },
    { v: 'E4812', l: '公路工程建筑' },
    { v: 'E4813', l: '市政道路工程建筑' },
    { v: 'E4814', l: '城市轨道交通工程建筑' },
    { v: 'E4819', l: '其他道路、隧道和桥梁工程建筑' },
    { v: 'E482', l: '水利和水运工程建筑' },
    { v: 'E4821', l: '水源及供水设施工程建筑' },
    { v: 'E4822', l: '河湖治理及防洪设施工程建筑' },
    { v: 'E4823', l: '港口及航运设施工程建筑' },
    { v: 'E483', l: '海洋工程建筑' },
    { v: 'E4831', l: '海洋油气资源开发利用工程建筑' },
    { v: 'E4832', l: '海洋能源开发利用工程建筑' },
    { v: 'E4833', l: '海底隧道工程建筑' },
    { v: 'E4834', l: '海底设施铺设工程建筑' },
    { v: 'E4839', l: '其他海洋工程建筑' },
    { v: 'E484', l: '工矿工程建筑' },
    { v: 'E4840', l: '工矿工程建筑' },
    { v: 'E485', l: '架线和管道工程建筑' },
    { v: 'E4851', l: '架线及设备工程建筑' },
    { v: 'E4852', l: '管道工程建筑' },
    { v: 'E4853', l: '地下综合管廊工程建筑' },
    { v: 'E486', l: '节能环保工程施工' },
    { v: 'E4861', l: '节能工程施工' },
    { v: 'E4862', l: '环保工程施工' },
    { v: 'E4863', l: '生态保护工程施工' },
    { v: 'E487', l: '电力工程施工' },
    { v: 'E4871', l: '火力发电工程施工' },
    { v: 'E4872', l: '水力发电工程施工' },
    { v: 'E4873', l: '核电工程施工' },
    { v: 'E4874', l: '风能发电工程施工' },
    { v: 'E4875', l: '太阳能发电工程施工' },
    { v: 'E4879', l: '其他电力工程施工' },
    { v: 'E489', l: '其他土木工程建筑' },
    { v: 'E4891', l: '园林绿化工程施工' },
    { v: 'E4892', l: '体育场地设施工程施工' },
    { v: 'E4893', l: '游乐设施工程施工' },
    { v: 'E4899', l: '其他土木工程建筑施工' },
    { v: 'E49', l: '建筑安装业' },
    { v: 'E491', l: '电气安装' },
    { v: 'E4910', l: '电气安装' },
    { v: 'E492', l: '管道和设备安装' },
    { v: 'E4920', l: '管道和设备安装' },
    { v: 'E499', l: '其他建筑安装业' },
    { v: 'E4991', l: '体育场地设施安装' },
    { v: 'E4999', l: '其他建筑安装' },
    { v: 'E50', l: '建筑装饰、装修和其他建筑业' },
    { v: 'E501', l: '建筑装饰和装修业' },
    { v: 'E5011', l: '公共建筑装饰和装修' },
    { v: 'E5012', l: '住宅装饰和装修' },
    { v: 'E5013', l: '建筑幕墙装饰和装修' },
    { v: 'E502', l: '建筑物拆除和场地准备活动' },
    { v: 'E5021', l: '建筑物拆除活动' },
    { v: 'E5022', l: '场地准备活动' },
    { v: 'E503', l: '提供施工设备服务' },
    { v: 'E5030', l: '提供施工设备服务' },
    { v: 'E509', l: '其他未列明建筑业' },
    { v: 'E5090', l: '其他未列明建筑业' },
    { v: 'F', l: '批发和零售业' },
    { v: 'F51', l: '批发业' },
    { v: 'F511', l: '农、林、牧、渔产品批发' },
    { v: 'F5111', l: '谷物、豆及薯类批发' },
    { v: 'F5112', l: '种子批发' },
    { v: 'F5113', l: '畜牧渔业饲料批发' },
    { v: 'F5114', l: '棉、麻批发' },
    { v: 'F5115', l: '林业产品批发' },
    { v: 'F5116', l: '牲畜批发' },
    { v: 'F5117', l: '渔业产品批发' },
    { v: 'F5119', l: '其他农牧产品批发' },
    { v: 'F512', l: '食品、饮料及烟草制品批发' },
    { v: 'F5121', l: '米、面制品及食用油批发' },
    { v: 'F5122', l: '糕点、糖果及糖批发' },
    { v: 'F5123', l: '果品、蔬菜批发' },
    { v: 'F5124', l: '肉、禽、蛋、奶及水产品批发' },
    { v: 'F5125', l: '盐及调味品批发' },
    { v: 'F5126', l: '营养和保健品批发' },
    { v: 'F5127', l: '酒、饮料及茶叶批发' },
    { v: 'F5128', l: '烟草制品批发' },
    { v: 'F5129', l: '其他食品批发' },
    { v: 'F513', l: '纺织、服装及家庭用品批发' },
    { v: 'F5131', l: '纺织品、针织品及原料批发' },
    { v: 'F5132', l: '服装批发' },
    { v: 'F5133', l: '鞋帽批发' },
    { v: 'F5134', l: '化妆品及卫生用品批发' },
    { v: 'F5135', l: '厨具卫具及日用杂品批发' },
    { v: 'F5136', l: '灯具、装饰物品批发' },
    { v: 'F5137', l: '家用视听设备批发' },
    { v: 'F5138', l: '日用家电批发' },
    { v: 'F5139', l: '其他家庭用品批发' },
    { v: 'F514', l: '文化、体育用品及器材批发' },
    { v: 'F5141', l: '文具用品批发' },
    { v: 'F5142', l: '体育用品及器材批发' },
    { v: 'F5143', l: '图书批发' },
    { v: 'F5144', l: '报刊批发' },
    { v: 'F5145', l: '音像制品、电子和数字出版物批发' },
    { v: 'F5146', l: '首饰、工艺品及收藏品批发' },
    { v: 'F5147', l: '乐器批发' },
    { v: 'F5149', l: '其他文化用品批发' },
    { v: 'F515', l: '医药及医疗器材批发' },
    { v: 'F5151', l: '西药批发' },
    { v: 'F5152', l: '中药批发' },
    { v: 'F5153', l: '动物用药品批发' },
    { v: 'F5154', l: '医疗用品及器材批发' },
    { v: 'F516', l: '矿产品、建材及化工产品批发' },
    { v: 'F5161', l: '煤炭及制品批发' },
    { v: 'F5162', l: '石油及制品批发' },
    { v: 'F5163', l: '非金属矿及制品批发' },
    { v: 'F5164', l: '金属及金属矿批发' },
    { v: 'F5165', l: '建材批发' },
    { v: 'F5166', l: '化肥批发' },
    { v: 'F5167', l: '农药批发' },
    { v: 'F5168', l: '农用薄膜批发' },
    { v: 'F5169', l: '其他化工产品批发' },
    { v: 'F517', l: '机械设备、五金产品及电子产品批发' },
    { v: 'F5171', l: '农业机械批发' },
    { v: 'F5172', l: '汽车及零配件批发' },
    { v: 'F5173', l: '摩托车及零配件批发' },
    { v: 'F5174', l: '五金产品批发' },
    { v: 'F5175', l: '电气设备批发' },
    { v: 'F5176', l: '计算机、软件及辅助设备批发' },
    { v: 'F5177', l: '通讯设备批发' },
    { v: 'F5178', l: '广播影视设备批发' },
    { v: 'F5179', l: '其他机械设备及电子产品批发' },
    { v: 'F518', l: '贸易经纪与代理' },
    { v: 'F5181', l: '贸易代理' },
    { v: 'F5182', l: '一般物品拍卖' },
    { v: 'F5183', l: '艺术品、收藏品拍卖' },
    { v: 'F5184', l: '艺术品代理' },
    { v: 'F5189', l: '其他贸易经纪与代理' },
    { v: 'F519', l: '其他批发业' },
    { v: 'F5191', l: '再生物资回收与批发' },
    { v: 'F5192', l: '宠物食品用品批发' },
    { v: 'F5193', l: '互联网批发' },
    { v: 'F5199', l: '其他未列明批发业' },
    { v: 'F52', l: '零售业' },
    { v: 'F521', l: '综合零售' },
    { v: 'F5211', l: '百货零售' },
    { v: 'F5212', l: '超级市场零售' },
    { v: 'F5213', l: '便利店零售' },
    { v: 'F5219', l: '其他综合零售' },
    { v: 'F522', l: '食品、饮料及烟草制品专门零售' },
    { v: 'F5221', l: '粮油零售' },
    { v: 'F5222', l: '糕点、面包零售' },
    { v: 'F5223', l: '果品、蔬菜零售' },
    { v: 'F5224', l: '肉、禽、蛋、奶及水产品零售' },
    { v: 'F5225', l: '营养和保健品零售' },
    { v: 'F5226', l: '酒、饮料及茶叶零售' },
    { v: 'F5227', l: '烟草制品零售' },
    { v: 'F5229', l: '其他食品零售' },
    { v: 'F523', l: '纺织、服装及日用品专门零售' },
    { v: 'F5231', l: '纺织品及针织品零售' },
    { v: 'F5232', l: '服装零售' },
    { v: 'F5233', l: '鞋帽零售' },
    { v: 'F5234', l: '化妆品及卫生用品零售' },
    { v: 'F5235', l: '厨具卫具及日用杂品零售' },
    { v: 'F5236', l: '钟表、眼镜零售' },
    { v: 'F5237', l: '箱包零售' },
    { v: 'F5238', l: '自行车等代步设备零售' },
    { v: 'F5239', l: '其他日用品零售' },
    { v: 'F524', l: '文化、体育用品及器材专门零售' },
    { v: 'F5241', l: '文具用品零售' },
    { v: 'F5242', l: '体育用品及器材零售' },
    { v: 'F5243', l: '图书、报刊零售' },
    { v: 'F5244', l: '音像制品、电子和数字出版物零售' },
    { v: 'F5245', l: '珠宝首饰零售' },
    { v: 'F5246', l: '工艺美术品及收藏品零售' },
    { v: 'F5247', l: '乐器零售' },
    { v: 'F5248', l: '照相器材零售' },
    { v: 'F5249', l: '其他文化用品零售' },
    { v: 'F525', l: '医药及医疗器材专门零售' },
    { v: 'F5251', l: '西药零售' },
    { v: 'F5252', l: '中药零售' },
    { v: 'F5253', l: '动物用药品零售' },
    { v: 'F5254', l: '医疗用品及器材零售' },
    { v: 'F5255', l: '保健辅助治疗器材零售' },
    { v: 'F526', l: '汽车、摩托车、零配件和燃料及其他动力销售' },
    { v: 'F5261', l: '汽车新车零售' },
    { v: 'F5262', l: '汽车旧车零售' },
    { v: 'F5263', l: '汽车零配件零售' },
    { v: 'F5264', l: '摩托车及零配件零售' },
    { v: 'F5265', l: '机动车燃油零售' },
    { v: 'F5266', l: '机动车燃气零售' },
    { v: 'F5267', l: '机动车充电销售' },
    { v: 'F527', l: '家用电器及电子产品专门零售' },
    { v: 'F5271', l: '家用视听设备零售' },
    { v: 'F5272', l: '日用家电零售' },
    { v: 'F5273', l: '计算机、软件及辅助设备零售' },
    { v: 'F5274', l: '通信设备零售' },
    { v: 'F5279', l: '其他电子产品零售' },
    { v: 'F528', l: '五金、家具及室内装饰材料专门零售' },
    { v: 'F5281', l: '五金零售' },
    { v: 'F5282', l: '灯具零售' },
    { v: 'F5283', l: '家具零售' },
    { v: 'F5284', l: '涂料零售' },
    { v: 'F5285', l: '卫生洁具零售' },
    { v: 'F5286', l: '木质装饰材料零售' },
    { v: 'F5287', l: '陶瓷、石材装饰材料零售' },
    { v: 'F5289', l: '其他室内装饰材料零售' },
    { v: 'F529', l: '货摊、无店铺及其他零售业' },
    { v: 'F5291', l: '流动货摊零售' },
    { v: 'F5292', l: '互联网零售' },
    { v: 'F5293', l: '邮购及电视、电话零售' },
    { v: 'F5294', l: '自动售货机零售' },
    { v: 'F5295', l: '旧货零售' },
    { v: 'F5296', l: '生活用燃料零售' },
    { v: 'F5297', l: '宠物食品用品零售' },
    { v: 'F5299', l: '其他未列明零售业' },
    { v: 'G', l: '交通运输、仓储和邮政业' },
    { v: 'G53', l: '铁路运输业' },
    { v: 'G531', l: '铁路旅客运输' },
    { v: 'G5311', l: '高速铁路旅客运输' },
    { v: 'G5312', l: '城际铁路旅客运输' },
    { v: 'G5313', l: '普通铁路旅客运输' },
    { v: 'G532', l: '铁路货物运输' },
    { v: 'G5320', l: '铁路货物运输' },
    { v: 'G533', l: '铁路运输辅助活动' },
    { v: 'G5331', l: '客运火车站' },
    { v: 'G5332', l: '货运火车站（场）' },
    { v: 'G5333', l: '铁路运输维护活动' },
    { v: 'G5339', l: '其他铁路运输辅助活动' },
    { v: 'G54', l: '道路运输业' },
    { v: 'G541', l: '城市公共交通运输' },
    { v: 'G5411', l: '公共电汽车客运' },
    { v: 'G5412', l: '城市轨道交通' },
    { v: 'G5413', l: '出租车客运' },
    { v: 'G5414', l: '公共自行车服务' },
    { v: 'G5419', l: '其他城市公共交通运输' },
    { v: 'G542', l: '公路旅客运输' },
    { v: 'G5421', l: '长途客运' },
    { v: 'G5422', l: '旅游客运' },
    { v: 'G5429', l: '其他公路客运' },
    { v: 'G543', l: '道路货物运输' },
    { v: 'G5431', l: '普通货物道路运输' },
    { v: 'G5432', l: '冷藏车道路运输' },
    { v: 'G5433', l: '集装箱道路运输' },
    { v: 'G5434', l: '大型货物道路运输' },
    { v: 'G5435', l: '危险货物道路运输' },
    { v: 'G5436', l: '邮件包裹道路运输' },
    { v: 'G5437', l: '城市配送' },
    { v: 'G5438', l: '搬家运输' },
    { v: 'G5439', l: '其他道路货物运输' },
    { v: 'G544', l: '道路运输辅助活动' },
    { v: 'G5441', l: '客运汽车站' },
    { v: 'G5442', l: '货运枢纽（站）' },
    { v: 'G5443', l: '公路管理与养护' },
    { v: 'G5449', l: '其他道路运输辅助活动' },
    { v: 'G55', l: '水上运输业' },
    { v: 'G551', l: '水上旅客运输' },
    { v: 'G5511', l: '海上旅客运输' },
    { v: 'G5512', l: '内河旅客运输' },
    { v: 'G5513', l: '客运轮渡运输' },
    { v: 'G552', l: '水上货物运输' },
    { v: 'G5521', l: '远洋货物运输' },
    { v: 'G5522', l: '沿海货物运输' },
    { v: 'G5523', l: '内河货物运输' },
    { v: 'G553', l: '水上运输辅助活动' },
    { v: 'G5531', l: '客运港口' },
    { v: 'G5532', l: '货运港口' },
    { v: 'G5539', l: '其他水上运输辅助活动' },
    { v: 'G56', l: '航空运输业' },
    { v: 'G561', l: '航空客货运输' },
    { v: 'G5611', l: '航空旅客运输' },
    { v: 'G5612', l: '航空货物运输' },
    { v: 'G562', l: '通用航空服务' },
    { v: 'G5621', l: '通用航空生产服务' },
    { v: 'G5622', l: '观光游览航空服务' },
    { v: 'G5623', l: '体育航空运动服务' },
    { v: 'G5629', l: '其他通用航空服务' },
    { v: 'G563', l: '航空运输辅助活动' },
    { v: 'G5631', l: '机场' },
    { v: 'G5632', l: '空中交通管理' },
    { v: 'G5639', l: '其他航空运输辅助活动' },
    { v: 'G57', l: '管道运输业' },
    { v: 'G571', l: '海底管道运输' },
    { v: 'G5710', l: '海底管道运输' },
    { v: 'G572', l: '陆地管道运输' },
    { v: 'G5720', l: '陆地管道运输' },
    { v: 'G58', l: '多式联运和运输代理业' },
    { v: 'G581', l: '多式联运' },
    { v: 'G5810', l: '多式联运' },
    { v: 'G582', l: '运输代理业' },
    { v: 'G5821', l: '货物运输代理' },
    { v: 'G5822', l: '旅客票务代理' },
    { v: 'G5829', l: '其他运输代理业' },
    { v: 'G59', l: '装卸搬运和仓储业' },
    { v: 'G591', l: '装卸搬运' },
    { v: 'G5910', l: '装卸搬运' },
    { v: 'G592', l: '通用仓储' },
    { v: 'G5920', l: '通用仓储' },
    { v: 'G593', l: '低温仓储' },
    { v: 'G5930', l: '低温仓储' },
    { v: 'G594', l: '危险品仓储' },
    { v: 'G5941', l: '油气仓储' },
    { v: 'G5942', l: '危险化学品仓储' },
    { v: 'G5949', l: '其他危险品仓储' },
    { v: 'G595', l: '谷物、棉花等农产品仓储' },
    { v: 'G5951', l: '谷物仓储' },
    { v: 'G5952', l: '棉花仓储' },
    { v: 'G5959', l: '其他农产品仓储' },
    { v: 'G596', l: '中药材仓储' },
    { v: 'G5960', l: '中药材仓储' },
    { v: 'G599', l: '其他仓储业' },
    { v: 'G5990', l: '其他仓储业' },
    { v: 'G60', l: '邮政业' },
    { v: 'G601', l: '邮政基本服务' },
    { v: 'G6010', l: '邮政基本服务' },
    { v: 'G602', l: '快递服务' },
    { v: 'G6020', l: '快递服务' },
    { v: 'G609', l: '其他寄递服务' },
    { v: 'G6090', l: '其他寄递服务' },
    { v: 'H', l: '住宿和餐饮业' },
    { v: 'H61', l: '住宿业' },
    { v: 'H611', l: '旅游饭店' },
    { v: 'H6110', l: '旅游饭店' },
    { v: 'H612', l: '一般旅馆' },
    { v: 'H6121', l: '经济型连锁酒店' },
    { v: 'H6129', l: '其他一般旅馆' },
    { v: 'H613', l: '民宿服务' },
    { v: 'H6130', l: '民宿服务' },
    { v: 'H614', l: '露营地服务' },
    { v: 'H6140', l: '露营地服务' },
    { v: 'H619', l: '其他住宿业' },
    { v: 'H6190', l: '其他住宿业' },
    { v: 'H62', l: '餐饮业' },
    { v: 'H621', l: '正餐服务' },
    { v: 'H6210', l: '正餐服务' },
    { v: 'H622', l: '快餐服务' },
    { v: 'H6220', l: '快餐服务' },
    { v: 'H623', l: '饮料及冷饮服务' },
    { v: 'H6231', l: '茶馆服务' },
    { v: 'H6232', l: '咖啡馆服务' },
    { v: 'H6233', l: '酒吧服务' },
    { v: 'H6239', l: '其他饮料及冷饮服务' },
    { v: 'H624', l: '餐饮配送及外卖送餐服务' },
    { v: 'H6241', l: '餐饮配送服务' },
    { v: 'H6242', l: '外卖送餐服务' },
    { v: 'H629', l: '其他餐饮业' },
    { v: 'H6291', l: '小吃服务' },
    { v: 'H6299', l: '其他未列明餐饮业' },
    { v: 'I', l: '信息传输、软件和信息技术服务业' },
    { v: 'I63', l: '电信、广播电视和卫星传输服务' },
    { v: 'I631', l: '电信' },
    { v: 'I6311', l: '固定电信服务' },
    { v: 'I6312', l: '移动电信服务' },
    { v: 'I6319', l: '其他电信服务' },
    { v: 'I632', l: '广播电视传输服务' },
    { v: 'I6321', l: '有线广播电视传输服务' },
    { v: 'I6322', l: '无线广播电视传输服务' },
    { v: 'I633', l: '卫星传输服务' },
    { v: 'I6331', l: '广播电视卫星传输服务' },
    { v: 'I6339', l: '其他卫星传输服务' },
    { v: 'I64', l: '互联网和相关服务' },
    { v: 'I641', l: '互联网接入及相关服务' },
    { v: 'I6410', l: '互联网接入及相关服务' },
    { v: 'I642', l: '互联网信息服务' },
    { v: 'I6421', l: '互联网搜索服务' },
    { v: 'I6422', l: '互联网游戏服务' },
    { v: 'I6429', l: '互联网其他信息服务' },
    { v: 'I643', l: '互联网平台' },
    { v: 'I6431', l: '互联网生产服务平台' },
    { v: 'I6432', l: '互联网生活服务平台' },
    { v: 'I6433', l: '互联网科技创新平台' },
    { v: 'I6434', l: '互联网公共服务平台' },
    { v: 'I6439', l: '其他互联网平台' },
    { v: 'I644', l: '互联网安全服务' },
    { v: 'I6440', l: '互联网安全服务' },
    { v: 'I645', l: '互联网数据服务' },
    { v: 'I6450', l: '互联网数据服务' },
    { v: 'I649', l: '其他互联网服务' },
    { v: 'I6490', l: '其他互联网服务' },
    { v: 'I65', l: '软件和信息技术服务业' },
    { v: 'I651', l: '软件开发' },
    { v: 'I6511', l: '基础软件开发' },
    { v: 'I6512', l: '支撑软件开发' },
    { v: 'I6513', l: '应用软件开发' },
    { v: 'I6519', l: '其他软件开发' },
    { v: 'I652', l: '集成电路设计' },
    { v: 'I6520', l: '集成电路设计' },
    { v: 'I653', l: '信息系统集成和物联网技术服务' },
    { v: 'I6531', l: '信息系统集成服务' },
    { v: 'I6532', l: '物联网技术服务' },
    { v: 'I654', l: '运行维护服务' },
    { v: 'I6540', l: '运行维护服务' },
    { v: 'I655', l: '信息处理和存储支持服务' },
    { v: 'I6550', l: '信息处理和存储支持服务' },
    { v: 'I656', l: '信息技术咨询服务' },
    { v: 'I6560', l: '信息技术咨询服务' },
    { v: 'I657', l: '数字内容服务' },
    { v: 'I6571', l: '地理遥感信息服务' },
    { v: 'I6572', l: '动漫、游戏数字内容服务' },
    { v: 'I6579', l: '其他数字内容服务' },
    { v: 'I659', l: '其他信息技术服务业' },
    { v: 'I6591', l: '呼叫中心' },
    { v: 'I6599', l: '其他未列明信息技术服务业' },
    { v: 'J', l: '金融业' },
    { v: 'J66', l: '货币金融服务' },
    { v: 'J661', l: '中央银行服务' },
    { v: 'J6610', l: '中央银行服务' },
    { v: 'J662', l: '货币银行服务' },
    { v: 'J6621', l: '商业银行服务' },
    { v: 'J6622', l: '政策性银行服务' },
    { v: 'J6623', l: '信用合作社服务' },
    { v: 'J6624', l: '农村资金互助社服务' },
    { v: 'J6629', l: '其他货币银行服务' },
    { v: 'J663', l: '非货币银行服务' },
    { v: 'J6631', l: '融资租赁服务' },
    { v: 'J6632', l: '财务公司服务' },
    { v: 'J6633', l: '典当' },
    { v: 'J6634', l: '汽车金融公司服务' },
    { v: 'J6635', l: '小额贷款公司服务' },
    { v: 'J6636', l: '消费金融公司服务' },
    { v: 'J6637', l: '网络借贷服务' },
    { v: 'J6639', l: '其他非货币银行服务' },
    { v: 'J664', l: '银行理财服务' },
    { v: 'J6640', l: '银行理财服务' },
    { v: 'J665', l: '银行监管服务' },
    { v: 'J6650', l: '银行监管服务' },
    { v: 'J67', l: '资本市场服务' },
    { v: 'J671', l: '证券市场服务' },
    { v: 'J6711', l: '证券市场管理服务' },
    { v: 'J6712', l: '证券经纪交易服务' },
    { v: 'J672', l: '公开募集证券投资基金' },
    { v: 'J6720', l: '公开募集证券投资基金' },
    { v: 'J673', l: '非公开募集证券投资基金' },
    { v: 'J6731', l: '创业投资基金' },
    { v: 'J6732', l: '天使投资' },
    { v: 'J6739', l: '其他非公开募集证券投资基金' },
    { v: 'J674', l: '期货市场服务' },
    { v: 'J6741', l: '期货市场管理服务' },
    { v: 'J6749', l: '其他期货市场服务' },
    { v: 'J675', l: '证券期货监管服务' },
    { v: 'J6750', l: '证券期货监管服务' },
    { v: 'J676', l: '资本投资服务' },
    { v: 'J6760', l: '资本投资服务' },
    { v: 'J679', l: '其他资本市场服务' },
    { v: 'J6790', l: '其他资本市场服务' },
    { v: 'J68', l: '保险业' },
    { v: 'J681', l: '人身保险' },
    { v: 'J6811', l: '人寿保险' },
    { v: 'J6812', l: '年金保险' },
    { v: 'J6813', l: '健康保险' },
    { v: 'J6814', l: '意外伤害保险' },
    { v: 'J682', l: '财产保险' },
    { v: 'J6820', l: '财产保险' },
    { v: 'J683', l: '再保险' },
    { v: 'J6830', l: '再保险' },
    { v: 'J684', l: '商业养老金' },
    { v: 'J6840', l: '商业养老金' },
    { v: 'J685', l: '保险中介服务' },
    { v: 'J6851', l: '保险经纪服务' },
    { v: 'J6852', l: '保险代理服务' },
    { v: 'J6853', l: '保险公估服务' },
    { v: 'J686', l: '保险资产管理' },
    { v: 'J6860', l: '保险资产管理' },
    { v: 'J687', l: '保险监管服务' },
    { v: 'J6870', l: '保险监管服务' },
    { v: 'J689', l: '其他保险活动' },
    { v: 'J6890', l: '其他保险活动' },
    { v: 'J69', l: '其他金融业' },
    { v: 'J691', l: '金融信托与管理服务' },
    { v: 'J6911', l: '信托公司' },
    { v: 'J6919', l: '其他金融信托与管理服务' },
    { v: 'J692', l: '控股公司服务' },
    { v: 'J6920', l: '控股公司服务' },
    { v: 'J693', l: '非金融机构支付服务' },
    { v: 'J6930', l: '非金融机构支付服务' },
    { v: 'J694', l: '金融信息服务' },
    { v: 'J6940', l: '金融信息服务' },
    { v: 'J695', l: '金融资产管理公司' },
    { v: 'J6950', l: '金融资产管理公司' },
    { v: 'J699', l: '其他未列明金融业' },
    { v: 'J6991', l: '货币经纪公司服务' },
    { v: 'J6999', l: '其他未包括金融业' },
    { v: 'K', l: '房地产业' },
    { v: 'K70', l: '房地产业' },
    { v: 'K701', l: '房地产开发经营' },
    { v: 'K7010', l: '房地产开发经营' },
    { v: 'K702', l: '物业管理' },
    { v: 'K7020', l: '物业管理' },
    { v: 'K703', l: '房地产中介服务' },
    { v: 'K7030', l: '房地产中介服务' },
    { v: 'K704', l: '房地产租赁经营' },
    { v: 'K7040', l: '房地产租赁经营' },
    { v: 'K709', l: '其他房地产业' },
    { v: 'K7090', l: '其他房地产业' },
    { v: 'L', l: '租赁和商务服务业' },
    { v: 'L71', l: '租赁业' },
    { v: 'L711', l: '机械设备经营租赁' },
    { v: 'L7111', l: '汽车租赁' },
    { v: 'L7112', l: '农业机械经营租赁' },
    { v: 'L7113', l: '建筑工程机械与设备经营租赁' },
    { v: 'L7114', l: '计算机及通讯设备经营租赁' },
    { v: 'L7115', l: '医疗设备经营租赁' },
    { v: 'L7119', l: '其他机械与设备经营租赁' },
    { v: 'L712', l: '文体设备和用品出租' },
    { v: 'L7121', l: '休闲娱乐用品设备出租' },
    { v: 'L7122', l: '体育用品设备出租' },
    { v: 'L7123', l: '文化用品设备出租' },
    { v: 'L7124', l: '图书出租' },
    { v: 'L7125', l: '音像制品出租' },
    { v: 'L7129', l: '其他文体设备和用品出租' },
    { v: 'L713', l: '日用品出租' },
    { v: 'L7130', l: '日用品出租' },
    { v: 'L72', l: '商务服务业' },
    { v: 'L721', l: '组织管理服务' },
    { v: 'L7211', l: '企业总部管理' },
    { v: 'L7212', l: '投资与资产管理' },
    { v: 'L7213', l: '资源与产权交易服务' },
    { v: 'L7214', l: '单位后勤管理服务' },
    { v: 'L7215', l: '农村集体经济组织管理' },
    { v: 'L7219', l: '其他组织管理服务' },
    { v: 'L722', l: '综合管理服务' },
    { v: 'L7221', l: '园区管理服务' },
    { v: 'L7222', l: '商业综合体管理服务' },
    { v: 'L7223', l: '市场管理服务' },
    { v: 'L7224', l: '供应链管理服务' },
    { v: 'L7229', l: '其他综合管理服务' },
    { v: 'L723', l: '法律服务' },
    { v: 'L7231', l: '律师及相关法律服务' },
    { v: 'L7232', l: '公证服务' },
    { v: 'L7239', l: '其他法律服务' },
    { v: 'L724', l: '咨询与调查' },
    { v: 'L7241', l: '会计、审计及税务服务' },
    { v: 'L7242', l: '市场调查' },
    { v: 'L7243', l: '社会经济咨询' },
    { v: 'L7244', l: '健康咨询' },
    { v: 'L7245', l: '环保咨询' },
    { v: 'L7246', l: '体育咨询' },
    { v: 'L7249', l: '其他专业咨询与调查' },
    { v: 'L725', l: '广告业' },
    { v: 'L7251', l: '互联网广告服务' },
    { v: 'L7259', l: '其他广告服务' },
    { v: 'L726', l: '人力资源服务' },
    { v: 'L7261', l: '公共就业服务' },
    { v: 'L7262', l: '职业中介服务' },
    { v: 'L7263', l: '劳务派遣服务' },
    { v: 'L7264', l: '创业指导服务' },
    { v: 'L7269', l: '其他人力资源服务' },
    { v: 'L727', l: '安全保护服务' },
    { v: 'L7271', l: '安全服务' },
    { v: 'L7272', l: '安全系统监控服务' },
    { v: 'L7279', l: '其他安全保护服务' },
    { v: 'L728', l: '会议、展览及相关服务' },
    { v: 'L7281', l: '科技会展服务' },
    { v: 'L7282', l: '旅游会展服务' },
    { v: 'L7283', l: '体育会展服务' },
    { v: 'L7284', l: '文化会展服务' },
    { v: 'L7289', l: '其他会议、展览及相关服务' },
    { v: 'L729', l: '其他商务服务业' },
    { v: 'L7291', l: '旅行社及相关服务' },
    { v: 'L7292', l: '包装服务' },
    { v: 'L7293', l: '办公服务' },
    { v: 'L7294', l: '翻译服务' },
    { v: 'L7295', l: '信用服务' },
    { v: 'L7296', l: '非融资担保服务' },
    { v: 'L7297', l: '商务代理代办服务' },
    { v: 'L7298', l: '票务代理服务' },
    { v: 'L7299', l: '其他未列明商务服务业' },
    { v: 'M', l: '科学研究和技术服务业' },
    { v: 'M73', l: '研究和试验发展' },
    { v: 'M731', l: '自然科学研究和试验发展' },
    { v: 'M7310', l: '自然科学研究和试验发展' },
    { v: 'M732', l: '工程和技术研究和试验发展' },
    { v: 'M7320', l: '工程和技术研究和试验发展' },
    { v: 'M733', l: '农业科学研究和试验发展' },
    { v: 'M7330', l: '农业科学研究和试验发展' },
    { v: 'M734', l: '医学研究和试验发展' },
    { v: 'M7340', l: '医学研究和试验发展' },
    { v: 'M735', l: '社会人文科学研究' },
    { v: 'M7350', l: '社会人文科学研究' },
    { v: 'M74', l: '专业技术服务业' },
    { v: 'M741', l: '气象服务' },
    { v: 'M7410', l: '气象服务' },
    { v: 'M742', l: '地震服务' },
    { v: 'M7420', l: '地震服务' },
    { v: 'M743', l: '海洋服务' },
    { v: 'M7431', l: '海洋气象服务' },
    { v: 'M7432', l: '海洋环境服务' },
    { v: 'M7439', l: '其他海洋服务' },
    { v: 'M744', l: '测绘地理信息服务' },
    { v: 'M7441', l: '遥感测绘服务' },
    { v: 'M7449', l: '其他测绘地理信息服务' },
    { v: 'M745', l: '质检技术服务' },
    { v: 'M7451', l: '检验检疫服务' },
    { v: 'M7452', l: '检测服务' },
    { v: 'M7453', l: '计量服务' },
    { v: 'M7454', l: '标准化服务' },
    { v: 'M7455', l: '认证认可服务' },
    { v: 'M7459', l: '其他质检技术服务' },
    { v: 'M746', l: '环境与生态监测' },
    { v: 'M7461', l: '环境保护监测' },
    { v: 'M7462', l: '生态资源监测' },
    { v: 'M7463', l: '野生动物疫源疫病防控监测' },
    { v: 'M747', l: '地质勘查' },
    { v: 'M7471', l: '能源矿产地质勘查' },
    { v: 'M7472', l: '固体矿产地质勘查' },
    { v: 'M7473', l: '水、二氧化碳等矿产地质勘查' },
    { v: 'M7474', l: '基础地质勘查' },
    { v: 'M7475', l: '地质勘查技术服务' },
    { v: 'M748', l: '工程技术与设计服务' },
    { v: 'M7481', l: '工程管理服务' },
    { v: 'M7482', l: '工程监理服务' },
    { v: 'M7483', l: '工程勘察活动' },
    { v: 'M7484', l: '工程设计活动' },
    { v: 'M7485', l: '规划设计管理' },
    { v: 'M7486', l: '土地规划服务' },
    { v: 'M749', l: '工业与专业设计及其他专业技术服务' },
    { v: 'M7491', l: '工业设计服务' },
    { v: 'M7492', l: '专业设计服务' },
    { v: 'M7493', l: '兽医服务' },
    { v: 'M7499', l: '其他未列明专业技术服务业' },
    { v: 'M75', l: '科技推广和应用服务' },
    { v: 'M751', l: '技术推广服务' },
    { v: 'M7511', l: '农林牧渔技术推广服务' },
    { v: 'M7512', l: '生物技术推广服务' },
    { v: 'M7513', l: '新材料技术推广服务' },
    { v: 'M7514', l: '节能技术推广服务' },
    { v: 'M7515', l: '新能源技术推广服务' },
    { v: 'M7516', l: '环保技术推广服务' },
    { v: 'M7517', l: '三维（3D）打印技术推广服务' },
    { v: 'M7519', l: '其他技术推广服务' },
    { v: 'M752', l: '知识产权服务' },
    { v: 'M7520', l: '知识产权服务' },
    { v: 'M753', l: '科技中介服务' },
    { v: 'M7530', l: '科技中介服务' },
    { v: 'M754', l: '创业空间服务' },
    { v: 'M7540', l: '创业空间服务' },
    { v: 'M759', l: '其他科技推广服务业' },
    { v: 'M7590', l: '其他科技推广服务业' },
    { v: 'N', l: '水利、环境和公共设施管理业' },
    { v: 'N76', l: '水利管理业' },
    { v: 'N761', l: '防洪除涝设施管理' },
    { v: 'N7610', l: '防洪除涝设施管理' },
    { v: 'N762', l: '水资源管理' },
    { v: 'N7620', l: '水资源管理' },
    { v: 'N763', l: '天然水收集与分配' },
    { v: 'N7630', l: '天然水收集与分配' },
    { v: 'N764', l: '水文服务' },
    { v: 'N7640', l: '水文服务' },
    { v: 'N769', l: '其他水利管理业' },
    { v: 'N7690', l: '其他水利管理业' },
    { v: 'N77', l: '生态保护和环境治理业' },
    { v: 'N771', l: '生态保护' },
    { v: 'N7711', l: '自然生态系统保护管理' },
    { v: 'N7712', l: '自然遗迹保护管理' },
    { v: 'N7713', l: '野生动物保护' },
    { v: 'N7714', l: '野生植物保护' },
    { v: 'N7715', l: '动物园、水族馆管理服务' },
    { v: 'N7716', l: '植物园管理服务' },
    { v: 'N7719', l: '其他自然保护' },
    { v: 'N772', l: '环境治理业' },
    { v: 'N7721', l: '水污染治理' },
    { v: 'N7722', l: '大气污染治理' },
    { v: 'N7723', l: '固体废物治理' },
    { v: 'N7724', l: '危险废物治理' },
    { v: 'N7725', l: '放射性废物治理' },
    { v: 'N7726', l: '土壤污染治理与修复服务' },
    { v: 'N7727', l: '噪声与振动控制服务' },
    { v: 'N7729', l: '其他污染治理' },
    { v: 'N78', l: '公共设施管理业' },
    { v: 'N781', l: '市政设施管理' },
    { v: 'N7810', l: '市政设施管理' },
    { v: 'N782', l: '环境卫生管理' },
    { v: 'N7820', l: '环境卫生管理' },
    { v: 'N783', l: '城乡市容管理' },
    { v: 'N7830', l: '城乡市容管理' },
    { v: 'N784', l: '绿化管理' },
    { v: 'N7840', l: '绿化管理' },
    { v: 'N785', l: '城市公园管理' },
    { v: 'N7850', l: '城市公园管理' },
    { v: 'N786', l: '游览景区管理' },
    { v: 'N7861', l: '名胜风景区管理' },
    { v: 'N7862', l: '森林公园管理' },
    { v: 'N7869', l: '其他游览景区管理' },
    { v: 'N79', l: '土地管理业' },
    { v: 'N791', l: '土地整治服务' },
    { v: 'N7910', l: '土地整治服务' },
    { v: 'N792', l: '土地调查评估服务' },
    { v: 'N7920', l: '土地调查评估服务' },
    { v: 'N793', l: '土地登记服务' },
    { v: 'N7930', l: '土地登记服务' },
    { v: 'N794', l: '土地登记代理服务' },
    { v: 'N7940', l: '土地登记代理服务' },
    { v: 'N799', l: '其他土地管理服务' },
    { v: 'N7990', l: '其他土地管理服务' },
    { v: 'O', l: '居民服务、修理和其他服务业' },
    { v: 'O80', l: '居民服务业' },
    { v: 'O801', l: '家庭服务' },
    { v: 'O8010', l: '家庭服务' },
    { v: 'O802', l: '托儿所服务' },
    { v: 'O8020', l: '托儿所服务' },
    { v: 'O803', l: '洗染服务' },
    { v: 'O8030', l: '洗染服务' },
    { v: 'O804', l: '理发及美容服务' },
    { v: 'O8040', l: '理发及美容服务' },
    { v: 'O805', l: '洗浴和保健养生服务' },
    { v: 'O8051', l: '洗浴服务' },
    { v: 'O8052', l: '足浴服务' },
    { v: 'O8053', l: '养生保健服务' },
    { v: 'O806', l: '摄影扩印服务' },
    { v: 'O8060', l: '摄影扩印服务' },
    { v: 'O807', l: '婚姻服务' },
    { v: 'O8070', l: '婚姻服务' },
    { v: 'O808', l: '殡葬服务' },
    { v: 'O8080', l: '殡葬服务' },
    { v: 'O809', l: '其他居民服务业' },
    { v: 'O8090', l: '其他居民服务业' },
    { v: 'O81', l: '机动车、电子产品和日用产品修理业' },
    { v: 'O811', l: '汽车、摩托车等修理与维护' },
    { v: 'O8111', l: '汽车修理与维护' },
    { v: 'O8112', l: '大型车辆装备修理与维护' },
    { v: 'O8113', l: '摩托车修理与维护' },
    { v: 'O8114', l: '助动车等修理与维护' },
    { v: 'O812', l: '计算机和办公设备维修' },
    { v: 'O8121', l: '计算机和辅助设备修理' },
    { v: 'O8122', l: '通讯设备修理' },
    { v: 'O8129', l: '其他办公设备维修' },
    { v: 'O813', l: '家用电器修理' },
    { v: 'O8131', l: '家用电子产品修理' },
    { v: 'O8132', l: '日用电器修理' },
    { v: 'O819', l: '其他日用产品修理业' },
    { v: 'O8191', l: '自行车修理' },
    { v: 'O8192', l: '鞋和皮革修理' },
    { v: 'O8193', l: '家具和相关物品修理' },
    { v: 'O8199', l: '其他未列明日用产品修理业' },
    { v: 'O82', l: '其他服务业' },
    { v: 'O821', l: '清洁服务' },
    { v: 'O8211', l: '建筑物清洁服务' },
    { v: 'O8219', l: '其他清洁服务' },
    { v: 'O822', l: '宠物服务' },
    { v: 'O8221', l: '宠物饲养' },
    { v: 'O8222', l: '宠物医院服务' },
    { v: 'O8223', l: '宠物美容服务' },
    { v: 'O8224', l: '宠物寄托收养服务' },
    { v: 'O8229', l: '其他宠物服务' },
    { v: 'O829', l: '其他未列明服务业' },
    { v: 'O8290', l: '其他未列明服务业' },
    { v: 'P', l: '教育' },
    { v: 'P83', l: '教育' },
    { v: 'P831', l: '学前教育' },
    { v: 'P8310', l: '学前教育' },
    { v: 'P832', l: '初等教育' },
    { v: 'P8321', l: '普通小学教育' },
    { v: 'P8322', l: '成人小学教育' },
    { v: 'P833', l: '中等教育' },
    { v: 'P8331', l: '普通初中教育' },
    { v: 'P8332', l: '职业初中教育' },
    { v: 'P8333', l: '成人初中教育' },
    { v: 'P8334', l: '普通高中教育' },
    { v: 'P8335', l: '成人高中教育' },
    { v: 'P8336', l: '中等职业学校教育' },
    { v: 'P834', l: '高等教育' },
    { v: 'P8341', l: '普通高等教育' },
    { v: 'P8342', l: '成人高等教育' },
    { v: 'P835', l: '特殊教育' },
    { v: 'P8350', l: '特殊教育' },
    { v: 'P839', l: '技能培训、教育辅助及其他教育' },
    { v: 'P8391', l: '职业技能培训' },
    { v: 'P8392', l: '体校及体育培训' },
    { v: 'P8393', l: '文化艺术培训' },
    { v: 'P8394', l: '教育辅助服务' },
    { v: 'P8399', l: '其他未列明教育' },
    { v: 'Q', l: '卫生和社会工作' },
    { v: 'Q84', l: '卫生' },
    { v: 'Q841', l: '医院' },
    { v: 'Q8411', l: '综合医院' },
    { v: 'Q8412', l: '中医医院' },
    { v: 'Q8413', l: '中西医结合医院' },
    { v: 'Q8414', l: '民族医院' },
    { v: 'Q8415', l: '专科医院' },
    { v: 'Q8416', l: '疗养院' },
    { v: 'Q842', l: '基层医疗卫生服务' },
    { v: 'Q8421', l: '社区卫生服务中心（站）' },
    { v: 'Q8422', l: '街道卫生院' },
    { v: 'Q8423', l: '乡镇卫生院' },
    { v: 'Q8424', l: '村卫生室' },
    { v: 'Q8425', l: '门诊部（所）' },
    { v: 'Q843', l: '专业公共卫生服务' },
    { v: 'Q8431', l: '疾病预防控制中心' },
    { v: 'Q8432', l: '专科疾病防治院（所、站）' },
    { v: 'Q8433', l: '妇幼保健院（所、站）' },
    { v: 'Q8434', l: '急救中心（站）服务' },
    { v: 'Q8435', l: '采供血机构服务' },
    { v: 'Q8436', l: '计划生育技术服务活动' },
    { v: 'Q849', l: '其他卫生活动' },
    { v: 'Q8491', l: '健康体检服务' },
    { v: 'Q8492', l: '临床检验服务' },
    { v: 'Q8499', l: '其他未列明卫生服务' },
    { v: 'Q85', l: '社会工作' },
    { v: 'Q851', l: '提供住宿社会工作' },
    { v: 'Q8511', l: '干部休养所' },
    { v: 'Q8512', l: '护理机构服务' },
    { v: 'Q8513', l: '精神康复服务' },
    { v: 'Q8514', l: '老年人、残疾人养护服务' },
    { v: 'Q8515', l: '临终关怀服务' },
    { v: 'Q8516', l: '孤残儿童收养和庇护服务' },
    { v: 'Q8519', l: '其他提供住宿社会救助' },
    { v: 'Q852', l: '不提供住宿社会工作' },
    { v: 'Q8521', l: '社会看护与帮助服务' },
    { v: 'Q8522', l: '康复辅具适配服务' },
    { v: 'Q8529', l: '其他不提供住宿社会工作' },
    { v: 'R', l: '文化、体育和娱乐业' },
    { v: 'R86', l: '新闻和出版业' },
    { v: 'R861', l: '新闻业' },
    { v: 'R8610', l: '新闻业' },
    { v: 'R862', l: '出版业' },
    { v: 'R8621', l: '图书出版' },
    { v: 'R8622', l: '报纸出版' },
    { v: 'R8623', l: '期刊出版' },
    { v: 'R8624', l: '音像制品出版' },
    { v: 'R8625', l: '电子出版物出版' },
    { v: 'R8626', l: '数字出版' },
    { v: 'R8629', l: '其他出版业' },
    { v: 'R87', l: '广播、电视、电影和影视录音制作业' },
    { v: 'R871', l: '广播' },
    { v: 'R8710', l: '广播' },
    { v: 'R872', l: '电视' },
    { v: 'R8720', l: '电视' },
    { v: 'R873', l: '影视节目制作' },
    { v: 'R8730', l: '影视节目制作' },
    { v: 'R874', l: '广播电视集成播控' },
    { v: 'R8740', l: '广播电视集成播控' },
    { v: 'R875', l: '电影和广播电视节目发行' },
    { v: 'R8750', l: '电影和广播电视节目发行' },
    { v: 'R876', l: '电影放映' },
    { v: 'R8760', l: '电影放映' },
    { v: 'R877', l: '录音制作' },
    { v: 'R8770', l: '录音制作' },
    { v: 'R88', l: '文化艺术业' },
    { v: 'R881', l: '文艺创作与表演' },
    { v: 'R8810', l: '文艺创作与表演' },
    { v: 'R882', l: '艺术表演场馆' },
    { v: 'R8820', l: '艺术表演场馆' },
    { v: 'R883', l: '图书馆与档案馆' },
    { v: 'R8831', l: '图书馆' },
    { v: 'R8832', l: '档案馆' },
    { v: 'R884', l: '文物及非物质文化遗产保护' },
    { v: 'R8840', l: '文物及非物质文化遗产保护' },
    { v: 'R885', l: '博物馆' },
    { v: 'R8850', l: '博物馆' },
    { v: 'R886', l: '烈士陵园、纪念馆' },
    { v: 'R8860', l: '烈士陵园、纪念馆' },
    { v: 'R887', l: '群众文体活动' },
    { v: 'R8870', l: '群众文体活动' },
    { v: 'R889', l: '其他文化艺术业' },
    { v: 'R8890', l: '其他文化艺术业' },
    { v: 'R89', l: '体育' },
    { v: 'R891', l: '体育组织' },
    { v: 'R8911', l: '体育竞赛组织' },
    { v: 'R8912', l: '体育保障组织' },
    { v: 'R8919', l: '其他体育组织' },
    { v: 'R892', l: '体育场地设施管理' },
    { v: 'R8921', l: '体育场馆管理' },
    { v: 'R8929', l: '其他体育场地设施管理' },
    { v: 'R893', l: '健身休闲活动' },
    { v: 'R8930', l: '健身休闲活动' },
    { v: 'R899', l: '其他体育' },
    { v: 'R8991', l: '体育中介代理服务' },
    { v: 'R8992', l: '体育健康服务' },
    { v: 'R8999', l: '其他未列明体育' },
    { v: 'R90', l: '娱乐业' },
    { v: 'R901', l: '室内娱乐活动' },
    { v: 'R9011', l: '歌舞厅娱乐活动' },
    { v: 'R9012', l: '电子游艺厅娱乐活动' },
    { v: 'R9013', l: '网吧活动' },
    { v: 'R9019', l: '其他室内娱乐活动' },
    { v: 'R902', l: '游乐园' },
    { v: 'R9020', l: '游乐园' },
    { v: 'R903', l: '休闲观光活动' },
    { v: 'R9030', l: '休闲观光活动' },
    { v: 'R904', l: '彩票活动' },
    { v: 'R9041', l: '体育彩票服务' },
    { v: 'R9042', l: '福利彩票服务' },
    { v: 'R9049', l: '其他彩票服务' },
    { v: 'R905', l: '文化娱乐体育活动和经纪代理服务' },
    { v: 'R9051', l: '文化活动服务' },
    { v: 'R9052', l: '体育表演服务' },
    { v: 'R9053', l: '文化娱乐经纪人' },
    { v: 'R9054', l: '体育经纪人' },
    { v: 'R9059', l: '其他文化艺术经纪代理' },
    { v: 'R909', l: '其他娱乐业' },
    { v: 'R9090', l: '其他娱乐业' },
    { v: 'S', l: '公共管理、社会保障和社会组织' },
    { v: 'S91', l: '中国共产党机关' },
    { v: 'S910', l: '中国共产党机关' },
    { v: 'S9100', l: '中国共产党机关' },
    { v: 'S92', l: '国家机构' },
    { v: 'S921', l: '国家权力机构' },
    { v: 'S9210', l: '国家权力机构' },
    { v: 'S922', l: '国家行政机构' },
    { v: 'S9221', l: '综合事务管理机构' },
    { v: 'S9222', l: '对外事务管理机构' },
    { v: 'S9223', l: '公共安全管理机构' },
    { v: 'S9224', l: '社会事务管理机构' },
    { v: 'S9225', l: '经济事务管理机构' },
    { v: 'S9226', l: '行政监督检查机构' },
    { v: 'S923', l: '人民法院和人民检察院' },
    { v: 'S9231', l: '人民法院' },
    { v: 'S9232', l: '人民检察院' },
    { v: 'S929', l: '其他国家机构' },
    { v: 'S9291', l: '消防管理机构' },
    { v: 'S9299', l: '其他未列明国家机构' },
    { v: 'S93', l: '人民政协、民主党派' },
    { v: 'S931', l: '人民政协' },
    { v: 'S9310', l: '人民政协' },
    { v: 'S932', l: '民主党派' },
    { v: 'S9320', l: '民主党派' },
    { v: 'S94', l: '社会保障' },
    { v: 'S941', l: '基本保险' },
    { v: 'S9411', l: '基本养老保险' },
    { v: 'S9412', l: '基本医疗保险' },
    { v: 'S9413', l: '失业保险' },
    { v: 'S9414', l: '工伤保险' },
    { v: 'S9415', l: '生育保险' },
    { v: 'S9419', l: '其他基本保险' },
    { v: 'S942', l: '补充保险' },
    { v: 'S9420', l: '补充保险' },
    { v: 'S949', l: '其他社会保障' },
    { v: 'S9490', l: '其他社会保障' },
    { v: 'S95', l: '群众团体、社会团体和其他成员组织' },
    { v: 'S951', l: '群众团体' },
    { v: 'S9511', l: '工会' },
    { v: 'S9512', l: '妇联' },
    { v: 'S9513', l: '共青团' },
    { v: 'S9519', l: '其他群众团体' },
    { v: 'S952', l: '社会团体' },
    { v: 'S9521', l: '专业性团体' },
    { v: 'S9522', l: '行业性团体' },
    { v: 'S9529', l: '其他社会团体' },
    { v: 'S953', l: '基金会' },
    { v: 'S9530', l: '基金会' },
    { v: 'S954', l: '宗教组织' },
    { v: 'S9541', l: '宗教团体服务' },
    { v: 'S9542', l: '宗教活动场所服务' },
    { v: 'S96', l: '基层群众自治组织及其他组织' },
    { v: 'S961', l: '社区居民自治组织' },
    { v: 'S9610', l: '社区居民自治组织' },
    { v: 'S962', l: '村民自治组织' },
    { v: 'S9620', l: '村民自治组织' },
    { v: 'T', l: '国际组织' },
    { v: 'T97', l: '国际组织' },
    { v: 'T970', l: '国际组织' },
    { v: 'T9700', l: '国际组织' },
  ],
}
export const dict_rawdata = {
  ActRcvFndCcyCd: [
    { value: '004', label: '阿富汗尼' },
    { value: '008', label: '列克' },
    { value: '012', label: '阿尔及利亚第纳尔' },
    { value: '020', label: '安道尔比塞塔' },
    { value: '031', label: '阿塞拜疆马纳特' },
    { value: '032', label: '阿根廷比索' },
    { value: '036', label: '澳大利亚元' },
    { value: '040', label: '先令' },
    { value: '044', label: '巴哈马元' },
    { value: '048', label: '巴林第纳尔' },
    { value: '050', label: '塔卡' },
    { value: '051', label: '亚美尼亚达姆' },
    { value: '052', label: '巴巴多斯元' },
    { value: '056', label: '比利时法郎' },
    { value: '060', label: '百慕大元' },
    { value: '064', label: '努尔特鲁姆' },
    { value: '068', label: '玻利瓦诺' },
    { value: '072', label: '普拉' },
    { value: '084', label: '伯利兹元' },
    { value: '090', label: '所罗门群岛元' },
    { value: '096', label: '文莱元' },
    { value: '100', label: '列弗' },
    { value: '104', label: '缅元' },
    { value: '108', label: '布隆迪法郎' },
    { value: '116', label: '瑞尔' },
    { value: '124', label: '加元' },
    { value: '132', label: '佛得角埃斯库多' },
    { value: '136', label: '开曼群岛元' },
    { value: '144', label: '斯里兰卡卢比' },
    { value: '152', label: '智利比索' },
    { value: '156', label: '人民币' },
    { value: '170', label: '哥伦比亚比索' },
    { value: '174', label: '科摩罗法郎' },
    { value: '188', label: '哥斯达黎加科郎' },
    { value: '191', label: '克罗地亚库纳' },
    { value: '192', label: '古巴比索' },
    { value: '196', label: '塞浦路斯镑' },
    { value: '203', label: '捷克克朗' },
    { value: '208', label: '丹麦克朗' },
    { value: '214', label: '多米尼加比索' },
    { value: '222', label: '萨尔瓦多科郎' },
    { value: '230', label: '埃塞俄比亚比尔' },
    { value: '232', label: '纳克法' },
    { value: '233', label: '克罗姆' },
    { value: '238', label: '福克兰群岛镑' },
    { value: '242', label: '斐济元' },
    { value: '246', label: '马克' },
    { value: '250', label: '法国法郎' },
    { value: '262', label: '吉布提法郎' },
    { value: '270', label: '达拉西' },
    { value: '276', label: '德国马克' },
    { value: '288', label: '塞地' },
    { value: '292', label: '直布罗陀镑' },
    { value: '300', label: '德拉克马' },
    { value: '320', label: '格查尔' },
    { value: '324', label: '几内亚法郎' },
    { value: '328', label: '圭亚那元' },
    { value: '332', label: '古德' },
    { value: '340', label: '伦皮拉' },
    { value: '344', label: '香港元' },
    { value: '348', label: '福林' },
    { value: '352', label: '冰岛克朗' },
    { value: '356', label: '印度卢比' },
    { value: '360', label: '卢比' },
    { value: '364', label: '伊朗里亚尔' },
    { value: '368', label: '伊拉克第纳尔' },
    { value: '372', label: '爱尔兰镑' },
    { value: '376', label: '新谢客尔' },
    { value: '380', label: '意大利里拉' },
    { value: '388', label: '牙买加元' },
    { value: '392', label: '日元' },
    { value: '398', label: '坚戈' },
    { value: '400', label: '约旦第纳尔' },
    { value: '404', label: '肯尼亚先令' },
    { value: '408', label: '北朝鲜圆' },
    { value: '410', label: '圆' },
    { value: '414', label: '科威特第纳尔' },
    { value: '417', label: '索姆' },
    { value: '418', label: '基普' },
    { value: '422', label: '黎巴嫩镑' },
    { value: '426', label: '罗提' },
    { value: '428', label: '拉脱维亚拉特' },
    { value: '430', label: '利比里亚元' },
    { value: '434', label: '利比亚第纳尔' },
    { value: '440', label: '立陶宛' },
    { value: '442', label: '卢森堡法郎' },
    { value: '446', label: '澳门元' },
    { value: '450', label: '马尔加什法郎' },
    { value: '454', label: '克瓦查' },
    { value: '458', label: '马来西亚林吉特' },
    { value: '462', label: '卢菲亚' },
    { value: '470', label: '马尔他里拉' },
    { value: '478', label: '乌吉亚' },
    { value: '480', label: '毛里求斯卢比' },
    { value: '484', label: '墨西哥比索' },
    { value: '496', label: '图格里克' },
    { value: '498', label: '摩尔瓦多列伊' },
    { value: '504', label: '摩洛哥迪拉姆' },
    { value: '508', label: '麦梯卡尔' },
    { value: '512', label: '阿曼里亚尔' },
    { value: '516', label: '纳米比亚元' },
    { value: '524', label: '尼泊尔卢比' },
    { value: '528', label: '荷兰盾' },
    { value: '532', label: '荷属安的列斯盾' },
    { value: '533', label: '阿鲁巴盾' },
    { value: '534', label: '卡塔尔里亚尔' },
    { value: '548', label: '瓦图' },
    { value: '554', label: '新西兰元' },
    { value: '558', label: '金科多巴' },
    { value: '566', label: '奈拉' },
    { value: '578', label: '挪威克朗' },
    { value: '586', label: '巴基斯坦卢比' },
    { value: '590', label: '巴波亚' },
    { value: '598', label: '基那' },
    { value: '600', label: '瓜拉尼' },
    { value: '604', label: '索尔' },
    { value: '608', label: '非律宾比索' },
    { value: '620', label: '葡萄牙埃斯库多' },
    { value: '624', label: '几内亚比绍比索' },
    { value: '626', label: '东帝汶埃斯库多' },
    { value: '642', label: '列伊' },
    { value: '643', label: '俄罗斯卢布' },
    { value: '646', label: '卢旺达法郎' },
    { value: '654', label: '圣赫勒拿磅' },
    { value: '678', label: '多布拉' },
    { value: '682', label: '沙特里亚尔' },
    { value: '690', label: '塞舌尔卢比' },
    { value: '694', label: '利昂' },
    { value: '702', label: '新加坡元' },
    { value: '703', label: '斯洛伐克克朗' },
    { value: '704', label: '盾' },
    { value: '705', label: '托拉尔' },
    { value: '706', label: '索马里先令' },
    { value: '710', label: '兰特' },
    { value: '716', label: '津巴布韦元' },
    { value: '724', label: '西班牙比塞塔' },
    { value: '736', label: '苏丹第纳尔' },
    { value: '740', label: '苏里南盾' },
    { value: '748', label: '里兰吉尼' },
    { value: '752', label: '瑞典克朗' },
    { value: '756', label: '瑞士法郎' },
    { value: '760', label: '叙利亚镑' },
    { value: '764', label: '铢' },
    { value: '776', label: '邦加' },
    { value: '780', label: '特立尼达和多巴哥元' },
    { value: '784', label: 'UAE迪拉姆' },
    { value: '788', label: '突尼斯第纳尔' },
    { value: '792', label: '土耳其里拉' },
    { value: '795', label: '马纳特' },
    { value: '800', label: '乌干达先令' },
    { value: '807', label: '第纳尔' },
    { value: '810', label: '俄罗斯卢布' },
    { value: '818', label: '埃及镑' },
    { value: '826', label: '英镑' },
    { value: '834', label: '坦桑尼亚先令' },
    { value: '840', label: '美元' },
    { value: '858', label: '乌拉圭比索' },
    { value: '860', label: '乌兹别克斯坦苏姆' },
    { value: '882', label: '塔拉' },
    { value: '886', label: '也门里亚尔' },
    { value: '891', label: '南斯拉夫第纳尔' },
    { value: '894', label: '克瓦查' },
    { value: '901', label: '新台湾元' },
    { value: '950', label: 'CFA法郎BEAC' },
    { value: '951', label: '东加勒比元' },
    { value: '952', label: 'CFA法郎BCEAO' },
    { value: '953', label: 'CFP法郎' },
    { value: '955', label: '欧洲货币合成单位' },
    { value: '956', label: '欧洲货币单位(E.M.U.-6)' },
    { value: '957', label: '欧洲账户9单位' },
    { value: '958', label: '欧洲账户17单位' },
    { value: '959', label: '黄金' },
    { value: '960', label: '特别提款权' },
    { value: '961', label: '银' },
    { value: '962', label: '铂白金' },
    { value: '963', label: '测试特别保留' },
    { value: '964', label: '钯' },
    { value: '972', label: '索莫尼' },
    { value: '973', label: '宽扎' },
    { value: '974', label: '白俄罗斯卢布' },
    { value: '975', label: '保加利亚列弗' },
    { value: '976', label: '刚果法郎' },
    { value: '977', label: '可自由兑换标记' },
    { value: '978', label: '欧元' },
    { value: '979', label: '墨西哥发展单位' },
    { value: '980', label: '格里夫纳' },
    { value: '984', label: 'Mvdol(玻利维亚)' },
    { value: '985', label: '兹罗提' },
    { value: '986', label: '巴西瑞尔' },
    { value: '98l', label: '拉里' },
    { value: '990', label: '发展单位' },
    { value: '993', label: 'UIC法郎' },
    { value: '994', label: '黄金法郎' },
    { value: '997', label: '(次日)' },
    { value: '998', label: '(同日)' },
    { value: '999', label: '未包括的交易货币' },
  ],
  AdTyp: [
    { value: '301', label: '户籍地址' },
    { value: '302', label: '常住地址' },
    { value: '303', label: '现居地址' },
    { value: '304', label: '邮寄地址' },
    { value: '305', label: '通讯地址' },
    { value: '306', label: '单位地址' },
    { value: '307', label: '证件地址' },
    { value: '308', label: '收货地址' },
    { value: '399', label: '个人其他地址' },
    { value: '401', label: '注册地址' },
    { value: '402', label: '邮寄地址' },
    { value: '403', label: '经营地址' },
    { value: '404', label: '办公地址' },
    { value: '405', label: '机构地址' },
    { value: '406', label: '英文地址' },
    { value: '499', label: '对公其他地址' },
  ],
  AgrcltRelatedCustInd: [
    { value: '1', label: '是' },
    { value: '0', label: '否' },
  ],
  AtchRltnpTp: [
    // { value: '1', label: '本人' },
    { value: '2', label: '配偶' },
    { value: '3', label: '父母' },
    { value: '4', label: '子女' },
    { value: '5', label: '兄弟姐妹' },
    // { value: '6', label: '无关' },
    { value: '9', label: '其他' },
  ],
  BnBodyChkFlg: [
    { value: '0', label: '未识别' },
    { value: '1', label: '自动通过' },
    { value: '2', label: '人工通过' },
    { value: '3', label: '未通过' },
  ],
  ClsGrpSsts: [
    { value: '1', label: '是' },

    { value: '0', label: '否' },
  ],
  CoChrctrstcTp: [
    { value: '100', label: '党政机关' },
    { value: '200', label: '事业单位' },
    { value: '300', label: '军队' },
    { value: '400', label: '社会团体' },
    { value: '500', label: '内资企业' },
    { value: '510', label: '国有企业' },
    { value: '520', label: '集体企业' },
    { value: '530', label: '股份合作企业' },
    { value: '540', label: '联营企业' },
    { value: '550', label: '有限责任公司' },
    { value: '560', label: '股份有限公司' },
    { value: '570', label: '私营企业' },
    { value: '600', label: '外商投资企业(含港、澳、台)' },
    { value: '610', label: '中外合资经营企业(含港、澳、台)' },
    { value: '620', label: '中外合作经营企业(含港、澳、台)' },
    { value: '630', label: '外资企业(含港、澳、台)' },
    { value: '640', label: '外商投资股份有限公司(含港、澳、台)' },
    { value: '700', label: '个体经营' },
    { value: '800', label: '其他' },
    { value: '900', label: '未知' },
  ],
  CorpOperStatCD: [
    { value: '01', label: '正常' },
    { value: '02', label: '关闭' },
    { value: '03', label: '停产' },
    { value: '04', label: '半停产' },
    { value: '05', label: '破产' },
    { value: '06', label: '清算' },
    { value: '07', label: '重组' },
    { value: '08', label: '接管' },
    { value: '90', label: '非正常' },
    { value: '99', label: '其他' },
  ],
  CorpSizeCD: [
    { value: '1', label: '大型' },
    { value: '2', label: '中型' },
    { value: '3', label: '小型' },
    { value: '5', label: '微型' },
    { value: '9', label: '其他（非企业）' },
  ],
  CstSt: [
    { value: '', label: '全部' },
    { value: '100', label: '潜在客户' },
    { value: '200', label: '正式客户' },
    { value: '300', label: '退出客户' },
    { value: '301', label: '退出客户-注销' },
    { value: '302', label: '退出客户-死亡' },
    { value: '303', label: '退出客户-合并' },
    { value: '304', label: '退出客户-删除' },
    { value: '305', label: '退出客户-冻结' },
    { value: '400', label: '其他' },
    { value: '401', label: '其他-被收购' },
    { value: '402', label: '其他-暂停营业' },
    { value: '403', label: '其他-特殊' },
  ],
  CstTp: [
    { value: '1', label: '个人客户' },
    { value: '2', label: '对公客户' },
  ],
  CstTp1: [
    { value: '0101', label: '法人企业' },
    { value: '0102', label: '非法人企业' },
    { value: '0103', label: '经营性个人客户' },
    { value: '0104', label: '事业单位' },
    { value: '0105', label: '社会团体' },
    { value: '0106', label: '党政机关' },
    { value: '0107', label: '金融机构' },
    { value: '0199', label: '其他' },
    { value: '0201', label: '单一集团客户' },
    { value: '0202', label: '综合集团客户' },
    { value: '0203', label: '准集团客户' },
    { value: '0204', label: '联保体客户' },
    { value: '03', label: '消费类个人客户' },
    { value: '04', label: '个体工商户' },
    { value: '05', label: '同业客户' },
    { value: '06', label: '专业市场客户' },
    { value: '07', label: '产/行业集群' },
    { value: '08', label: '网格化商圈' },
    { value: '2', label: '一般成员' },
  ],
  CtcTp: [
    { value: '101', label: '家庭电话' },
    { value: '102', label: '家庭传真' },
    { value: '103', label: '单位电话' },
    { value: '104', label: '单位传真' },
    { value: '105', label: '移动电话' },
    { value: '106', label: '传呼号码' },
    { value: '107', label: '电子邮箱' },
    { value: '108', label: 'QQ' },
    { value: '109', label: 'MSN' },
    { value: '110', label: '网址' },
    { value: '111', label: '微信' },
    { value: '112', label: '微博' },
    { value: '113', label: '主页' },
    { value: '114', label: '信贷电话' },
    { value: '199', label: '个人其他' },
    { value: '201', label: '公司电话' },
    { value: '202', label: '法人电话' },
    { value: '203', label: '经办人电话' },
    { value: '204', label: '财务电话' },
    { value: '205', label: '企业邮箱' },
    { value: '206', label: '企业公众号' },
    { value: '207', label: '公司主页' },
    { value: '299', label: '对公其他' },
  ],
  DepPrsnTp: [
    { value: '01', label: '企业法人' },
    { value: '02', label: '非企业法人' },
    { value: '03', label: '机关' },
    { value: '04', label: '实行预算管理的事业单位' },
    { value: '05', label: '非预算管理的事业单位' },
    { value: '06', label: '团级（含）以上军队及分散执勤的支（分）队' },
    { value: '07', label: '团级（含）以上武警部队及分散执勤的支（分）队' },
    { value: '08', label: '社会团队' },
    { value: '09', label: '宗教组织' },
    { value: '10', label: '民办非企业组织' },
    { value: '11', label: '外地常设机构' },
    { value: '12', label: '外国驻华机构' },
    { value: '13', label: '有字号的个体工商户' },
    { value: '14', label: '无字号的个体工商户' },
    { value: '15', label: '居民委员会、村民委员会、社区委员会' },
    { value: '16', label: '单位设立的独立核算的附属机构' },
    { value: '17', label: '其他组织' },
    { value: '18', label: '境外机构' },
    { value: '1 ', label: '中国公民' },
    { value: '2 ', label: '军人' },
    { value: '3  ', label: '武警' },
    { value: '4 ', label: '香港台湾澳门居民' },
    { value: '5 ', label: '外国公民' },
    { value: '6 ', label: '定居国外的中国公民' },
  ],
  DmstOrOvrsInd: [
    { value: '1', label: '境内' },
    { value: '0', label: '境外' },
  ],
  EcnmChrctrstcCd: [
    { value: '10', label: '内资' },
    { value: '11', label: '国有全资' },
    { value: '12', label: '集体全资' },
    { value: '13', label: '股份合作' },
    { value: '14', label: '联营' },
    { value: '15', label: '有限责任公司' },
    { value: '16', label: '股份有限公司' },
    { value: '17', label: '私有' },
    { value: '19', label: '其它内资' },
    { value: '20', label: '港澳台投资' },
    { value: '21', label: '内地和港澳台投资' },
    { value: '22', label: '内地和港澳台合作' },
    { value: '23', label: '港澳台独资' },
    { value: '24', label: '港澳台投资股份有限公司' },
    { value: '29', label: '其他港澳台投资' },
    { value: '30', label: '国外投资' },
    { value: '31', label: '中外合资' },
    { value: '32', label: '中外合作' },
    { value: '33', label: '外资' },
    { value: '34', label: '国外投资股份有限公司' },
    { value: '39', label: '其他国外投资' },
    { value: '40', label: '境外机构' },
    { value: '90', label: '其他' },
  ],
  EcomnCompntCD: [
    { value: 'A0000', label: '公有控股经济' },
    { value: 'A0100', label: '国有控股' },
    { value: 'A0101', label: '国有相对控股' },
    { value: 'A0102', label: '国有绝对控股' },
    { value: 'A0200', label: '集体控股' },
    { value: 'A0201', label: '集体相对控股' },
    { value: 'A0202', label: '集体绝对控股' },
    { value: 'B0000', label: '非公有控股经济' },
    { value: 'B0100', label: '私人控股' },
    { value: 'B0101', label: '私人相对控股' },
    { value: 'B0102', label: '私人绝对控股' },
    { value: 'B0200', label: '港澳台控股' },
    { value: 'B0201', label: '港澳台相对控股' },
    { value: 'B0202', label: '港澳台绝对控股' },
    { value: 'B0300', label: '外商控股' },
    { value: 'B0301', label: '外商相对控股' },
    { value: 'B0302', label: '外商绝对控股' },
    { value: 'Z9999', label: '其他' },
  ],
  EntpTp: [
    { value: '1000', label: '内资公司' },
    { value: '1100', label: '有限责任公司' },
    { value: '1110', label: '有限责任公司(国有独资)' },
    { value: '1120', label: '有限责任公司(外商投资企业投资)' },
    { value: '1121', label: '有限责任公司(外商投资企业合资)' },
    { value: '1122', label: '有限责任公司(外商投资企业与内资合资)' },
    { value: '1123', label: '有限责任公司(外商投资企业法人独资)' },
    { value: '1130', label: '有限责任公司(自然人投资或控股)' },
    { value: '1140', label: '有限责任公司(国有控股)' },
    { value: '1150', label: '一人有限责任公司' },
    { value: '1151', label: '有限责任公司(自然人独资)' },
    { value: '1152', label: '有限责任公司(自然人投资或控股的法人独资)' },
    { value: '1153', label: '有限责任公司(非自然人投资或控股的法人独资)' },
    { value: '1190', label: '其他有限责任公司' },
    { value: '1200', label: '股份有限公司' },
    { value: '1210', label: '股份有限公司(上市)' },
    { value: '1211', label: '股份有限公司(上市、外商投资企业投资)' },
    { value: '1212', label: '股份有限公司(上市、自然人投资或控股)' },
    { value: '1213', label: '股份有限公司(上市、国有控股)' },
    { value: '1219', label: '其他股份有限公司(上市)' },
    { value: '1220', label: '股份有限公司(非上市)' },
    { value: '1221', label: '股份有限公司(非上市、外商投资企业投资)' },
    { value: '1222', label: '股份有限公司(非上市、自然人投资或控股)' },
    { value: '1223', label: '股份有限公司(非上市、国有控股)' },
    { value: '1229', label: '其他股份有限公司(非上市)' },
    { value: '2000', label: '内资分公司' },
    { value: '2100', label: '有限责任公司分公司' },
    { value: '2110', label: '有限责任公司分公司(国有独资)' },
    { value: '2120', label: '有限责任公司分公司(外商投资企业投资)' },
    { value: '2121', label: '有限责任公司分公司(外商投资企业合资)' },
    { value: '2122', label: '有限责任公司分公司(外商投资企业与内资合资)' },
    { value: '2123', label: '有限责任公司分公司(外商投资企业法人独资)' },
    { value: '2130', label: '有限责任公司分公司(自然人投资或控股)' },
    { value: '2140', label: '有限责任公司分公司(国有控股)' },
    { value: '2150', label: '一人有限责任公司分公司' },
    { value: '2151', label: '有限责任公司分公司(自然人独资)' },
    { value: '2152', label: '有限责任公司分公司(自然人投资或控股的法人独资)' },
    {
      value: '2153',
      label: '有限责任公司分公司(非自然人投资或控股的法人独资)',
    },
    { value: '2190', label: '其他有限责任公司分公司' },
    { value: '2200', label: '股份有限公司分公司' },
    { value: '2210', label: '股份有限公司分公司(上市)' },
    { value: '2211', label: '股份有限公司分公司(上市、外商投资企业投资)' },
    { value: '2212', label: '股份有限公司分公司(上市、自然人投资或控股)' },
    { value: '2213', label: '股份有限公司分公司(上市、国有控股)' },
    { value: '2219', label: '其他股份有限公司分公司(上市)' },
    { value: '2220', label: '股份有限公司分公司(非上市)' },
    { value: '2221', label: '股份有限公司分公司(非上市、外商投资企业投资)' },
    { value: '2222', label: '股份有限公司分公司(非上市、自然人投资或控股)' },
    { value: '2223', label: '股份有限公司分公司(非上市、国有控股)' },
    { value: '2229', label: '其他股份有限公司分公司(非上市)' },
    { value: '3000', label: '内资企业法人' },
    { value: '3100', label: '全民所有制' },
    { value: '3200', label: '集体所有制' },
    { value: '3300', label: '股份制' },
    { value: '3400', label: '股份合作制' },
    { value: '3410', label: '集体所有制（股份合作）' },
    { value: '3420', label: '集体所有制（股份合作）－全资设立' },
    { value: '3500', label: '联营' },
    {
      value: '4000',
      label: '内资非法人企业、非公司私营企业及内资非公司企业分支机构',
    },
    { value: '4100', label: '事业单位营业' },
    { value: '4110', label: '国有事业单位营业' },
    { value: '4120', label: '集体事业单位营业' },
    { value: '4200', label: '社团法人营业' },
    { value: '4210', label: '国有社团法人营业' },
    { value: '4220', label: '集体社团法人营业' },
    { value: '4300', label: '内资企业法人分支机构(非法人)' },
    { value: '4310', label: '全民所有制分支机构(非法人)' },
    { value: '4320', label: '集体分支机构(非法人)' },
    { value: '4330', label: '股份制分支机构' },
    { value: '4340', label: '股份合作制分支机构' },
    { value: '4341', label: '集体所有制（股份合作）分支机构' },
    { value: '4342', label: '集体所有制（股份合作）－全资设立(分支机构)' },
    { value: '4400', label: '经营单位(非法人)' },
    { value: '4410', label: '国有经营单位(非法人)' },
    { value: '4420', label: '集体经营单位(非法人)' },
    { value: '4500', label: '非公司私营企业' },
    { value: '4530', label: '合伙企业' },
    { value: '4531', label: '普通合伙企业' },
    { value: '4532', label: '特殊普通合伙企业' },
    { value: '4533', label: '有限合伙企业' },
    { value: '4540', label: '个人独资企业' },
    { value: '4550', label: '合伙企业分支机构' },
    { value: '4551', label: '普通合伙企业分支机构' },
    { value: '4552', label: '特殊普通合伙企业分支机构' },
    { value: '4553', label: '有限合伙企业分支机构' },
    { value: '4560', label: '个人独资企业分支机构' },
    { value: '4700', label: '股份制企业(非法人)' },
    { value: '5000', label: '外商投资企业' },
    { value: '5100', label: '有限责任公司（外商投资）' },
    { value: '5110', label: '有限责任公司(中外合资)' },
    { value: '5120', label: '有限责任公司(中外合作)' },
    { value: '5130', label: '有限责任公司(外商合资)' },
    { value: '5140', label: '有限责任公司(外国自然人独资)' },
    { value: '5150', label: '有限责任公司(外国法人独资)' },
    { value: '5160', label: '有限责任公司(外国非法人经济组织独资)' },
    { value: '5180', label: '有限责任公司(外商投资、非独资)' },
    { value: '5190', label: '有限责任公司(其他)' },
    { value: '5200', label: '股份有限公司（外商投资）' },
    { value: '5210', label: '股份有限公司(中外合资、未上市)' },
    { value: '5220', label: '股份有限公司(中外合资、上市)' },
    { value: '5230', label: '股份有限公司(外商合资、未上市)' },
    { value: '5240', label: '股份有限公司(外商合资、上市)' },
    { value: '5290', label: '股份有限公司(其他)' },
    { value: '5300', label: '非公司（外商投资）' },
    { value: '5310', label: '非公司外商投资企业(中外合作)' },
    { value: '5320', label: '非公司外商投资企业(外商合资)' },
    { value: '5390', label: '非公司外商投资企业(其他)' },
    { value: '5400', label: '外商投资合伙企业' },
    { value: '5410', label: '外商投资普通合伙企业' },
    { value: '5420', label: '外商投资特殊普通合伙企业' },
    { value: '5430', label: '外商投资有限合伙企业' },
    { value: '5490', label: '其他' },
    { value: '5800', label: '外商投资企业分支机构' },
    { value: '5810', label: '外商投资企业分公司' },
    { value: '5820', label: '外商投资企业非公司分支机构' },
    { value: '5830', label: '办事处' },
    { value: '5840', label: '外商投资合伙企业分支机构' },
    { value: '5890', label: '外商投资企业其他分支机构' },
    { value: '6000', label: '台、港、澳投资企业' },
    { value: '6100', label: '有限责任公司（台、港、澳资）' },
    { value: '6110', label: '有限责任公司(台港澳与境内合资)' },
    { value: '6120', label: '有限责任公司(台港澳与境内合作)' },
    { value: '6130', label: '有限责任公司(台港澳合资)' },
    { value: '6140', label: '有限责任公司(台港澳自然人独资)' },
    { value: '6150', label: '有限责任公司(台港澳法人独资)' },
    { value: '6160', label: '有限责任公司(台港澳非法人经济组织独资)' },
    { value: '6170', label: '有限责任公司(台港澳与外国投资者合资)' },
    { value: '6180', label: '有限责任公司(港澳台投资、非独资)' },
    { value: '6190', label: '有限责任公司(其他台港澳有限责任公司)' },
    { value: '6200', label: '股份有限公司(台港澳与境内合资)' },
    { value: '6210', label: '股份有限公司(台港澳与境内合资、未上市)' },
    { value: '6220', label: '股份有限公司(台港澳与境内合资、上市)' },
    { value: '6230', label: '股份有限公司(台港澳合资、未上市)' },
    { value: '6240', label: '股份有限公司(台港澳合资、上市)' },
    { value: '6250', label: '股份有限公司(台港澳与外国投资者合资、未上市)' },
    { value: '6260', label: '股份有限公司(台港澳与外国投资者合资、上市)' },
    { value: '6290', label: '股份有限公司(其他台港澳股份有限公司)' },
    { value: '6300', label: '非公司（台、港、澳资）' },
    { value: '6310', label: '非公司台、港、澳企业(台港澳与境内合作)' },
    { value: '6320', label: '非公司台、港、澳企业(台港澳合资)' },
    { value: '6390', label: '非公司台、港、澳企业(其他)' },
    { value: '6400', label: '港、澳、台投资合伙企业' },
    { value: '6800', label: '台、港、澳投资企业分支机构' },
    { value: '6810', label: '台、港、澳投资企业分公司' },
    { value: '6820', label: '台、港、澳企业非公司分支机构' },
    { value: '6840', label: '港、澳、台投资合伙企业分支机构' },
    { value: '6841', label: '台港澳投资普通合伙企业分支机构' },
    { value: '6890', label: '其他台港澳投资企业分支机构' },
    { value: '7000', label: '外国（地区）企业' },
    { value: '7100', label: '外国（地区）公司分支机构' },
    { value: '7110', label: '外国(地区)无限责任公司分支机构' },
    { value: '7120', label: '外国(地区)有限责任公司分支机构' },
    { value: '7130', label: '外国(地区)股份有限责任公司分支机构' },
    { value: '7190', label: '外国(地区)其他形式公司分支机构' },
    { value: '7200', label: '外国(地区)企业常驻代表机构' },
    { value: '7300', label: '外国(地区)企业在中国境内从事经营活动' },
    { value: '7310', label: '分公司' },
    { value: '8000', label: '集团' },
    { value: '8100', label: '内资集团' },
    { value: '8500', label: '外资集团' },
    { value: '9000', label: '其他类型' },
    { value: '9100', label: '农民专业合作经济组织' },
    { value: '9200', label: '农民专业合作社分支机构' },
    { value: '9500', label: '个体工商户' },
    { value: '9600', label: '自然人' },
    { value: '9999', label: '个体' },
  ],
  FinOrgCustCD: [
    { value: '100', label: '银行' },
    { value: '101', label: '中央银行' },
    { value: '102', label: '多边开放银行' },
    { value: '103', label: '政策性银行' },
    { value: '104', label: '国家开发银行' },
    { value: '105', label: '国有商业银行' },
    { value: '106', label: '股份制商业银行' },
    { value: '107', label: '邮政储蓄银行' },
    { value: '108', label: '城市商业银行' },
    { value: '109', label: '农村商业银行' },
    { value: '110', label: '农村合作银行' },
    { value: '111', label: '城市信用合作社' },
    { value: '112', label: '农村信用合作社' },
    { value: '113', label: '村镇银行' },
    { value: '114', label: '外资法人银行' },
    { value: '115', label: '外国银行分行' },
    { value: '200', label: '非银行金融机构' },
    { value: '201', label: '农村资金互助社' },
    { value: '202', label: '贷款公司' },
    { value: '203', label: '企业集团财务公司' },
    { value: '204', label: '信托投资公司' },
    { value: '205', label: '金融租赁公司' },
    { value: '206', label: '汽车金融公司' },
    { value: '207', label: '货币经纪公司' },
    { value: '208', label: '消费金融公司' },
    { value: '209', label: '接受存款公司' },
    { value: '300', label: '证券公司' },
    { value: '400', label: '基金公司' },
    { value: '500', label: '保险公司' },
    { value: '600', label: '期货公司' },
    { value: '700', label: '金融资产管理公司' },
    { value: '999', label: '其他' },
  ],
  FinOrgIndusCD: [
    { value: '000000', label: '未知' },
    { value: '100000', label: '境内金融客户' },
    { value: '1A0000', label: '货币当局' },
    { value: '1A1000', label: '中国人民银行' },
    { value: '1A2000', label: '国家外汇管理局' },
    { value: '1B0000', label: '监管当局' },
    { value: '1B1000', label: '中国银行业监督管理委员会' },
    { value: '1B2000', label: '中国证券监督管理委员会' },
    { value: '1B3000', label: '中国保险监督管理委员会' },
    { value: '1C0000', label: '银行业存款类金融机构' },
    { value: '1C1000', label: '银行' },
    { value: '1C1100', label: '政策性银行' },
    { value: '1C1200', label: '商业银行' },
    { value: '1C1201', label: '大型商业银行' },
    { value: '1C1202', label: '股份制商业银行' },
    { value: '1C1203', label: '城市商业银行' },
    { value: '1C1204', label: '农村商业银行' },
    { value: '1C1205', label: '外资银行' },
    { value: '1C1300', label: '农村合作银行' },
    { value: '1C1400', label: '邮政储蓄银行' },
    { value: '1C1500', label: '村镇银行' },
    { value: '1C2000', label: '城市信用合作社（含联社）' },
    { value: '1C3000', label: '农村信用合作社（含联社）' },
    { value: '1C4000', label: '农村资金互助社' },
    { value: '1C5000', label: '财务公司' },
    { value: '1D0000', label: '银行业非存款类金融机构' },
    { value: '1D1000', label: '信托公司' },
    { value: '1D2000', label: '金融资产管理公司' },
    { value: '1D3000', label: '金融租赁公司' },
    { value: '1D4000', label: '汽车金融公司' },
    { value: '1D5000', label: '贷款公司' },
    { value: '1D6000', label: '货币经纪公司' },
    { value: '1D7000', label: '消费金融公司' },
    { value: '1E0000', label: '证券业金融机构' },
    { value: '1E1000', label: '证券公司' },
    { value: '1E2000', label: '证券投资基金管理公司' },
    { value: '1E3000', label: '期货公司' },
    { value: '1E4000', label: '投资咨询公司' },
    { value: '1F0000', label: '保险业金融机构' },
    { value: '1F1000', label: '财产保险公司' },
    { value: '1F2000', label: '人身保险公司' },
    { value: '1F3000', label: '再保险公司' },
    { value: '1F4000', label: '保险资产管理公司' },
    { value: '1F5000', label: '保险经纪公司' },
    { value: '1F6000', label: '保险代理公司' },
    { value: '1F7000', label: '保险公估公司' },
    { value: '1F8000', label: '企业年金' },
    { value: '1G0000', label: '交易及结算类金融机构' },
    { value: '1G1000', label: '交易所' },
    { value: '1G2000', label: '登记结算类机构' },
    { value: '1H0000', label: '金融控股公司' },
    { value: '1H1000', label: '中央金融控股公司' },
    { value: '1H2000', label: '其他金融控股公司' },
    { value: '1Z0000', label: '其他' },
    { value: '1Z1000', label: '小额贷款公司' },
    { value: '200000', label: '境外金融客户' },
    { value: '210000', label: '国际金融机构' },
    { value: '220000', label: '境外金融主管机构' },
    { value: '230000', label: '境外银行' },
    { value: '240000', label: '境外非银行金融机构' },
    { value: '299999', label: '其他境外金融客户' },
  ],
  FrgnExgEntpTp: [
    { value: '1', label: '中资外经贸企业' },
    { value: '2', label: '中资生产型企业' },
    { value: '3', label: '外商投资企业' },
    { value: '4', label: '驻华机构' },
    { value: '5', label: '特殊经济区' },
  ],
  GndFlg: [
    // { value: '0', label: '未知的性别' },
    { value: '1', label: '男' },
    { value: '2', label: '女' },
  ],
  gender: [
    { value: '0', label: '男性' },
    { value: '1', label: '女性' },
  ],
  GreenCorpInd: [
    { value: '1', label: '是' },

    { value: '0', label: '否' },
  ],
  GroupType: [
    { value: '0203', label: '集团客户' },
    { value: '0204', label: '联保体客户' },
  ],
  HestDgr: [
    { value: '0', label: '其他' },
    { value: '1', label: '名誉博士' },
    { value: '2', label: '博士' },
    { value: '3', label: '硕士' },
    { value: '4', label: '学士' },
    // { value: '9', label: '未知' },
  ],
  HestEdct: [
    // { value: '00', label: '空值' },
    { value: '01', label: '研究生' },
    { value: '02', label: '大学本科' },
    { value: '03', label: '大学专科和专科学校' },
    { value: '04', label: '中等专业学校' },
    { value: '05', label: '技工学校' },
    { value: '06', label: '高中' },
    { value: '07', label: '初中' },
    { value: '08', label: '小学' },
    { value: '09', label: '文盲或半文盲' },
    { value: '99', label: '其它' },
  ],
  eduexperience:[
    {value:"05", label:"博士"},
    {value:"10", label:"研究生"},
    {value:"20", label:"本科"},
    {value:"30", label:"大专"},
    {value:"40", label:"中专/中等技校"},
    {value:"50", label:"技术学校"},
    {value:"60", label:"高中"},
    {value:"70", label:"初中"},
    {value:"80", label:"小学"},
    {value:"90", label:"文盲或半文盲"},
    {value:"99", label:"未知"},
  ],
  IdentTp: [
    { value: '1001', label: '居民身份证' },
    { value: '1003', label: '中国护照' },
    { value: '1004', label: '户口簿' },
    { value: '1006', label: '学生证' },
    { value: '1007', label: '军官证' },
    { value: '1011', label: '武警证' },
    { value: '1012', label: '士兵证' },
    { value: '1019', label: '外国护照' },
    { value: '1021', label: '往来港澳通行证' },
    { value: '1022', label: '往来台湾通行证' },
   { value: '2002', label: '统一社会信用代码' },
    // { value: '2004', label: '统一社会信用代码' },
  ],
  IndvBizInd: [
    { value: '1', label: '是' },
    { value: '0', label: '否' },
  ],
  InptMd: [
    { value: '1', label: 'dms' },
    { value: '2', label: 'hadoop' },
    { value: '3', label: 'oss' },
  ],
  InstCtgry: [
    { value: '0', label: '个人' },
    { value: '10', label: '企业法人' },
    { value: '11', label: '其他企业' },
    { value: '12', label: '农民专业合作社' },
    { value: '13', label: '个人独资、合伙企业' },
    { value: '14', label: '企业的分支机构' },
    { value: '20', label: '事业法人' },
    { value: '21', label: '未登记的事业单位' },
    { value: '24', label: '事业单位的分支机构' },
    { value: '30', label: '机关法人' },
    { value: '31', label: '机关的内设机构' },
    { value: '32', label: '机关的下设机构' },
    { value: '40', label: '社会团体法人' },
    { value: '41', label: '社会团体分支机构' },
    { value: '5', label: '境外机构' },
    { value: '51', label: '民办非企业' },
    { value: '52', label: '基金会' },
    { value: '53', label: '居委会' },
    { value: '54', label: '村委会' },
    { value: '60', label: '律师事务所、司法鉴定所' },
    { value: '61', label: '宗教活动场所' },
    { value: '62', label: '境外在境内成立的组织机构' },
    { value: '70', label: '个体工商户' },
    { value: '71', label: '破产管理人' },
    { value: '72', label: '旧区改造' },
    { value: '73', label: '摄制组' },
    { value: '74', label: '宗教组织筹备组' },
    { value: '75', label: '其他临时机构' },
    { value: '91', label: '金融同业' },
    { value: '99', label: '其他' },
  ],
  InvestCurrCD: [
    { value: '004', label: '阿富汗尼' },
    { value: '008', label: '列克' },
    { value: '012', label: '阿尔及利亚第纳尔' },
    { value: '020', label: '安道尔比塞塔' },
    { value: '031', label: '阿塞拜疆马纳特' },
    { value: '032', label: '阿根廷比索' },
    { value: '036', label: '澳大利亚元' },
    { value: '040', label: '先令' },
    { value: '044', label: '巴哈马元' },
    { value: '048', label: '巴林第纳尔' },
    { value: '050', label: '塔卡' },
    { value: '051', label: '亚美尼亚达姆' },
    { value: '052', label: '巴巴多斯元' },
    { value: '056', label: '比利时法郎' },
    { value: '060', label: '百慕大元' },
    { value: '064', label: '努尔特鲁姆' },
    { value: '068', label: '玻利瓦诺' },
    { value: '072', label: '普拉' },
    { value: '084', label: '伯利兹元' },
    { value: '090', label: '所罗门群岛元' },
    { value: '096', label: '文莱元' },
    { value: '100', label: '列弗' },
    { value: '104', label: '缅元' },
    { value: '108', label: '布隆迪法郎' },
    { value: '116', label: '瑞尔' },
    { value: '124', label: '加元' },
    { value: '132', label: '佛得角埃斯库多' },
    { value: '136', label: '开曼群岛元' },
    { value: '144', label: '斯里兰卡卢比' },
    { value: '152', label: '智利比索' },
    { value: '156', label: '人民币' },
    { value: '170', label: '哥伦比亚比索' },
    { value: '174', label: '科摩罗法郎' },
    { value: '188', label: '哥斯达黎加科郎' },
    { value: '191', label: '克罗地亚库纳' },
    { value: '192', label: '古巴比索' },
    { value: '196', label: '塞浦路斯镑' },
    { value: '203', label: '捷克克朗' },
    { value: '208', label: '丹麦克朗' },
    { value: '214', label: '多米尼加比索' },
    { value: '222', label: '萨尔瓦多科郎' },
    { value: '230', label: '埃塞俄比亚比尔' },
    { value: '232', label: '纳克法' },
    { value: '233', label: '克罗姆' },
    { value: '238', label: '福克兰群岛镑' },
    { value: '242', label: '斐济元' },
    { value: '246', label: '马克' },
    { value: '250', label: '法国法郎' },
    { value: '262', label: '吉布提法郎' },
    { value: '270', label: '达拉西' },
    { value: '276', label: '德国马克' },
    { value: '288', label: '塞地' },
    { value: '292', label: '直布罗陀镑' },
    { value: '300', label: '德拉克马' },
    { value: '320', label: '格查尔' },
    { value: '324', label: '几内亚法郎' },
    { value: '328', label: '圭亚那元' },
    { value: '332', label: '古德' },
    { value: '340', label: '伦皮拉' },
    { value: '344', label: '香港元' },
    { value: '348', label: '福林' },
    { value: '352', label: '冰岛克朗' },
    { value: '356', label: '印度卢比' },
    { value: '360', label: '卢比' },
    { value: '364', label: '伊朗里亚尔' },
    { value: '368', label: '伊拉克第纳尔' },
    { value: '372', label: '爱尔兰镑' },
    { value: '376', label: '新谢客尔' },
    { value: '380', label: '意大利里拉' },
    { value: '388', label: '牙买加元' },
    { value: '392', label: '日元' },
    { value: '398', label: '坚戈' },
    { value: '400', label: '约旦第纳尔' },
    { value: '404', label: '肯尼亚先令' },
    { value: '408', label: '北朝鲜圆' },
    { value: '410', label: '圆' },
    { value: '414', label: '科威特第纳尔' },
    { value: '417', label: '索姆' },
    { value: '418', label: '基普' },
    { value: '422', label: '黎巴嫩镑' },
    { value: '426', label: '罗提' },
    { value: '428', label: '拉脱维亚拉特' },
    { value: '430', label: '利比里亚元' },
    { value: '434', label: '利比亚第纳尔' },
    { value: '440', label: '立陶宛' },
    { value: '442', label: '卢森堡法郎' },
    { value: '446', label: '澳门元' },
    { value: '450', label: '马尔加什法郎' },
    { value: '454', label: '克瓦查' },
    { value: '458', label: '马来西亚林吉特' },
    { value: '462', label: '卢菲亚' },
    { value: '470', label: '马尔他里拉' },
    { value: '478', label: '乌吉亚' },
    { value: '480', label: '毛里求斯卢比' },
    { value: '484', label: '墨西哥比索' },
    { value: '496', label: '图格里克' },
    { value: '498', label: '摩尔瓦多列伊' },
    { value: '504', label: '摩洛哥迪拉姆' },
    { value: '508', label: '麦梯卡尔' },
    { value: '512', label: '阿曼里亚尔' },
    { value: '516', label: '纳米比亚元' },
    { value: '524', label: '尼泊尔卢比' },
    { value: '528', label: '荷兰盾' },
    { value: '532', label: '荷属安的列斯盾' },
    { value: '533', label: '阿鲁巴盾' },
    { value: '534', label: '卡塔尔里亚尔' },
    { value: '548', label: '瓦图' },
    { value: '554', label: '新西兰元' },
    { value: '558', label: '金科多巴' },
    { value: '566', label: '奈拉' },
    { value: '578', label: '挪威克朗' },
    { value: '586', label: '巴基斯坦卢比' },
    { value: '590', label: '巴波亚' },
    { value: '598', label: '基那' },
    { value: '600', label: '瓜拉尼' },
    { value: '604', label: '索尔' },
    { value: '608', label: '非律宾比索' },
    { value: '620', label: '葡萄牙埃斯库多' },
    { value: '624', label: '几内亚比绍比索' },
    { value: '626', label: '东帝汶埃斯库多' },
    { value: '642', label: '列伊' },
    { value: '643', label: '俄罗斯卢布' },
    { value: '646', label: '卢旺达法郎' },
    { value: '654', label: '圣赫勒拿磅' },
    { value: '678', label: '多布拉' },
    { value: '682', label: '沙特里亚尔' },
    { value: '690', label: '塞舌尔卢比' },
    { value: '694', label: '利昂' },
    { value: '702', label: '新加坡元' },
    { value: '703', label: '斯洛伐克克朗' },
    { value: '704', label: '盾' },
    { value: '705', label: '托拉尔' },
    { value: '706', label: '索马里先令' },
    { value: '710', label: '兰特' },
    { value: '716', label: '津巴布韦元' },
    { value: '724', label: '西班牙比塞塔' },
    { value: '736', label: '苏丹第纳尔' },
    { value: '740', label: '苏里南盾' },
    { value: '748', label: '里兰吉尼' },
    { value: '752', label: '瑞典克朗' },
    { value: '756', label: '瑞士法郎' },
    { value: '760', label: '叙利亚镑' },
    { value: '764', label: '铢' },
    { value: '776', label: '邦加' },
    { value: '780', label: '特立尼达和多巴哥元' },
    { value: '784', label: 'UAE迪拉姆' },
    { value: '788', label: '突尼斯第纳尔' },
    { value: '792', label: '土耳其里拉' },
    { value: '795', label: '马纳特' },
    { value: '800', label: '乌干达先令' },
    { value: '807', label: '第纳尔' },
    { value: '810', label: '俄罗斯卢布' },
    { value: '818', label: '埃及镑' },
    { value: '826', label: '英镑' },
    { value: '834', label: '坦桑尼亚先令' },
    { value: '840', label: '美元' },
    { value: '858', label: '乌拉圭比索' },
    { value: '860', label: '乌兹别克斯坦苏姆' },
    { value: '882', label: '塔拉' },
    { value: '886', label: '也门里亚尔' },
    { value: '891', label: '南斯拉夫第纳尔' },
    { value: '894', label: '克瓦查' },
    { value: '901', label: '新台湾元' },
    { value: '950', label: 'CFA法郎BEAC' },
    { value: '951', label: '东加勒比元' },
    { value: '952', label: 'CFA法郎BCEAO' },
    { value: '953', label: 'CFP法郎' },
    { value: '955', label: '欧洲货币合成单位' },
    { value: '956', label: '欧洲货币单位(E.M.U.-6)' },
    { value: '957', label: '欧洲账户9单位' },
    { value: '958', label: '欧洲账户17单位' },
    { value: '959', label: '黄金' },
    { value: '960', label: '特别提款权' },
    { value: '961', label: '银' },
    { value: '962', label: '铂白金' },
    { value: '963', label: '测试特别保留' },
    { value: '964', label: '钯' },
    { value: '972', label: '索莫尼' },
    { value: '973', label: '宽扎' },
    { value: '974', label: '白俄罗斯卢布' },
    { value: '975', label: '保加利亚列弗' },
    { value: '976', label: '刚果法郎' },
    { value: '977', label: '可自由兑换标记' },
    { value: '978', label: '欧元' },
    { value: '979', label: '墨西哥发展单位' },
    { value: '980', label: '格里夫纳' },
    { value: '984', label: 'Mvdol(玻利维亚)' },
    { value: '985', label: '兹罗提' },
    { value: '986', label: '巴西瑞尔' },
    { value: '98l', label: '拉里' },
    { value: '990', label: '发展单位' },
    { value: '993', label: 'UIC法郎' },
    { value: '994', label: '黄金法郎' },
    { value: '997', label: '(次日)' },
    { value: '998', label: '(同日)' },
    { value: '999', label: '未包括的交易货币' },
  ],
  InvestType: [
    { value: '0200', label: '资金和实物(投资)' },
    { value: '0201', label: '资金(投资)' },
    { value: '0202', label: '技术(投资)' },
    { value: '0203', label: '实物(投资)' },
    { value: '0204', label: '权利(投资)' },
    { value: '0205', label: '其他(投资)' },
  ],
  IsFlg1: [
    { value: '0', label: '否' },
    { value: '1', label: '是' },
  ],
  IsFlg4: [
    { value: '0', label: '未查验' },
    { value: '0', label: '未识别' },
    { value: '1', label: '自动通过' },
    { value: '1', label: '公安识别通过' },
    { value: '2', label: '人工通过' },
    { value: '2', label: '三方识别通过' },
    { value: '3', label: '未通过' },
    { value: '3', label: '未通过' },
  ],
  IssuerInd: [
    { value: '0', label: '否' },
    { value: '1', label: '是' },
  ],
  JobCd: [
    {
      value: '1',
      label: '高级领导（行政级别局级及局级以上领导或大公司高级管理人员）',
    },
    {
      value: '2',
      label: '中级领导（行政级别局级以下领导或大公司中级管理人员）',
    },
    { value: '3', label: '一般员工' },
    { value: '4', label: '其他' },
    { value: '9', label: '未知' },
  ],
  LstdEntpFlg: [
    { value: '1', label: '是' },
    { value: '0', label: '否' },
  ],
  MainIdentFlg: [
    { value: '0', label: '否' },
    { value: '1', label: '是' },
  ],
  MajorEconSrcCD: [
    { value: '01', label: '工资收入' },
    { value: '02', label: '个体经营者经营收入' },
    { value: '03', label: '投资收益收入' },
    { value: '04', label: '对企事业单位的承包经营、承租经营所得' },
    { value: '05', label: '劳务报酬' },
    { value: '06', label: '稿酬' },
    { value: '07', label: '特许权使用费' },
    { value: '08', label: '财产租赁' },
    { value: '09', label: '财产转让' },
    { value: '10', label: '偶然所得' },
    { value: '99', label: '其他' },
  ],
  MajorInd: [
    { value: '0', label: '否' },
    { value: '1', label: '是' },
  ],
  MrchFlg: [
    { value: '0', label: '否' },
    { value: '1', label: '是' },
  ],
  MrtlSt: [
    { value: '10', label: '未婚' },
    { value: '20', label: '已婚' },
    // { value: '21', label: '初婚' },
    // { value: '22', label: '再婚' },
    // { value: '23', label: '复婚' },
    { value: '30', label: '丧偶' },
    { value: '40', label: '离异' },
    { value: '90', label: '未说明的婚姻状况' },
  ],
  NtnEcnmSctrCd: [
    { value: 'A', label: '政府部门' },
    { value: 'A01', label: '中央政府' },
    { value: 'A02', label: '地方政府' },
    { value: 'A03', label: '社会保障基金' },
    { value: 'B', label: '金融机构部门' },
    { value: 'B01', label: '货币当局' },
    { value: 'B02', label: '监管当局' },
    { value: 'B03', label: '银行业存款类金融机构' },
    { value: 'B04', label: '银行业非存款类金融机构' },
    { value: 'B05', label: '证券业金融机构' },
    { value: 'B06', label: '保险业金融机构' },
    { value: 'B07', label: '交易及结算类金融机构' },
    { value: 'B08', label: '金融控股公司' },
    { value: 'B09', label: '特定目的载体' },
    { value: 'B99', label: '其他' },
    { value: 'C', label: '非金融企业部门' },
    { value: 'C01', label: '公司' },
    { value: 'C02', label: '非公司企业' },
    { value: 'C99', label: '其他非金融企业部门' },
    { value: 'D', label: '住户部门' },
    { value: 'D01', label: '住户' },
    { value: 'D02', label: '为住户服务的非营利机构' },
    { value: 'E', label: '国外部门' },
    { value: 'E01', label: '国际组织' },
    { value: 'E02', label: '外国政府' },
    { value: 'E03', label: '国外金融机构' },
    { value: 'E04', label: '国外非金融企业' },
    { value: 'E05', label: '外国居民' },
  ],
  NtntyCd: [
    { value: '156', label: '中国' },
    { value: '158', label: '中国台湾' },
    { value: '344', label: '中国香港' },
    { value: '446', label: '中国澳门' },
    { value: '004', label: '阿富汗' },
    { value: '008', label: '阿尔巴尼亚' },
    { value: '010', label: '南极洲' },
    { value: '012', label: '阿尔及利亚' },
    { value: '016', label: '美属萨摩亚' },
    { value: '020', label: '安道尔' },
    { value: '024', label: '安哥拉' },
    { value: '028', label: '安提瓜和巴布达' },
    { value: '031', label: '阿塞拜疆' },
    { value: '032', label: '阿根廷' },
    { value: '036', label: '澳大利亚' },
    { value: '040', label: '奥地利' },
    { value: '044', label: '巴哈马' },
    { value: '048', label: '巴林' },
    { value: '050', label: '孟加拉国' },
    { value: '051', label: '亚美尼亚' },
    { value: '052', label: '巴巴多斯' },
    { value: '056', label: '比利时' },
    { value: '060', label: '百慕大' },
    { value: '064', label: '不丹' },
    { value: '068', label: '玻利维亚' },
    { value: '070', label: '波斯尼亚和黑塞哥维那' },
    { value: '072', label: '博茨瓦纳' },
    { value: '074', label: '布维岛' },
    { value: '076', label: '巴西' },
    { value: '084', label: '伯利兹' },
    { value: '086', label: '英属印度洋领地' },
    { value: '090', label: '所罗门群岛' },
    { value: '092', label: '英属维尔京群岛' },
    { value: '096', label: '文莱' },
    { value: '100', label: '保加利亚' },
    { value: '104', label: '缅甸' },
    { value: '108', label: '布隆迪' },
    { value: '112', label: '白俄罗斯' },
    { value: '116', label: '柬埔寨' },
    { value: '120', label: '喀麦隆' },
    { value: '124', label: '加拿大' },
    { value: '132', label: '佛得角' },
    { value: '136', label: '开曼群岛' },
    { value: '140', label: '中非' },
    { value: '144', label: '斯里兰卡' },
    { value: '148', label: '乍得' },
    { value: '152', label: '智利' },
    { value: '162', label: '圣诞岛' },
    { value: '166', label: '科科斯（基林）群岛' },
    { value: '170', label: '哥伦比亚' },
    { value: '174', label: '科摩罗' },
    { value: '175', label: '马约特' },
    { value: '178', label: '刚果（布）' },
    { value: '180', label: '刚果（金）' },
    { value: '184', label: '库克群岛' },
    { value: '188', label: '哥斯达黎加' },
    { value: '191', label: '克罗地亚' },
    { value: '192', label: '古巴' },
    { value: '196', label: '塞浦路斯' },
    { value: '203', label: '捷克' },
    { value: '204', label: '贝宁' },
    { value: '208', label: '丹麦' },
    { value: '212', label: '多米尼克' },
    { value: '214', label: '多米尼加' },
    { value: '218', label: '厄瓜多尔' },
    { value: '222', label: '萨尔瓦多' },
    { value: '226', label: '赤道几内亚' },
    { value: '231', label: '埃塞俄比亚' },
    { value: '232', label: '厄立特里亚' },
    { value: '233', label: '爱沙尼亚' },
    { value: '234', label: '法罗群岛' },
    { value: '238', label: '福克兰群岛（马尔维纳斯）' },
    { value: '239', label: '南乔治亚岛和南桑德维奇岛' },
    { value: '242', label: '斐济' },
    { value: '246', label: '芬兰' },
    { value: '248', label: '奥兰群岛' },
    { value: '250', label: '法国' },
    { value: '254', label: '法属圭亚那' },
    { value: '258', label: '法属伯利尼西亚' },
    { value: '260', label: '法属南部领地' },
    { value: '262', label: '吉布提' },
    { value: '266', label: '加蓬' },
    { value: '268', label: '格鲁吉亚' },
    { value: '270', label: '冈比亚' },
    { value: '275', label: '巴勒斯坦' },
    { value: '276', label: '德国' },
    { value: '288', label: '加纳' },
    { value: '292', label: '直布罗陀' },
    { value: '296', label: '基里巴斯' },
    { value: '300', label: '希腊' },
    { value: '304', label: '格陵兰' },
    { value: '308', label: '格林纳达' },
    { value: '312', label: '瓜德罗普' },
    { value: '316', label: '关岛' },
    { value: '320', label: '危地马拉' },
    { value: '324', label: '几内亚' },
    { value: '328', label: '圭亚那' },
    { value: '332', label: '海地' },
    { value: '334', label: '赫德岛和麦克唐纳岛' },
    { value: '336', label: '梵蒂冈' },
    { value: '340', label: '洪都拉斯' },
    { value: '348', label: '匈牙利' },
    { value: '352', label: '冰岛' },
    { value: '356', label: '印度' },
    { value: '360', label: '印度尼西亚' },
    { value: '364', label: '伊朗' },
    { value: '368', label: '伊拉克' },
    { value: '372', label: '爱尔兰' },
    { value: '376', label: '以色列' },
    { value: '380', label: '意大利' },
    { value: '384', label: '科特迪瓦' },
    { value: '388', label: '牙买加' },
    { value: '392', label: '日本' },
    { value: '398', label: '哈萨克斯坦' },
    { value: '400', label: '约旦' },
    { value: '404', label: '肯尼亚' },
    { value: '408', label: '朝鲜' },
    { value: '410', label: '韩国' },
    { value: '414', label: '科威特' },
    { value: '417', label: '吉尔吉斯斯坦' },
    { value: '418', label: '老挝' },
    { value: '422', label: '黎巴嫩' },
    { value: '426', label: '莱索托' },
    { value: '428', label: '拉脱维亚' },
    { value: '430', label: '利比里亚' },
    { value: '434', label: '利比亚' },
    { value: '438', label: '列支敦士登' },
    { value: '440', label: '立陶宛' },
    { value: '442', label: '卢森堡' },
    { value: '450', label: '马达加斯加' },
    { value: '454', label: '马拉维' },
    { value: '458', label: '马来西亚' },
    { value: '462', label: '马尔代夫' },
    { value: '466', label: '马里' },
    { value: '470', label: '马耳他' },
    { value: '474', label: '马提尼克' },
    { value: '478', label: '毛里塔尼亚' },
    { value: '480', label: '毛里求斯' },
    { value: '484', label: '墨西哥' },
    { value: '492', label: '摩纳哥' },
    { value: '496', label: '蒙古' },
    { value: '498', label: '摩尔多瓦' },
    { value: '499', label: '黑山共和国' },
    { value: '500', label: '蒙特塞拉特' },
    { value: '504', label: '摩洛哥' },
    { value: '508', label: '莫桑比克' },
    { value: '512', label: '阿曼' },
    { value: '516', label: '纳米比亚' },
    { value: '520', label: '瑙鲁' },
    { value: '524', label: '尼泊尔' },
    { value: '528', label: '荷兰' },
    { value: '531', label: '库拉索' },
    { value: '533', label: '阿鲁巴' },
    { value: '534', label: '荷属圣马丁岛' },
    { value: '535', label: '博奈尔、圣尤斯特歇斯和萨巴岛' },
    { value: '540', label: '新喀里多尼亚' },
    { value: '548', label: '瓦努阿图' },
    { value: '554', label: '新西兰' },
    { value: '558', label: '尼加拉瓜' },
    { value: '562', label: '尼日尔' },
    { value: '566', label: '尼日利亚' },
    { value: '570', label: '纽埃' },
    { value: '574', label: '诺福克岛' },
    { value: '578', label: '挪威' },
    { value: '580', label: '北马里亚纳' },
    { value: '581', label: '美国本土外小岛屿' },
    { value: '583', label: '密克罗尼西亚联邦' },
    { value: '584', label: '马绍尔群岛' },
    { value: '585', label: '帕劳' },
    { value: '586', label: '巴基斯坦' },
    { value: '591', label: '巴拿马' },
    { value: '598', label: '巴布亚新几内亚' },
    { value: '600', label: '巴拉圭' },
    { value: '604', label: '秘鲁' },
    { value: '608', label: '菲律宾' },
    { value: '612', label: '皮特凯恩' },
    { value: '616', label: '波兰' },
    { value: '620', label: '葡萄牙' },
    { value: '624', label: '几内亚比绍' },
    { value: '626', label: '东帝汶' },
    { value: '630', label: '波多黎各' },
    { value: '634', label: '卡塔尔' },
    { value: '638', label: '留尼汪' },
    { value: '642', label: '罗马尼亚' },
    { value: '643', label: '俄罗斯联邦' },
    { value: '646', label: '卢旺达' },
    { value: '652', label: '圣巴泰勒米岛' },
    { value: '654', label: '圣赫勒拿' },
    { value: '659', label: '圣基茨和尼维斯' },
    { value: '660', label: '安圭拉' },
    { value: '662', label: '圣卢西亚' },
    { value: '663', label: '法属圣马丁' },
    { value: '666', label: '圣皮埃尔和密克隆' },
    { value: '670', label: '圣文森特和格林纳丁斯' },
    { value: '674', label: '圣马力诺' },
    { value: '678', label: '圣多美和普林西比' },
    { value: '682', label: '沙特阿拉伯' },
    { value: '686', label: '塞内加尔' },
    { value: '688', label: '塞尔维亚' },
    { value: '690', label: '塞舌尔' },
    { value: '694', label: '塞拉利昂' },
    { value: '702', label: '新加坡' },
    { value: '703', label: '斯洛伐克' },
    { value: '704', label: '越南' },
    { value: '705', label: '斯洛文尼亚' },
    { value: '706', label: '索马里' },
    { value: '710', label: '南非' },
    { value: '716', label: '津巴布韦' },
    { value: '724', label: '西班牙' },
    { value: '728', label: '南苏丹' },
    { value: '729', label: '苏丹' },
    { value: '732', label: '西撒哈拉' },
    { value: '740', label: '苏里南' },
    { value: '744', label: '斯瓦尔巴岛和养马延岛' },
    { value: '748', label: '斯威士兰' },
    { value: '752', label: '瑞典' },
    { value: '756', label: '瑞士' },
    { value: '760', label: '叙利亚' },
    { value: '762', label: '塔吉克斯坦' },
    { value: '764', label: '泰国' },
    { value: '768', label: '多哥' },
    { value: '772', label: '托克劳' },
    { value: '776', label: '汤加' },
    { value: '780', label: '特立尼达和多巴哥' },
    { value: '784', label: '阿联酋' },
    { value: '788', label: '突尼斯' },
    { value: '792', label: '土耳其' },
    { value: '795', label: '土库曼斯坦' },
    { value: '796', label: '特克斯和凯克斯群岛' },
    { value: '798', label: '图瓦卢' },
    { value: '800', label: '乌干达' },
    { value: '804', label: '乌克兰' },
    { value: '807', label: '前南马其顿' },
    { value: '818', label: '埃及' },
    { value: '826', label: '英国' },
    { value: '831', label: '格恩西岛' },
    { value: '832', label: '泽西岛' },
    { value: '833', label: '马恩岛' },
    { value: '834', label: '坦桑尼亚' },
    { value: '840', label: '美国' },
    { value: '850', label: '美属维尔京群岛' },
    { value: '854', label: '布基纳法索' },
    { value: '858', label: '乌拉圭' },
    { value: '860', label: '乌兹别克斯坦' },
    { value: '862', label: '委内瑞拉' },
    { value: '876', label: '瓦利斯和富图纳' },
    { value: '882', label: '萨摩亚' },
    { value: '887', label: '也门' },
    { value: '891', label: '塞尔维亚和黑山共和国' },
    { value: '894', label: '赞比亚' },
    { value: '999', label: '其他国家和地区' },
  ],
  OcpGrdg: [
    // { value: '0', label: '无' },
    { value: '1', label: '高级' },
    { value: '2', label: '中级' },
    { value: '3', label: '初级' },
    { value: '9', label: '未知' },
  ],
  Pst: [
    {
      value: '1',
      label: '高级领导（行政级别局级及局级以上领导或大公司高级管理人员）',
    },
    {
      value: '2',
      label: '中级领导（行政级别局级以下领导或大公司中级管理人员）',
    },
    { value: '3', label: '一般员工' },
    { value: '4', label: '其他' },
    { value: '9', label: '未知' },
  ],
  RaceCd: [
    // { value: '00', label: '空值' },
    { value: '01', label: '汉族' },
    { value: '02', label: '蒙古族' },
    { value: '03', label: '回族' },
    { value: '04', label: '藏族' },
    { value: '05', label: '维吾尔族' },
    { value: '06', label: '苗族' },
    { value: '07', label: '彝族' },
    { value: '08', label: '壮族' },
    { value: '09', label: '布依族' },
    { value: '10', label: '朝鲜族' },
    { value: '11', label: '满族' },
    { value: '12', label: '侗族' },
    { value: '13', label: '瑶族' },
    { value: '14', label: '白族' },
    { value: '15', label: '土家族' },
    { value: '16', label: '哈尼族' },
    { value: '17', label: '哈萨克族' },
    { value: '18', label: '傣族' },
    { value: '19', label: '黎族' },
    { value: '20', label: '傈僳族' },
    { value: '21', label: '佤族' },
    { value: '22', label: '畲族' },
    { value: '23', label: '高山族' },
    { value: '24', label: '拉祜族' },
    { value: '25', label: '水族' },
    { value: '26', label: '东乡族' },
    { value: '27', label: '纳西族' },
    { value: '28', label: '景颇族' },
    { value: '29', label: '柯尔克孜族' },
    { value: '30', label: '土族' },
    { value: '31', label: '达斡尔族' },
    { value: '32', label: '仫佬族' },
    { value: '33', label: '羌族' },
    { value: '34', label: '布朗族' },
    { value: '35', label: '撒拉族' },
    { value: '36', label: '毛南族' },
    { value: '37', label: '仡佬族' },
    { value: '38', label: '锡伯族' },
    { value: '39', label: '阿昌族' },
    { value: '40', label: '普米族' },
    { value: '41', label: '塔吉克族' },
    { value: '42', label: '怒族' },
    { value: '43', label: '乌孜别克族' },
    { value: '44', label: '俄罗斯族' },
    { value: '45', label: '鄂温克族' },
    { value: '46', label: '德昂族' },
    { value: '47', label: '保安族' },
    { value: '48', label: '裕固族' },
    { value: '49', label: '京族' },
    { value: '50', label: '塔塔尔族' },
    { value: '51', label: '独龙族' },
    { value: '52', label: '鄂伦春族' },
    { value: '53', label: '赫哲族' },
    { value: '54', label: '门巴族' },
    { value: '55', label: '珞巴族' },
    { value: '56', label: '基诺族' },
    { value: '97', label: '外国血统中国籍人士' },
    { value: '98', label: '其它' },
  ],
  RgstInfIntgrtyFlg: [
    { value: '0', label: '否' },
    { value: '1', label: '是' },
  ],
  RgstrtnFndCcyCd: [
    { value: '004', label: '阿富汗尼' },
    { value: '008', label: '列克' },
    { value: '012', label: '阿尔及利亚第纳尔' },
    { value: '020', label: '安道尔比塞塔' },
    { value: '031', label: '阿塞拜疆马纳特' },
    { value: '032', label: '阿根廷比索' },
    { value: '036', label: '澳大利亚元' },
    { value: '040', label: '先令' },
    { value: '044', label: '巴哈马元' },
    { value: '048', label: '巴林第纳尔' },
    { value: '050', label: '塔卡' },
    { value: '051', label: '亚美尼亚达姆' },
    { value: '052', label: '巴巴多斯元' },
    { value: '056', label: '比利时法郎' },
    { value: '060', label: '百慕大元' },
    { value: '064', label: '努尔特鲁姆' },
    { value: '068', label: '玻利瓦诺' },
    { value: '072', label: '普拉' },
    { value: '084', label: '伯利兹元' },
    { value: '090', label: '所罗门群岛元' },
    { value: '096', label: '文莱元' },
    { value: '100', label: '列弗' },
    { value: '104', label: '缅元' },
    { value: '108', label: '布隆迪法郎' },
    { value: '116', label: '瑞尔' },
    { value: '124', label: '加元' },
    { value: '132', label: '佛得角埃斯库多' },
    { value: '136', label: '开曼群岛元' },
    { value: '144', label: '斯里兰卡卢比' },
    { value: '152', label: '智利比索' },
    { value: '156', label: '人民币' },
    { value: '170', label: '哥伦比亚比索' },
    { value: '174', label: '科摩罗法郎' },
    { value: '188', label: '哥斯达黎加科郎' },
    { value: '191', label: '克罗地亚库纳' },
    { value: '192', label: '古巴比索' },
    { value: '196', label: '塞浦路斯镑' },
    { value: '203', label: '捷克克朗' },
    { value: '208', label: '丹麦克朗' },
    { value: '214', label: '多米尼加比索' },
    { value: '222', label: '萨尔瓦多科郎' },
    { value: '230', label: '埃塞俄比亚比尔' },
    { value: '232', label: '纳克法' },
    { value: '233', label: '克罗姆' },
    { value: '238', label: '福克兰群岛镑' },
    { value: '242', label: '斐济元' },
    { value: '246', label: '马克' },
    { value: '250', label: '法国法郎' },
    { value: '262', label: '吉布提法郎' },
    { value: '270', label: '达拉西' },
    { value: '276', label: '德国马克' },
    { value: '288', label: '塞地' },
    { value: '292', label: '直布罗陀镑' },
    { value: '300', label: '德拉克马' },
    { value: '320', label: '格查尔' },
    { value: '324', label: '几内亚法郎' },
    { value: '328', label: '圭亚那元' },
    { value: '332', label: '古德' },
    { value: '340', label: '伦皮拉' },
    { value: '344', label: '香港元' },
    { value: '348', label: '福林' },
    { value: '352', label: '冰岛克朗' },
    { value: '356', label: '印度卢比' },
    { value: '360', label: '卢比' },
    { value: '364', label: '伊朗里亚尔' },
    { value: '368', label: '伊拉克第纳尔' },
    { value: '372', label: '爱尔兰镑' },
    { value: '376', label: '新谢客尔' },
    { value: '380', label: '意大利里拉' },
    { value: '388', label: '牙买加元' },
    { value: '392', label: '日元' },
    { value: '398', label: '坚戈' },
    { value: '400', label: '约旦第纳尔' },
    { value: '404', label: '肯尼亚先令' },
    { value: '408', label: '北朝鲜圆' },
    { value: '410', label: '圆' },
    { value: '414', label: '科威特第纳尔' },
    { value: '417', label: '索姆' },
    { value: '418', label: '基普' },
    { value: '422', label: '黎巴嫩镑' },
    { value: '426', label: '罗提' },
    { value: '428', label: '拉脱维亚拉特' },
    { value: '430', label: '利比里亚元' },
    { value: '434', label: '利比亚第纳尔' },
    { value: '440', label: '立陶宛' },
    { value: '442', label: '卢森堡法郎' },
    { value: '446', label: '澳门元' },
    { value: '450', label: '马尔加什法郎' },
    { value: '454', label: '克瓦查' },
    { value: '458', label: '马来西亚林吉特' },
    { value: '462', label: '卢菲亚' },
    { value: '470', label: '马尔他里拉' },
    { value: '478', label: '乌吉亚' },
    { value: '480', label: '毛里求斯卢比' },
    { value: '484', label: '墨西哥比索' },
    { value: '496', label: '图格里克' },
    { value: '498', label: '摩尔瓦多列伊' },
    { value: '504', label: '摩洛哥迪拉姆' },
    { value: '508', label: '麦梯卡尔' },
    { value: '512', label: '阿曼里亚尔' },
    { value: '516', label: '纳米比亚元' },
    { value: '524', label: '尼泊尔卢比' },
    { value: '528', label: '荷兰盾' },
    { value: '532', label: '荷属安的列斯盾' },
    { value: '533', label: '阿鲁巴盾' },
    { value: '534', label: '卡塔尔里亚尔' },
    { value: '548', label: '瓦图' },
    { value: '554', label: '新西兰元' },
    { value: '558', label: '金科多巴' },
    { value: '566', label: '奈拉' },
    { value: '578', label: '挪威克朗' },
    { value: '586', label: '巴基斯坦卢比' },
    { value: '590', label: '巴波亚' },
    { value: '598', label: '基那' },
    { value: '600', label: '瓜拉尼' },
    { value: '604', label: '索尔' },
    { value: '608', label: '非律宾比索' },
    { value: '620', label: '葡萄牙埃斯库多' },
    { value: '624', label: '几内亚比绍比索' },
    { value: '626', label: '东帝汶埃斯库多' },
    { value: '642', label: '列伊' },
    { value: '643', label: '俄罗斯卢布' },
    { value: '646', label: '卢旺达法郎' },
    { value: '654', label: '圣赫勒拿磅' },
    { value: '678', label: '多布拉' },
    { value: '682', label: '沙特里亚尔' },
    { value: '690', label: '塞舌尔卢比' },
    { value: '694', label: '利昂' },
    { value: '702', label: '新加坡元' },
    { value: '703', label: '斯洛伐克克朗' },
    { value: '704', label: '盾' },
    { value: '705', label: '托拉尔' },
    { value: '706', label: '索马里先令' },
    { value: '710', label: '兰特' },
    { value: '716', label: '津巴布韦元' },
    { value: '724', label: '西班牙比塞塔' },
    { value: '736', label: '苏丹第纳尔' },
    { value: '740', label: '苏里南盾' },
    { value: '748', label: '里兰吉尼' },
    { value: '752', label: '瑞典克朗' },
    { value: '756', label: '瑞士法郎' },
    { value: '760', label: '叙利亚镑' },
    { value: '764', label: '铢' },
    { value: '776', label: '邦加' },
    { value: '780', label: '特立尼达和多巴哥元' },
    { value: '784', label: 'UAE迪拉姆' },
    { value: '788', label: '突尼斯第纳尔' },
    { value: '792', label: '土耳其里拉' },
    { value: '795', label: '马纳特' },
    { value: '800', label: '乌干达先令' },
    { value: '807', label: '第纳尔' },
    { value: '810', label: '俄罗斯卢布' },
    { value: '818', label: '埃及镑' },
    { value: '826', label: '英镑' },
    { value: '834', label: '坦桑尼亚先令' },
    { value: '840', label: '美元' },
    { value: '858', label: '乌拉圭比索' },
    { value: '860', label: '乌兹别克斯坦苏姆' },
    { value: '882', label: '塔拉' },
    { value: '886', label: '也门里亚尔' },
    { value: '891', label: '南斯拉夫第纳尔' },
    { value: '894', label: '克瓦查' },
    { value: '901', label: '新台湾元' },
    { value: '950', label: 'CFA法郎BEAC' },
    { value: '951', label: '东加勒比元' },
    { value: '952', label: 'CFA法郎BCEAO' },
    { value: '953', label: 'CFP法郎' },
    { value: '955', label: '欧洲货币合成单位' },
    { value: '956', label: '欧洲货币单位(E.M.U.-6)' },
    { value: '957', label: '欧洲账户9单位' },
    { value: '958', label: '欧洲账户17单位' },
    { value: '959', label: '黄金' },
    { value: '960', label: '特别提款权' },
    { value: '961', label: '银' },
    { value: '962', label: '铂白金' },
    { value: '963', label: '测试特别保留' },
    { value: '964', label: '钯' },
    { value: '972', label: '索莫尼' },
    { value: '973', label: '宽扎' },
    { value: '974', label: '白俄罗斯卢布' },
    { value: '975', label: '保加利亚列弗' },
    { value: '976', label: '刚果法郎' },
    { value: '977', label: '可自由兑换标记' },
    { value: '978', label: '欧元' },
    { value: '979', label: '墨西哥发展单位' },
    { value: '980', label: '格里夫纳' },
    { value: '984', label: 'Mvdol(玻利维亚)' },
    { value: '985', label: '兹罗提' },
    { value: '986', label: '巴西瑞尔' },
    { value: '98l', label: '拉里' },
    { value: '990', label: '发展单位' },
    { value: '993', label: 'UIC法郎' },
    { value: '994', label: '黄金法郎' },
    { value: '997', label: '(次日)' },
    { value: '998', label: '(同日)' },
    { value: '999', label: '未包括的交易货币' },
  ],
  RltnpTp: [
    { value: '01', label: '高管及主要关系人（公司与个人关系）' },
    { value: '0100', label: '法定代表人（高管）' },
    { value: '0101', label: '董事长（高管）' },
    { value: '0102', label: '总经理/主要负责人/厂长/CEO（高管）' },
    { value: '0103', label: '财务负责人/CFO（高管）' },
    { value: '0104', label: '授权经办人（高管）' },
    { value: '0105', label: '部门经理（高管）' },
    { value: '0106', label: '董事（高管）' },
    { value: '0107', label: '监事长' },
    { value: '0108', label: '监事（高管）' },
    { value: '0109', label: '实际控制人（高管）' },
    { value: '0110', label: '经营者（个体工商户）' },
    { value: '0111', label: '企业主(控制企业的个人)' },
    { value: '0112', label: '受益所有人' },
    { value: '0113', label: '股东' },
    { value: '0199', label: '其他' },
    { value: '02', label: '对外投资(个人与公司关系)' },
    { value: '0200', label: '资金和实物（投资）' },
    { value: '0201', label: '资金（投资）' },
    { value: '0202', label: '技术（投资）' },
    { value: '0203', label: '实物（投资）' },
    { value: '0204', label: '权利（投资）' },
    { value: '0205', label: '其他（投资）' },
    { value: '03', label: '个人与个人关系(家庭关系)' },
    { value: '0301', label: '配偶' },
    { value: '0302', label: '父母' },
    { value: '0303', label: '子女' },
    { value: '0304', label: '其他血亲' },
    { value: '0305', label: '其他姻亲' },
    { value: '04', label: '关联集团（公司与公司关系）' },
    { value: '0401', label: '主体成员' },
    { value: '0402', label: '一般成员' },
    { value: '0403', label: '外围成员' },
    { value: '05', label: '联保小组' },
    { value: '0501', label: '小组成员' },
    { value: '06', label: '家庭关系（法人代表）' },
    { value: '0601', label: '配偶（法人代表）' },
    { value: '0602', label: '父母（法人代表）' },
    { value: '0603', label: '子女（法人代表）' },
    { value: '0604', label: '其他血亲（法人代表）' },
    { value: '0605', label: '其他姻亲（法人代表）' },
    { value: '07', label: '拥有企业' },
    { value: '08', label: '隐性关联关系' },
    { value: '0801', label: '一般关联' },
    { value: '0802', label: '强关联' },
    { value: '0803', label: '无关联' },
    { value: '100', label: '经营实体' },
    { value: '105', label: '经营实体（控制人）' },
    { value: '51', label: '任职(个人与公司关系)' },
    { value: '5100', label: '法人代表（任职）' },
    { value: '5101', label: '公司董事长（任职）' },
    { value: '5102', label: '公司总经理/厂长/CEO（任职）' },
    { value: '5103', label: '财务主管/CFO（任职）' },
    { value: '5104', label: '授权经办人（任职）' },
    { value: '5105', label: '部门经理（任职）' },
    { value: '5106', label: '董事（任职）' },
    { value: '5107', label: '监事长（任职）' },
    { value: '5108', label: '监事（任职）' },
    { value: '5109', label: '实际控制人（任职）' },
    { value: '5110', label: '经营者（个体工商户）' },
    { value: '5199', label: '其他' },
    { value: '52', label: '资本构成(公司与个人关系)' },
    { value: '5200', label: '资金和实物（资本构成）' },
    { value: '5201', label: '资金（资本构成）' },
    { value: '5202', label: '技术（资本构成）' },
    { value: '5203', label: '实物（资本构成）' },
    { value: '5204', label: '权利（资本构成）' },
    { value: '5205', label: '其他（资本构成）' },
    { value: '54', label: '关联集团（公司与公司关系）' },
    { value: '5401', label: '所属集团' },
    { value: '55', label: '主要关联企业（公司与公司关系）' },
    { value: '55', label: '联保小组' },
    { value: '5501', label: '企业实际控制人' },
    { value: '5501', label: '所属小组' },
    {
      value: '5502',
      label: '除本企业的股东外直接或间接控制本企业的关联企业。',
    },
    { value: '5503', label: '本企业直接或间接控制的关联企业。' },
    { value: '5504', label: '直接或间接被同一控制方控制的关联企业。' },
    { value: '5505', label: '符合《企业会计制度》第147条规定的其他关联企业。' },
    { value: '56', label: '主要关联企业（公司与公司关系）' },
    { value: '5600', label: '实际控制人（企业）' },
    {
      value: '5601',
      label: '除本企业的股东外直接或间接控制本企业的关联企业。',
    },
    { value: '5602', label: '本企业直接或间接控制的关联企业。' },
    { value: '5603', label: '直接或间接被同一控制方控制的关联企业。' },
    { value: '5604', label: '符合《企业会计制度》第147条规定的其他关联企业。' },
    { value: '58', label: '下属单位' },
    { value: '59', label: '上级机构（主管单位）' },
    { value: '88', label: '待定' },
    { value: '97', label: '承包人' },
    { value: '98', label: '合伙人' },
    { value: '9801', label: '实际控制人或控股股东关系' },
    { value: '99', label: '其他' },
    { value: '9901', label: '供应商' },
    { value: '9951', label: '销售商' },
    { value: '9952', label: '关系人控股' },
    { value: '9953', label: '子母公司' },
    { value: '9954', label: '其他' },
  ],
  RsdnCstSt: [
    // { value: '0', label: '未知' },
    { value: '1', label: '居民' },
    { value: '2', label: '非居民' },
    // { value: '9', label: '其他' },
  ],
  RsdntDsc: [
    // { value: '0', label: '未知' },
    { value: '1', label: '自置' },
    { value: '2', label: '按揭' },
    { value: '3', label: '亲属楼宇' },
    { value: '4', label: '集体宿舍' },
    { value: '5', label: '租房' },
    { value: '6', label: '共有住宅' },
    { value: '9', label: '其他' },
  ],
  TechEntpFlg: [
    { value: '1', label: '是' },
    { value: '0', label: '否' },
  ],
  UdtftCheckInd: [
    { value: '0', label: '否' },
    { value: '1', label: '是' },
  ],
  WorkCorpTypeCD: [
    { value: '100', label: '党政机关' },
    { value: '200', label: '事业单位' },
    { value: '300', label: '军队' },
    { value: '400', label: '社会团体' },
    { value: '500', label: '内资企业' },
    { value: '510', label: '国有企业' },
    { value: '520', label: '集体企业' },
    { value: '530', label: '股份合作企业' },
    { value: '540', label: '联营企业' },
    { value: '550', label: '有限责任公司' },
    { value: '560', label: '股份有限公司' },
    { value: '570', label: '私营企业' },
    { value: '600', label: '外商投资企业(含港、澳、台)' },
    { value: '610', label: '中外合资经营企业(含港、澳、台)' },
    { value: '620', label: '中外合作经营企业(含港、澳、台)' },
    { value: '630', label: '外资企业(含港、澳、台)' },
    { value: '640', label: '外商投资股份有限公司(含港、澳、台)' },
    { value: '700', label: '个体经营' },
    { value: '800', label: '其他' },
    { value: '900', label: '未知' },
  ],
  WorkStatCD: [
    { value: '11', label: '国家公务员' },
    { value: '13', label: '专业技术人员' },
    { value: '17', label: '职员' },
    { value: '21', label: '企业管理人员' },
    { value: '24', label: '工人' },
    { value: '27', label: '农民' },
    { value: '31', label: '学生' },
    { value: '37', label: '现役军人' },
    { value: '51', label: '自由职业者' },
    { value: '54', label: '个体经营者' },
    { value: '70', label: '无业人员' },
    { value: '80', label: '退（离）休人员' },
    { value: '90', label: '其他' },
  ],
  isexpcer: [
    { value: '1', label: '是' },
    { value: '0', label: '否' },
  ],
  microbizind: [
    { value: '1', label: '是' },
    { value: '0', label: '否' },
  ],
  recdstatus: [
    { value: '1', label: '是' },
    { value: '0', label: '否' },
  ],
  InvFrmUsg: [
    { value: 'A', label: '非支农' },
    { value: 'B', label: '支农' },
    { value: 'B01', label: '农田基本建设' },
    { value: 'B02', label: '农产品加工' },
    { value: 'B03', label: '农业生产资料制造' },
    { value: 'B04', label: '农用物资和农副产品流通' },
    { value: 'B041', label: '农产品出口' },
    { value: 'B042', label: '非农产品出口' },
    { value: 'B05', label: '农业科技' },
    { value: 'B06', label: '农村基础设施建设' },
  ],
  whethen1: [
    { value: '010', label: '法定代表人' },
    { value: '020', label: '股东 ' },
    { value: '030', label: '合伙人 ' },
    { value: '040', label: '承包人 ' },
    { value: '050', label: '实控人' },
  ],
  AccountClass: [
    { value: '0', label: '活期'},
    { value: '6', label: '冻结'},
    { value: '7', label: '贷款'},
    { value: '2', label: '保证'},
  ],
  LoanAppStatus: [
    { label: '未申请', value: '01', color:''},
    { label: '审批中', value: '02', color:'blue'},
    { label: '审批拒绝', value: '03', color: 'red'},
    { label: '审批通过未签约', value: '04', color:'cyan'},
    { label: '额度已过期', value: '05', color:'orange'},
    { label: '已签约', value: '06', color:'green'},
    { label: '无权限', value: '07', color:'grey'},
    { label: '已作废', value: '08', color:'orange'},
    { label: '申请失败', value: '09', color:'grey'},
  ],
  BankNo:[
    {label:"浙江稠州商业银行",value:"313338707013"},
    {label:"中国工商银行",value:"102100099996"},
    {label:"中国农业银行股份有限公司",value:"103100000026"},
    {label:"中国银行总行",value:"104100000004"},
    {label:"中国建设银行股份有限公司总行",value:"105100000017"},
    {label:"交通银行",value:"301290000007"},
    {label:"中信银行股份有限公司",value:"302100011000"},
    {label:"中国光大银行",value:"303100000006"},
    {label:"华夏银行股份有限公司总行",value:"304100040000"},
    {label:"中国民生银行",value:"305100000013"},
    {label:"广发银行股份有限公司",value:"306581000003"},
    {label:"平安银行（原深圳发展银行）",value:"307584007998"},
    {label:"招商银行股份有限公司",value:"308584000013"},
    {label:"兴业银行总行",value:"309391000011"},
    {label:"上海浦东发展银行",value:"310290000013"},
    {label:"北京银行",value:"313100000013"},
    {label:"天津银行股份有限公司",value:"313110000017"},
    {label:"河北银行股份有限公司",value:"313121006888"},
    {label:"唐山银行股份有限公司",value:"313124000018"},
    {label:"秦皇岛银行股份有限公司",value:"313126001022"},
    {label:"邯郸市商业银行股份有限公司",value:"313127000013"},
    {label:"邢台银行股份有限公司",value:"313131000016"},
    {label:"保定银行股份有限公司",value:"313134000011"},
    {label:"张家口市商业银行股份有限公司",value:"313138000019"},
    {label:"张家口银行股份有限公司",value:"313138000019"},
    {label:"承德银行股份有限公司",value:"313141052422"},
    {label:"沧州银行",value:"313143005157"},
    {label:"廊坊银行股份有限公司",value:"313146000019"},
    {label:"衡水市商业银行股份有限公司",value:"313148053964"},
    {label:"衡水银行股份有限公司",value:"313148053964"},
    {label:"晋商银行股份有限公司",value:"313161000017"},
    {label:"长治银行",value:"313164000006"},
    {label:"晋城市商业银行",value:"313168000003"},
    {label:"晋城银行",value:"313168000003"},
    {label:"晋中银行",value:"313175000011"},
    {label:"内蒙古银行",value:"313191000011"},
    {label:"包商银行股份有限公司",value:"313192000013"},
    {label:"乌海银行股份有限公司",value:"313193057846"},
    {label:"鄂尔多斯银行股份有限公司",value:"313205057830"},
    {label:"盛京银行清算中心",value:"313221030008"},
    {label:"大连银行",value:"313222080002"},
    {label:"鞍山市商业银行",value:"313223007007"},
    {label:"抚顺银行",value:"313224000015"},
    {label:"丹东银行清算中心",value:"313226009000"},
    {label:"锦州银行",value:"313227000012"},
    {label:"葫芦岛银行股份有限公司",value:"313227600018"},
    {label:"营口银行股份有限公司资金清算中心",value:"313228000276"},
    {label:"营口沿海银行",value:"313228060009"},
    {label:"阜新银行结算中心",value:"313229000008"},
    {label:"辽阳银行",value:"313231000013"},
    {label:"朝阳银行股份有限公司",value:"313234001089"},
    {label:"吉林银行",value:"313241066661"},
    {label:"哈尔滨银行",value:"313261000018"},
    {label:"哈尔滨银行结算中心",value:"313261000018"},
    {label:"龙江银行股份有限公司",value:"313261099913"},
    {label:"南京银行股份有限公司",value:"313301008887"},
    {label:"江苏银行股份有限公司",value:"313301099999"},
    {label:"苏州银行",value:"313305066661"},
    {label:"苏州银行股份有限公司",value:"313305066661"},
    {label:"江苏长江商业银行",value:"313312300018"},
    {label:"杭州银行股份有限公司",value:"313331000014"},
    {label:"宁波东海银行",value:"313332040018"},
    {label:"宁波银行股份有限公司",value:"313332082914"},
    {label:"宁波通商银行股份有限公司",value:"313332090019"},
    {label:"温州银行股份有限公司",value:"313333007331"},
    {label:"湖州银行股份有限公司",value:"313336071575"},
    {label:"绍兴银行股份有限公司营业部",value:"313337009004"},
    {label:"金华银行股份有限公司",value:"313338009688"},
    {label:"台州银行股份有限公司",value:"313345001665"},
    {label:"浙江泰隆商业银行",value:"313345010019"},
    {label:"浙江民泰商业银行",value:"313345400010"},
    {label:"福建海峡银行股份有限公司",value:"313391080007"},
    {label:"厦门银行",value:"313393080005"},
    {label:"厦门银行股份有限公司",value:"313393080005"},
    {label:"泉州银行股份有限公司",value:"313397075189"},
    {label:"南昌银行",value:"313421087506"},
    {label:"九江银行",value:"313424076706"},
    {label:"九江银行股份有限公司",value:"313424076706"},
    {label:"赣州银行股份有限公司",value:"313428076517"},
    {label:"上饶银行",value:"313433076801"},
    {label:"齐鲁银行",value:"313451000019"},
    {label:"青岛银行",value:"313452060150"},
    {label:"齐商银行",value:"313453001017"},
    {label:"枣庄银行股份有限公司",value:"313454000016"},
    {label:"东营市商业银行",value:"313455000018"},
    {label:"烟台银行股份有限公司",value:"313456000108"},
    {label:"潍坊银行",value:"313458000013"},
    {label:"济宁银行股份有限公司",value:"313461000012"},
    {label:"泰安市商业银行",value:"313463000993"},
    {label:"泰安银行股份有限公司",value:"313463000993"},
    {label:"莱商银行",value:"313463400019"},
    {label:"威海市商业银行",value:"313465000010"},
    {label:"德州银行股份有限公司",value:"313468000015"},
    {label:"临商银行股份有限公司",value:"313473070018"},
    {label:"日照银行股份有限公司",value:"313473200011"},
    {label:"郑州银行",value:"313491000232"},
    {label:"中原银行股份有限公司",value:"313491099996"},
    {label:"洛阳银行",value:"313493080539"},
    {label:"平顶山银行股份有限公司",value:"313495081900"},
    {label:"焦作中旅银行股份有限公司",value:"313501080608"},
    {label:"漯河市商业银行",value:"313504000010"},
    {label:"商丘市商业银行股份有限公司",value:"313506082510"},
    {label:"南阳市商业银行",value:"313513080408"},
    {label:"汉口银行资金清算中心",value:"313521000011"},
    {label:"湖北银行股份有限公司",value:"313521006000"},
    {label:"华融湘江银行股份有限公司",value:"313551070008"},
    {label:"长沙银行股份有限公司",value:"313551088886"},
    {label:"广州银行",value:"313581003284"},
    {label:"珠海华润银行股份有限公司清算中心",value:"313585000990"},
    {label:"广东华兴银行股份有限公司",value:"313586000006"},
    {label:"广东南粤银行股份有限公司",value:"313591001001"},
    {label:"东莞银行股份有限公司",value:"313602088017"},
    {label:"广西北部湾银行",value:"313611001018"},
    {label:"柳州银行股份有限公司清算中心",value:"313614000012"},
    {label:"桂林银行股份有限公司",value:"313617000018"},
    {label:"海南银行股份有限公司",value:"313641099995"},
    {label:"成都银行",value:"313651099999"},
    {label:"重庆银行",value:"313653000013"},
    {label:"自贡银行股份有限公司",value:"313655091983"},
    {label:"攀枝花市商业银行",value:"313656000019"},
    {label:"泸州市商业银行",value:"313657092617"},
    {label:"德阳银行股份有限公司",value:"313658000014"},
    {label:"绵阳市商业银行",value:"313659000016"},
    {label:"遂宁银行",value:"313662000015"},
    {label:"乐山市商业银行",value:"313665092924"},
    {label:"宜宾市商业银行",value:"313671000017"},
    {label:"四川天府银行股份有限公司",value:"313673093259"},
    {label:"贵阳市商业银行",value:"313701098010"},
    {label:"贵州银行",value:"313701099012"},
    {label:"贵州银行股份有限公司",value:"313701099012"},
    {label:"富滇银行股份有限公司运营管理部",value:"313731010015"},
    {label:"曲靖市商业银行",value:"313736000019"},
    {label:"云南红塔银行股份有限公司",value:"313741095715"},
    {label:"西安银行股份有限公司",value:"313791000015"},
    {label:"长安银行股份有限公司",value:"313791030003"},
    {label:"兰州银行股份有限公司",value:"313821001016"},
    {label:"甘肃银行股份有限公司",value:"313821050016"},
    {label:"青海银行股份有限公司营业部",value:"313851000018"},
    {label:"宁夏银行",value:"313871000007"},
    {label:"宁夏银行总行清算中心",value:"313871000007"},
    {label:"石嘴山银行股份有限公司",value:"313872097457"},
    {label:"乌鲁木齐市商业银行清算中心",value:"313881000002"},
    {label:"昆仑银行股份有限公司",value:"313882000012"},
    {label:"天津滨海农村商业银行股份有限公司",value:"314110000011"},
    {label:"江苏江南农村商业银行股份有限公司(不对外)",value:"314304099999"},
    {label:"昆山农村商业银行",value:"314305206650"},
    {label:"吴江农村商业银行清算中心",value:"314305400015"},
    {label:"江苏常熟农村商业银行股份有限公司清算中心",value:"314305506621"},
    {label:"张家港农村商业银行",value:"314305670002"},
    {label:"广州农村商业银行股份有限公司",value:"314581000011"},
    {label:"佛山顺德农村商业银行股份有限公司",value:"314588000016"},
    {label:"海口联合农村商业银行股份有限公司",value:"314641000014"},
    {label:"成都农商银行",value:"314651000000"},
    {label:"重庆农村商业银行股份有限公司",value:"314653000011"},
    {label:"恒丰银行",value:"315456000105"},
    {label:"浙商银行",value:"316331000018"},
    {label:"天津农村合作银行",value:"317110010019"},
    {label:"渤海银行股份有限公司",value:"318110000014"},
    {label:"徽商银行股份有限公司",value:"319361000013"},
    {label:"北京顺义银座村镇银行股份有限公司",value:"320100010011"},
    {label:"宁波市海曙国民村镇银行有限责任公司",value:"320332000015"},
    {label:"象山国民村镇银行股份有限公司",value:"320332100016"},
    {label:"浙江景宁银座村镇银行股份有限公司",value:"320343800019"},
    {label:"浙江龙泉民泰村镇银行股份有限公司",value:"320343900011"},
    {label:"浙江三门银座村镇银行股份有限公司",value:"320345790018"},
    {label:"江西赣州银座村镇银行股份有限公司",value:"320428090311"},
    {label:"东营莱商村镇银行股份有限公司",value:"320455000019"},
    {label:"深圳福田银座村镇银行股份有限公司",value:"320584002002"},
    {label:"广西上林国民村镇银行有限责任公司",value:"320611000067"},
    {label:"广西银海国民村镇银行有限责任公司",value:"320623000015"},
    {label:"合浦国民村镇银行有限责任公司",value:"320623100016"},
    {label:"平果国民村镇银行有限责任公司",value:"320626400049"},
    {label:"广西钦州市钦南国民村镇银行有限责任公司",value:"320631100016"},
    {label:"广西浦北国民村镇银行有限责任公司",value:"320631500019"},
    {label:"防城港防城国民村镇银行有限责任公司",value:"320633000027"},
    {label:"东兴国民村镇银行有限责任公司",value:"320633100011"},
    {label:"重庆渝北银座村镇银行股份有限公司",value:"320653000104"},
    {label:"重庆九龙坡民泰村镇银行股份有限公司",value:"320653000137"},
    {label:"重庆黔江银座村镇银行股份有限公司",value:"320687000016"},
    {label:"重庆彭水民泰村镇银行股份有限公司",value:"320687500011"},
    {label:"新疆绿洲国民村镇银行有限责任公司",value:"320881000011"},
    {label:"克拉玛依金龙国民村镇银行有限责任公司",value:"320882000013"},
    {label:"哈密红星国民村镇银行有限责任公司",value:"320884000025"},
    {label:"北屯国民村镇银行有限责任公司",value:"320884400011"},
    {label:"昌吉国民村镇银行有限责任公司",value:"320885099990"},
    {label:"五家渠国民村镇银行有限责任公司",value:"320885800018"},
    {label:"博乐国民村镇银行有限责任公司",value:"320887000014"},
    {label:"伊犁国民村镇银行有限责任公司",value:"320898000010"},
    {label:"奎屯国民村镇银行有限责任公司",value:"320898100019"},
    {label:"石河子国民村镇银行有限责任公司",value:"320902800016"},
    {label:"重庆三峡银行股份有限公司",value:"321667090019"},
    {label:"上海农村商业银行",value:"322290000011"},
    {label:"天津金城银行",value:"323110000008"},
    {label:"上海华瑞银行",value:"323290000016"},
    {label:"温州民商银行股份有限公司",value:"323333000013"},
    {label:"深圳前海微众银行股份有限公司",value:"323584000888"},
    {label:"重庆富民银行股份有限公司",value:"323653010015"},
    {label:"上海银行股份有限公司",value:"325290000012"},
    {label:"北京农村商业银行股份有限公司",value:"402100000018"},
    {label:"内蒙古自治区农村信用社联合社",value:"402191009992"},
    {label:"辽宁省农村信用社联合社运营管理部",value:"402221010013"},
    {label:"江苏省农村信用社联合社",value:"402301099998"},
    {label:"江苏省农村信用社联合社信息结算中心",value:"402301099998"},
    {label:"浙江省农村信用社联合社",value:"402331000007"},
    {label:"宁波鄞州农村合作银行",value:"402332010004"},
    {label:"宁波鄞州农村商业银行股份有限公司(鄞州银行)",value:"402332010004"},
    {label:"安徽省农村信用联社资金清算中心",value:"402361018886"},
    {label:"福建省农村信用社联合社",value:"402391000068"},
    {label:"江西省农村信用社联合社",value:"402421099990"},
    {label:"河南省农村信用社联合社资金清算中心(不转汇)",value:"402491000026"},
    {label:"湖北省农村信用社联合社结算中心",value:"402521000032"},
    {label:"武汉农村商业银行股份有限公司",value:"402521090019"},
    {label:"湖南省农村信用社联合社",value:"402551080008"},
    {label:"广东省农村信用社联合社",value:"402581090008"},
    {label:"深圳农村商业银行",value:"402584009991"},
    {label:"深圳农村商业银行股份有限公司",value:"402584009991"},
    {label:"东莞农村商业银行股份有限公司",value:"402602000018"},
    {label:"广西壮族自治区农村信用社联合社",value:"402611099974"},
    {label:"海南省农村信用社联合社资金清算中心",value:"402641000014"},
    {label:"四川省农村信用社联合社",value:"402651020006"},
    {label:"贵州省农村信用社联合社",value:"402701002999"},
    {label:"云南省农村信用社联合社",value:"402731057238"},
    {label:"陕西省农村信用社联合社资金清算中心",value:"402791000010"},
    {label:"宁夏黄河农村商业银行股份有限公司",value:"402871099996"},
    {label:"中国邮政储蓄银行有限责任公司",value:"403100000004"},
    {label:"东亚银行（中国）有限公司",value:"502290000006"},
    {label:"花旗银行(中国)有限公司",value:"531290088881"},
    {label:"友利银行(中国)有限公司",value:"593100000020"},
    {label:"新韩银行（中国）有限公司",value:"595100000007"},
    {label:"企业银行（中国）有限公司",value:"596110000013"},
    {label:"韩亚银行（中国）有限公司",value:"597100000014"},
    {label:"星展银行",value:"623290000019"},
    {label:"中德住房储蓄银行",value:"717110000010"},
    {label:"厦门国际银行股份有限公司",value:"781393010011"},
    {label:"富邦华一银行有限公司",value:"787290000019"},
    {label:"支付机构备付金集中存管账户",value:"991290000015"},
    {label:"浙江网商银行股份有限公司",value:"323331000001"},
    {label:"嘉兴银行股份有限公司营业部",value:"313335081013"},
  ],

  monthList: [
    {value: 1, label: '1月'},
    {value: 2, label: '2月'},
    {value: 3, label: '3月'},
    {value: 4, label: '4月'},
    {value: 5, label: '5月'},
    {value: 6, label: '6月'},
    {value: 7, label: '7月'},
    {value: 8, label: '8月'},
    {value: 9, label: '9月'},
    {value: 10, label: '10月'},
    {value: 11, label: '11月'},
    {value: 12, label: '12月'}
  ]
  ,

  yearList: [
    {value: 2024, label: '2024年'},
    {value: 2025, label: '2025年'},
    {value: 2026, label: '2026年'},
    {value: 2027, label: '2027年'},
    {value: 2028, label: '2028年'},
    {value: 2029, label: '2029年'},
    {value: 2030, label: '2030年'},
    {value: 2031, label: '2031年'},
    {value: 2032, label: '2032年'},
    {value: 2033, label: '2033年'},
    {value: 2034, label: '2034年'},
    {value: 2035, label: '2035年'},
    {value: 2036, label: '2036年'},
    {value: 2037, label: '2037年'},
    {value: 2038, label: '2038年'},
    {value: 2039, label: '2039年'},
    {value: 2040, label: '2040年'},
    {value: 2041, label: '2041年'},
    {value: 2042, label: '2042年'},
    {value: 2043, label: '2043年'},
    {value: 2044, label: '2044年'},
    {value: 2045, label: '2045年'},
    {value: 2046, label: '2046年'},
    {value: 2047, label: '2047年'},
    {value: 2048, label: '2048年'},
    {value: 2049, label: '2049年'},
    {value: 2050, label: '2050年'},
    {value: 2051, label: '2051年'}

  ]
}
