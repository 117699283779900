<template>

  <a-card style="margin: 20px;">
    <template #title>
      <div class="top-title">
        <div class="left">
          <span>发票匹配</span>
        </div>
      </div>
    </template>
    <div>

      <a-form-model ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-form-model-item label="开票日期" ref="invoiceDate">
          <a-date-picker v-model="form.invoiceDate" placeholder="请选择开票日期" valueFormat="YYYY-MM-DD" :disabled="true"/>
        </a-form-model-item>


        <a-form-model-item ref="mchNo" label="商户">
          <a-select class="dianshang" :dropdownMatchSelectWidth="false" placeholder="请选择商户" :show-search="true" v-model.trim="form.mchNo"
                    style="width: 100%;" @change="getShopList">
            <a-select-option v-for="item in mchNolist" :key="item.value" :value="item.value">
              <div style="display: flex; justify-content: space-between">
                <span> {{ item.label }} </span>
                <code style="color: grey;font-size: 0.8em">{{ item.value }}</code>
              </div>
            </a-select-option>
          </a-select>
        </a-form-model-item>


        <a-form-model-item ref="shopNo" label="收款账号">
          <a-select :dropdownMatchSelectWidth="false" placeholder="请选择回款账号" :show-search="true" v-model.trim="form.shopNo"
                    style="width: 100%;" @change="onShopChange">
            <a-select-option v-for="item in shoplist" :key="item.shopNo" :value="item.shopNo">
              <div style="display: flex; justify-content: space-between">
                <span>{{ item.shopName }}</span>
                <code style="color: grey;font-size: 0.8em">{{ item.refundAccountNo }}</code></div>
            </a-select-option>
          </a-select>
        </a-form-model-item>


        <a-form-model-item ref="collAccountName" label="归集账户">
          <WalletInfo :accountName="form.shopInfo.collAccountName" :accountNo="form.shopInfo.collAccountNo" :showAccountName="true"/>
        </a-form-model-item>


        <a-form-model-item label="发票金额">
          <a-input v-model="form.totalAmount" :disabled="true"/>
        </a-form-model-item>
        <a-form-model-item label="匹配金额">
          <a-input v-model="form.matchedRefundAmount" :disabled="true"/>
        </a-form-model-item>
        <div class="tablebox">
          <a-transfer
              :data-source="pipelinedata"
              :operations="['匹配', '取消匹配']"
              :show-search="true"
              :list-style="{   height: '800px' }"
              :filter-option="(inputValue, item) => item.title.indexOf(inputValue) !== -1"
              :target-keys="targetKeys"
              :show-select-all="false"
              @change="onChange"
          >
            <template
                slot="children"
                slot-scope="{ props: { direction, filteredItems, selectedKeys, disabled: listDisabled }, on: { itemSelectAll, itemSelect } }"
            >
              <a-table
                  :row-selection="getRowSelection({ disabled: listDisabled, selectedKeys, itemSelectAll, itemSelect })"
                  :columns="direction === 'left' ? leftColumns : rightColumns"
                  :data-source="filteredItems"
                  size="small"
                  :pagination="false"
                  :style="{ pointerEvents: listDisabled ? 'none' : null, width: '100%' }"
                  :scroll="{ y: 600 }"
              />
              <!--                  :row-key="record => record.id"-->
            </template>
          </a-transfer>
        </div>
      </a-form-model>
    </div>


    <div class="buttons">
      <a-button type="primary" @click="normalInvoice">匹配</a-button>
      <a-button @click="cancelInvoice">取消</a-button>
    </div>

  </a-card>

</template>

<script>
import {dict_rawdata as busiDict} from "@/utils/bizDict/rawdata";
import urlConfig from "@/utils/urlConfig";
import difference from 'lodash/difference';
import WalletInfo from "@/view/components/WalletInfo.vue";
import Balance from '@/view/components/Balance.vue'

const token = sessionStorage.getItem("token");


export default {
  name: 'MchShopInvoiceNormal',
  components: {WalletInfo},
  data() {
    return {
      pipelinedata: [], // 存储未匹配的流水数据
      pipelinedataMap: {}, // 存储未匹配流水数据的映射，键为 depositSerialNo，值为 unmatchedAmount
      originalPipelinedataMap: {}, // 原始存储未匹配流水数据的映射，键为 depositSerialNo，值为 unmatchedAmount
      targetKeys: [], // 目标键（选中的流水项）
      filteredItems: [], // 存储已匹配的流水数据

      depositSerialNos: [], // 存储原本已匹配的流水号
      matchAmountTotal: 0.0,


      leftColumns: [
        {
          dataIndex: 'tradeTime',
          title: '时间'
        },
        {
          dataIndex: 'transAmount',
          key: 'transAmount',
          title: '交易金额',
          customRender: (text) => {
            return <Balance amount={text} dcflag="b"></Balance>
          }
        },
        {
          dataIndex: 'matchAmount',
          title: '已匹配金额',
          customRender: (text) => {
            return <Balance amount={text} dcflag="d"></Balance>
          }
        },
        {
          dataIndex: 'oppAccountNo',
          title: '付款账号',
          customRender: (text, record) => {
            return <WalletInfo accountName={record.oppAccountName}
                               accountNo={text}
                               showAccountNo={false}
                               showAccountName={true}
                               isWallet={false}
                               showBalance={false}
                               showBank={false}

            ></WalletInfo>
          }
        },
      ],
      rightColumns: [
        {
          dataIndex: 'tradeTime',
          title: '时间'
        },
        {
          dataIndex: 'transAmount',
          title: '交易金额',
          customRender: (text) => {
            return <Balance amount={text} dcflag="b"></Balance>
          }
        },
        {
          dataIndex: 'unmatchedAmount',
          title: '未匹配金额',
          customRender: (text) => {
            return <Balance amount={text} dcflag="c"></Balance>
          }
        },
        {
          dataIndex: 'oppAccountNo',
          title: '付款账号',
          customRender: (text, record) => {
            return <WalletInfo accountName={record.oppAccountName}
                               accountNo={text}
                               showAccountNo={false}
                               showAccountName={true}
                               isWallet={false}
                               showBalance={false}
                               showBank={false}

            ></WalletInfo>
          }
        },
      ],


      licenseBusiURL: urlConfig.busiURL + "/base/ocr/recognizeInvoice",
      headers: {
        token,
      },
      selectedShop: {}, // 存储选中店铺的完整信息
      form: {
        mchNo: null,
        shopNo: null,
        invoiceCode: '',
        invoiceNumber: '',
        invoiceDate: '',
        sellerName: '',
        purchaserAnkAccountInfo: '',
        totalAmount: '',
        matchedRefundAmount: '',
        shopInfo: {
          refundAccountNo: '',
          refundAccountName: '',
          collAccountNo: '',
          collAccountName: '',
        },
        keys: [], // 选中的流水项
        date: [],

      },
      rules: {
        mchNo: [{required: true, message: '请选择电商', trigger: 'change'}],
        shopNo: [{required: true, message: '请选择店铺', trigger: 'change'}]
      },
      mchNolist: [],
      shoplist: [],
      labelCol: {span: 11},
      wrapperCol: {span: 4},
      busiDict };
  },
  computed: {},
  methods: {

    async getMchList() {
      const res = await this.api.mchshopsApi({pageNo: 1, pageSize: 10000, mchType: 1, goodsType: '50'});
      this.mchNolist = res.data.records.map(x => ({label: x.mchName, value: x.mchNo, fatherName: x.fatherName}));
      // 默认选中第一个，并刷新店铺列表
      if (this.mchNolist.length > 0) {
        this.form.mchNo = this.mchNolist[0].value
        await this.getShopList()
      }
    },


    async getShopList() {
      this.shoplist = [];
      const res = await this.api.managerpageApi({pageNo: 1, pageSize: 10000, mchType: 1, departmentId: this.departmentId, mchNo: this.form.mchNo});
      if (res.code !== 200) {
        this.$message.error(res.info);
        return;
      }
      this.shoplist = res.data.records;
    },

    onChange: function (nextTargetKeys) {
      let tempTotalAmount = 0; // 临时存储选中的总金额
      let validKeys = [...this.targetKeys]; // 有效选中项的列表

      // 检查 nextTargetKeys 是否为空
      if (nextTargetKeys.length === 0) {
        this.targetKeys = [];
        tempTotalAmount = 0;
        console.log("当前有效选项的总金额:", tempTotalAmount);
        return;
      }

      if (nextTargetKeys.length > this.targetKeys.length) {
        // 新增选中项
        const newlySelectedKeys = nextTargetKeys.filter(key => !this.targetKeys.includes(key));

        // 遍历新选中的项并更新金额
        for (let key of newlySelectedKeys) {
          let amount = Number(this.pipelinedataMap.get(key));  // 确保金额是数字类型

          if (isNaN(amount)) {
            console.error(`Invalid amount for key ${key}: ${this.pipelinedataMap.get(key)}`);
            continue;
          }

          if ((tempTotalAmount + amount) > this.form.totalAmount && nextTargetKeys.length > 1) {
            console.log("匹配金额超出总金额，停止选择剩余的项");
            break; // 超出总金额时停止选择
          }

          tempTotalAmount += amount;
          validKeys.push(key); // 将有效的项添加到 validKeys
        }

        // 更新选中的 keys 列表为有效的选项
        this.targetKeys = validKeys;

        // 更新 pipelinedata 中的金额
        const lastSelectedKey = newlySelectedKeys[newlySelectedKeys.length - 1];
        this.pipelinedata = this.pipelinedata.map(item => {
          if (item.key === lastSelectedKey) {
            if (tempTotalAmount > this.form.totalAmount) {
              console.log("匹配金额超出总金额，将剩余金额分配给最后一个选项");
              const excessAmount = (tempTotalAmount - this.form.totalAmount).toFixed(2);
              item.unmatchedAmount = (item.unmatchedAmount - excessAmount).toFixed(2);
            }
            // 更新 pipelinedataMap
            this.pipelinedataMap.set(lastSelectedKey, item.unmatchedAmount);
          }
          return item;
        });

      } else {
        // 取消选中
        console.log("取消选中");

        const removedKeys = this.targetKeys.filter(key => !nextTargetKeys.includes(key));

        validKeys = nextTargetKeys;
        // 恢复被移除的键的原始金额
        removedKeys.forEach(key => {
          const originalAmount = Number(this.originalPipelinedataMap.get(key)) || 0;  // 确保金额为数字
          this.pipelinedataMap.set(key, originalAmount);
        });

        // 重新计算选中项的总金额
        tempTotalAmount = nextTargetKeys.reduce((total, key) => {
          const amount = Number(this.pipelinedataMap.get(key)) || 0;  // 确保金额为数字
          return total + amount;
        }, 0);
      }

      console.log("当前有效选项的总金额:", tempTotalAmount);
      this.targetKeys = validKeys;
    },

    getRowSelection({disabled, selectedKeys, itemSelectAll, itemSelect}) {
      return {
        getCheckboxProps: item => ({props: {disabled: disabled || item.disabled}}),
        onSelectAll(selected, selectedRows) {
          const treeSelectedKeys = selectedRows.filter(item => !item.disabled).map(({key}) => key);
          const diffKeys = selected ? difference(treeSelectedKeys, selectedKeys) : difference(selectedKeys, treeSelectedKeys);
          itemSelectAll(diffKeys, selected);
        },
        onSelect({key}, selected) {
          itemSelect(key, selected);
        },
        selectedRowKeys: selectedKeys,
      };
    },


    async getDetails() {
      const res = await this.api.getDetails(this.$route.query.id);
      if (res.code == 200) {
        this.form = res.data;
        return;
      }
      this.$message.error(res.info);

    },

    async fetchTransactionData() {
      try {
        // 调用 API 获取未匹配和已匹配的流水数据
        let unmatchedRes = await this.api.mchShopInvoiceUnmatchedTransQueryApi(this.$route.query.id)

        if (unmatchedRes.code !== 200) {
          this.$message.error(unmatchedRes.info);
          return;
        }

        // 处理未匹配的数据
        this.pipelinedata = unmatchedRes.data
            .filter(item => item.matchStatus !== '1')
            .map(record => ({
              ...record,
              key: record.seqNo,// 使用 seqNo 作为 key
              unmatchedAmount: (record.transAmount - record.matchAmount).toFixed(2), // 处理部分匹配金额问题
            }));

        // 构建映射，键为 seqNo 或 depositSerialNo，值为 unmatchedAmount
        this.pipelinedataMap = this.pipelinedata.reduce((map, item) => {
          const key = item.seqNo || item.depositSerialNo;
          const value = item.unmatchedAmount;
          map.set(key, value);
          return map;
        }, new Map());

        this.originalPipelinedataMap = this.pipelinedata.reduce((map, item) => {
          const key = item.seqNo || item.depositSerialNo;
          const value = item.unmatchedAmount;
          map.set(key, value);
          return map;
        }, new Map());


      } catch (error) {
        this.$message.error('获取交易数据失败');
      }
    },


    // 普通发票处理
    normalInvoice: async function () {
      try {
        // 初始化数据数组
        let data = [];

        // 验证 this.pipelinedataMap 是否存在且为 Map 类型
        if (!this.pipelinedataMap || !(this.pipelinedataMap instanceof Map)) {
          throw new Error('pipelinedataMa 移除');
        }

        // 遍历 targetKeys 并构建 data 数组
        this.targetKeys.forEach(key => {
          const matchAmount = this.pipelinedataMap.get(key);
          if (matchAmount === undefined) {
            throw new Error(`Key ${key} 不存在`);
          }
          data.push({depositSerialNo: key, matchAmount: matchAmount});
        });

        console.log(data);

        // 构建参数对象
        const param = {
          id: this.$route.query.id,
          data: data
        };

        console.log(param);

        // 发送 API 请求
        const res = await this.api.mchShopInvoiceMatchApi(param);

        // 处理响应结果
        if (res.code !== 200) {
          this.$message.error(res.info);
          return;
        }

        // 匹配成功，显示成功消息并跳转到发票页面
        this.$message.success(res.info);
        this.$router.push({path: '/Mch/MchShopInvoice', query: this.$route.query});

      } catch (error) {
        // 记录详细的错误信息
        this.$message.error('发票匹配失败');
      }
    },


    cancelInvoice() {
      this.$router.push({path: '/Mch/MchShopInvoice', query: this.$route.query});
    },

    onShopChange(newShopNo) {
      // 当选择器的值变化时，更新selectedShop
      const matchedShop = this.shoplist.find(item => item.shopNo === newShopNo);
      if (matchedShop) {
        this.selectedShop = matchedShop;
      } else {
        this.selectedShop = {}; // 清空或设置默认值
      }

    },

  },
  created() {
    this.getMchList();
    this.getDetails();
    this.fetchTransactionData();

  }
};
</script>

<style scoped>
.tablebox {
  width: 60%;
  height: 800px;
  border: 1px solid #ccc;
  display: block;
  margin: 10px auto;
}

.button-col a-button {
  margin: 5px 0;
}

.form-container {
  padding: 20px;
}

.top-title {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
}

.formbox {
  width: 30%;
  height: 240px;
  margin-bottom: 20px;
  display: block;
  margin: 0 auto;
}

.row-gap {
  margin-bottom: 16px;
}

.col-span {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 10px;
}

.buttons {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}


a-input {
  width: 100%;
}
</style>
