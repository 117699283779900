import { render, staticRenderFns } from "./OrderStatus.vue?vue&type=template&id=a6a910bc&scoped=true"
import script from "./OrderStatus.vue?vue&type=script&lang=js"
export * from "./OrderStatus.vue?vue&type=script&lang=js"
import style0 from "./OrderStatus.vue?vue&type=style&index=0&id=a6a910bc&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a6a910bc",
  null
  
)

export default component.exports