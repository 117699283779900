<template>
  <div>
    <!-- 电商提现管理page页面 -->
    <a-card title="提现记录" style="margin: 20px">
      <!-- 筛选区域 -->
      <div class="selectBox">
        <div class="item">
          <span>所属机构</span>
          <DepartmentChoose :department-code.sync="paramsData.departmentCode" />
        </div>
        <div class="item">
          <span>电商编号</span>
          <a-input v-model.trim="paramsData.mchNo" placeholder="请输入电商编号" />
        </div>
        <div class="item">
          <span>电商名称</span>
          <a-input v-model.trim="paramsData.mchName" placeholder="请输入电商名称" />
        </div>
        <!-- 店铺名称 -->
        <div class="item">
          <span>店铺名称</span>
          <a-input v-model.trim="paramsData.shopName" placeholder="请输入店铺名称" />
        </div>
        <!-- 提现方式 -->
        <!--        <div class="item">-->
        <!--          <span>提现方式</span>-->
        <!--          <a-select v-model="paramsData.paymentMethod" placeholder="请选择提现方式">-->
        <!--            <a-select-option value=""> 全部 </a-select-option>-->
        <!--            <a-select-option value="1">小店余额</a-select-option>-->
        <!--            <a-select-option value="2"> 微信 </a-select-option>-->
        <!--            <a-select-option value="3"> 支付宝 </a-select-option>-->
        <!--            <a-select-option value="4"> 安心钱包 </a-select-option>-->
        <!--          </a-select>-->
        <!--        </div>-->
        <!-- 申请状态-->
        <div class="item">
          <span>申请状态</span>
          <a-select v-model="paramsData.status" placeholder="请选择申请状态">
            <a-select-option value=""> 全部 </a-select-option>
            <a-select-option value="0"> <a-tag color="grey">申请失败 </a-tag></a-select-option>
            <a-select-option value="1"> <a-tag color="blue">待处理 </a-tag></a-select-option>
            <a-select-option value="2"> <a-tag color="green">已批准</a-tag></a-select-option>
            <a-select-option value="3"> <a-tag color="red">已拒绝 </a-tag></a-select-option>
          </a-select>
        </div>

        <div class="item">
          <a-button type="primary" @click="handleQuery">查询</a-button>
          <a-button @click="handleReset">重置</a-button>
        </div>

      </div>

      <div class="Toleadinto">

        <a-button class="inquire" @click="derive" type="primary" v-if="$authority('withdrawManuallyExport')">订单导出</a-button>
        <a-button @click="withdrawManually" type="primary" v-if="$authority('withdrawManually')">再次申请提现</a-button>
      </div>


      <!-- 表格部分 -->
      <div class="tableBox">
        <!-- 表格 -->
        <div class="tableBody">
          <a-table size="small" :row-selection="{
            selectedRowKeys: selectedRowKeys,
            onChange: onSelectChange,
          }" :columns="columns" :customRow="clickrow" :data-source="data" :pagination="false"
            :rowKey="(record, index) => `${record.id}`">
          </a-table>
        </div>
      </div>
      <!-- 分页部分 -->
      <div class="paginationBox">
        <a-button class="Homepage" :disabled="Number(paramsData.pageNo) == 1" @click="Homepage">
          首页
        </a-button> <a-pagination v-model.trim="paramsData.pageNo" :page-size-options="pageSizeOptions" :total="total"
          show-size-changer :page-size="paramsData.pageSize" @change="handleChangePage" @showSizeChange="onShowSizeChange"
          :item-render="itemRender">
        </a-pagination>
        <a-button @click="Trailingpage"
          :disabled="paramsData.pageNo == Math.ceil(Number(total) / Number(paramsData.pageSize))" class="Trailingpage">
          尾页
        </a-button>
      </div>
      <!-- 删除弹框 -->
      <a-modal title="提示" :visible="isVisibleDelDialog" @cancel="isVisibleDelDialog = false" :centered="true"
        :body-style="{ textAlign: 'center' }">
        <template slot="footer">
          <a-button type="primary" @click="handleOk">确认</a-button>
          <a-button @click="isVisibleDelDialog = false">取消</a-button>
        </template>
        <p>是否确认删除所勾选数据？删除后数据将不可恢复</p>
      </a-modal>
    </a-card>
  </div>
</template>
<script>
import {message} from "ant-design-vue";
import {BizDict, BizDictCascade} from "@/utils/bizDict/kvMap";
import Balance from "@/view/components/Balance.vue"
import DepartmentChoose from "@/view/components/DepartmentChoose.vue";

const data = [];
export default {
  components: {DepartmentChoose, Balance },
  name: "MchWithdrawManage",
  data() {
    const columns = [
      {
        title: "No",
        customRender: (text, record, index) =>
          `${Number(this.paramsData.pageNo - 1) *
          Number(this.paramsData.pageSize) +
          Number(index + 1)
          }`,
        align: "center",
        width: 80,
      },
      {
        title: "电商编号",
        dataIndex: "mchNo",
        align: "center",
        width: 90,
        ellipsis: true,
      },
      {
        title: "电商",
        dataIndex: "mchName",
        align: "center",
        width: 240,
      },
      {
        title: "店铺",
        dataIndex: "shopName",
        align: "center",
        width: 200,
      },
      {
        title: "户名",
        dataIndex: "accountHolder",
        align: "center",
        width: 200,
      },
      {
        title: "账号",
        dataIndex: "accountNumber",
        align: "center",
        width: 200,
      },
      {
        title: "申请金额",
        dataIndex: "amount",
        align: "center",
        width: 180,
        customRender: (text) => {
          return <Balance amount={text / 100}></Balance>
        }
      },
      {
        title: "申请时间",
        dataIndex: "requestTime",
        align: "center",
        width: 180,
      },
      {
        title: "申请状态",
        dataIndex: "status",
        align: "center",
        width: 80,
        customRender: (text, row) => {
          let statusText = { "0": "申请失败", "1": "待处理", "2": "已批准", "3": "已拒绝" }[text] || ""
          let statusColor = { "0": "grey", "1": "blue", "2": "green", "3": "red" }[text] || "yellow"
          return <a-tooltip title={row.applyFailReason}><a-tag color={statusColor}>{statusText}</a-tag></a-tooltip>
        }
      },
      {
        title: "失败原因",
        dataIndex: "applyFailReason",
        align: "center",
        ellipsis: true,
      },
    ];
    return {
      // 机构
      treeData: [],
      departmentData: {},
      data,
      columns,
      selectedRowKeys: [],
      pageSizeOptions: ["10", "20", "30", "40", "50"],
      total: 0,
      // 筛选参数
      departmentId: undefined,
      paramsData: {
        departmentCode: undefined,
        // 当前页，
        pageNo: 1,
        // 一页多少条
        pageSize: 10,
        // 电商编号
        mchNo: "",
        // 电商名称
        mchName: "",
        // 店铺名称
        shopName: '',
        // 提现方式
        paymentMethod: ''
      },
      // 贷款产品数组
      loanProductList: [],
      // 选中的客户数组
      selectCustData: [],
      isVisibleDelDialog: false
    };
  },
  created() {
    this.busiDict = { ...BizDict, ...BizDictCascade };
    // 获取表格
    this.departmentId = sessionStorage.getItem("companyId");
    this.paramsData.departmentCode = sessionStorage.getItem("companyCode");
    this.getTableData();
  },
  methods: {
    // 获取表格数据
    async getTableData() {
      const res = await this.api.getMchWithDrawalApi(
        // pageNo: this.paramsData.pageNo,
        // pageSize: this.paramsData.pageSize,
        this.paramsData
      );
      if (res.code == 200) {
        this.data = res.data.records;
        this.paramsData.pageNo = res.data.current;
        this.paramsData.pageSize = res.data.size;
        this.total = res.data.total;
      } else {
        return message.error(res.info);
      }
    },

    // 查询
    handleQuery() {
      this.paramsData.pageNo = 1;
      this.getTableData();
    },
    // 重置
    handleReset() {
      this.paramsData = {
        departmentCode: undefined,
        // 当前页，
        pageNo: 1,
        // 一页多少条
        pageSize: 10,
        // 电商编号
        mchNo: "",
        // 电商名称
        mchName: "",
        // 客户名称
        custname: "",
        // 回款周期(订单类型)
        orderType: "",
        // 贷款产品编号
        productId: undefined,
        // 客户类型
        custtypecd: "",
        // 电商类型
        mchType: "",
        // 产品类型
        goodsType: "",
      };
      this.getTableData();
    },
    // 操作事件
    handleBtn(handlleType) {
      if (handlleType == 'edit' || handlleType == 'view') {
        if (this.selectCustData.length != 1) {
          return message.warning("只能选择一个客户!");
        } else {
          this.$router.push({
            name: "AddMchManage",
            query: {
              id: this.selectCustData[0].id,
              type: handlleType == 'edit' ? 'edit' : 'view',
            },
          });
        }
      } else if (handlleType == 'del') {
        // 删除操作
        if (this.selectCustData.length === 0) {
          return message.warning("请选择客户!");
        } else {
          this.isVisibleDelDialog = true;
        }
      }

    },
    // 确认删除
    async handleOk() {
      // console.log(this.selectCustData, 'selectCustData');
      let idArr = []
      this.selectCustData.forEach(item => {
        idArr.push(item.id)
      })
      let res = await this.api.delMchManageDataApi(idArr)
      if (res.code == 200) {
        this.$message.success(res.info);
      }
      this.isVisibleDelDialog = false;
      this.getTableData()
    },
    // 选中表格的事件函数
    onSelectChange(selectedRowKeys, row) {
      // console.log(selectedRowKeys);
      this.selectedRowKeys = selectedRowKeys;
      this.selectCustData = row;

    },
    clickrow(record) {
      return {
        on: {
          click: () => {
            let id = record.id.toString()
            this.selectedRowKeys = [id]
            this.selectCustData = [record]
          }
        }
      }
    },
    async withdrawManually() {
      if (this.selectCustData.length == 0) {
        this.$message.info("请先选中一条记录");
        return
      }
      if (this.selectCustData[0].status == 2) {
        this.$message.info("该店提现成功，请勿重复提交");
        return
      }
      let res = await this.api.getMchWithDrawalManually(this.selectCustData[0].shopNo)

      if (!res.code == 200) {
        this.$message.error(res.info);
      } else {
        await this.getTableData();
      }
    },

    async derive() {

      const res = await this.api.withdrawManuallyExport(
          this.paramsData
      )

      this.$uploadfile.ExcelDownload('提现记录', res)
    },
    // 改变当前页的事件函数
    // 改变pagesize的事件函数
    onShowSizeChange(current, pageSize) {
      // console.log(current);
      // console.log(pageSize);
      this.paramsData.pageNo = current;
      this.paramsData.pageSize = pageSize;
      this.selectedRowKeys = [];
      this.getTableData();
    },
    // 改变当前页的事件
    handleChangePage(page) {
      this.paramsData.pageNo = page;
      this.selectedRowKeys = [];
      this.getTableData();
    },
    // 首页
    Homepage() {
      if (this.total == 0) {
        return
      }
      this.paramsData.pageNo = 1,
        this.getTableData()
    },
    // 尾页
    Trailingpage() {
      if (this.total == 0) {
        return
      }
      this.paramsData.pageNo = Math.ceil(Number(this.total) / Number(this.paramsData.pageSize))
      this.getTableData()
    },
    // 修改上一页和下一页为文字链接
    itemRender(current, type, originalElement) {
      if (type === "prev") {
        return <a>上一页</a>;
      } else if (type === "next") {
        return <a>下一页</a>;
      }
      return originalElement;
    },
  },
};
</script>
<style lang="less" scoped>
/deep/.ant-card-body {
  padding: 24px 34px;
}

.selectBox {
  display: flex;
  flex-wrap: wrap;

  /deep/ .item {
    margin-bottom: 20px;
    margin-right: 20px;

    .ant-input {
      width: 200px;
      margin-left: 20px;
    }

    .ant-select {
      width: 200px;
      margin-left: 20px;
    }
  }
}

.tableBox {
  margin-bottom: 20px;

  .handleBtn {
    margin-bottom: 20px;
  }
}

.Toleadinto {

  // padding-left: 30px;
  // margin-bottom: 20px;
  button {
    margin-bottom: 20px;
  }
}
/deep/.paginationBox {
  // float: right;
  display: flex;
  margin-top: 20px;
  justify-content: flex-end;

  // .ant-pagination-options {
  //   float: left;
  // }
  .ant-pagination-prev,
  .ant-pagination-next,
  .ant-pagination-jump-prev,
  .ant-pagination-jump-next {
    border: 1px solid #d9d9d9;
    width: 60px;
  }
}
</style>
