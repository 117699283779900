<template>
  <div>
  <a-cascader
      :disabled="!appNo || !channelNo"
      :options="options"
      :load-data="loadData"
      placeholder="请选择所属地区"
      :change-on-select="true"
      @change="onChange"
      v-model="value"
      ref="cascade"
      @popupVisibleChange="popupVisibleChange"
  >
  </a-cascader>
    <a-alert  v-if="value?.length<5 && value?.length>0" :message="'请选择5级地址,当前选中'+value?.length+'级'" show-icon type="error" />
  </div>
</template>

<script>
// 导入 fiveCascadeQuery API 方法

export default {
  name: 'RegionChooseDialog', // 组件名称
  props: {
    channelNo: {
      type: String,
      required: true,
    },
    appNo: {
      type: String,
      required: true,
    },
    purifiedAddr: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      value: [],
      // options 用于存储级联选择器的选项
      options: [],
      purifiedDistrict: {}
    };
  },
  created() {
  },
  methods: {
    popupVisibleChange(visible){
      if(visible){
        this.loadInitialOptions()
      }
    },
    // 加载初始选项方法
    async loadInitialOptions() {
      if (this.options.length > 0) {
        return;
      }
      try {
        // 调用 API 获取初始选项数据
        const response = await this.api.fiveCascadeQuery({
          channelNo: this.channelNo,
          appNo: this.appNo,
          zoningCode: '',
        });

        // 将返回的数据格式化为级联选择器需要的格式
        this.options = response.data.map(item => ({
          value: item.zoningCode,
          label: item.zoningName,
          isLeaf: false, // 初始选项不是叶子节点
        }));

      } catch (error) {
        console.error('Error loading initial options:', error);
      }
    },
    // 根据传入的详细地址，选中默认区域
    async selectDefault(address) {
      await this.loadInitialOptions()
      if (address?.trim().length>0) {
        try {
          let res = await this.api.addressNormalization(address)
          if (res.code === 200) {
            this.purifiedDistrict = res.data
            let selectedOptions = []
            let prov = this.options.find(item => item.label === this.purifiedDistrict.prov)
            prov && selectedOptions.push(prov)
            await this.loadData(selectedOptions)

            let city = prov.children.find(item => item.label === this.purifiedDistrict.city)
            city && selectedOptions.push(city)
            await this.loadData(selectedOptions)

            let district = city.children.find(item => item.label === this.purifiedDistrict.district)
            district && selectedOptions.push(district)
            await this.loadData(selectedOptions)

            let town = district.children.find(item => item.label === this.purifiedDistrict.town)
            town && selectedOptions.push(town)
            await this.loadData(selectedOptions)

            let road = town.children.find(item => item.label === this.purifiedDistrict.road)
            road && selectedOptions.push(road)

            this.value = selectedOptions.map(item => item.value) ?? []
            this.onChange(this.value, selectedOptions)
          }
        } catch (e) {
          console.error(e)
        }
      }
    },
    // 处理选项变化的方法
    onChange(value, selectedOptions) {
      if(!selectedOptions || selectedOptions.length===0){
        this.$emit('change', undefined);
        return
      }
      // 获取选中选项的最后一级的 zoningCode
      const zoningCode = selectedOptions[selectedOptions?.length - 1].value;
      // 通过事件将选中的 zoningCode 传递给父组件
      this.$emit('change', zoningCode);
    },
    // 动态加载选项的方法
    async loadData(selectedOptions) {
      // 获取当前选中的选项
      const targetOption = selectedOptions[selectedOptions.length - 1];
      targetOption.loading = true; // 设置加载状态

      try {
        // 调用 API 获取动态加载的子选项
        const response = await this.api.fiveCascadeQuery({
          channelNo: this.channelNo,
          appNo: this.appNo,
          zoningCode: targetOption.value,
        });
        targetOption.loading = false; // 取消加载状态
        // 将返回的数据格式化为级联选择器需要的格式
        targetOption.children = response.data.map(item => ({
          label: item.zoningName,
          value: item.zoningCode,
          // 如果当前选项是第五级（假设有五级），则设置为叶子节点
          isLeaf: selectedOptions.length === 4,
        }));
        // 更新选项数据
        this.options = [...this.options];
      } catch (error) {
        console.error('Error loading data:', error);
        targetOption.loading = false; // 取消加载状态
      }
    },
  },
};
</script>

<style scoped>
/* 添加必要的样式 */
.red {
  color: red;
}
</style>
