<template>
  <div>
    <a-card style="margin:20px">
      <template #title>
        <div class="top-title">
          <div class="left">
            <span>清分协议管理</span>
            <a-icon type="right"/>
            <span>清分协议详情</span>
          </div>

        </div>
      </template>
      <template slot="extra">
        <a-button @click="toBack">返回</a-button>
      </template>

      <div class="Accountview">
        <!-- 用户信息区域 -->

        <div class="userinfo">
          <div class="userinfo-title">
            {{ this.settlementAccountInfo.accountName }}（ {{ this.settlementAccountInfo.accountNo }}）
          </div>
          <a-menu  mode="horizontal">
            <!-- 第一个 <a-menu-item> -->
            <a-menu-item key="0" @click="clickFirstItem()">
              <div style="display: flex;">

                <a-card style=" width: 350px; height: 220px;border-radius: 8px;padding: 0 8px;"
                         >
                  <div class="balance-box">
                    <div class="box-left">
                      类型：
                    </div>
                    <span>待清算户1</span>
                  </div>

                  <div class="balance-box">
                    <div class="box-left">
                      户名：
                    </div>
                    <span>{{ this.settlementAccountInfo.accountNameSettling1 }}</span>
                  </div>

                  <div class="balance-box">
                    <div class="box-left">
                      账户：
                    </div>
                    <span>{{ this.settlementAccountInfo.accountNoSettling1 }}</span>
                  </div>

                  <div class="balance-box">
                    <div class="box-left">
                      余额:
                    </div>
                    <Balance :amount="this.settlementAccountInfo.settling1Balance"
                             style="font-weight: bold; "></Balance>

                  </div>
                </a-card>
              </div>
            </a-menu-item>

            <!-- 第二个 <a-menu-item> -->
            <a-menu-item key="1" @click="clickSecondItem()">
              <div style="display: flex;">
                <a-card style=" width: 350px; height: 220px;border-radius: 8px;padding: 0 8px;"
                        >
                  <div class="balance-box">
                    <div class="box-left">
                      类型：
                    </div>
                    <span>待清算户2</span>
                  </div>

                  <div class="balance-box">
                    <div class="box-left">
                      户名：
                    </div>
                    <span>{{ this.settlementAccountInfo.accountNameSettling2 }}</span>
                  </div>

                  <div class="balance-box">
                    <div class="box-left">
                      账户：
                    </div>
                    <span>{{ this.settlementAccountInfo.accountNoSettling2 }}</span>
                  </div>

                  <div class="balance-box">
                    <div class="box-left">
                      余额:
                    </div>
                    <Balance :amount="this.settlementAccountInfo.settling2Balance"
                             style="font-weight: bold; "></Balance>

                  </div>
                </a-card>
              </div>
            </a-menu-item>
          </a-menu>
        </div>

        <div class="tablebox">

          <div class="searchbox">
            <div class="lefttime">
              <span>交易时间:</span>
              <a-range-picker v-model.trim="searchtime" valueFormat="YYYY-MM-DD" @change="onChange"/>
              <a-radio-group v-model="datater" @change="dataonChange">
                <a-radio :value="1">
                  今天
                </a-radio>
                <a-radio :value="2">
                  昨天
                </a-radio>
                <a-radio :value="3">
                  近七天
                </a-radio>
                <a-radio :value="4">
                  当月
                  <a-input v-if="value === 4" :style="{ width: 100, marginLeft: 10 }"/>
                </a-radio>
                <a-radio :value="5">
                  上月
                  <a-input v-if="value === 4" :style="{ width: 100, marginLeft: 10 }"/>
                </a-radio>
              </a-radio-group>
            </div>

            <div class="right">
              <a-button type="primary" @click="search">
                查询
              </a-button>
              <a-button @click="reset">重置</a-button>
            </div>
          </div>

          <div class="tableBody">
            <a-table :rowKey="(record, index) => `${record.id}`" :columns="columns" :data-source="data" size="small"
                     :scroll="{ x: 1800, y: 500 }"
                     :row-selection="{ selectedRowKeys: selectedRowKeys, hideDefaultSelections: true, onChange: onSelectChange, }"
                     :pagination="false">
            </a-table>
          </div>
        </div>

      </div>

    </a-card>

  </div>
</template>
<script>
import dayjs from 'dayjs'
import Balance from '@/view/components/Balance.vue'

// import moment from "moment"

export default {
  components: {
    Balance
  },
  data() {
    return {
      value: '',
      selectedKey: '0',
      userinfo: {},
      searchtime: [],
      selectedRowKeys: [],
      selectedRows: [],
      show: true,
      datater: "",// 日期
      form: {
        page: 1,
        channel_no: "", // 渠道编号
        app_no: '',//	应用编号
        account_no: "",// 账号
        start_date: '',
        end_date: '',
      },
      columns: [
        {
          title: 'No',
          dataIndex: 'No',
          customRender: (text, record, index) => `${index + 1}`,
          align: 'center',
          width: 60,
        },
        {
          title: '交易流水号',
          dataIndex: 'serial_no',
          align: 'center',
          width: 230,
          ellipsis: true,
        },
        {
          title: '记账日期',
          dataIndex: 'trade_date',
          align: 'center',
          width: 180,
        },
        {
          title: '交易金额',
          dataIndex: 'trade_amount',
          align: 'center',
          customRender: (text, row) => {
            let dcflag = ''
            if (['4', '6'].indexOf(row.trans_type) >= 0) dcflag = "d"
            if (['5', '7'].indexOf(row.trans_type) >= 0) dcflag = "c"
            return <Balance amount={text} dcflag={dcflag}></Balance>
          },
          width: 140,
        },
        {
          title: '待清算余额',
          dataIndex: 'settling_balance',
          align: 'center',
          customRender: (text, row) => {
            let dcflag = ''
            if (['4', '6'].indexOf(row.trans_type) >= 0) dcflag = "d"
            if (['5', '7'].indexOf(row.trans_type) >= 0) dcflag = "c"
            return <Balance amount={text} dcflag={dcflag}></Balance>
          },
          width: 140,
        },
        {
          title: '协议编号',
          dataIndex: 'contract_no',
          align: 'center',
          width: 230,
          ellipsis: true,
        },
        {
          title: '协议户名（电商A2）',
          dataIndex: 'account_name',
          align: 'center',
          width: 230,
          ellipsis: true,
        },
        {
          title: '协议账号（电商A2）',
          dataIndex: 'account_no',
          align: 'center',
          width: 230,
          ellipsis: true,
        },
        {
          title: '交易时间',
          dataIndex: 'trade_time',
          align: 'center',
          width: 180,
        },
      ],

      // 表格数据
      data: [],
      total: 0, // 总数

    }
  },

  created() {
    // 获取存储的用户信息
    this.settlementAccountInfo = this.$store.state.settlementAccountInfo[0]
    this.form.app_no = this.settlementAccountInfo.appNo
    this.form.channel_no = this.settlementAccountInfo.channelNo

    this.form.contract_no = this.settlementAccountInfo.contractNo

    this.form.account_no = this.settlementAccountInfo.accountNo
    this.form.account_name = this.settlementAccountInfo.accountName

    this.form.account_no_settling = this.settlementAccountInfo.accountNoSettling1
    this.form.account_name_settling = this.settlementAccountInfo.accountNoSettling1


    const currentTime = dayjs(); // 获取当前日期
    const startOfMonth = dayjs().startOf('month'); // 获取本月月初时间
    this.form.start_date = startOfMonth.format('YYYY-MM-DD')
    this.form.end_date = currentTime/*.add(1, 'day')*/.format('YYYY-MM-DD')
    this.searchtime[0] = this.form.start_date
    this.searchtime[1] = this.form.end_date

    // 获取交易详细
    this.settleBalanceLogQueryApi()
  },
  mounted() {
    sessionStorage.setItem('msgInfo', true)
  },

  computed: {},
  methods: {
    search() {
      this.settleBalanceLogQueryApi()
    },
    // 获取明细数据
    async settleBalanceLogQueryApi() {
      // this.form = {
      //   "channel_no": "1021",
      //   "contract_no": "2024011801",
      //   "account_no": "1021231114000014",
      //   "account_name": "李言言",
      //   "start_date": this.form.start_date,
      //   "end_date": this.form.end_date,
      // }
      const res = await this.api.settleBalanceLogQueryApi(this.form)
      if (res.code !== 200) {
        this.$message.error(res.info);
        return
      }
      this.total = res.data.total
      this.form.page = res.data.current;
      this.data = res.data.reverse()
    },
    //时间选择
    dataonChange($event) {
      //  今天 1 昨天2 近七天 3 当月4 上月5
      this.$set(this.searchtime, 0, dayjs().format('YYYY-MM-DD')); // 替换数组中的某一项
      if ($event.target.value == 1) {
        this.form.start_date = dayjs().format('YYYY-MM-DD')
        this.form.end_date = dayjs().format('YYYY-MM-DD')
      } else if ($event.target.value == 2) {
        this.form.start_date = dayjs().subtract(1, 'day').format('YYYY-MM-DD')
        this.form.end_date = dayjs().subtract(1, 'day').format('YYYY-MM-DD')
      } else if ($event.target.value == 3) {
        this.form.start_date = dayjs().subtract(7, 'day').format('YYYY-MM-DD')
        this.form.end_date = dayjs()/*.add(1, 'day')*/.format('YYYY-MM-DD')
      } else if ($event.target.value == 4) {
        this.form.start_date = dayjs().startOf('month').format('YYYY-MM-DD')
        this.form.end_date = dayjs()/*.add(1, 'day')*/.format('YYYY-MM-DD')
      } else if ($event.target.value == 5) {
        this.form.start_date = dayjs().subtract(1, 'month').startOf('month').format('YYYY-MM-DD')
        this.form.end_date = dayjs(this.form.start_date).endOf('month')/*.add(1, 'day')*/.format('YYYY-MM-DD')
      }
      // 开始时间
      this.$set(this.searchtime, 0, this.form.start_date);
      // 结束时间
      this.$set(this.searchtime, 1, this.form.end_date);
    },


    // 时间选择器
    onChange() {
      this.form.start_date = this.searchtime[0]
      // this.form.end_date = this.searchtime[1]
      this.form.end_date = dayjs(this.searchtime[1])/*.add(1, 'day')*/.format('YYYY-MM-DD')
      this.datater = ''
    },


    //选中时触发的回调
    onSelectChange(selectedRowKeys, selectedRows) {

      this.selectedRowKeys = selectedRowKeys
      if (selectedRows.length > 1) {
        //获取选中的数据的key
        var selectNumber = this.selectedRowKeys[1]
        //清空选中的key
        this.selectedRowKeys = []
        //选中的数据的key重新赋值给selectedRowKeys
        this.selectedRowKeys.push(selectNumber)
      }
      //获取选中的数据详细信息
      this.selectedRows = [selectedRows[0]]

      if (this.selectedRows[0])
        this.form.account_no = this.selectedRows[0].account_no
      else
        this.form.account_no = ""

    },



    clickFirstItem() {

      this.selectedKey = '0'
      this.form.account_no_settling = this.settlementAccountInfo.accountNoSettling1
      this.form.account_name_settling = this.settlementAccountInfo.accountNameSettling1
      this.settleBalanceLogQueryApi()
    },

    clickSecondItem() {
      this.selectedKey = '1'
      this.form.account_no_settling = this.settlementAccountInfo.accountNoSettling2
      this.form.account_name_settling = this.settlementAccountInfo.accountNameSettling2
      this.settleBalanceLogQueryApi()
    },

    reset() {
      this.searchtime = []
      this.form.start_date = ''
      this.form.pageNo = 0
      this.form.end_date = ''
    },


    toBack() {
      // 返回列表页
      this.$router.push('/wallet/ClearAgreementManage')
    }
  },
}
</script>
<style>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
</style>
<style lang="less" scoped>
/* 自定义表头样式 */
.ant-table-thead > tr > th {
  background-color: #f2f2f2;
}


.ant-menu-horizontal > .ant-menu-item,
/deep/ .ant-menu-horizontal > .ant-menu-submenu {
  border-bottom: 10px solid transparent;
  transition: color 0
}

.ant-menu-horizontal > .ant-menu-item-selected {
  border-bottom: 2px solid #1890ff;
}

/deep/ .ant-card-body {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.top-title {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .left {
    span {
      &:first-child {
        font-size: 15px;
        font-weight: 700;
      }

      // &:last-child {}
    }
  }

  .right {
    button {
      height: 24px;
    }
  }
}

.Accountview {
  width: 100%;

  .userinfo {
    position: relative;
    width: 100%;
    min-height: 250px;

    .userinfo-title {
      font-size: 16px;
      font-weight: bold;
      color: #262626;
      margin: 0 0 20px 20px;
    }

    .balance-box {
      display: flex;
      align-items: center;
      margin-top: 15px;
      margin-bottom: 18px;

      .box-left {
        margin-right: 35px;
        font-weight: bold;
        color: #262626;
        font-size: 16px;
      }
    }

    .content {
      width: 100%;
      padding: 0 24px;

      .mian {
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 15px;
        width: 100%;
        padding-bottom: 10px;
        min-height: 138px;
        box-shadow: inset 0 0 1px 1px #f2f2f2;

        .toptext {


          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          font-size: 20px;

          .item {
            width: 188px;
            margin-bottom: 25px;

            span {
              &:first-child {
                margin-right: 10px;
              }
            }


            &:nth-child(2) {
              width: 300px;
            }

            &:nth-child(4) {
              width: 200px;
            }
          }
        }

        .Currentbalance {
          display: flex;
          align-items: center;
          font-size: 18px;
          font-weight: 600;
          flex-wrap: wrap;
          justify-content: space-between;
          margin-bottom: 30px;
          padding-right: 60px;

          > div {
            display: flex;
          }

          .Current {
            // width: 300px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            span {
              font-size: 18px;
              font-weight: 700;
              color: #333333;
              margin-right: 10px;

              &:last-child {
                // margin-left: 5px;
                // margin-top: 5px;
                font-weight: 600;
              }
            }
          }

          .usable {
            margin-left: 210px;
            width: 140px;
            display: flex;
            justify-content: space-between;

            span {
              font-size: 18px;
              font-weight: 700;

              &:last-child {
                font-weight: 600;
                margin-top: 5px;
              }
            }
          }

          .Customerdetails {


            button {
              height: 24px;
            }
          }
        }

        .Yesterdaybalance {
          span {
            font-size: 13px;
            color: #aaaaaa;
          }
        }
      }
    }

    .Breadcrab {
      position: absolute;
      bottom: 0px;
      width: 100%;
      height: 35px;
      display: flex;
      padding-left: 24px;
      align-items: center;
      border-bottom: 1px solid #d7d7d7;
      margin-bottom: 10px;
      padding-bottom: 10px;
      justify-content: space-between;
      padding-right: 25px;

      .left {
        span {
          &:first-child {
            font-size: 15px;
            font-weight: 700;
          }

          // &:last-child {}
        }
      }
    }
  }

  .tablebox {
    padding: 0 24px;
    width: 100%;
    height: 100%;
    margin-bottom: 20px;

    .searchbox {
      min-height: 44px;
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      padding-top: 15px;


      .lefttime {
        .ant-calendar-picker {
          margin-left: 20px;
          margin-right: 20px;
          width: 200px;
          border-radius: 6px;
          height: 24px !important;
        }
      }

      .right {
        margin-left: 100px;

        button {
          height: 32px;
        }
      }
    }

    .uploadbox {
      display: flex;
      justify-content: flex-end;

      button {
        height: 28px;
      }

      margin-bottom: 20px;
    }
  }

  .paginationBox {
    display: flex;
    justify-content: flex-end;

    // .ant-pagination-options {
    //   float: left;
    // }

    .ant-pagination-prev,
    .ant-pagination-next,
    .ant-pagination-jump-prev,
    .ant-pagination-jump-next {
      border: 1px solid #d9d9d9;
      width: 60px;
    }
  }

  // 开户弹框
}

.Openanaccount {
  padding: 30px;

  .personage {
    width: 100%;
    height: 40px !important;
    border-radius: 8px;
    background-color: #555555;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    margin-bottom: 30px;
  }

  .firm {
    width: 100%;
    height: 40px;
    border-radius: 8px;
    background-color: #555555;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    margin-bottom: 20px;
  }
}

.ant-modal-footer {
  border: none;
  padding-bottom: 20px;
}

.ant-modal-content {
  width: 420px;

  .Payover {
    /deep/ .ant-input {
      position: relative;
      width: 220px;
      padding-right: 0px !important;
    }

    /deep/ .ant-input-suffix {
      position: absolute;
      width: 40px;
      right: 95px;
      top: 0;
      margin-top: 16px;
      margin-left: 20px;
      height: 32px;
      background-color: #f2f2f2;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.SecuritydepositRelease,
.Confirmedwithdrawal {
  padding-bottom: 20px;

  .userinfo {
    padding: 20px;
    background-color: #f5f8ff;
    min-height: 130px;


    div {
      margin-bottom: 25px;

      &:last-child {
        margin-bottom: 0px;
      }
    }

    .money {
      display: flex;
    }

    .blane {
      margin-bottom: 0px;
    }

    margin-bottom: 30px;
  }

  .Payoverbox {
    display: flex;
    padding-right: 0px;
    // justify-content: space-between;
    align-items: center;
    padding-right: 30px;

    width: 100%;
    padding-left: 25px;
    margin-bottom: 20px;

    .left-title {
      flex: 0 0 100px;
      text-align: left;
    }


  }

  .codebox {
    display: flex;
    display: flex;
    padding-right: 0px;

    align-items: center;
    padding-right: 30px;

    width: 100%;
    padding-left: 25px;

    .left-title {
      flex: 0 0 100px;
      text-align: left;
    }

    input {
      margin-right: 10px;
    }

    .code {
      width: 110px;

      // margin-right: 10px;

    }
  }
}

.Confirmedwithdrawal {
  .Bankcard {
    display: flex;
    display: flex;
    padding-right: 0px;

    align-items: center;
    padding-right: 30px;

    width: 100%;
    padding-left: 25px;
    margin-bottom: 20px;

    .left-title {
      flex: 0 0 100px;
      text-align: left;
    }
  }

  .Bankaccountopening {
    display: flex;
    display: flex;
    padding-right: 0px;
    // justify-content: space-between;
    align-items: center;
    padding-right: 30px;

    width: 100%;
    padding-left: 25px;
    margin-bottom: 20px;

    .left-title {
      flex: 0 0 100px;
      text-align: left;
    }
  }
}
</style>
