<template>
  <div>
    <a-input placeholder="请选择商户" v-model="chosenShowName" disabled v-if="isShowInput">
      <template #suffix>
        <a-button type="primary" @click="startChoose" v-if="$route.query.type !== 'view'">选择</a-button>
      </template>
    </a-input>
    <a-modal v-if="choosing || isShowModel" :visible="true" title="请选择商户" :footer="null" @cancel="onCancel" width="1200">
      <div class="table-header">
        <div class="header-item">
          <a-form layout="inline">
            <a-form-item label="所属机构">
              <DepartmentChoose :departmentCode.sync="departmentCode" :isDisabled="!searchable" @change="onSearch"></DepartmentChoose>
            </a-form-item>
            <a-form-item label="客户类型">
              <a-select v-model="custtypecd" :disabled="!searchable" @change="onSearch">
                <a-select-option value=""> 全部 </a-select-option>
                <a-select-option value="1">个人</a-select-option>
                <a-select-option value="2">企业</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="商户类型">
              <a-select v-model="mchType" :disabled="!searchable" @change="onSearch">
                <a-select-option value=""> 全部 </a-select-option>
                <a-select-option value="0"> <a-tag color="blue">回款商户</a-tag></a-select-option>
                <a-select-option value="1"> <a-tag color="purple">融资商户</a-tag></a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="商户编号">
              <a-input v-model="mchNo" :disabled="!searchable"></a-input>
            </a-form-item>
            <a-form-item label="商户名称">
              <a-input v-model="mchName" :disabled="!searchable"></a-input>
            </a-form-item>
            <a-form-item>
            <a-button type="primary" @click="onSearch">查询</a-button>
            </a-form-item>
            <a-form-item>
            <a-button @click="onReset">重置</a-button>
            </a-form-item>

          </a-form>
        </div>
      </div>
      <div class="table-main">
        <a-table :columns="columns" :data-source="data" :pagination="false" rowKey="mchNo" size="small">
          <span slot="action" slot-scope="text, record">
            <a-button @click="onChoose(record)" type="primary">选择</a-button>
          </span>
        </a-table>
      </div>
      <!-- 分页部分 -->
      <div class="paginationBox">
        <a-pagination v-model="pageNo" :total="total" :page-size="pageSize" @change="onPagesizeChange">
        </a-pagination>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { handleChannelNo } from "@/utils/commonMethods";
import DepartmentChoose from "@/view/components/DepartmentChoose.vue";

export default {
  name: 'MchChooseDialog',
  components: {DepartmentChoose},
  model: {
    prop: 'chosenShowName',
    event: 'change'
  },
  props: {
    scope: {
      required: false,
      type: Object,
    },
    chosenShowName: {
      required: false,
      type: String,
    },
    departmentId: String,
    departmentCode: String,
    custtypecd: { // 	客户类型 1:个人 2:对公
      required: false,
      type: String,
    },
    mchType: { // 商户类型 0、可控商户 1、融资商户
      required: false,
      type: String,
    },
    goodsType: {//商品类型 40 代销类
      required: false,
      type: String,
    },
    custname: { // 客户名称
      required: false,
      type: String
    },
    mchName: { // 商户名称
      required: false,
      type: String
    },
    mchNo: { // 商户编号
      required: false,
      type: String,
    },
    searchable: { // 是否可以自定义搜索
      required: false,
      type: Boolean,
      default: true,
    },
    isShowInput: {
      required: false,
      type: Boolean,
      default: true,
    },
    isShowModel: {
      required: false,
      type: Boolean,
      default: false,
    }
  },
  data() {
    const columns = [
      {
        title: "渠道",
        dataIndex: "channelNo",
        align: "center",
        width: 80,
        customRender: (text) => {
          return handleChannelNo(JSON.parse(this.$session.getItem('coreCompanyList')), text, '1')
        },
      },
      {
        title: "类型",
        dataIndex: "mchType",
        align: "center",
        width: 80,
        customRender: (text) => {
          if (text === "0") {
            return <a-tag color="blue">回款</a-tag>
          } else if (text === "1") {
            return <a-tag color="purple">融资</a-tag>
          } else {
            return "";
          }
        },
      },
      {
        title: "商户编号",
        dataIndex: "mchNo",
        align: "center",
        width: 160,
      },
      {
        title: "商户",
        dataIndex: "mchName",
        align: "center",
        customRender: (text, row) => {
            if(row.mchType === '1' && row.checkStatus !== '1'){
              return <span>{text} <a-tag color="red">未审核</a-tag></span>
            } else {
              return text
            }
        },
      },
      {
        title: "回款商户",
        dataIndex: "fatherName",
        align: "center",
        customRender: (text, row) => {
          if (row.fatherName) {
            return <span><a-tag color="blue">回款</a-tag>{row.fatherName}</span>
          }
          return ""
        }
      },
      {
        title: '操作',
        key: 'action',
        scopedSlots: { customRender: 'action' },
        align: 'center',
      },
    ]
    return {
      choosing: false,
      pageNo: 1,
      pageSize: 10,
      total: 0,
      columns,
      data: [],
    }
  },
  created() {
    if (this.isShowModel) {
      // 加载客户列表
      this.loadTableData()
    }
  },
  methods: {
    startChoose() {
      this.choosing = true
      // 加载机构列表
      this.loadTableData()
    },
    onSearch() {
      this.pageNo = 1
      this.loadTableData()
    },
    // 获取列表信息page
    async loadTableData() {
      let queryParam = {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        // departmentId: this.departmentId,
        departmentCode: this.departmentCode,
        mchType: this.mchType,
        custtypecd: this.custtypecd,
        mchNo: this.mchNo,
        mchName: this.mchName,
        custname: this.custname,
        custnum: this.custnum,
        // 筛选代销类的
        goodsType: this.goodsType
      }
      let res = await this.api.mchshopsApi(queryParam)
      if (res.code !== 200) {
        this.$message.error(res.info)
      } else {
        this.data = res.data.records
        this.total = res.data.total
        this.pageNo = res.data.current
        this.pageSize = res.data.size
      }
    },
    // 点击选择
    onChoose(record) {
      this.choosing = false
      this.$emit("change", record.mchName)
      this.$emit('chooseDone', record, this.scope)
    },
    // 分页事件
    onPagesizeChange(page) {
      this.pageNo = page
      this.loadTableData()
    },
    onReset() {
      this.pageNo = 1
      this.loadTableData()
    },
    //取消事件
    onCancel() {
      this.choosing = false
      this.$emit('chooseCancel')
    },
  },
}
</script>

<style lang="less" scoped>
.table-hide {
  display: none;
}

.table-show {
  display: revert;
}

/deep/ .ant-modal {
  width: 1200px;

  .ant-modal-content {
    width: 100%;
  }
}

/deep/ .table-header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 10px;

  .ant-select-selection--single {
    width: 200px;
  }
}

.paginationBox {
  margin: 10px 0;
  display: flex;
  justify-content: end;
}
.ant-btn {
  min-width: 70px;
  margin: 0;
  right: -12px;
}
</style>
