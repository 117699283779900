import { render, staticRenderFns } from "./import.vue?vue&type=template&id=11ce2fa8&scoped=true"
import script from "./import.vue?vue&type=script&lang=js"
export * from "./import.vue?vue&type=script&lang=js"
import style0 from "./import.vue?vue&type=style&index=0&id=11ce2fa8&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "11ce2fa8",
  null
  
)

export default component.exports