<template>
  <div id="organization">
    <a-card title="机构管理" style="margin:20px">
      <!-- 操作栏 -->
      <div class="action">
        <a-button v-if="$authority('organization_add')" type="primary" @click="addHandle">新增</a-button>
        <a-button v-if="$authority('organization_update')" @click="updateHandle">修改</a-button>
        <a-button v-if="$authority('organization_delete')" type="danger" :disabled="selectedRowKeys.length !== 1"
                  @click="deleteHandle">删除
        </a-button>
      </div>
      <!-- 表格 -->
      <div class="table">
        <a-table :columns="columns" :data-source="tableData" :row-selection="rowSelection" :pagination="false"
                 :customRow="clickrow" :rowKey="(record, index) => `${record.id}`">
          <template slot="parentId" slot-scope="text">
            <span>
              {{ levelPart(text) }}
            </span>
          </template>
          <template slot="note" slot-scope="text">
            <span>
              {{ apiTypeStatus[text] }}
            </span>
          </template>
        </a-table>
      </div>
      <!-- page -->
      <div class="page">
        <a-pagination :current="currentPage" :total="total" show-size-changer show-quick-jumper style="float:right"
                      @change="pageChange" @showSizeChange="sizePageChange" :show-total="total => `共 ${total} 条`"/>
      </div>
      <!-- 模态框 -->
      <div class='modal' v-if="modalBool">
        <view-modal :modalBool="modalBool" :modalTitle="modalTitle" :selectedRows="selectedRows" :dataPart="data"
                    @okModalHandle="okModalHandle" @cancelModalHandle="cancelModalHandle" ref="modalChild"></view-modal>
      </div>

      <!-- 模态框 -->
      <a-modal :title="机构新增" :visible="addmodalBool" centered
               @cancel="addCancel">
        <a-form-model layout="horizontal" :model="addForm"
                      :label-col="{ span: 4 }" :wrapper-col="{ span: 18}">

          <a-form-model-item label="机构名称" prop="name">
            <a-input v-model="addForm.name" placeholder="请输入机构名称"></a-input>
          </a-form-model-item>
          <a-form-model-item label="上级机构" prop="parentId">
            <DepartmentChoose :departmentId.sync="addForm.parentId"></DepartmentChoose>
          </a-form-model-item>
          <a-form-model-item label="备注" prop="note">
            <a-textarea rows=3 v-model="addForm.note" placeholder="请输入备注"></a-textarea>
          </a-form-model-item>

        </a-form-model>
        <template slot="footer">
          <a-button key="back" @click="addCancel">
            取消
          </a-button>
          <a-button key="submit" type="primary" @click="addSubmit">
            提交
          </a-button>
        </template>
      </a-modal>

    </a-card>
  </div>
</template>
<script>

import Modal from "./Modal";
import DepartmentChoose from "@/view/components/DepartmentChoose.vue";

const columns = [
  {
    title: '机构ID',
    dataIndex: 'id',
    align: 'center',
  },
  {
    title: '机构名称',
    dataIndex: 'name',
    align: 'center',
  },
  {
    title: '机构级别',
    dataIndex: 'level',
    align: 'center',
  },
  {
    title: '上级机构',
    dataIndex: 'parentId',
    align: 'center',
    scopedSlots: {customRender: 'parentId'},
  },
  {
    title: '备注',
    dataIndex: 'note',
    align: 'center',
  },
  // {
  //   title: '请求最大量',
  //   dataIndex: 'apiCallTimes',
  //   align: 'center',
  // },
  // {
  //   title: '请求时间段',
  //   dataIndex: 'apiExpireTime',
  //   align: 'center',
  // },
  // {
  //   title: '请求权限类型',
  //   dataIndex: 'apiType',
  //   align: 'center',
  //   scopedSlots: { customRender: 'apiType' },
  // }
];
export default {
  name: 'organizationPage',
  components: {
    DepartmentChoose,
    "view-modal": Modal,
  },
  data() {
    return {
      data: [],
      tableData: [],
      treeData: [],
      currentPage: 1,
      pageSize: 10,
      total: 10,
      columns,
      modalBool: false,
      addmodalBool: false,
      modalTitle: "操作",
      addmodalTitle: "操作",
      selectedRowKeys: [],
      selectedRows: [{}],
      apiTypeStatus: {1: "白名单", 2: "灰名单", 3: "黑名单"},
      addForm: {name: "", parentId: null, note: ""}
    }
  },
  created() {
    this.departmentRequestHandle()
  },
  methods: {
    //页码操作
    pageChange(page, pageSize) {
      const data = this.data
      this.currentPage = page
      this.tableData = data.slice((page - 1) * pageSize, page * pageSize)
    },
    sizePageChange(current, size) {
      const data = this.data
      this.tableData = data.slice((current - 1) * size, current * size)
    },
    //数据增删改
    addHandle() {
      this.selectedRowKeys = []
      this.selectedRows = [{}]
      this.addmodalTitle = "新增数据"
      this.addmodalBool = true
    },
    updateHandle() {
      const sum = this.selectedRowKeys
      if (sum.length !== 1) return this.$message.warn("只能选择一条数据")
      this.modalTitle = "修改数据"
      this.modalBool = true
    },
    deleteHandle() {
      const data = this.selectedRowKeys
      const tableData = this.tableData
      const currentPage = this.currentPage
      this.$confirm({
        // title: '删除数据',
        // content: () => "是否删除此条数据？此过程不可逆！",
        title: '是否删除此条数据？此过程不可逆！',
        onOk: () => {
          this.$axios.apiPost('/user/department/del?id=' + data).then(res => {
            if (res.code !== 200) return this.$message.error(res.info)
            if (tableData.length === data.length && currentPage > 1) {
              this.currentPage = currentPage - 1
            }
            this.departmentRequestHandle()
            this.selectedRowKeys = []
            this.$message.success(res.info)
          })
        },
        onCancel() {
          console.log('Cancel');
        },
        class: 'test',
      });
    },
    addSubmit() {
      if (!this.addForm.name) {
        return this.$message.error("请输入机构名称")
      }
      if (this.addForm.parentId === null) {
        return this.$message.error("请选择上级机构")
      }


      this.$axios.apiPost("/user/department/save", this.addForm).then((res) => {
        if (res.code !== 200) return this.$message.error(res.info)
        this.departmentRequestHandle()
        this.$message.success(res.info)
      })
      this.addmodalBool = false
      this.addForm = {name: "", parentId: null, note: ""}
    },
    addCancel() {
      this.addmodalBool = false
      this.addForm = {name: "", parentId: null, note: ""}
    },

    //Modal操作
    okModalHandle() {
      const id = this.selectedRows[0].id
      this.$refs['modalChild'].form.validateFields((err, values) => {
        console.log(values, 'values')
        if (!err) {
          if (!id) {
            this.$axios.apiPost("/user/department/save", values).then((res) => {
              if (res.code !== 200) return this.$message.error(res.info)
              this.departmentRequestHandle()
              this.$message.success(res.info)
            })
          } else {
            this.$axios.apiPost("/user/department/save?id=" + id, values).then((res) => {
              if (res.code !== 200) return this.$message.error(res.info)
              this.departmentRequestHandle()
              this.selectedRowKeys = []
              this.$message.success("修改成功")
            })
          }
          this.modalBool = false
          this.addmodalBool = false
        }
      })
    },
    cancelModalHandle() {
      this.modalBool = false
      this.addmodalBool = false
    },
    //上级机构
    levelPart(text) {
      const parentCompany = this.data.filter(e => e.id === text)[0];
      if (parentCompany) {
        return parentCompany.name;
      } else {
        return text;
      }
    },
    departmentRequestHandle() {
      this.$axios.apiGet('/user/department/list').then((res) => {
        if (res.code !== 200) return this.$message.error(res.info);

        const data = res.data;
        let arrTree = [];
        let arrTable = [];

        // 获取层级
        const levels = Array.from(new Set(data.map((item) => item.level)));
        const maxLevel = Math.max(...levels);
        const minLevel = Math.min(...levels);

        // 构造 treeData 和 tableData 的递归函数
        const buildTree = (level, data, arrObj, isTree = true) => {
          data.forEach((item) => {
            if (item.level === level) {
              if (item.parentId) {
                if (arrObj.length) {
                  arrObj.forEach((parentItem) => {
                    recuTree(parentItem, item, isTree);
                  });
                } else {
                  arrObj.push(createNode(item, isTree));
                }
              } else {
                arrObj.push(createNode(item, isTree));
              }
            }
          });
        };

        const recuTree = (parent, item, isTree) => {
          if ((isTree ? parent.key : parent.id) === item.parentId) {
            parent.children.push(createNode(item, isTree));
            return;
          }
          if (parent.children && parent.children.length) {
            parent.children.forEach((child) => recuTree(child, item, isTree));
          }
        };

        // 生成节点的函数
        const createNode = (item, isTree) => {
          return isTree
              ? {title: item.name, value: item.id, key: item.id, children: []}
              : {...item, children: []};
        };

        // 生成 treeData 和 tableData
        for (let level = minLevel; level <= maxLevel; level++) {
          buildTree(level, data, arrTree, true); // 生成 treeData
          buildTree(level, data, arrTable, false); // 生成 tableData
        }

        // 删除 tableData 中无用的 children 属性
        const cleanEmptyChildren = (arr) => {
          arr.forEach((item) => {
            if (item.children.length) {
              cleanEmptyChildren(item.children);
            } else {
              delete item.children;
            }
          });
        };
        cleanEmptyChildren(arrTable);

        // 设置最终的数据
        this.treeData = arrTree;
        this.tableData = arrTable;
        this.companys = data;
        this.data = data;
        this.total = data.length;
      });
    },

    clickrow(record) {
      return {
        on: {
          click: () => {
            let id = record.id.toString()
            // let index = this.selectedRowKeys.findIndex((item) => id == item)
            this.selectedRowKeys = [id]
            this.selectedRows = [record]
            // if (index == -1) {
            //   this.selectedRowKeys.push(id)
            //   this.selectedRows.push(record)
            // } else {
            //
            //   this.selectedRowKeys.splice(index, 1)
            //   this.selectedRows.splice(index, 1)
            // }
          }
        }
      }
    },
  },
  computed: {
    rowSelection() {
      return {
        onChange: (selectedRowKeys, selectedRows) => {
          this.selectedRowKeys = selectedRowKeys
          this.selectedRows = selectedRows
        },
        selectedRowKeys: this.selectedRowKeys
      };
    },
  }
}
</script>
<style lang="less" scoped>
#organization {
  .page {
    margin: 12px 0;
  }

  .table {
    margin-top: 24px;
  }

  .form {
    margin-bottom: 16px;
  }
}
</style>