<template>
  <div>
    <!-- 企业开户 -->
    <a-card style="margin: 20px">
      <template #title>
        <div class="top-title">
          <div class="left">
            <span>账户管理</span> <a-icon type="right" /> <span>企业开户信息查看</span>
          </div>
          <div style="display: flex">
            <div class="right">
              <a-button type="primary" @click="back">返回</a-button>
            </div>
          </div>
        </div>
      </template>
      <!-- 企业开户页面 -->
      <div>
        <template>
          <a-descriptions title=" 企业信息" bordered size="small">
            <a-descriptions-item label="账户户名">
              {{ resData.accountName }}
            </a-descriptions-item>
            <a-descriptions-item label="账户账号">
              {{ resData.accountNo }}
            </a-descriptions-item>
            <a-descriptions-item label="联系电话">
              {{ resData.mobile }}
            </a-descriptions-item>


            <a-descriptions-item label="统一社会信用代码">
              {{ resData.idInfo?.certnum }}
            </a-descriptions-item>

            <a-descriptions-item label="营业执照发放日">
              {{ resData.idInfo?.certeffectdt }}
            </a-descriptions-item>
            <a-descriptions-item label="营业执照到期日">
              {{ resData.idInfo?.certinvaliddt }}
            </a-descriptions-item>


            <!--            <a-descriptions-item label="签发机关" :span="3">-->
            <!--              {{ resData.idInfo?.certissueorgname }}-->
            <!--            </a-descriptions-item>-->


            <a-descriptions-item label="经营地址" :span="3">
              {{ resData.addressInfos?.addrinfo }}
            </a-descriptions-item>

            <a-descriptions-item label="经营范围" :span="3">
              {{ resData.companyInfo?.operbiz }}
            </a-descriptions-item>

            <a-descriptions-item label="行业类别" :span="3">
              {{ belonginduscd }}
            </a-descriptions-item>


            <a-descriptions-item label="企业银行账号信息" :span="3">
              <br/>
              卡号: {{ resData.bankCards[0]?.bankCardNo }}
              <br/>
              开户行: {{ resData.bankCards[0]?.bankName }}

            </a-descriptions-item>


            <a-descriptions-item label="营业执照" :span="3">
              <img style=" width: 200px;height: 122px;"
                   :src="businessLicense.filepath"
                   alt="展示图片"
                   @click="faceCollection=true"
              />
            </a-descriptions-item>


          </a-descriptions>

          <a-descriptions title="法定代表人信息" bordered>
            <a-descriptions-item label="姓名">
              {{ legalPerson?.custname || '' }}
            </a-descriptions-item>

            <a-descriptions-item label="性别">
              {{ legalPersonGenderDesc }}
            </a-descriptions-item>

            <a-descriptions-item label="国籍">
              {{ legalPersonNationality }}
            </a-descriptions-item>

            <a-descriptions-item label="证件号">
              {{ legalPerson?.certnnum || '' }}
            </a-descriptions-item>


            <a-descriptions-item label="证件失效日期">
              {{ legalPerson?.certinvaliddt || '' }}
            </a-descriptions-item>
            <a-descriptions-item label="联系电话">
              {{ legalPerson?.continfo || '' }}
            </a-descriptions-item>
            <a-descriptions-item label="身份证人像面">


              <img style=" width: 200px;height: 122px;"
                   :src="identityCardPortraitFile.filepath"
                   alt="展示图片"
                   @click="faceOfIdentityCard=true"
              />
            </a-descriptions-item>

            <a-descriptions-item label="身份证国徽面">
              <img style=" width: 200px;height: 122px;"
                   :src="nationalIdentityFile.filepath"
                   alt="展示图片"
                   @click="nationalEmblemFaceOfIDCard=true"
              />
            </a-descriptions-item>
          </a-descriptions>

          <a-descriptions title="受益人信息" bordered>

            <a-descriptions-item label="姓名">
              {{ resData.eandpInfos[0]?.custname || '' }}
            </a-descriptions-item>

            <a-descriptions-item label="性别">
              {{ genderDescription }}
            </a-descriptions-item>

            <a-descriptions-item label="国籍">
              {{ nationality }}
            </a-descriptions-item>

            <a-descriptions-item label="证件号">
              {{ resData.eandpInfos[0]?.certnnum || '' }}
            </a-descriptions-item>

            <!--            <a-descriptions-item label="受益人证件生效日期">-->
            <!--              {{ resData.eandpInfos[0]?.certeffectdt || '' }}-->
            <!--            </a-descriptions-item>-->

            <a-descriptions-item label="证件失效日期">
              {{ resData.eandpInfos[0]?.certinvaliddt || '' }}
            </a-descriptions-item>
            <a-descriptions-item label="联系电话">
              {{ resData.eandpInfos[0]?.continfo || '' }}
            </a-descriptions-item>
          </a-descriptions>
        </template>
      </div>
    </a-card>

    <a-modal
        title="身份证人像面"
        :visible.sync="faceOfIdentityCard"
        @cancel="faceOfIdentityCard = false"
        @ok="faceOfIdentityCard = false"
        :width="'60vw'"
        :bodyStyle="{ padding: 0, height: '80vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }"
        centered
    >
      <img
          style="max-width: 100%; max-height: 100%; height: auto; object-fit: contain;"
          @load="onImageLoad"
          :src="identityCardPortraitFile.filepath"
          alt="展示图片"
      />
    </a-modal>
    <a-modal
        title="身份证国徽面"
        :visible.sync="nationalEmblemFaceOfIDCard"
        @cancel="nationalEmblemFaceOfIDCard = false"
        @ok="nationalEmblemFaceOfIDCard = false"
        :width="'60vw'"
        :bodyStyle="{ padding: 0, height: '80vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }"
        centered
    >
      <img
          style="max-width: 100%; max-height: 100%; height: auto; object-fit: contain;"
          @load="onImageLoad"
          :src="nationalIdentityFile.filepath"
          alt="展示图片"
      />
    </a-modal>
    <a-modal
        title="营业执照"
        :visible.sync="faceCollection"
        @cancel="faceCollection = false"
        @ok="faceCollection = false"
        :width="'60vw'"
        :bodyStyle="{ padding: 0, height: '80vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }"
        centered
    >
      <img
          style="max-width: 100%; max-height: 100%; height: auto; object-fit: contain;"
          @load="onImageLoad"
          :src="businessLicense.filepath"
          alt="展示图片"
      />
    </a-modal>

  </div>


</template>


<script>

import {BizDict, BizDictCascade} from "@/utils/bizDict/kvMap";

export default {
  name: "openaccEnterpriseInfo",
  data() {
    return {
      modalWidth: '200px',  // 初始宽度
      modalHeight: '122px',  // 初始高度
      // 民族列表
      ethnicitylist: BizDict.RaceCd,
      // 性别list
      sexlist: BizDict.GndFlg,
      // 行业列表
      industrylist: BizDictCascade.WorkCorpIndusCD,

      //
      legalPerson: {},


      faceOfIdentityCard: false,
      nationalEmblemFaceOfIDCard: false,
      faceCollection: false,
      resData: {
        bankCards: [],
        personInfo: {},
        addressInfos: {},
        idInfo: {},
        eandpInfos: [],
        companyInfo: {},
      },
      businessLicense: {},
      identityCardPortraitFile: {},
      nationalIdentityFile: {},
      creditReportAuth: {},
    };
  },
  components: {},
  computed: {

    legalPersonGenderDesc() {
      const legalPersonGenderCd = this.legalPerson?.gendercd || '';
      const legalPersonGender = BizDict.GndFlg.find(item => item.value === legalPersonGenderCd);
      return legalPersonGender ? legalPersonGender.label : '未知的性别';
    },

    legalPersonNationality() {
      const legalPersonNationalityCd = this.legalPerson?.nationcd || '';
      const legalPersonNationality = BizDict.NtntyCd.find(item => item.value === legalPersonNationalityCd);
      return legalPersonNationality ? legalPersonNationality.label : '未知的国籍';
    },

    genderDescription() {
      const genderCd = this.resData.eandpInfos[0]?.gendercd || '';
      const gender = BizDict.GndFlg.find(item => item.value === genderCd);
      return gender ? gender.label : '未知的性别';
    },

    nationality() {
      const nationalityCd = this.resData.eandpInfos[0]?.nationcd || '';
      const nationality = BizDict.NtntyCd.find(item => item.value === nationalityCd);
      return nationality ? nationality.label : '未知的国籍';
    },

    belonginduscd() {
      const belonginduscdCd = this.resData.companyInfo?.belonginduscd || '';
      const belonginduscd = BizDictCascade.WorkCorpIndusCD2.find(item => item.v === belonginduscdCd);
      return belonginduscd ? belonginduscd.l : '未知的行业';
    }
  },
  async created() {
    this.getAccountDetails()
  },
  mounted() {

  },
  methods: {
    back() {
      //回到上一页
      this.$router.go(-1)
    },

    onImageLoad: function (event) {
      // 获取图片的原始宽高度
      const imgWidth = event.target.naturalWidth;
      const imgHeight = event.target.naturalHeight;

      // 获取屏幕的最大和最小宽高度限制
      const maxWidth = window.innerWidth * 0.7;
      const maxHeight = window.innerHeight * 0.7;

      const minWidth = 300;
      const minHeight = 300;


      // 根据图片的大小和设定的范围限制来调整宽度和高度
      let width = imgWidth;
      let height = imgHeight;

      // 宽度调整
      if (imgWidth < minWidth) {
        width = minWidth;
      } else if (imgWidth > maxWidth) {
        width = maxWidth;
      }

      // 高度调整
      if (imgHeight < minHeight) {
        height = minHeight;
      } else if (imgHeight > maxHeight) {
        height = maxHeight;
      }

      // 将调整后的宽高度赋值给 modalWidth 和 modalHeight
      this.modalWidth = width + 'px';
      this.modalHeight = height + 'px';
    },


    async getAccountDetails() {
      let id = this.$route.query.id;
      let res = await this.api.getAccountDetailsApi(id)
      if (res.code !== 200) {
        this.$message.error(res.info);
        return;
      }
      this.resData = res.data
      this.identityCardPortraitFile = res.data.materials
          .filter(obj => obj.fileType === '01')
          .find(obj => obj.fileType === '01');

      this.nationalIdentityFile = res.data.materials
          .filter(obj => obj.fileType === '02')
          .find(obj => obj.fileType === '02');

      this.businessLicense = res.data.materials
          .filter(obj => obj.fileType === '12')
          .find(obj => obj.fileType === '12');


      this.legalPerson = res.data.eandpInfos
          .filter(obj => obj.islegalperson === '1')
          .find(obj => obj.islegalperson === '1');
    },


  }

};
</script>

<style scoped lang="less">
.top-title {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .left {
    span {
      &:first-child {
        font-size: 15px;
        font-weight: 700;
      }

      // &:last-child {}
    }
  }

  .agreement {
    /deep/.ant-checkbox-wrapper {
      margin-right: 2px;
    }

    margin-right: 100px;
    display: flex;
    align-items: center;

    .item {
      margin-left: 15px;

      span {
        &:hover {
          line-height: 20px;
          color: #40a9ff;
          border-bottom: 1px solid #40a9ff;
        }
      }
    }
  }

  .right {
    button {
      height: 38px;
      border: none;

      &:first-child {
        width: 108px;

        color: #ffff;
      }

      &:last-child {
        width: 108px;
        color: #ffff;
      }
    }
  }
}
img{
  cursor: pointer;
}
</style>