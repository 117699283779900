<template>
  <a-select :disabled="disabled" :show-search="true" placeholder="请选择开户行" v-model.trim="value"
            :filter-option="filterOption" @change="handleChange" allow-clear
            :dropdownMatchSelectWidth="false">
    <a-select-option v-for="item  in options" :title="item.label" :key="item.value" :value="item.value">
      {{ item.label }}
    </a-select-option>
  </a-select>
</template>
<script>
import {BizDict} from "@/utils/bizDict/kvMap";

export default {
  name: "Bank",
  props: {
    name: String,
    // value: String,
    disabled: Boolean,
  },
  data() {
    return {
      value: undefined
    }
  },
  methods: {
    filterOption(input, option) {

      let val = input?.trim().toLowerCase()
      if (val.length < 1) return false;
      try{
        return (option.componentOptions.children[0].text.indexOf(val) >= 0);
      }catch(e){
        console.error(e)
        return false
      }
    },
    handleChange(value, option) {
      this.$emit('changeBank', {value, option})
      // this.$emit('update:name', option.componentOptions.children[0].text)
      // this.$emit('update:value', value)

    }
  },
  computed: {
    options() {
      // 去重
      let uniqueMap = {}
      let uniqueArr = []
      BizDict.BankNo.forEach(item => {
        if (!uniqueMap[item.value]) {
          uniqueMap[item.value] = true
          uniqueArr.push(new Object(item))
        }
      })
      return uniqueArr
    }
  }
}
</script>
<style scoped lang="less"></style>