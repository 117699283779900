<template>
  <div>
    <!-- 企业开户 -->
    <a-card style="margin: 20px">
      <template #title>
        <div class="top-title">
          <div class="left">
            <span>账户管理</span>
            <a-icon type="right"/>
            <span>个人开户信息查看</span>
          </div>
          <div style="display: flex">
            <div class="right">
              <a-button type="primary" @click="back"> 返回</a-button>
            </div>
          </div>
        </div>
      </template>
      <!-- 企业开户页面 -->
      <div>
        <template>
          <a-descriptions title=" 个人开户信息" bordered size="small">
            <a-descriptions-item label="账户户名">
              {{ resData.accountName }}
            </a-descriptions-item>
            <a-descriptions-item label="账户账号">
              {{ resData.accountNo }}
            </a-descriptions-item>
            <a-descriptions-item label="手机号">
              {{ resData.mobile }}
            </a-descriptions-item>


            <a-descriptions-item label="身份证号">
              {{ resData.idInfo?.certnum }}
            </a-descriptions-item>
            <a-descriptions-item label="证件失效日期">
              {{ resData.idInfo?.certinvaliddt }}
            </a-descriptions-item>

            <!--            <a-descriptions-item label="签发机关">-->
            <!--              {{ resData.idInfo.certissueorgname }}-->
            <!--            </a-descriptions-item>-->

            <a-descriptions-item label="性别">
              {{ genderDescription }}
            </a-descriptions-item>

            <a-descriptions-item label="职业" :span="3">
              {{ occupation }}
            </a-descriptions-item>

            <a-descriptions-item label="地址" :span="3">
              {{ resData.addressInfos?.addrinfo }}
            </a-descriptions-item>


            <a-descriptions-item label="身份证人像面" :span="3">
              <img style=" width: 200px;height: 122px;"
                   :src="identityCardPortraitFile.filepath"
                   alt="展示图片"
                   @click="faceOfIdentityCard=true"
              />
            </a-descriptions-item>

            <a-descriptions-item label="身份证国徽面" :span="3">
              <img style=" width: 200px;height: 122px;"
                   :src="nationalIdentityFile.filepath"
                   alt="展示图片"
                   @click="nationalEmblemFaceOfIDCard=true"
              />
            </a-descriptions-item>

            <a-descriptions-item label="人脸采集" :span="3">
              <img style=" width: 200px;height: 122px;"
                   @load="onImageLoad"
                   :src="humanFaceFile.filepath"
                   alt="展示图片"
                   @click="faceCollection=true"
              />
            </a-descriptions-item>

            <a-descriptions-item label="银行卡信息" :span="3">
              <br/>

              卡号: {{ resData.bankCards[0]?.bankCardNo }}
              <br/>
              开户行: {{ resData.bankCards[0]?.bankName }}

            </a-descriptions-item>

          </a-descriptions>
        </template>
      </div>
    </a-card>

    <a-modal
        title="身份证人像面"
        :visible.sync="faceOfIdentityCard"
        @cancel="faceOfIdentityCard = false"
        @ok="faceOfIdentityCard = false"
        :width="'60vw'"
        :bodyStyle="{ padding: 0, height: '80vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }"
        centered
    >
      <img
          style="max-width: 100%; max-height: 100%; height: auto; object-fit: contain;"
          @load="onImageLoad"
          :src="identityCardPortraitFile.filepath"
          alt="展示图片"
      />
    </a-modal>
    <a-modal
        title="身份证国徽面"
        :visible.sync="nationalEmblemFaceOfIDCard"
        @cancel="nationalEmblemFaceOfIDCard = false"
        @ok="nationalEmblemFaceOfIDCard = false"
        :width="'60vw'"
        :bodyStyle="{ padding: 0, height: '80vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }"
        centered
    >
      <img
          style="max-width: 100%; max-height: 100%; height: auto; object-fit: contain;"
          @load="onImageLoad"
          :src="nationalIdentityFile.filepath"
          alt="展示图片"
      />
    </a-modal>
    <a-modal
        title="人脸采集"
        :visible.sync="faceCollection"
        @cancel="faceCollection = false"
        @ok="faceCollection = false"
        :width="'60vw'"
        :bodyStyle="{ padding: 0, height: '80vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }"
        centered
    >
      <img
          style="max-width: 100%; max-height: 100%; height: auto; object-fit: contain;"
          @load="onImageLoad"
          :src="humanFaceFile.filepath"
          alt="展示图片"
      />
    </a-modal>

  </div>


</template>


<script>

import {BizDict, BizDictCascade} from "@/utils/bizDict/kvMap";

export default {
  name: "openAccPersonalInfo",
  data() {
    return {
      modalWidth: 'auto',  // 初始宽度

      faceOfIdentityCard: false,
      nationalEmblemFaceOfIDCard: false,
      faceCollection: false,
      resData: {
        bankCards:[],
        personInfo: {},
        addressInfos: {},
        idInfo: {},
      },
      humanFaceFile: {},
      identityCardPortraitFile: {},
      nationalIdentityFile: {},
    };
  },
  components: {},
  async created() {
    this.getAccountDetails()
  },
  computed: {

    genderDescription() {
      const genderCd = this.resData.personInfo?.gendercd || '';
      const gender = BizDict.GndFlg.find(item => item.value === genderCd);
      return gender ? gender.label : '未知的性别';
    },


    //职业
    occupation() {
      const careercd = this.resData.personInfo?.careercd || '';
      const occupation = BizDictCascade.OcpCd2.find(item => item.c === careercd);
      return occupation ? occupation.l : '未知的职业';
    },


  },

  mounted() {

  },
  methods: {
    back() {
      //回到上一页
      this.$router.go(-1)
    },

    onImageLoad(event) {
      // 获取图片的原始宽度
      const imgWidth = event.target.naturalWidth;
      // 根据图片的宽度设置 Modal 的宽度
      this.modalWidth = imgWidth + 'px';
    },
    async getAccountDetails() {
      let id = this.$route.query.id;
      let res = await this.api.getAccountDetailsApi(id)
      if (res.code !== 200) {
        this.$message.error(res.info);
        return;
      }
      this.resData = res.data
      this.identityCardPortraitFile = res.data.materials
          .filter(obj => obj.fileType === '01')
          .find(obj => obj.fileType === '01');

      this.nationalIdentityFile = res.data.materials
          .filter(obj => obj.fileType === '02')
          .find(obj => obj.fileType === '02');

      this.humanFaceFile = res.data.materials
          .filter(obj => obj.fileType === '04')
          .find(obj => obj.fileType === '04');

    },


  }

};
</script>

<style scoped lang="less">
.top-title {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .left {
    span {
      &:first-child {
        font-size: 15px;
        font-weight: 700;
      }

      // &:last-child {}
    }
  }

  .agreement {
    /deep/.ant-checkbox-wrapper {
      margin-right: 2px;
    }

    margin-right: 100px;
    display: flex;
    align-items: center;

    .item {
      margin-left: 15px;

      span {
        &:hover {
          line-height: 20px;
          color: #40a9ff;
          border-bottom: 1px solid #40a9ff;
        }
      }
    }
  }

  .right {
    button {
      height: 38px;
      border: none;

      &:first-child {
        width: 108px;

        color: #ffff;
      }

      &:last-child {
        width: 108px;
        color: #ffff;
      }
    }
  }
}
img{
  cursor: pointer;
}
</style>